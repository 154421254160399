import Chart from 'chart.js';
import _ from 'lodash';

import i18n from 'src/i18n';
import store from 'src/redux_store';
import { getVideoHls } from 'src/redux_store/video/video_action';
import {
  onVideoSeeked,
  onVideoSeeking,
  refreshTimeline,
  toggleMediaPlay,
} from 'src/redux_store/video/video_slice';
import { toastMessage } from 'src/utils/toast';
import { renderMediaSource } from '../utils/video';
import {
  convertPosPixelToTime,
  convertTimeToSecond,
  getCurrentDateToSeconds,
  hhmmss,
} from './chart_horizontal_bar';

const { t } = i18n;

const notFoundVideoAlert = t('stream.messages.findVideo');
const SECONDS_IN_A_DAY = 86400;
const SECONDS_IN_A_HOUR = 3600;
const REWIND_SECONDS = 10;
const X_SECONDS_NOT_FOUND = 5;
const VIDEO_NOT_FOUND_BG = '#000000'; //| '#041C32'
const CLOSE_DISTANCE = 5000;
const LONG_DISTANCE = 300000;

export class TimelineChart {
  canvas;
  ctx;
  videoNotFoundPos = [];
  rafDebounceRequestId = -1;
  xCoordStartUpdate = 0;
  refreshVideoHlsId = null;
  refreshed = false;
  video = null;
  violationTime = null;
  isClicked = false;

  constructor(canvas, violationTime, chartJsOptions) {
    let ctx = (this.ctx = canvas.getContext('2d'));
    this.violationTime = violationTime;
    if (!ctx) {
      throw new Error(`Could not get CanvasRenderingContext from canvas: ${canvas}`);
    }

    this.chart = window.chart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: [],
        datasets: [],
      },
      options: Object.assign(getChartOptions(), { ...chartJsOptions }),
      plugins: [
        {
          afterRender: () => {
            this.handleViolationPoint();
            this.handleBlurNotFoundVideo();
            this.drawCurrentTime();
            this.handleMedia();
          },
        },
      ],
    });

    canvas.onwheel = (event) => {
      event.preventDefault();
      if (event.deltaMode || !this.videoLinks?.length) return;

      const { chart = {} } = this;
      const pos = Chart.helpers.getRelativePosition(event, chart);

      if (pos.x > this.chartArea.left) {
        const scale = this.chartScales;

        if (event.deltaY) {
          const direction = -event.deltaY / Math.abs(event.deltaY);
          const normal = Math.abs(event.deltaY) / 1000;
          const ease = 1 - (1 - normal) * (1 - normal);

          this.zoom(scale, pos, ease * direction);
        }
      }
    };

    let moved = false;
    canvas.onpointerdown = (downEvent) => {
      if (!downEvent.isPrimary) return;

      const { chart = {} } = this;
      const pos = Chart.helpers.getRelativePosition(downEvent, chart);

      if (pos.x > this.chartArea.left) {
        const scale = this.chartScales;
        const startX = downEvent.clientX;

        const { min, max, width } = scale;
        const xToVal = (max - min) / width;
        moved = false;
        canvas.setPointerCapture(downEvent.pointerId);
        canvas.onpointermove = (moveEvent) => {
          if (!downEvent.isPrimary) return;

          const movedX = startX - moveEvent.clientX;
          const movedValue = movedX * xToVal;
          moved = moved || Math.abs(movedX) > 8;
          this.pan(scale, movedValue, min, max);
        };
      }
    };

    canvas.onpointerup = canvas.onpointercancel = (upEvent) => {
      if (canvas.onpointermove) {
        canvas.onpointermove = null;
        canvas.releasePointerCapture(upEvent.pointerId);
      }
      if (!this.videoLinks?.length) return;
      if (!moved && upEvent.isPrimary) {
        this.click(upEvent);
      }
    };

    this.init(this.chart);
  }

  get chartScales() {
    return this.chart?.scales?.['x-axis-0'];
  }

  get chartArea() {
    return this.chart.chartArea;
  }

  get getState() {
    return store.getState();
  }

  get media() {
    return window?.hls?.media;
  }

  get mediaPlayer() {
    return store.getState().videoSlice?.mediaPlayer;
  }

  get isPlay() {
    return this.mediaPlayer.isPlay;
  }

  get speed() {
    return this.mediaPlayer.speed;
  }

  get videoLinks() {
    return this.mediaPlayer.videoLinks;
  }

  findVideo(dateTime, shift) {
    if (!this.videoLinks.length) return;

    const index = this.videoLinks.findIndex(
      (video) => dateTime >= video.start && dateTime <= video.end,
    );

    return this.videoLinks[index + shift];
  }

  onKeyPressNavigateNext() {
    const media = window?.hls?.media;

    if (!media || !this.isClicked || media.currentTimePos >= SECONDS_IN_A_DAY) return;

    media.currentTime += REWIND_SECONDS;

    if (media.currentTime >= media.duration) {
      const vid = this.findVideo(media.startTime, 1);
      const video = renderMediaSource(vid, media.currentTimePos);
      this.video = video;

      this.setCurrentTimeForVideo(video, media.currentTimePos + REWIND_SECONDS);
    }
  }

  onKeyPressNavigatePrevious() {
    const media = window?.hls?.media;

    if (!media || !this.isClicked || media.currentTimePos <= this.chart.chartArea.left) return;

    media.currentTime =
      media.currentTime - REWIND_SECONDS >= 0 ? media.currentTime - REWIND_SECONDS : 0;

    if (media.currentTime <= 0) {
      const vid = this.findVideo(media?.startTime, -1);
      const video = renderMediaSource(vid, media.currentTimePos);
      this.video = video;

      this.playVideo();
      this.setCurrentTimeForVideo(video, media.currentTimePos - REWIND_SECONDS);
    }
  }

  init(chart) {
    if (_.isEmpty(chart) || !this.videoLinks?.length) return;
    this.videoLinks.forEach((video, index) => {
      let nextVideo = this.videoLinks[index + 1];
      if (!nextVideo) return;
      let xSeconds = getCurrentDateToSeconds(nextVideo.start) - getCurrentDateToSeconds(video.end);
      if (xSeconds > X_SECONDS_NOT_FOUND) {
        this.videoNotFoundPos.push({
          xCoordStart: convertTimeToSecond(video.end, video.ms),
          xCoordEnd: convertTimeToSecond(nextVideo.start, nextVideo.ms),
        });
      }
    });

    console.log('initChart');

    if (this.media) {
      this.media.playbackRate = this.speed;
    }

    // if (this.violationTime && !_.isEmpty(this.violationTime)) {
    //   store.dispatch(toggleMediaPlay({ isPlay: false }));
    // }
    if (!this.media || !this.violationTime || !this.violationTime.vioTime) return;

    let coordVioTime = convertTimeToSecond(new Date(this.violationTime.vioTime));
    let coordAlprTime = convertTimeToSecond(new Date(this.violationTime?.alprTime));
    let coordTime = convertTimeToSecond(new Date(this.violationTime?.time));

    let point = !coordTime
      ? this.compareViolationPoint(coordVioTime, coordAlprTime)
      : this.compareViolationPoint(coordVioTime, coordAlprTime, coordTime);

    let timeArraySplitted = hhmmss(point, 0).split(':');
    let dateTime = convertPosPixelToTime(timeArraySplitted, this.media.startTime, 0);

    this.renderVideoAfterClick(point, dateTime);
    this.playVideo();
  }

  minTwoNumOfViolationPoint(a, b) {
    return a < b ? a : b;
  }

  compareViolationPoint(vioT, alprT, time) {
    if (!alprT) return vioT;
    if (!time) return this.minTwoNumOfViolationPoint(vioT, alprT);
    return this.minTwoNumOfViolationPoint(vioT, this.minTwoNumOfViolationPoint(alprT, time));
  }

  handleViolationPoint() {
    const scale = this.chartScales;
    if (!this.violationTime || !this.chartArea || !this.ctx || !scale) return;

    let coordVioTime = convertTimeToSecond(new Date(this.violationTime?.vioTime));
    let coordAlprTime = convertTimeToSecond(new Date(this.violationTime?.alprTime));
    let coordTime = convertTimeToSecond(new Date(this.violationTime?.time));

    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = 'red';

    this.ctx.beginPath();

    this.ctx.moveTo(scale.getPixelForValue(coordVioTime), 0);
    this.ctx.lineTo(scale.getPixelForValue(coordVioTime), this.chartArea.bottom);

    if (coordAlprTime) {
      this.ctx.moveTo(scale.getPixelForValue(coordAlprTime), 0);
      this.ctx.lineTo(scale.getPixelForValue(coordAlprTime), this.chartArea.bottom);
    }

    if (coordTime) {
      this.ctx.moveTo(scale.getPixelForValue(coordTime), 0);
      this.ctx.lineTo(scale.getPixelForValue(coordTime), this.chartArea.bottom);
    }

    this.ctx.stroke();
  }

  getWidth(xStart, xEnd) {
    let x1 = this.chartArea.right - xStart;
    let x2 = this.chartArea.right - xEnd;

    if (x1 - x2 < 0) return 0;

    return x1 - x2;
  }

  refreshVideoHls(ms) {
    const { date, cameraId } = this.mediaPlayer;

    if (this.refreshed) return this.clearTimeout();

    if (this.refreshVideoHlsId) return;

    this.refreshVideoHlsId = setTimeout(() => {
      store.dispatch(refreshTimeline(true));
      store.dispatch(getVideoHls({ cameraId, date }));
      this.xCoordStartUpdate = 0;
      this.refreshed = true;
    }, ms);

    console.log('this.refreshVideoHlsId', this.refreshVideoHlsId);
  }

  clearTimeout() {
    // const { date, today } = this.mediaPlayer;
    // if (date.getDate() !== today.getDate()) return;

    if (this.refreshVideoHlsId) {
      console.log('clear refreshVideoHlsId', this.refreshVideoHlsId);
      clearTimeout(this.refreshVideoHlsId);
      this.refreshVideoHlsId = null;
      this.refreshed = false;
    }
  }

  destroy() {
    this.isClicked = false;
  }

  showMessageForVideoNotFound() {
    return toastMessage.error(notFoundVideoAlert);
  }

  handleXCoordStartForFinalVideoLink() {
    const { videoLinks } = this;
    let xCoordStart = convertTimeToSecond(
      videoLinks[videoLinks.length - 1]?.end,
      videoLinks[videoLinks.length - 1]?.ms,
    );

    let currentTimePos = this?.media?.currentTimePos;

    if (currentTimePos && currentTimePos >= xCoordStart) {
      this.xCoordStartUpdate = currentTimePos;
      return {
        xCoordStart: currentTimePos,
        xCoordEnd: this.chartArea?.right,
      };
    }

    return {
      xCoordStart: this.xCoordStartUpdate ? this.xCoordStartUpdate : xCoordStart,
      xCoordEnd: this.chartArea?.right,
    };
  }

  handlePlaybackAndLiveView() {
    const { videoLinks } = this;
    const { currentTimePos } = this?.media;
    let timeArraySplitted = hhmmss(currentTimePos, 0).split(':');
    let currentTime = convertPosPixelToTime(timeArraySplitted, this.media.startTime, this.media.ms);
    let endTime = videoLinks[videoLinks.length - 1]?.end;
    let diffMs = endTime - currentTime;
    // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    let diffMins = ((diffMs % 86400000) % 3600000) / 60000;

    if (this.isClicked) {
      if (diffMins > 5) return this.refreshVideoHls(LONG_DISTANCE);

      if (Math.abs(diffMins) >= 0 && Math.abs(diffMins) < 2)
        return this.refreshVideoHls(CLOSE_DISTANCE);
    } else {
      if (diffMins < 0) return this.refreshVideoHls(CLOSE_DISTANCE);
    }

    // if (this.isClicked && diffMins > 5) return this.refreshVideoHls(LONG_DISTANCE);

    // if (Math.abs(diffMins) >= 0 && Math.abs(diffMins) <= 0.1)
    //   return this.refreshVideoHls(CLOSE_DISTANCE);
  }

  handleBlurNotFoundVideo() {
    const scale = this.chartScales;

    const newVideoNotFoundPos = this.videoLinks.length
      ? [
          {
            xCoordStart: this.chartArea?.left,
            xCoordEnd: convertTimeToSecond(this.videoLinks[0]?.start, this.videoLinks[0]?.ms),
          },
          ...this.videoNotFoundPos,
          this.handleXCoordStartForFinalVideoLink(),
        ]
      : [{ xCoordStart: this.chartArea?.left, xCoordEnd: this.chartArea?.right }];

    for (let item of newVideoNotFoundPos) {
      let xCoordStart =
        item.xCoordStart === this.chartArea.left
          ? item.xCoordStart
          : scale.getPixelForValue(item.xCoordStart);
      let xCoordEnd =
        item.xCoordEnd === this.chartArea.right
          ? item.xCoordEnd
          : scale.getPixelForValue(item.xCoordEnd);
      let width = this.getWidth(xCoordStart, xCoordEnd);
      this.ctx.fillStyle = VIDEO_NOT_FOUND_BG;
      this.ctx.fillRect(xCoordStart, 0, width, this.chartArea?.bottom);
    }

    const { date, today, isPlay, isSeeking, cameraId } = this.mediaPlayer;

    if (
      !this?.media?.currentTimePos ||
      !cameraId ||
      !isPlay ||
      isSeeking ||
      this?.media?.currentTimePos < 1 ||
      !this.videoLinks.length ||
      this?.media?.readyState < this?.media?.HAVE_FUTURE_DATA ||
      date.getDate() !== today.getDate()
    )
      return;

    this.handlePlaybackAndLiveView();
  }

  click(event) {
    this.isClicked = true;
    const { chart } = this;
    const scale = this.chartScales;
    const pos = Chart.helpers.getRelativePosition(event, chart);

    if (!window?.hls) return toastMessage.error('Hls not found');
    // if (!window?.hls || !this.media) return this.showMessageForVideoNotFound();
    this.clearTimeout();

    const posPixel = scale.getValueForPixel(pos.x);
    let timeArraySplitted = hhmmss(posPixel, 0).split(':');
    let dateTime = convertPosPixelToTime(timeArraySplitted, this.media.startTime, this.media.ms);

    this.renderVideoAfterClick(posPixel, dateTime);
  }

  renderVideoAfterClick(posPixel, dateTime) {
    const clickingOnStream = this.checkScopeOfTimelineClick(dateTime, this.media);

    if (clickingOnStream) {
      this.setCurrentTimeForVideo(this.media, posPixel);
    } else {
      let videoCompare = this.compareVideo(dateTime);
      if (!videoCompare) return this.showMessageForVideoNotFound();

      let video = renderMediaSource(videoCompare, posPixel);
      this.video = video;

      this.playVideo();
      this.setCurrentTimeForVideo(video, posPixel);
    }
  }

  playVideo() {
    if (!this.video) return;
    if (this.isPlay) return this.video.handlePlay();
    return this.video.handlePause();
  }

  setCurrentTimeForVideo(media, posPixel) {
    media.currentTime = media.isOvernight
      ? posPixel + media.currentTimeOvernightDefault - media.videoStartTimeDefault
      : posPixel - media.videoStartTimeDefault;
    media.playbackRate = Number(this.speed || 1);
  }

  compareVideo(dateTime, next = 0) {
    if (!this.videoLinks?.length) return false;
    let index = this.videoLinks.findIndex(
      (video) => dateTime >= video?.start && dateTime <= video?.end,
    );
    return this.videoLinks[index + next];
  }

  checkScopeOfTimelineClick(dateTime, media) {
    return dateTime >= media.startTime && dateTime <= media.endTime;
  }

  pan(scale, amount, min, max) {
    if (amount === 0) return;
    let pan = amount;

    if (amount > 0) {
      pan = Math.min(SECONDS_IN_A_DAY - max, amount);
    } else {
      pan = Math.max(-min, amount);
    }

    scale.options.ticks.min = min + pan;
    scale.options.ticks.max = max + pan;

    this.updateOnRepaint();
  }

  zoom(scale, pos, amount) {
    const range = scale.max - scale.min;
    const diff = range * amount;
    const minPercent = (scale.getValueForPixel(pos.x) - scale.min) / range;
    const maxPercent = 1 - minPercent;
    let minDelta = diff * minPercent;
    let maxDelta = diff * maxPercent;
    const { ticks } = scale.options;

    if (amount > 0 && minDelta <= 30) return;
    if (amount > 0) delete ticks.stepSize;
    if (scale.max === SECONDS_IN_A_DAY && scale.max - maxDelta >= SECONDS_IN_A_DAY) {
      ticks.stepSize = SECONDS_IN_A_HOUR * 2;
    }

    let minValue = Math.max(0, scale.min + minDelta),
      maxValue = Math.min(SECONDS_IN_A_DAY, scale.max - maxDelta);

    if (maxValue - minValue <= 500) return;

    scale.options.ticks.min = minValue;
    scale.options.ticks.max = maxValue;

    this.updateOnRepaint();
  }

  updateOnRepaint() {
    window.cancelAnimationFrame(this.rafDebounceRequestId);
    this.rafDebounceRequestId = window.requestAnimationFrame(() => this.update());
  }

  update() {
    if (!this.chart.ctx?.canvas.width) return;
    window.cancelAnimationFrame(this.rafDebounceRequestId);
    this.chart.update({
      duration: 0,
      lazy: true,
    });
  }

  reset() {
    const scale = this.chartScales;
    scale.options.ticks.min = 0;
    scale.options.ticks.max = SECONDS_IN_A_DAY;
    scale.options.ticks.stepSize = SECONDS_IN_A_HOUR * 2;
    this.updateOnRepaint();
  }

  chevronLeft() {
    const scale = this.chartScales;
    let { ticks } = scale.options;
    if (ticks.min === 0) return;
    ticks.max = ticks.min;
    ticks.min = 0;
    this.updateOnRepaint();
  }

  chevronRight() {
    const scale = this.chartScales;
    let { ticks } = scale.options;
    if (ticks.max === SECONDS_IN_A_DAY) return;
    ticks.min = ticks.max;
    ticks.max = SECONDS_IN_A_DAY;
    this.updateOnRepaint();
  }

  handleMedia() {
    if (!window?.hls || !this.media || !this.videoLinks.length) return;

    this.media.onseeking = () => {
      if (this.mediaPlayer?.isSeeking) return;
      store.dispatch(onVideoSeeking());
      this.playVideo();
    };

    this.media.onseeked = () => {
      store.dispatch(onVideoSeeked());
      this.playVideo();
    };

    this.media.ontimeupdate = (e) => {
      if (!this.media) return;
      const { srcElement } = e;
      const { currentTimeOvernightDefault, isOvernight, isEndDay } = this.media;

      if (isOvernight) {
        if (this.media.currentTimePos >= SECONDS_IN_A_DAY) {
          this.media.handlePause();
          store.dispatch(toggleMediaPlay({ isPlay: false }));
        }
        this.media.currentTimePos = isEndDay
          ? this.media.videoStartTimeDefault + srcElement.currentTime
          : srcElement.currentTime - currentTimeOvernightDefault <= 0
          ? srcElement.currentTime
          : srcElement.currentTime - currentTimeOvernightDefault;
      } else {
        this.media.currentTimePos = this.media.videoStartTimeDefault + srcElement.currentTime;
      }
      return this.updateOnRepaint();
    };

    if (this.media.ended) {
      let videoCompare = this.compareVideo(this.media.endTime, 1);
      if (!videoCompare) {
        this.media.handlePause();
        this.showMessageForVideoNotFound();

        this.media.currentTime = this.media.currentTime - 2;
        store.dispatch(toggleMediaPlay({ isPlay: false }));
      } else {
        const posPixel = this.media.currentTimePos;
        let video = renderMediaSource(videoCompare, posPixel);
        this.video = video;
        this.playVideo();

        this.setCurrentTimeForVideo(video, posPixel);
      }
    }
  }

  drawCurrentTime() {
    if (!window?.hls || !this.media || !this.videoLinks?.length) return;

    const currentTime = this.setCurrentTime(this.media.currentTimePos);

    const scale = this.chartScales;
    const x = scale.getPixelForValue(currentTime);

    this.ctx.restore();
    this.ctx.save();
    this.drawLineX(this.ctx, x, this.chartArea);

    if (x < this.chartArea?.right) {
      let xT = x + 2;
      if (x >= 750) xT = x - 75;
      const y = this.chartArea?.top;
      this.ctx.fillText(hhmmss(currentTime, 3), xT, y, 100);
    }

    this.ctx.restore();
  }

  setCurrentTime = (current) => {
    if (current <= 0) return 0;
    if (current >= SECONDS_IN_A_DAY) return SECONDS_IN_A_DAY - 1;
    return current || 0;
  };

  drawLineX(ctx, x, chartArea) {
    if (x < chartArea.right) {
      ctx.lineWidth = 3;
      ctx.fillStyle = '#f5f5f5';
      ctx.strokeStyle = '#FFC300';
      ctx.beginPath();
      ctx.moveTo(x, chartArea.top);
      ctx.lineTo(x, chartArea.bottom);
      ctx.stroke();
    }
  }
}

function getChartOptions() {
  return {
    legend: {
      labels: {
        fontColor: '#f3f3f3',
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxRotation: 0,
            min: 0,
            max: SECONDS_IN_A_DAY,
            stepSize: SECONDS_IN_A_HOUR * 2,
            fontColor: '#f3f3f3',
            callback: (tickValue, i, ticks) => {
              if (i === 0 || i === ticks.length - 1) {
                if (tickValue % SECONDS_IN_A_HOUR !== 0) return '';
                return tickValue ? hhmmss(tickValue) : '0';
              } else {
                return hhmmss(tickValue);
              }
            },
          },
          gridLines: {
            color: '#f3f3f3',
            tickColor: '#f3f3f3',
          },
        },
      ],
    },
  };
}
