import React from 'react';
import { DoneAllOutlined, SettingsOutlined, LogoutOutlined } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NotificationSetting from 'src/components/notification/notification_setting';
import { MODAL_IDS } from 'src/constants/modal';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { useIsRequestPending } from 'src/hooks';
import { openModal } from 'src/redux_store/common/modal/modal_slice';

import {
  changePayload,
  incrementalNotificationList,
} from 'src/redux_store/notification/notification_slice';
import { markAllNotification } from 'src/utils/notification';
import TypeTabNotification from 'src/components/notification/tab_type_notification';
import { IPayloadNotification } from 'src/types/notification';
import websocket from 'src/clients/websocket';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from 'src/components/loading';
import { getParamToNavigate } from 'src/utils/helper';
import NotificationFilterType from 'src/components/filter/notification_filter_type';

type TProps = {
  asyncGetALlNotification: (newPayload: IPayloadNotification, isReset?: boolean) => void;
};

const NotificationFilter = (props: TProps) => {
  const { asyncGetALlNotification } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { me, role } = useAppSelector((state) => state.myAccountSlice);
  const isLoadingGetNotificationSetting = useIsRequestPending(
    'notification',
    'getNotificationSetting',
  );
  const location: any = useLocation();
  const navigate = useNavigate();

  const { payload } = useAppSelector((state) => state.notificationSlice);

  const onFilter = (source: string[]) => {
    dispatch(incrementalNotificationList({ isReset: true, data: [] }));

    const newPayload: IPayloadNotification = { ...payload, placeOfOrigin: source, after: '' };

    console.log({ newPayload });

    dispatch(changePayload(newPayload));

    if (_.isEmpty(source) && _.isEmpty(payload.type)) {
      websocket.syncNumberOfNotification(me.id);
    }

    asyncGetALlNotification(newPayload, true);
  };

  const handleOpenModalSettingNotification = () => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.notificationSetting,
        dialogComponent: <NotificationSetting />,
      }),
    );
  };

  const handleSelectType = (type: string) => {
    dispatch(incrementalNotificationList({ isReset: true, data: [] }));

    let currentTypeSelected = payload.type;

    if (currentTypeSelected === type) {
      currentTypeSelected = '';
    } else {
      currentTypeSelected = type;
    }

    const newPayload: IPayloadNotification = { ...payload, type: currentTypeSelected, after: '' };

    if (_.isEmpty(payload.placeOfOrigin) && !currentTypeSelected) {
      websocket.syncNumberOfNotification(me.id);
    }

    dispatch(changePayload(newPayload));
    asyncGetALlNotification(newPayload, true);
  };

  const handleCloseNotification = () => {
    const { state } = location;

    if (state) {
      const pathname: string = state.from?.pathname;
      navigate(pathname);
    } else {
      navigate(getParamToNavigate(me, role));
    }
  };

  return (
    <Grid container columnSpacing={1} alignItems="center">
      <Grid item md={6}>
        <Grid container alignItems="center" columnSpacing={2}>
          <Grid item>
            <TypeTabNotification handleSelectType={handleSelectType} type={payload.type} />
          </Grid>
          <Grid item md={4}>
            {isLoadingGetNotificationSetting ? (
              <Loading />
            ) : (
              <NotificationFilterType onFilter={onFilter} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container justifyContent="flex-end" columnGap={2}>
          <Button
            color="secondary"
            startIcon={<DoneAllOutlined />}
            onClick={() => {
              if (payload.type.includes('UNREAD')) {
                dispatch(incrementalNotificationList({ data: [], isReset: true }));
              }
              markAllNotification(dispatch);
            }}
          >
            {t('notification.button.markAll')}
          </Button>
          <Button
            color="secondary"
            startIcon={<SettingsOutlined />}
            onClick={handleOpenModalSettingNotification}
          >
            {t('notification.button.setting')}
          </Button>
          <Button
            color="secondary"
            startIcon={<LogoutOutlined />}
            onClick={handleCloseNotification}
          >
            {t('notification.button.close')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotificationFilter;
