import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';

import { ISystemBackup } from 'src/types/system';
import { toastMessage } from 'src/utils/toast';

export const getBackup = createAsyncThunk<ISystemBackup, void>(
  'system/getBackup',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getBackup();
      return data;
    } catch (error: any) {
      toastMessage.error(error);
      return rejectWithValue(error);
    }
  },
);

export const updateBackup = createAsyncThunk<ISystemBackup, ISystemBackup>(
  'system/updateBackup',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateBackup(payload);
      toastMessage.success(data.message);
      return payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBackupSpaceUsage = createAsyncThunk<any, void>(
  'system/getBackupSpaceUsage',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getBackupSpaceUsage();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
