import theme from 'src/theme';
import { EStationStatus } from 'src/types/station';
import { isStationLevelWarning } from 'src/utils/station';

export const getColorIconStation = (stationStatus: EStationStatus) => {
  if (isStationLevelWarning(stationStatus)) return theme.palette.warning.main;
  if (stationStatus === EStationStatus.ERROR) return theme.palette.error.main;
  if (stationStatus === EStationStatus.OFFLINE) return theme.palette.grey[500];
  return theme.palette.success.main;
};
