import { IPlayer } from '../../types/player';
import fullscreen from '../../utils/fullscreen';

export function snapshot(this: any) {
  const { video } = this.video;

  if (!video) return;

  const { videoHeight, videoWidth } = video;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return;

  canvas.width = videoWidth;
  canvas.height = videoHeight;

  context.fillRect(0, 0, videoWidth, videoHeight);
  context.drawImage(video, 0, 0, videoWidth, videoHeight);

  const link = document.createElement('a');
  link.setAttribute('download', `snapshot_${new Date().getTime()}.jpg`);
  link.setAttribute(
    'href',
    canvas.toDataURL('image/URL').replace('image/png', 'image/octet-stream'),
  );
  link.click();
}

export function toggleFullscreen(rootElement: HTMLElement, player: IPlayer) {
  if (!rootElement) return;

  if (fullscreen.enabled) {
    if (fullscreen.isFullscreen) {
      fullscreen.exit();
    } else {
      fullscreen.request(rootElement);
    }
  }

  return {
    type: 'player_record/changeFullscreen',
    payload: !player.isFullscreen,
  };
}
