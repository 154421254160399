import Hls from 'hls.js';
import _ from 'lodash';

import { convertTimeToSecond } from '../chart_timeline/chart_horizontal_bar';
import { replacePath } from './common';
import store from 'src/redux_store';

export const fullscreenElementToggle = (docElm) => {
  let isInFullscreen =
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

  if (!isInFullscreen) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozFullScreenElement) {
      docElm.mozFullScreenElement();
    } else if (docElm.webkitFullscreenElement) {
      docElm.webkitFullscreenElement();
    } else if (docElm.msFullscreenElement) docElm.msFullscreenElement();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.msExitFullscreen();
    }
  }
};

const HlsOptions = {
  // maxBufferHole: 2,
  // liveMaxLatencyDuration: 30,
  // liveSyncDuration: 10,
  xhrSetup: (xhr, url) => {
    const {
      me: { id },

      token,
    } = store.getState().myAccountSlice;

    xhr.setRequestHeader('Authorization', 'Bearer=' + token);
    xhr.setRequestHeader('user_id', id);

    xhr.withCredentials = true;
    xhr.open('GET', url, true);
  },
};

let hls = new Hls(HlsOptions);

export function renderMediaSource(media, posPixel, autoPlay = true) {
  let video = document.getElementById('videoHls');

  if (_.isEmpty(media)) return (video.src = '');

  const { path, start, end, isEndDay, isOvernight, ms, videoSeekDefault } = media;

  if (!path || !start || !end) return;

  let currentTime = 0,
    currentTimeOvernightDefault = 0;

  if (isOvernight) {
    if (!posPixel) {
      currentTime = videoSeekDefault;
    } else {
      currentTime = videoSeekDefault + posPixel;
    }
    currentTimeOvernightDefault = videoSeekDefault;
  }

  if (isOvernight) {
    video.currentTime = currentTime;
  }

  video.startTime = start;
  video.endTime = end;
  video.isEndDay = isEndDay;
  video.ms = ms;
  video.path = replacePath(path);
  video.currentTimePos = posPixel || convertTimeToSecond(start, ms);
  video.videoStartTimeDefault = convertTimeToSecond(start, ms);
  video.currentTimeOvernightDefault = currentTimeOvernightDefault;
  video.isOvernight = isOvernight;

  if (Hls.isSupported()) {
    resetHls();
    hls.detachMedia();
    hls.attachMedia(video);

    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
      // video.muted = false;
      hls.loadSource(replacePath(path));
    });

    hls.on(Hls.Events.MANIFEST_PARSED, function (e, data) {
      hls.startLoad();
      if (autoPlay) {
        const promise = video.play();
        if (promise === undefined) {
          promise
            .then((_) => {
              video.pause();
            })
            .catch((error) => console.log({ error }));
        }
        if (promise !== undefined) {
          promise.catch((error) => console.log({ error })).then(() => {});
        }
      }
    });

    hls.on(Hls.Events.ERROR, (event, data) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.log('fatal network error encountered, try to recover');
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('fatal media error encountered, try to recover', data);
            hls.recoverMediaError();
            break;
          default:
            console.log('fatal default', data);
            this.hls.startLoad();
            // hls.destroy();
            break;
        }
      }
    });

    window.hls = hls;
    // window.self.Hls = Hls;
  }

  const handlePlay = () => {
    const promise = video.play();
    if (promise !== undefined) promise.catch(() => {}).then(() => {});
  };

  const handlePause = () => {
    const promise = video.pause();
    if (promise !== undefined) promise.catch(() => {}).then(() => {});
  };

  video.handlePause = handlePause;
  video.handlePlay = handlePlay;

  return video;
}

function resetHls() {
  if (hls) {
    hls.destroy();
    window.hls = null;
  }
  hls = new Hls(HlsOptions);
}

export function snapshot(video) {
  if (!video) return;

  const { videoHeight, videoWidth } = video;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return;

  canvas.width = videoWidth;
  canvas.height = videoHeight;

  context.fillRect(0, 0, videoWidth, videoHeight);
  context.drawImage(video, 0, 0, videoWidth, videoHeight);

  const link = document.createElement('a');
  link.setAttribute('download', `snapshot_${new Date().getTime()}.jpg`);
  link.setAttribute(
    'href',
    canvas.toDataURL('image/URL').replace('image/png', 'image/octet-stream'),
  );
  link.click();
}
