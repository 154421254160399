import { GetAppOutlined, LinkOutlined, ShareOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogTitle, FormControl, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm } from 'react-hook-form';

import FormDateTimePicker from 'src/components/hook_form/form_datetimepicker';
import DialogWrapper from 'src/components/modal/dialog_wrapper';
import { useIsRequestPending } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { downloadVideo, shareVideo } from 'src/redux_store/video/video_action';
import { toastMessage } from 'src/utils/toast';
import { useStyles } from './styles';
import config from 'src/config';
import { useTranslation } from 'react-i18next';

interface IExportVideoModal {
  modalId: string;
  dateTime?: Date;
}

interface IDateForm {
  startDate: Date | null;
  endDate: Date;
}

const ExportVideoModal = (props: IExportVideoModal) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const isSharing = useIsRequestPending('video', 'shareVideo');
  const isDownloading = useIsRequestPending('video', 'downloadVideo');

  // const { copyText } = useCopyToClipboard();
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const { me, token } = useAppSelector(({ myAccountSlice }) => myAccountSlice);
  const { t } = useTranslation();

  const { modalId, dateTime } = props;
  const { cameraId, exportCameraId, exportTime } = mediaPlayer;

  const defaultValues = useMemo(() => {
    if (exportTime) return exportTime;

    return {
      startDate: null,
      endDate: new Date(),
    };
  }, [exportTime]);

  const [submitType, setSubmitType] = useState<'share' | 'download' | ''>('');
  const [shareLink, setShareLink] = useState<string | ''>('');
  const { control, reset, handleSubmit } = useForm<IDateForm>({
    defaultValues: defaultValues,
  });

  const setTimeInit = () => {
    if (!dateTime && !exportTime) return;

    if (exportTime) {
      reset(defaultValues);

      return;
    }

    if (dateTime) {
      const endDate = new Date(dateTime.getTime());

      if (dateTime.getHours() !== 0 || dateTime.getMinutes() !== 0 || dateTime.getSeconds() !== 0) {
        dateTime.setSeconds(dateTime.getSeconds() - 30);
      }

      endDate.setSeconds(endDate.getSeconds() + 30);

      reset({
        startDate: dateTime,
        endDate,
      });
    }
  };

  // useEffect(() => {
  //   if (!dateTime) return;
  //   const endDate = new Date(dateTime.getTime());

  //   if (dateTime.getHours() !== 0 || dateTime.getMinutes() !== 0 || dateTime.getSeconds() !== 0) {
  //     dateTime.setSeconds(dateTime.getSeconds() - 30);
  //   }

  //   endDate.setSeconds(endDate.getSeconds() + 30);

  //   reset({
  //     startDate: dateTime,
  //     endDate,
  //   });
  // }, [dateTime]);

  useEffect(() => {
    setTimeInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTime, exportTime]);

  // const handleCopyShareLink = () => {
  //   copyText(shareLink, 'Đã sao chép link video vào bộ nhớ tạm');
  // };

  const onChangeSubmit = (newType: 'download' | 'share') => {
    setSubmitType(newType);
  };

  const onSubmit = (data: IDateForm) => {
    const { startDate, endDate } = data;
    if (!submitType || !startDate) return;

    const isTimeInvalid = new Date(endDate).getTime() > new Date(startDate).getTime();

    if (!isTimeInvalid) return toastMessage.error(t('label.workingMode.time.validate.compare'));

    switch (submitType) {
      case 'download':
        dispatch(
          downloadVideo({
            cameraId: cameraId || exportCameraId,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            authorization: token || '',
            user_id: me.id,
          }),
        );
        break;
      case 'share':
        dispatch(
          shareVideo({
            cameraId: cameraId || exportCameraId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          }),
        )
          .unwrap()
          .then((response) => {
            const link = `${config.baseUrl}${response}`;
            setShareLink(link);
          })
          .catch(() => setShareLink(''));

        break;
      default:
        return;
    }
  };

  // const handleClose = () => {
  //   dispatch(closeModal({ modalId: MODAL_IDS.player.exportVideo }));
  // };

  return (
    <DialogWrapper modalId={modalId}>
      <Box className={classes.dialog}>
        <DialogTitle>{t('button.exportVideo')}</DialogTitle>
        {/* <BootstrapDialogTitle onClose={handleClose}>Trích xuất video</BootstrapDialogTitle> */}
        <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
          <FormDateTimePicker
            label={t('label.fromDate')}
            name="startDate"
            control={control}
            size="small"
            inputFormat="DD/MM/YYYY HH:mm:ss"
            maxDate={new Date()}
          />
          <FormDateTimePicker
            label={t('label.toDate')}
            name="endDate"
            control={control}
            size="small"
            inputFormat="DD/MM/YYYY HH:mm:ss"
            maxDate={new Date()}
          />
          <Box className={classes.buttons}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              startIcon={<GetAppOutlined />}
              onClick={() => onChangeSubmit('download')}
              loading={isDownloading}
            >
              {t('button.downloadVideo')}
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              startIcon={<ShareOutlined />}
              onClick={() => onChangeSubmit('share')}
              loading={isSharing}
            >
              {t('button.share')}
            </LoadingButton>
          </Box>
        </Box>

        {shareLink && (
          <Box display="flex" alignItems="center" p={2} pt={0}>
            <FormControl margin="dense" fullWidth className={classes.shareLink}>
              <TextField
                size="small"
                variant="outlined"
                value={shareLink}
                label="Share link"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </FormControl>
            <Box className={classes.buttonCp}>
              <CopyToClipboard
                text={shareLink}
                onCopy={() => toastMessage.success(t('message.copy.link.success'))}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<LinkOutlined />}
                >
                  {t('button.copy')}
                </Button>
              </CopyToClipboard>
              {/* <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<LinkOutlined />}
                onClick={handleCopyShareLink}
              >
                Copy
              </Button> */}
            </Box>
          </Box>
        )}
      </Box>
    </DialogWrapper>
  );
};

export default ExportVideoModal;
