import { Box, Grid, Typography } from '@mui/material';
import parse from 'html-react-parser';
import moment from 'moment';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';

import classNames from 'classnames';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import BadgeAvatar from 'src/components/avatar/badge_avatar';
import Loading from 'src/components/loading';
import DeleteNotification from 'src/components/notification/notification_action/delete_notification';
import MarkNotificationItem from 'src/components/notification/notification_action/mark_notification';
import { useAppDispatch } from 'src/redux_store';
import { markItemNotification } from 'src/utils/notification';
import { useStyles } from '../style';
import './style_table.css';

const levelNotificationList = [
  {
    level: 'INFO',
    label: 'Thông tin',
  },
  {
    level: 'WARNING',
    label: 'Cảnh báo',
  },
  {
    level: 'ERROR',
    label: 'Lỗi',
  },
  {
    level: 'CRITICAL',
    label: 'Quan trọng',
  },
];

const HEADER_HEIGHT = 40;
const ROW_HEIGHT = 53;

const TableNotification = (props) => {
  const { bodyData, id, handelScrollTop, isLoading } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const renderLevelName = (level) => {
    const levelItem = levelNotificationList.find((item) => item.level === level);
    if (levelItem) {
      return levelItem.label;
    }
    return '';
  };
  const renderStatusColorLevel = (level) => {
    const statusKeyColor = levelNotificationList.find((item) => item.level === level);

    if (statusKeyColor) {
      return statusKeyColor.level;
    }
    return 'SUCCESS';
  };

  const renderBadge = (level) => {
    return (
      <BadgeAvatar
        className={classNames(classes.customBadge, classes[renderStatusColorLevel(level)])}
      />
    );
  };

  const renderLevelNotification = (level, isRead) => {
    return (
      <Box display="flex" alignItems="center" marginLeft={1}>
        {renderBadge(level)}
        <Typography
          marginLeft={1.5}
          variant="body2"
          lineHeight="initial"
          noWrap
          // fontWeight={isRead ? 500 : 'bold'}
          className={!isRead ? classes.hightLightText : classes.normalText}
        >
          {renderLevelName(level)}
        </Typography>
      </Box>
    );
  };

  const handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (handelScrollTop) {
      handelScrollTop({ clientHeight, scrollHeight, scrollTop });
    }
  };

  const handleRowClicked = (rowData) => {
    const { isRead, id } = rowData;

    if (!isRead) {
      markItemNotification(dispatch, 'read', id);
    }
  };

  const _rowRenderer = ({ className, rowData, columns, style }) => {
    return (
      <Box
        onClick={() => handleRowClicked(rowData)}
        className={clsx(className, {
          [classes.row]: true,
          [classes.rowActive]: id === rowData.id,
        })}
        style={style}
        key={rowData.id}
      >
        {columns}
      </Box>
    );
  };

  return (
    <AutoSizer style={{ height: 'calc(100% - 8px)', width: '100%', overflow: 'hidden' }}>
      {({ width, height }) => (
        <>
          <Table
            width={width}
            height={height}
            headerHeight={HEADER_HEIGHT}
            rowHeight={ROW_HEIGHT}
            rowCount={bodyData.length}
            rowGetter={({ index }) => bodyData[index]}
            rowRenderer={_rowRenderer}
            onScroll={handleScroll}
            noRowsRenderer={() => {
              if (isLoading) {
                return (
                  <Box className={classes.boxLoading}>
                    <Loading />
                  </Box>
                );
              }
              return (
                <Box className={classes.boxLoading}>
                  <Typography textAlign="center" py={2}>
                    Không có dữ liệu ...
                  </Typography>
                </Box>
              );
            }}
          >
            <Column
              label="level"
              dataKey="level"
              width={150}
              disableSort
              headerRenderer={() => {
                return (
                  <Typography textTransform="initial" variant="p">
                    {t('notification.infoNotification.level')}
                  </Typography>
                );
              }}
              cellRenderer={({ rowData }) => {
                const { isRead, level } = rowData;
                return <>{renderLevelNotification(level, isRead)}</>;
              }}
            />
            <Column
              width={700}
              dataKey="type"
              disableSort
              headerRenderer={() => {
                return (
                  <Typography textTransform="initial" variant="p">
                    {t('notification.infoNotification.type')}
                  </Typography>
                );
              }}
              label={t('notification.infoNotification.detail')}
              cellRenderer={({ rowData }) => {
                const { isRead, title } = rowData;

                return (
                  <Grid container alignItems="center" padding="0px 8px">
                    <Grid item md={0.25}>
                      {!isRead && renderBadge('SUCCESS')}
                    </Grid>
                    <Grid item md={11.75}>
                      <Typography
                        noWrap
                        className={!isRead ? classes.hightLightText : classes.normalText}
                        // fontWeight={isRead ? 500 : 'bold'}
                        // ml={1.5}
                      >
                        {title.content ? parse(title.content) : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
            <Column
              width={700}
              disableSort
              dataKey="description"
              headerRenderer={() => {
                return (
                  <Typography textTransform="initial" variant="p">
                    {t('notification.infoNotification.detail')}
                  </Typography>
                );
              }}
              cellRenderer={({ rowData }) => {
                const { isRead, description } = rowData;

                return (
                  <Typography
                    noWrap
                    className={!isRead ? classes.hightLightText : classes.normalText}
                    // fontWeight={isRead ? 500 : 'bold'}
                    // ml={1.5}
                  >
                    {description.content ? parse(description.content) : ''}
                  </Typography>
                );
              }}
            />
            <Column
              width={200}
              disableSort
              dataKey="time"
              label={t('notification.infoNotification.time')}
              headerRenderer={() => {
                return (
                  <Typography textTransform="initial" variant="p">
                    {t('notification.infoNotification.time')}
                  </Typography>
                );
              }}
              cellRenderer={({ rowData }) => {
                const { isRead, time } = rowData;

                return (
                  <Typography
                    textTransform="initial"
                    variant="p"
                    className={!isRead ? classes.hightLightText : classes.normalText}
                    // fontWeight={isRead ? 500 : 'bold'}
                  >
                    {moment(time).format('DD-MM-yyyy HH:mm:ss')}
                  </Typography>
                );
              }}
            />
            <Column
              width={150}
              disableSort
              dataKey="options"
              headerRenderer={() => {
                return (
                  <Typography textTransform="initial" variant="p">
                    {t('notification.infoNotification.options')}
                  </Typography>
                );
              }}
              cellRenderer={({ rowData }) => {
                const { isRead, id } = rowData;

                return (
                  <Grid container>
                    <MarkNotificationItem isRead={isRead} id={id} />
                    <DeleteNotification id={id} />
                  </Grid>
                );
              }}
            />
          </Table>
        </>
      )}
    </AutoSizer>
  );
};

export default TableNotification;
