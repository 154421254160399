import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
    cursor: 'pointer',
    '&:first-child': {
      marginLeft: 8,
    },
  },
  disabledBadge: {
    backgroundColor: '#B2BEC3 !important',
  },
}));
