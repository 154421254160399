import { createAsyncThunk } from '@reduxjs/toolkit';

import { ICustomerMembers, IInviteCustomerMember } from 'src/types/customer';
import { toastMessage } from 'src/utils/toast';
import { MODAL_IDS } from 'src/constants/modal';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import client from 'src/clients/http';
import i18n from 'src/i18n';

export const getCustomerMembers = createAsyncThunk<ICustomerMembers[], string>(
  'customerMembers/getCustomerMembers',
  async (customerId, { rejectWithValue }) => {
    try {
      const members = await client.getCustomerMembers(customerId);
      return members;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const inviteCustomerMember = createAsyncThunk<ICustomerMembers, IInviteCustomerMember>(
  'customerMembers/inviteCustomerMember',
  async (payload, { rejectWithValue }) => {
    try {
      const customerMember = await client.inviteCustomerMember(payload);
      return customerMember;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCustomerMembers = createAsyncThunk<
  string,
  { customerId: string; memberId: string }
>(
  'customerMembers/deleteCustomerMembers',
  async ({ customerId, memberId }, { rejectWithValue, dispatch }) => {
    try {
      await client.deleteCustomerMembers(customerId, memberId);

      toastMessage.success(i18n.t('message.deleteCustomerMember.success'));
      dispatch(closeModal({ modalId: MODAL_IDS.confirmDelete }));

      return memberId;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
