import { createSlice } from '@reduxjs/toolkit';

import { IPlayer } from '../../types/player';

const initialState: IPlayer = {
  paused: true,
  isFullscreen: false,
  waiting: false,
  seeking: false,
  error: null,
  showControlBar: false,
};

const playerSlice = createSlice({
  initialState,
  name: 'player_record',
  reducers: {
    changeFullscreen: (state, action) => {
      state.isFullscreen = action.payload;
    },
    playToggle: (state) => {
      state.paused = !state.paused;
    },
    play: (state) => {
      state.paused = false;
      state.waiting = false;
    },
    playing: (state) => {
      state.waiting = false;
    },
    canPlay: (state) => {
      state.waiting = false;
    },
    canPlayThrough: (state) => {
      state.waiting = false;
    },
    controlBarActive: (state, action) => {
      state.showControlBar = action.payload;
    },
    pause: (state) => {
      state.paused = true;
    },
    seeking: (state) => {
      state.seeking = true;
    },
    seeked: (state) => {
      state.seeking = false;
    },
    waiting: (state) => {
      state.waiting = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { reducer, actions } = playerSlice;
export default reducer;
