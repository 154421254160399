import React, { useState } from 'react';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';

// import { VideoWrapper } from '../styles';
import { setWsReconnection } from 'src/redux_store/stream/stream_slice';
import { ECameraStatus } from 'src/types/camera';
import { IActions, IPlayerError } from 'src/components/player_rtc/types/player';
import store from 'src/redux_store';
import { useTranslation } from 'react-i18next';

interface VideoStatusProps {
  status: ECameraStatus;
  hasOnline: boolean;
  wsOpen?: boolean;
  actions: IActions;
  error: IPlayerError | null;
}

const dispatch = store.dispatch;

function VideoStatus(props: VideoStatusProps) {
  const { status, error, hasOnline, wsOpen, actions } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { NORMAL, WARNING } = ECameraStatus;

  if ((status === NORMAL || status === WARNING) && !error && wsOpen) return null;

  let des = '';
  let reconnect = false;

  if (status === ECameraStatus.ERROR) {
    des = t('label.status.camera.error');
    reconnect = false;
  }

  if (status === ECameraStatus.OFFLINE) {
    des = t('label.status.camera.offline');
    reconnect = false;
  }

  if (error) {
    des = error.message;
  }

  if (!wsOpen) {
    des = t('message.error.cannotConnect.server');
    reconnect = true;
  }

  if (!hasOnline) {
    des = t('label.notHaveInternet');
  }

  const handleSignalingReConnect = async () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(setWsReconnection(true));
      actions.setError(null);
      setLoading(false);
    }, 3200);
  };

  console.log('wsOpen', wsOpen);

  return (
    // <VideoWrapper>
    <Box
      position="absolute"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      textAlign="center"
    >
      {loading ? (
        <>
          <Typography color="white" variant="body2" mb={3}>
            {/* Đang kết nối đến máy chủ */}
            {t('message.info.connecting.server')}
          </Typography>
          <CircularProgress style={{ color: 'white' }} size={32} />
        </>
      ) : (
        <>
          <Typography color="white" variant="body2" mb={2}>
            {des}
          </Typography>
          {!wsOpen && reconnect && (
            <IconButton onClick={handleSignalingReConnect}>
              <Refresh style={{ color: 'white' }} fontSize="large" />
            </IconButton>
          )}
        </>
      )}
    </Box>
    // </VideoWrapper>
  );
}

export default VideoStatus;
