import { Box, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroller';
import ErrorMessage from 'src/components/error_message';
import { FormAutocomplete, FormDatePicker } from 'src/components/hook_form';
import { useIsRequestError, useIsRequestPending } from 'src/hooks';
import { useAppDispatch } from 'src/redux_store';
import { getStationLogs } from 'src/redux_store/station/station_action';
import theme from 'src/theme';
import { ELeverLogFilter, ILogFilter } from 'src/types/log';
import { IPaginationStationLogs, IStationLog } from 'src/types/station';
import CardLog from './card_log';
import { useTranslation } from 'react-i18next';

interface IFilterState {
  level: { id: string; name: string };
  time: Date;
}

interface IProps {
  stationId: string;
}

const convertTime = (data: Date) => {
  return {
    startTime: moment(data)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toISOString(),
    endTime: moment(data)
      .set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 59,
      })
      .toISOString(),
  };
};

const Logs = ({ stationId }: IProps) => {
  const dispatch = useAppDispatch();
  const scrollbarsRef = useRef<any>();
  const { t } = useTranslation();

  const isLoading = useIsRequestPending('station', 'getStationLogs');
  const isError = useIsRequestError('station', 'getStationLogs');

  const [data, setData] = useState<IPaginationStationLogs<IStationLog>>({ totalPage: 0, data: [] });
  const [payload, setPayload] = useState<ILogFilter>({
    level: ELeverLogFilter.ALL,
    startTime: convertTime(new Date()).startTime,
    endTime: convertTime(new Date()).endTime,
    simpleMode: false,
    page: 1,
  });

  const levelLogList = [
    {
      id: ELeverLogFilter.ALL,
      name: t('label.level.all'),
    },
    {
      id: ELeverLogFilter.INFO,
      name: t('label.level.info'),
    },
    {
      id: ELeverLogFilter.WARNING,
      name: t('label.level.warning'),
    },
    {
      id: ELeverLogFilter.ERROR,
      name: t('label.level.error'),
    },
    {
      id: ELeverLogFilter.CRITICAL,
      name: t('label.level.critical'),
    },
  ];

  const defaultValues = {
    level: levelLogList[0],
    time: new Date(),
  };

  const { control, watch } = useForm<IFilterState>({
    defaultValues,
  });

  const handleChange = () => {
    const { level, time } = watch();
    setData({ data: [], totalPage: 0 });

    setPayload({
      level: level.id,
      startTime: convertTime(new Date(time)).startTime,
      endTime: convertTime(new Date(time)).endTime,
      simpleMode: false,
      page: 1,
    });
  };

  const getStationLogApi = () => {
    if (stationId) {
      dispatch(getStationLogs({ stationId: stationId, params: payload }))
        .unwrap()
        .then((data) => {
          setData((prev) => ({ totalPage: data.totalPage, data: prev.data.concat(data.data) }));
        });
    }
  };

  const renderContent = () => {
    if (!data.data.length) {
      if (isLoading) {
        return (
          <Box>
            {Array(10)
              .fill('')
              .map((item, index) => (
                <Box key={index} padding={'0 10px'} display={'flex'} gap={1}>
                  <Skeleton variant="circular" width={45} height={45} />
                  <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '230px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '180px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />
                  </Box>
                </Box>
              ))}
          </Box>
        );
      } else {
        return (
          <Box textAlign="center" p={1}>
            <Typography>{t('label.emptyData.main')}</Typography>
          </Box>
        );
      }
    } else {
      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {}}
          loader={
            <Box padding={'0 10px'} display={'flex'} gap={1}>
              <Skeleton variant="circular" width={45} height={45} />
              <Box>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '230px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '180px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />
              </Box>
            </Box>
          }
          useWindow={false}
          hasMore={isLoading}
          initialLoad={true}
        >
          {data.data.map((item, index) => {
            return (
              <CardLog
                key={index}
                title={item.title}
                level={item.level}
                time={item.time}
                description={item.description}
              />
            );
          })}
        </InfiniteScroll>
      );
    }
  };

  useEffect(() => {
    getStationLogApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  if (isError) return <ErrorMessage onClick={getStationLogApi} />;

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1}>
      <Box display={'flex'} gap={1} p={1}>
        <FormAutocomplete
          control={control}
          name="level"
          label={t('label.status.main')}
          labelOption="name"
          keyOption="id"
          disableClearable
          handleChange={handleChange}
          options={levelLogList}
          sx={{ background: theme.palette.background.paper }}
        />
        <FormDatePicker
          control={control}
          name="time"
          label={t('label.time.main')}
          maxDate={new Date()}
          handleChange={handleChange}
          sx={{ background: theme.palette.background.paper }}
        />
      </Box>

      <Scrollbars
        ref={scrollbarsRef}
        onScrollStop={() => {
          const elementHeight = document.getElementById('box_card') as HTMLElement;

          const offsetHeight = elementHeight.offsetHeight;

          if (scrollbarsRef.current) {
            const { scrollTop } = scrollbarsRef.current.getValues();

            if (offsetHeight - (innerHeight + scrollTop) <= 10) {
              if (payload.page < data.totalPage) {
                setPayload({ ...payload, page: payload.page + 1 });
              }
            }
          }
        }}
      >
        <Box flex={1} p={'0 8px'} id="box_card">
          {renderContent()}
        </Box>
      </Scrollbars>
    </Box>
  );
};

export default Logs;
