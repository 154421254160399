/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import TableLayout from 'src/layout/table_layout';
import {
  changePayload,
  incrementalNotificationList,
  resetNotificationState,
} from 'src/redux_store/notification/notification_slice';

import NotificationDetail from './notification_detail';
import NotificationDetailAction from './notification_detail_action';
import NotificationFilter from './notification_filter';
import NotificationTable from './notification_table';
import { IPayloadNotification } from 'src/types/notification';
import { getAllNotification } from 'src/redux_store/notification/notification_action';
import { initPayloadNotification } from 'src/constants/notification';

const NotificationManagement = () => {
  const { idNotificationSelected } = useAppSelector((state) => state.notificationSlice);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timerLoading = asyncGetALlNotification(initPayloadNotification, true);

    return () => {
      clearTimeout(timerLoading);
      dispatch(incrementalNotificationList({ isReset: true, data: [] }));
      dispatch(changePayload(initPayloadNotification));
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetNotificationState());
    };
  }, []);

  const asyncGetALlNotification = (newPayload: IPayloadNotification, isReset?: boolean) => {
    let timerLoading: any;
    setIsLoading(true);
    dispatch(getAllNotification(newPayload))
      .unwrap()
      .then((data) => {
        timerLoading = setTimeout(() => {
          setIsLoading(false);
          dispatch(incrementalNotificationList({ data: data, isReset }));
        }, 500);
      })
      .catch(() => {
        setIsLoading(false);
      });

    return timerLoading;
  };

  return (
    <Box flex="1">
      <TableLayout
        filterPanel={<NotificationFilter asyncGetALlNotification={asyncGetALlNotification} />}
        tablePanel={
          <NotificationTable
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            asyncGetALlNotification={asyncGetALlNotification}
          />
        }
        detailPanel={<NotificationDetail />}
        detailActionsPanel={<NotificationDetailAction />}
        isOpenDetailPanel={Boolean(idNotificationSelected)}
      />
    </Box>
  );
};

export default NotificationManagement;
