import { createSlice } from '@reduxjs/toolkit';

import { IOrderDetailView, IOrderView } from 'src/types/customer';
import { deleteCustomerOrder, getCustomerOrders, getOrder } from './actions';

interface IOrderState {
  orders: IOrderView[];
  order: IOrderDetailView | null;
}

const initialState: IOrderState = {
  orders: [],
  order: null,
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCustomerOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.order = action.payload;
      })
      .addCase(deleteCustomerOrder.fulfilled, (state, action) => {
        state.orders = state.orders.filter((order) => order.order.id !== action.payload);
      });
  },
});

const { reducer } = orderSlice;

export default reducer;
