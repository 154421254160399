import { createAsyncThunk } from '@reduxjs/toolkit';

import client from 'src/clients/http';
import { MODAL_IDS } from 'src/constants/modal';
import i18n from 'src/i18n';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import { IOrderDetailView, IOrderView } from 'src/types/customer';
import { toastMessage } from 'src/utils/toast';

export const addOrder = createAsyncThunk<
  any,
  { customerId: string; packageId: string; expiresAt: string }
>('orders/addOrder', async ({ customerId, packageId, expiresAt }, { rejectWithValue }) => {
  try {
    const order = await client.addOrder(customerId, packageId, expiresAt);
    return order;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getOrders = createAsyncThunk<IOrderView[], { customerId: string; productId: string }>(
  'orders/getOrders',
  async (payload, { rejectWithValue }) => {
    try {
      const orders = await client.getOrders(payload.customerId, payload.productId);
      return orders;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getOrder = createAsyncThunk<IOrderDetailView, { customerId: string; orderId: string }>(
  'orders/getOrder',
  async (payload, { rejectWithValue }) => {
    try {
      const order = await client.getOrder(payload.customerId, payload.orderId);
      return order;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getCustomerOrders = createAsyncThunk<IOrderView[], string>(
  'orders/getCustomerOrders',
  async (customerId, { rejectWithValue }) => {
    try {
      const orders = await client.getCustomerOrders(customerId);
      return orders;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteCustomerOrder = createAsyncThunk<
  string,
  { customerId: string; orderId: string }
>('orders/deleteCustomerOrder', async ({ customerId, orderId }, { rejectWithValue, dispatch }) => {
  try {
    await client.deleteCustomerOrder(customerId, orderId);

    toastMessage.success(i18n.t('message.deleteOrder.success'));
    dispatch(closeModal({ modalId: MODAL_IDS.confirmDelete }));

    return orderId;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const getCustomerOrder = createAsyncThunk<
  IOrderDetailView,
  { customerId: string; orderId: string }
>('orders/getCustomerOrder', async (payload, { rejectWithValue }) => {
  try {
    const orders = await client.getCustomerOrder(payload.customerId, payload.orderId);
    return orders;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
