import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IAddOrderMembersRequest,
  IDeleteOrderMember,
  IInviteOrderMemberRequest,
  IOrderMember,
  IResendInviteOrderMemberRequest,
} from 'src/types/customer';
import client from 'src/clients/http';
import { toastMessage } from 'src/utils/toast';
import { MODAL_IDS } from 'src/constants/modal';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import i18n from 'src/i18n';

export const addOrderMembers = createAsyncThunk<IOrderMember, IAddOrderMembersRequest>(
  'orderMembers/addOrderMembers',
  async (payload, { rejectWithValue }) => {
    try {
      const member = await client.addOrderMembers(payload);
      return member;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const inviteOrderMember = createAsyncThunk<IOrderMember, IInviteOrderMemberRequest>(
  'orderMembers/inviteOrderMember',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const orderMember = await client.inviteOrderMember(payload);
      toastMessage.success(i18n.t('message.invite.user.success'));

      dispatch(closeModal({ modalId: MODAL_IDS.customer.orders.addOrderMembers }));
      return orderMember;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const resendInviteOrderMember = createAsyncThunk<void, IResendInviteOrderMemberRequest>(
  'orderMembers/resendInviteOrderMember',
  async (payload, { rejectWithValue }) => {
    try {
      await client.resendInviteOrderMember(payload);
      toastMessage.success(i18n.t('message.reinvite.success'));

      return;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getOrderMembers = createAsyncThunk<
  IOrderMember[],
  { customerId: string; orderId: string }
>('orderMembers/getOrderMembers', async ({ customerId, orderId }, { rejectWithValue }) => {
  try {
    const members = await client.getOrderMembers(customerId, orderId);
    return members;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getOrderMembersNotBelongOrder = createAsyncThunk<
  IOrderMember[],
  { customerId: string; orderId: string }
>(
  'orderMembers/getOrderMembersNotBelongOrder',
  async ({ customerId, orderId }, { rejectWithValue }) => {
    try {
      const members = await client.getOrderMembersNotBelongOrder(customerId, orderId);
      return members;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteOrderMembers = createAsyncThunk<string, IDeleteOrderMember>(
  'orderMembers/deleteOrderMembers',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      await client.deleteOrderMembers(payload);

      dispatch(closeModal({ modalId: MODAL_IDS.confirmDelete }));
      return payload.memberId;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
