import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';

import { IPlayerProps } from '../../types/player';
import { useStyles } from './styles';

function Loading(props: any) {
  const classes = useStyles();
  const slice = props as IPlayerProps;
  const { player } = slice;

  if (player.error) {
    return <Typography>An error occurred video</Typography>;
  }

  return (
    <Box
      className={classNames(classes.loading, {
        [classes.load]: player.waiting,
      })}
    >
      <CircularProgress size={60} style={{ color: '#fff' }} />
    </Box>
  );
}

export default Loading;
