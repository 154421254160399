import { FlashlightOffOutlined, FlashlightOn } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ELightStatus, IPlayerProps } from 'src/components/player_rtc/types/player';
import ButtonWrapper from '../button_wrapper';

function LightToggle(props: any) {
  const { controlBar } = props as IPlayerProps;
  const { t } = useTranslation();

  if (!controlBar?.light) return null;

  const { status, toggleLight } = controlBar.light;

  const handleClick = () => toggleLight();

  const controlText = status === ELightStatus.ON ? t('label.turnOff') : t('label.turnOn');

  return (
    <ButtonWrapper
      title={controlText}
      icon={status === ELightStatus.ON ? FlashlightOn : FlashlightOffOutlined}
      handleClick={handleClick}
      disablePortal
    />
  );
}

export default LightToggle;
