import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getVideoHls } from 'src/redux_store/video/video_action';
import { changeHlsVideoFilterParams, refreshTimeline } from 'src/redux_store/video/video_slice';
import { useStyles } from './styles';

function Calendar() {
  const classes = useStyles();
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const { cameraId, date } = mediaPlayer;
  const dispatch = useAppDispatch();

  const handleOnChange = (newDate: Date | null) => {
    if (!cameraId || !newDate) return;
    dispatch(changeHlsVideoFilterParams({ date: new Date(newDate) }));
    dispatch(getVideoHls({ cameraId, date: newDate }));
    dispatch(refreshTimeline(false));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="date"
        inputFormat="DD/MM/YYYY"
        maxDate={new Date()}
        onChange={handleOnChange}
        value={date}
        renderInput={(params) => (
          <TextField
            {...params}
            size={'small'}
            classes={{ root: classes.root }}
            variant="outlined"
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default Calendar;
