import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import i18n from 'src/i18n';

import {
  IBlacklist,
  ITransportationHistorySearch,
  IBlackListItem,
  IBlacklistHistoryFilter,
} from 'src/types/blacklist';
import { ITransportationList } from 'src/types/transportation';
import { toastMessage } from 'src/utils/toast';

export const getBlackList = createAsyncThunk<IBlacklist, { plate: string; page: number }>(
  'blacklist/getBlackList',
  async ({ plate, page }, { rejectWithValue }) => {
    try {
      const data = await client.getBlackList({
        plate,
        page,
      });
      return data;
    } catch (error: any) {
      toastMessage.error(error.message || i18n.t('message.systemError'));
      return rejectWithValue(error);
    }
  },
);

export const getBlacklistHistory = createAsyncThunk<ITransportationList, IBlacklistHistoryFilter>(
  'blacklist/getBlacklistHistory',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getBlacklistHistory(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message || i18n.t('message.systemError'));
      return rejectWithValue(error);
    }
  },
);

export const createBlackListItem = createAsyncThunk<IBlackListItem, IBlackListItem>(
  'blacklist/createBlackListItem',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.createBlackListItem(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message || i18n.t('message.systemError'));
      return rejectWithValue(error);
    }
  },
);

export const deleteBlacklistById = createAsyncThunk<any, string>(
  'blacklist/deleteBlacklistById',
  async (id, { rejectWithValue }) => {
    try {
      await client.deleteBlacklistById(id);
    } catch (error: any) {
      toastMessage.error(error.message || i18n.t('message.systemError'));
      return rejectWithValue(error);
    }
  },
);

export const updateBlacklistById = createAsyncThunk<
  IBlackListItem,
  {
    id: string;
    payload: IBlackListItem;
  }
>('blacklist/updateBlacklistById', async ({ id, payload }, { rejectWithValue }) => {
  try {
    const data = await client.updateBlacklistById(id, payload);
    return data;
  } catch (error: any) {
    toastMessage.error(error.message || i18n.t('message.systemError'));
    return rejectWithValue(error);
  }
});

export const getTransportationByPlate = createAsyncThunk<
  ITransportationList,
  ITransportationHistorySearch
>('blacklist/getTransportationByPlate', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.getTransportationByPlate(payload);
    return data;
  } catch (error: any) {
    toastMessage.error(error.message || i18n.t('message.systemError'));
    return rejectWithValue(error);
  }
});
