import { ECameraStatus, ICamera } from 'src/types/camera';

export const updateStatusOfCamerasUtil = (
  cameras: ICamera[],
  cameraId: string,
  status: ECameraStatus,
) => {
  const index = cameras.findIndex((camera) => camera.id === cameraId);
  if (index !== -1) {
    const camera: ICamera = { ...cameras[index], status };
    cameras.splice(index, 1, camera);
  }

  return cameras;
};

export const updateStatusOfCameraUtil = (
  camera: ICamera | null,
  cameraId: string,
  status: ECameraStatus,
) => {
  if (!camera) return null;
  if (camera.id === cameraId) {
    camera.status = status;
  }

  return camera;
};

export const deleteCameraOfCamerasUtil = (cameras: ICamera[], cameraId: string) => {
  if (cameras.length) {
    const index = cameras.findIndex((camera) => camera.id === cameraId);
    if (index !== -1) {
      cameras.splice(index, 1);
    }
  }

  return cameras;
};

export const deleteCameraUtil = (camera: ICamera | null, cameraId: string) => {
  if (camera && camera.id === cameraId) {
    camera = null;
  }
  return camera;
};
