import { Component, Fragment } from 'react';
import { isBoolean } from 'lodash';

import Manager from '../../manager';
import { ICamProps, IManager, IPlayer, IPlayerControlBar, IPlayerProps } from '../../types/player';
import { mergeAndSortChildren } from '../../utils/video';
import { VideoDanaPlayer } from './styles';
import CameraName from '../camera_name';
import ControlBar from '../control_bar';
import Loading from '../loading';
import Shortcut from '../shortcut';
import Video from '../video';
// import { CameraStatusEnum } from 'src/types/stream';

interface IPlayerComponentProps {
  cam: ICamProps;
  wsOpen: boolean;
  controlBar?: IPlayerControlBar;
  // isTheaterMode?: boolean;
  // isFollowDisabled?: boolean;
  // isHideControlBar?: boolean;
  // isHideTheaterMode?: boolean;
  // isPtzSmall?: boolean;
  // station?: IStation | null;
  // onClickMode?: () => void;
}

export default class Player extends Component<IPlayerComponentProps> {
  manager = new Manager();
  actions = this.manager.getActions();

  constructor(props: IPlayerComponentProps) {
    super(props);
    this.manager.subscribeToPlayerStateChange(this.handleStateChange.bind(this));
  }

  handleMouseEnter = () => {
    if (isBoolean(this.props.controlBar?.isHover) && !this.props.controlBar?.isHover) return;
    this.actions.setIsControlBar(true);
  };

  handleMouseLeave = () => {
    if (isBoolean(this.props.controlBar?.isHover) && !this.props.controlBar?.isHover) return;
    const playerSlice: IPlayer = this.manager.getState().playerSlice;
    if (playerSlice.isPopover) return;

    this.actions.setIsControlBar(false);
  };

  getChildren = (props: IPlayerProps) => {
    const { ...propsWithoutChildren } = props;

    const defaultChildren = this.getDefaultChildren();
    return mergeAndSortChildren(defaultChildren, [], propsWithoutChildren);
  };

  renderControlBar = () => {
    // const { status, classifiedListId } = this.props.cam;
    // const { OFFLINE, ERROR } = CameraStatusEnum;

    // if ((status === OFFLINE || status === ERROR) && !classifiedListId) return <></>;

    const playerSlice: IPlayer = this.manager.getState().playerSlice;

    if (isBoolean(this.props.controlBar?.isHover) && !this.props.controlBar?.isHover)
      return <ControlBar key="control-bar" order={3.0} />;

    if (!playerSlice.isControlBar) return <Fragment key="control-bar" />;

    return <ControlBar key="control-bar" order={3.0} />;
  };

  getDefaultChildren = () => {
    return [
      <Video
        key="video"
        ref={(c: Video) => {
          this.manager.video = c;
        }}
        order={0.0}
      />,
      <CameraName key="name" order={1.0} />,
      <Loading key="loading" order={2.0} />,
      this.renderControlBar(),
      <Shortcut key="shortcut" />,
    ];
  };

  handleStateChange = () => {
    this.forceUpdate();
  };

  // componentDidUpdate(): void {
  //   if (this.manager.rootElement) {
  //     const { height } = calcVideoSize(this.manager.rootElement);

  //     this.setState({ height });
  //   }
  // }

  render() {
    const playerSlice: IPlayer = this.manager.getState().playerSlice;

    const props: IPlayerProps = {
      ...this.props,
      player: playerSlice,
      actions: this.actions,
      manager: this.manager as IManager,
      store: this.manager.store,
    };

    const children = this.getChildren(props);

    return (
      <VideoDanaPlayer
        ref={(c: HTMLElement | null) => (this.manager.rootElement = c)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
      </VideoDanaPlayer>
    );
  }
}
