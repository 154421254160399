import React from 'react';
import config from 'src/config';
import { PlayerHls } from '../player_hls';
import { PlayerRTC } from '../player_rtc';
import { ICamProps, IPlayerControlBar } from '../player_rtc/types/player';
// import { IStation } from 'src/types/station';

interface IPlayerProps {
  cam: ICamProps;
  wsOpen: boolean;
  controlBar?: IPlayerControlBar;
  // isTheaterMode?: boolean;
  // isFollowDisabled?: boolean;
  // isHideControlBar?: boolean;
  // isHideTheaterMode?: boolean;
  // isPtz?: boolean;
  // isPtzSmall?: boolean;
  // onClickTheaterMode?: () => void;
  // onClickMode?: () => void;
  // station?: IStation | null;
}

function PlayerOptions(props: IPlayerProps) {
  if (config.playerType === 'HLS') return <PlayerHls {...props} />;

  return <PlayerRTC {...props} />;
}

export default PlayerOptions;
