import config from 'src/config';
import store from 'src/redux_store';
// import  playerStore from './../redux_store/store'
import { store as playerStore } from '../redux_store/store';
import RTC from './rtc';
import { IRequestMessage, IResponseMessage, TViewerResponseStatus } from '../types/message';
import { debugLog, initDebugLog } from '../utils/log';
import { actions } from '../redux_store/player/player_slice';
import { toastMessage } from 'src/utils/toast';

// const SOCKET_SERVER_URL = 'wss://camera.danateq.vn/signaling';
const dispatch = playerStore.dispatch;

class Signaling {
  ws?: WebSocket;
  rtcList: Map<string, RTC>;
  disconnectNumber: number;
  debug?: boolean;
  timeoutId?: NodeJS.Timeout;

  constructor() {
    this.rtcList = new Map<string, RTC>();
    this.disconnectNumber = 0;
    this.debug = false;
  }

  get authState() {
    const {
      me: { id },
      token,
    } = store.getState().myAccountSlice;
    return { userId: id, token };
  }

  connect = (debug?: boolean) => {
    try {
      this.debug = debug;
      initDebugLog(debug);

      debugLog({ 'ws connect': config.signalingUrl });
      this.ws = new WebSocket(config.signalingUrl);

      // this.ws.onopen = this.wsOnOpen;
      this.ws.onmessage = this.wsOnMessage;
      // this.ws.onerror = this.wsOnError;
      // this.ws.onclose = this.wsOnClose;
    } catch (error) {
      console.log({ error });
    }
  };

  addRtc = (viewerId: string, rtc: RTC) => {
    this.rtcList.set(viewerId, rtc);
  };

  deleteRtc = (viewerId: string) => {
    if (this.rtcList.has(viewerId)) {
      this.rtcList.delete(viewerId);
    }
  };

  wsAuth = () => {
    this.sendMessage({
      token: this.authState.token as string,
      id: 'authen',
    });
  };

  // wsOnOpen = () => {
  //   console.log('vo day', actions);
  //   // debugLog({ 'Signaling.wsOnOpen.disconnectNumber': this.disconnectNumber });
  //   // if (!this.disconnectNumber) return;
  //   // const [rtc] = Array.from(this.rtcList.values());
  //   // rtc.initTrack();

  //   // this.disconnectNumber = 0;
  // };

  wsOnMessage = (message: any) => {
    const parsedMessage: IResponseMessage = JSON.parse(message.data);
    const viewerId = parsedMessage.viewerId;

    const rtc = this.rtcList.get(viewerId as string);

    switch (parsedMessage.id) {
      case 'camera':
        debugLog({ 'Signaling.wsOnMessage.switch.case.camera': rtc });
        if (!rtc) return;
        rtc.createPcPeerConnection();
        break;
      case 'viewerAccepted':
        if (!rtc) return;
        this.viewerResponse(parsedMessage, 'accepted');
        break;
      case 'viewerRejected':
        if (!rtc) return;
        this.viewerResponse(parsedMessage, 'rejected');
        break;
      case 'audioError':
        toastMessage.error(parsedMessage.error ? parsedMessage.error : 'Unknown error');
        dispatch(actions.setError({ id: 'audioError', message: parsedMessage.error }));
        break;
      case 'alarmError':
        toastMessage.error(parsedMessage.error ? parsedMessage.error : 'Unknown error');
        break;
      case 'iceCandidate':
        if (!rtc) return;
        if (parsedMessage.iceCandidate) {
          const candidate = parsedMessage.iceCandidate;
          if (candidate) {
            if (!rtc.pc) return;
            if (rtc.pc.remoteDescription) {
              rtc.pc.addIceCandidate({
                candidate: candidate.candidate,
                sdpMid: candidate.sdpMid,
                sdpMLineIndex: candidate.sdpMLineIndex,
              });
            } else {
              rtc.iceCandidateQueue.push(candidate);
            }
          }
        }
        break;
      case 'viewerError':
        console.log('wsOnMessage error', { parsedMessage, rtc, error: parsedMessage.error });
        if (!rtc) return;
        rtc.actions.setError({
          id: 'viewerError',
          message: parsedMessage.error,
        });
        break;
      case 'signalingError':
        console.log('wsOnMessage error', { parsedMessage });
        dispatch(actions.setError({ id: 'signalingError', message: parsedMessage.error }));
        break;
      default:
        console.warn('unrecognized message', parsedMessage);
        break;
    }
  };

  wsOnError = (ev: Event) => {
    console.log('Ws onError');
    debugLog({ 'Signaling.wsOnError': ev });
    if (this.disconnectNumber >= 5) return this.close();

    this.timeoutId = setTimeout(() => {
      console.log(`Signaling reconnect ${this.disconnectNumber}`);
      this.connect(this.debug);
    }, 5000);

    this.disconnectNumber++;
    // this.dispose();
    // this.close();
  };

  wsOnClose = (ev: CloseEvent) => {
    console.log('Ws onClose');
    debugLog({ 'WS.wsOnClose': ev });
  };

  viewerResponse = (message: IResponseMessage, status: TViewerResponseStatus) => {
    if (status === 'rejected') {
      const errorMsg = message.error ? message.error : 'Unknown error';
      console.warn('viewerResponse rejected: ', errorMsg);

      this.dispose(message.viewerId as string);
      return;
    }

    if (status === 'accepted') {
      if (!message.sdpAnswer) return;

      const sdpAnswer = message.sdpAnswer;
      const viewerId = message.viewerId;

      const rtc = this.rtcList.get(viewerId as string);
      rtc?.acceptOffer('answer', sdpAnswer);
    }
  };

  sendMessage = (message: IRequestMessage) => {
    const jsonMessage = JSON.stringify(message);

    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      // debugLog({ sendMessage: jsonMessage });
      this.ws.send(jsonMessage);
    } else {
      console.warn('ws.readyState != WebSocket.OPEN');
    }
  };

  dispose = (viewerId: string) => {
    console.log('Signaling.dispose', viewerId);
    this.close();
    const rtc = this.rtcList.get(viewerId as string);
    rtc?.close();
  };

  close = () => {
    console.log('WS Close', this.ws?.readyState, this.ws, WebSocket.CLOSED);
    debugLog({ 'WS.close': 'closed', id: this.timeoutId });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.disconnectNumber = 0;
      this.timeoutId = undefined;
    }

    this.rtcList.clear();

    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) return;
    this.ws.close();

    console.log('Close done');
  };
}

export const signaling = new Signaling();
export default Signaling;
