import ClientBase from './base';

export interface ClientPtzMix {
  initPTZ: (cameraId: string, stationId?: string) => Promise<string>;
  startPTZControl: (
    cameraId: string,
    { pan, tilt, zoom }: { pan: string; tilt: string; zoom: string },
    stationId?: string,
  ) => Promise<string>;
  stopPTZControl: (cameraId: string, stationId?: string) => Promise<string>;
  autoFocus: (cameraId: string, stationId?: string) => Promise<string>;
  syncDateTime: (cameraId: string, stationId?: string) => Promise<string>;
}

const ClientPtz = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientPtzMix {
    initPTZ = async (cameraId: string, stationId?: string) => {
      if (stationId) {
        return this.doFetch<string>(
          `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/ptz/init`,
          {
            method: 'GET',
          },
        );
      }

      return this.doFetch<string>(`${this.getPtzRoute()}/${cameraId}/ptz/init`, {
        method: 'GET',
      });
    };

    startPTZControl = async (
      cameraId: string,
      data: { pan: string; tilt: string; zoom: string },
      stationId?: string,
    ) => {
      if (stationId) {
        return this.doFetch<string>(
          `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/ptz/move`,
          {
            method: 'PUT',
            data,
          },
        );
      }

      return this.doFetch<string>(`${this.getPtzRoute()}/${cameraId}/ptz/move`, {
        method: 'PUT',
        data,
      });
    };

    stopPTZControl = async (cameraId: string, stationId?: string) => {
      if (stationId) {
        return this.doFetch<string>(
          `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/ptz/stop`,
          {
            method: 'PUT',
            data: {},
          },
        );
      }

      return this.doFetch<string>(`${this.getPtzRoute()}/${cameraId}/ptz/stop`, {
        method: 'PUT',
        data: {},
      });
    };

    autoFocus = async (cameraId: string, stationId?: string) => {
      if (stationId) {
        return this.doFetch<string>(
          `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/ptz/auto_focus`,
          {
            method: 'PUT',
            data: {},
          },
        );
      }

      return this.doFetch<string>(`${this.getPtzRoute()}/${cameraId}/ptz/auto_focus`, {
        method: 'PUT',
        data: {},
      });
    };

    syncDateTime = async (cameraId: string, stationId?: string) => {
      if (stationId) {
        return this.doFetch<string>(
          `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/ptz/sync_date_time`,
          {
            method: 'PUT',
            data: {},
          },
        );
      }

      return this.doFetch<string>(`${this.getPtzRoute()}/${cameraId}/ptz/sync_date_time`, {
        method: 'PUT',
        data: {},
      });
    };
  };

export default ClientPtz;
