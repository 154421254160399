import { Avatar, Box, Typography } from '@mui/material'
import { useAppSelector } from 'src/redux_store'
import { OrganizationMember } from 'src/types'

interface Props {
  orgId: string
  member: OrganizationMember
}

const MemberRow = ({ member }: Props) => {
  const { me } = useAppSelector((state) => state.myAccountSlice)
  return (
    <Box display={'flex'} flexDirection="row" py={1.5}>
      <Avatar src={member.user.avatarUrl} />
      <Box px={1}>
        <Box display={'flex'} flexDirection={'row'}>
          <Typography fontWeight={'bold'} pr={1}>
            {member.user.name}
          </Typography>
          {member.user.id === me.id && <Typography>(You)</Typography>}
        </Box>
        <Typography>{member.user.email}</Typography>
      </Box>
      <Box display={'flex'} ml="auto" alignItems={'center'} justifyContent={'center'}>
        <Typography fontWeight={'bold'}>
          {/* {Roles.owner.id === member.role.id ? Roles.owner.name : Roles.member.name} */}
          {member.role?.name}
        </Typography>
      </Box>
      {/* <Box pl={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <IconButton onClick={handleClick} disabled={role.level === Roles.owner.level && member.user.id === me.id}>
          <MoreVert />
        </IconButton>
        <Menu
          id="member-options"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={1}
          sx={{ minWidth: 320 }}
        >
          {member.roleId !== Roles.owner.id && member.status === OrganizationMemberStatuses.ACCEPTED && (
            <MenuItem onClick={handleClose}>Chuyển quyền sở hữu</MenuItem>
          )}
          <MenuItem onClick={deleteMember} sx={{ minWidth: 120 }} style={{ color: 'red', fontWeight: 'bold' }}>
            {t('button.delete')}
          </MenuItem>
        </Menu>
      </Box> */}
    </Box>
  )
}

export default MemberRow
