export const StationWorkingModes = {
  CHECKIN: 'checkin',
  SCHEDULED: 'scheduled',
  FULLTIME: 'fulltime',
} as const

export type StationWorkingMode = (typeof StationWorkingModes)[keyof typeof StationWorkingModes]

export type StationWorkingModeData = {
  mode: StationWorkingMode
  schedule: StationWorkingSchedule
  stream: {
    token: string
    resolution: { height: number; width: number }
  }
}

export interface StationWorkingTime {
  startHour: number
  startMinute: number
  endHour: number
  endMinute: number
}
export interface StationWorkingSchedule {
  monday: StationWorkingTime[]
  tuesday: StationWorkingTime[]
  wednesday: StationWorkingTime[]
  thursday: StationWorkingTime[]
  friday: StationWorkingTime[]
  saturday: StationWorkingTime[]
  sunday: StationWorkingTime[]
}

export const PowerBatteryStatuses = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
} as const

export type PowerBatteryStatus = (typeof PowerBatteryStatuses)[keyof typeof PowerBatteryStatuses]

export const LightStatuses = {
  ON: 'ON',
  OFF: 'OFF',
} as const

export type LightStatus = (typeof LightStatuses)[keyof typeof LightStatuses]

export const BatteryChargeStatuses = {
  CHARGE: 'charge',
  DISCHARGE: 'discharge',
} as const

export type BatteryChargeStatus = (typeof BatteryChargeStatuses)[keyof typeof BatteryChargeStatuses]

export const MobileNetworkStatuses = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
} as const

export type MobileNetworkStatus = (typeof MobileNetworkStatuses)[keyof typeof MobileNetworkStatuses]

export const StationStatuses = {
  NORMAL: 'normal',
  WARNING: 'warning',
  ERROR: 'error',
  OFFLINE: 'offline',
  ACTIVE: 'activating',
  INACTIVE: 'inactive',
  CONNECTING: 'connecting',
} as const

export type StationStatus = (typeof StationStatuses)[keyof typeof StationStatuses]

export const MobileNetworks = {
  '3G': '3G',
  '4G': '4G',
  '5G': '5G',
} as const

export type MobileNetwork = (typeof MobileNetworks)[keyof typeof MobileNetworks]

export const GPSStatuses = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
} as const

export type GPSStatus = (typeof GPSStatuses)[keyof typeof GPSStatuses]

export interface StationCamera {
  id: string
  name: string
  lat: number
  lng: number
  address: string
  snapshot: string
  status: string
  isEnabledMic: boolean
  isEnabledPtz: boolean
  isEnabledSpeaker: boolean
  isSharedLink: boolean
  isSupportAudio: boolean
  isSupportPtz: boolean
  isSyncDateTime: boolean
}

export interface Station {
  id: string
  uuid: string
  name: string
  status: StationStatus
  lat: number
  lng: number
  address: string
  detailAddress: string
  province: number
  district: number
  commune: number
  functionEnabled: boolean
  mobileNetwork: MobileNetwork
  mobileNetworkOperator: string
  mobileNetworkWaveStrength: number
  mobileNetworkQuota: number
  mobileNetworkQuotaUnit: string
  mobileNetworkDataUsed: string // TODO: change to number
  mobileNetworkConnectionStatus: MobileNetworkStatus
  mobileNetworkLastUpdatedAt: string
  gpsConnectionStatus: GPSStatus
  gpsWaveStrength: number
  gpsSystemName: string
  gpsLastUpdatedAt: string
  powerElectricLine: boolean
  powerElectricLineStatus: string
  powerSolar: boolean
  powerSolarStatus: string
  powerBattery: boolean
  powerBatteryChargeStatus: BatteryChargeStatus
  powerBatteryCapacityStatus: number
  powerBatteryHealth: number
  powerBatteryLastUpdatedAt: string
  powerBatteryConnectionStatus: PowerBatteryStatus
  isEnableMic: boolean
  isEnableSpeaker: boolean
  isEnableLight: boolean
  lightStatus: LightStatus
  cameras: StationCamera[]
  workingMode: string
  workingSchedule: StationWorkingSchedule
}

export interface ListStationsOptions {
  searchKeyword?: string
  province?: string
  district?: string
  commune?: string
  status?: string
}

export const StationLogLevels = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  CRITICAL: 'CRITICAL',
  ALL: 'ALL',
} as const

export type StationLogLevel = (typeof StationLogLevels)[keyof typeof StationLogLevels]

export interface StationLog {
  level: StationLogLevel
  type: string
  time: string
  title: string
  description: string
}

export type Pagination<T = any> = {
  totalPage: number
  data: T[]
}

export interface Light {
  id: string
  name: string
  status: LightStatus
}

export interface StationFunction {
  functionEnabled: boolean
  lights: Light[]
  cameras: StationCamera[]
  workingMode: StationWorkingMode
  workingSchedule: StationWorkingSchedule
  currentStream: StationFunctionStream
  streams: StationFunctionStream[]
}

export interface StationFunctionStream {
  name: string
  token: string
  resolution: {
    width: number
    height: number
  }
  resolutionRangeList: {
    width: number
    height: number
  }[]
  quality: number
  qualityRange: {
    min: number
    max: number
  }
  fps: number
  fpsRange: {
    min: number
    max: number
  }
  streamEnabled: boolean
  recordEnabled: boolean
}

export type StationStatusUpdated = {
  stationId: string
  status: StationStatus
  timestamp: number
}

export interface StationGPS {
  address: string
  province: number
  commune: number
  district: number
  gpsConnectionStatus: GPSStatus
  gpsLastUpdatedAt: string
  gpsWaveStrength: number
  lat: number
  lng: number
}

export interface StationGPSUpdated extends StationGPS {
  stationId: string
  timestamp: number
}

export interface StationGPSStatusUpdated {
  stationId: string
  timestamp: number
  gpsStatus: GPSStatus
}

export type StationBatteryUpdated = {
  stationId: string
  timestamp: number
  powerBattery: boolean
  powerBatteryCapacityStatus: number
  powerBatteryChargeStatus: BatteryChargeStatus
  powerBatteryLastUpdatedAt: string
}

export type StationMobileNetworkUpdated = {
  stationId: string
  timestamp: number
  mobileNetwork: MobileNetwork
  mobileNetworkConnectionStatus: MobileNetworkStatus
  mobileNetworkDataUsed: string
  mobileNetworkLastUpdatedAt: string
  mobileNetworkOperator: string
  mobileNetworkWaveStrength: number
}

export type StationWorkingModeUpdated = {
  stationId: string
  timestamp: number
  workingMode: StationWorkingMode
  workingSchedule: StationWorkingSchedule
}
