import apiSlice from './api/api_slice'
import cameraSlice from './camera/camera_slice'
import modalSlice from './common/modal/modal_slice'
import promptSlice from './common/prompt/prompt_slice'
import topBarSlice from './common/topbar/topbar_slice'
import userSlice from './user/user_slice'
import roleSlice from './role/role_slice'
import permissionSlice from './permission/permission_slice'
import ptzSlice from './ptz/ptz_slice'
import stationSlice from './station/station_slice'
import zoneSlice from './zone/zone_slice'
import myAccountSlice from './my_account/my_account_slice'
import watchlistSlice from './watchlist/watchlist_slice'
import streamSlice from './stream/stream_slice'
import videoSlice from './video/video_slice'
import systemSlice from './system/system_slice'
import notificationSlice from './notification/notification_slice'
import blacklistSlice from './blacklist/blacklist_slice'
import mapSlice from './map/map_slice'
import customerMembersSlice from './customer/customer_members/slice'

import customerSlice from './customer/customers/slice'
import orderSlice from './customer/orders/slice'
import orderCameraSlice from './customer/order_cameras/slice'
import orderRoleSlice from './customer/order_roles/slice'
import orderMemberSlice from './customer/order_members/slice'
import orderStationSlice from './customer/order_stations/slice'
import productPackageSlice from './customer/product_packages/slice'
import productSlice from './customer/products/slice'

import organizations from './organizations/slice'

export const reducer = {
  userSlice,
  cameraSlice,
  zoneSlice,
  ptzSlice,
  stationSlice,
  roleSlice,
  permissionSlice,
  myAccountSlice,
  watchlistSlice,
  streamSlice,
  videoSlice,
  promptSlice,
  modalSlice,
  topBarSlice,
  systemSlice,
  notificationSlice,
  blacklistSlice,
  mapSlice,

  //customer
  customerSlice,
  customerMembersSlice,
  orderSlice,
  orderCameraSlice,
  orderStationSlice,
  orderRoleSlice,
  orderMemberSlice,
  productSlice,
  productPackageSlice,

  // last reducer require
  apiSlice,

  organizations,
}
