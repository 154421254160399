import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICameraFeature, ICameraStream, IZone } from 'src/types/camera';
import { TVehicleType } from 'src/types/events';
import {
  getCameraFunction,
  getCameraStationFunction,
  updateCameraFunction,
} from '../camera/camera_action';

interface IZoneState {
  featuresZone: ICameraFeature[];
  drewFeaturesZone: ICameraFeature[];
  controlDraw: {
    id?: any;
    status: any;
  };
  controlDrawDirect: {
    id?: any;
    status: any;
  };
  dimension: {
    width: number;
    height: number;
  } | null;
  idDirect: any;
  idShape: string[];
  ratio: any;
  shapeSelected: any;
}

const initialState: IZoneState = {
  featuresZone: [],
  drewFeaturesZone: [],
  controlDraw: {
    id: null,
    status: null,
  },
  controlDrawDirect: {
    id: null,
    status: null,
  },
  dimension: null,
  idDirect: null,
  idShape: [],
  ratio: null,
  shapeSelected: null,
};

const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    setFeaturesZone: (state: IZoneState, action: PayloadAction<ICameraFeature[]>) => {
      state.featuresZone = action.payload;
    },
    addDrewFeaturesZone: (state: IZoneState, action: PayloadAction<ICameraFeature[]>) => {
      state.drewFeaturesZone = [...state.drewFeaturesZone, ...action.payload];
    },
    setDrewFeaturesZone: (state: IZoneState, action: PayloadAction<ICameraStream | null>) => {
      state.controlDraw = initialState.controlDraw;
      state.controlDrawDirect = initialState.controlDrawDirect;
      state.idDirect = null;

      const idShape: string[] = [];
      state.drewFeaturesZone.forEach((feature) => {
        feature.zones.forEach((zone) => {
          if (zone?.id) {
            idShape.push(zone.id);
          }
        });
      });

      state.idShape = idShape;

      const featuresZone = action.payload?.aiFeatures || [];
      state.drewFeaturesZone = featuresZone;
    },
    resetZones: (state: IZoneState) => {
      state.featuresZone = initialState.featuresZone;
      state.drewFeaturesZone = initialState.drewFeaturesZone;
      state.controlDraw = initialState.controlDraw;
      state.controlDrawDirect = initialState.controlDrawDirect;
      state.dimension = initialState.dimension;
      state.idDirect = initialState.idDirect;
      state.idShape = initialState.idShape;
      state.ratio = initialState.ratio;
      state.shapeSelected = initialState.shapeSelected;
    },
    isDrawZone: (state, action: PayloadAction<IZone>) => {
      state.controlDraw = {
        status: 1,
        ...action.payload,
      };
      state.controlDrawDirect = {
        status: null,
      };
      state.idDirect = null;
      state.idShape = [];
    },
    isDrawArrow: (state, action: PayloadAction<IZone>) => {
      state.controlDrawDirect = {
        status: 1,
        ...action.payload,
      };
      state.controlDraw = {
        status: null,
      };
      state.idShape = [];
      state.idDirect = null;
    },
    onChangeTypeDraw: () => {
      console.log('onChangeTypeDraw');
    },
    finishShape: (state, action) => {
      let status = null;

      if (state.controlDrawDirect.status) {
        status = 2;
      }

      const result = handleFinishShape(state.drewFeaturesZone, action.payload);

      state.controlDraw = {
        ...state.controlDraw,
        status: 2,
      };
      state.controlDrawDirect = {
        ...state.controlDrawDirect,
        status,
      };
      state.drewFeaturesZone = result;
    },
    addDimension: (
      state,
      action: PayloadAction<{
        width: number;
        height: number;
      }>,
    ) => {
      state.dimension = {
        width: action.payload.width,
        height: action.payload.height,
      };
    },
    removeFeatureShape: (
      state,
      action: PayloadAction<{
        key: string;
        index: number;
      }>,
    ) => {
      const drewFeaturesZone = [...state.drewFeaturesZone];
      const drewFeature = state.drewFeaturesZone.find(
        (feature, index) => feature.key === action.payload.key && index === action.payload.index,
      );
      const idShape: string[] = [];
      if (drewFeature) {
        drewFeature.zones.forEach((zone) => {
          if (zone?.id) {
            idShape.push(zone.id);
          }
        });
      }

      drewFeaturesZone.splice(action.payload.index, 1);

      state.idShape = idShape;
      state.idDirect = null;
      state.shapeSelected = null;
      state.drewFeaturesZone = drewFeaturesZone;
      state.controlDraw = {
        status: null,
      };
      state.controlDrawDirect = {
        status: null,
      };
    },

    removeZoneShape: (state, action: PayloadAction<{ featureKey: string; zoneId: string }>) => {
      const drewFeaturesZone = [...state.drewFeaturesZone].map((feature) => {
        if (feature.key === action.payload.featureKey) {
          const zones = feature.zones.map((zone) => {
            if (zone.id === action.payload.zoneId) {
              return { ...zone, vertices: [], arrow: [] };
            } else {
              return zone;
            }
          });
          return { ...feature, zones };
        } else {
          return feature;
        }
      });

      state.idShape = [action.payload.zoneId];
      state.idDirect = null;
      state.shapeSelected = null;
      state.drewFeaturesZone = drewFeaturesZone;
      state.controlDraw = {
        status: null,
      };
      state.controlDrawDirect = {
        status: null,
      };
    },

    removeDirect: (state, action: PayloadAction<string>) => {
      const result = handleRemoveDirect(state.drewFeaturesZone, action.payload);

      state.idDirect = action.payload;
      state.drewFeaturesZone = [...result];
      state.controlDrawDirect = {
        status: null,
      };
    },
    shapeSelected: (state, action: PayloadAction<string>) => {
      state.shapeSelected = action.payload;
    },
    setupRatio: (state, action) => {
      state.ratio = action.payload;
    },
    cancelDraw: (state) => {
      state.controlDraw = {
        status: null,
      };
    },
    resetZonesDrew: () => {
      console.log('resetZonesDrew');
    },
    removeIdDirect: (state) => {
      state.idDirect = null;
    },
    cancleDrawzone: (state) => {
      state.controlDraw = {
        status: null,
      };
    },
    addTimes: (
      state,
      action: PayloadAction<{
        id: string;
        time: {
          start: Date;
          end: Date;
        }[];
      }>,
    ) => {
      const zoneOsUpdateTime = handleAddTime(state.drewFeaturesZone, action.payload);

      state.drewFeaturesZone = zoneOsUpdateTime;
    },
    deleteSingleTime: (
      state,
      action: PayloadAction<{
        index: number;
        id: string;
      }>,
    ) => {
      const resultDelete = handleDeleteSingleTime(state.drewFeaturesZone, action.payload);
      state.drewFeaturesZone = resultDelete;
    },
    changeParamsTime: (
      state,
      action: PayloadAction<{
        time: Date;
        name: 'start' | 'end';
        index: number;
        id: string;
      }>,
    ) => {
      const resultChangeParams = handleChangParamsTime(state.drewFeaturesZone, action.payload);

      state.drewFeaturesZone = [...resultChangeParams];
    },
    addVehicle: (
      state,
      action: PayloadAction<{
        id: string;
        vehicle: TVehicleType[];
      }>,
    ) => {
      const zoneOsUpdateVehicle = handleAddVehicle(state.drewFeaturesZone, action.payload);

      state.drewFeaturesZone = zoneOsUpdateVehicle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCameraFunction.fulfilled, (state, action) => {
      const functions = action.payload;
      const featuresZone = functions?.aiEnabled ? functions.aiEnabled?.aiFeatures || [] : [];
      state.drewFeaturesZone = featuresZone;
      state.featuresZone = functions?.features || [];
    });

    builder.addCase(getCameraStationFunction.fulfilled, (state, action) => {
      const functions = action.payload;
      const featuresZone = functions?.aiEnabled ? functions.aiEnabled?.aiFeatures || [] : [];
      state.drewFeaturesZone = featuresZone;
      state.featuresZone = functions?.features || [];
    });

    builder.addCase(updateCameraFunction.pending, (state) => {
      state.shapeSelected = null;
    });
  },
});

export const {
  setFeaturesZone,
  isDrawArrow,
  isDrawZone,
  removeDirect,
  removeIdDirect,
  removeFeatureShape,
  removeZoneShape,
  resetZonesDrew,
  setupRatio,
  shapeSelected,
  addDimension,
  addTimes,
  addVehicle,
  finishShape,
  onChangeTypeDraw,
  cancelDraw,
  cancleDrawzone,
  changeParamsTime,
  deleteSingleTime,
  addDrewFeaturesZone,
  resetZones,
  setDrewFeaturesZone,
} = zoneSlice.actions;

const handleAddTime = (
  drewFeatureZones: ICameraFeature[],
  payload: {
    id: string;
    time: {
      start: Date;
      end: Date;
    }[];
  },
) => {
  const { time, id } = payload;

  const result = [...drewFeatureZones];
  if (!result.length) return result;
  for (const feature of result) {
    for (const zone of feature.zones) {
      if (zone.id !== id) continue;
      zone.time = [...(zone?.time || []), ...time];
    }
  }
  return result;
};

const handleAddVehicle = (
  drewFeatureZones: ICameraFeature[],
  payload: {
    id: string;
    vehicle: TVehicleType[];
  },
) => {
  const result = [...drewFeatureZones];
  if (!result.length) return result;
  for (const feature of result) {
    for (const zone of feature.zones) {
      if (!payload || (payload && zone.id !== payload.id)) continue;
      zone.vehicle = [...payload.vehicle];
    }
  }
  return result;
};

const handleDeleteSingleTime = (
  drewFeatureZones: ICameraFeature[],
  payload: {
    index: number;
    id: string;
  },
) => {
  const { index, id } = payload;
  const result = [...drewFeatureZones];

  if (!result.length) return result;

  for (const feature of result) {
    for (const zone of feature.zones) {
      if (zone.id !== id || zone?.time === undefined || zone?.time.length === 0 || index < -1)
        continue;
      if (zone.id === id) {
        zone.time.splice(index, 1);
      }
    }
  }

  return result;
};

const handleChangParamsTime = (
  drewFeatureZones: ICameraFeature[],
  payload: {
    time: Date;
    name: 'start' | 'end';
    index: number;
    id: string;
  },
) => {
  const { time, name, index, id } = payload;

  const result = [...drewFeatureZones];
  if (!result.length) return result;
  for (const feature of result) {
    for (const zone of feature.zones) {
      if (!zone.time?.length || zone.id !== id) continue;
      zone.time[index][name] = new Date(time);
    }
  }
  return result;
};

const handleFinishShape = (drewFeatureZones: ICameraFeature[], zones: IZone[]) => {
  const result = [...drewFeatureZones];
  if (!result.length) return result;
  for (const feature of result) {
    for (const zone of feature.zones) {
      for (const shape of zones) {
        if (shape.id !== zone.id) continue;
        zone.vertices = shape.vertices;

        if (!shape.arrow) continue;
        zone.arrow = shape.arrow;
      }
    }
  }
  return result;
};

const handleRemoveDirect = (drewFeatureZones: ICameraFeature[], id: string) => {
  const result = [...drewFeatureZones];
  if (!result.length) return result;
  for (const feature of result) {
    for (const zone of feature.zones) {
      if (zone.id !== id) continue;
      zone.arrow = [];
    }
  }

  return result;
};

export default zoneSlice.reducer;
