import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { ELeverLog, TLevelLog } from 'src/types/log';

const renderIconLog = (level: TLevelLog) => {
  switch (level) {
    case ELeverLog.INFO:
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 10C14.489 10 10 14.489 10 20C10 25.511 14.489 30 20 30C25.511 30 30 25.511 30 20C30 14.489 25.511 10 20 10ZM20 12C24.4301 12 28 15.5699 28 20C28 24.4301 24.4301 28 20 28C15.5699 28 12 24.4301 12 20C12 15.5699 15.5699 12 20 12ZM19 15V17H21V15H19ZM19 19V25H21V19H19Z"
            fill="#3F51B5"
          />
          <rect x="1" y="1" width="38" height="38" rx="19" stroke="#3F51B5" strokeWidth="2" />
        </svg>
      );

    case ELeverLog.WARNING:
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 11.99L27.53 25H12.47L20 11.99ZM20 8L9 27H31L20 8ZM21 22H19V24H21V22ZM21 16H19V20H21V16Z"
            fill="#F3B238"
          />
          <rect x="1" y="1" width="38" height="38" rx="19" stroke="#F3B238" strokeWidth="2" />
        </svg>
      );

    case ELeverLog.ERROR:
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7042_139705)">
            <path
              d="M22.59 16L20 18.59L17.41 16L16 17.41L18.59 20L16 22.59L17.41 24L20 21.41L22.59 24L24 22.59L21.41 20L24 17.41L22.59 16ZM20 10C14.47 10 10 14.47 10 20C10 25.53 14.47 30 20 30C25.53 30 30 25.53 30 20C30 14.47 25.53 10 20 10ZM20 28C15.59 28 12 24.41 12 20C12 15.59 15.59 12 20 12C24.41 12 28 15.59 28 20C28 24.41 24.41 28 20 28Z"
              fill="#D63032"
            />
          </g>
          <rect x="1" y="1" width="38" height="38" rx="19" stroke="#D63032" strokeWidth="2" />
          <defs>
            <clipPath id="clip0_7042_139705">
              <rect width="24" height="24" fill="white" transform="translate(8 8)" />
            </clipPath>
          </defs>
        </svg>
      );

    default:
      return (
        <svg
          width="45"
          height="45"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 11.99L27.53 25H12.47L20 11.99ZM20 8L9 27H31L20 8ZM21 22H19V24H21V22ZM21 16H19V20H21V16Z"
            fill="#D63032"
          />
          <rect x="1" y="1" width="38" height="38" rx="19" stroke="#D63032" strokeWidth="2" />
        </svg>
      );
  }
};

const CardLog = ({
  title,
  time,
  level,
  description,
}: {
  title: string;
  time: Date;
  level: TLevelLog;
  description: string;
}) => {
  return (
    <Box display={'flex'} gap={1} padding={'10px 0'}>
      <Box width={45}>{renderIconLog(level)}</Box>
      <Box overflow={'hidden'} display={'flex'} gap={0.3} flexDirection={'column'}>
        <Typography
          component={'p'}
          sx={{
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            // fontWeight: '500',
            fontSize: '16px',
          }}
        >
          {title}
        </Typography>
        <Typography component={'span'} fontSize={'14px'} title={description}>
          {description}
        </Typography>
        <Typography fontSize={'11px'}>{moment(time).format('DD/MM/yyyy HH:mm:ss')}</Typography>
      </Box>
    </Box>
  );
};

export default CardLog;
