import { Store } from '@reduxjs/toolkit';
import { ECameraStatus } from 'src/types/camera';
// import { IStation } from 'src/types/station';
import { ReactNode } from 'react';
import Video from '../components/video';
import Manager from '../manager';

export interface IManager {
  rootElement: HTMLElement;
  store: Store;
  video: Video | null;
}

export interface IPlayer {
  paused: boolean;
  isFullscreen: boolean;
  waiting: boolean;
  isWsOpen: boolean;
  isMuted: boolean;
  isAudioStarting: boolean;
  isAlarm: boolean;
  isAudioNotSupport: boolean;
  // error: {
  //   message: string;
  //   detail?: string;
  // } | null;
  error: IPlayerError | null;
  peerConnectionIssue?: number;
  isControlBar: boolean;
  isPopover: boolean;
}

export interface IPlayerError {
  id: 'viewerError' | 'signalingError' | 'audioError' | 'networkError' | 'rtcError' | 'error';
  message: string;
  detail?: string;
}

export interface IActions {
  snapshot: () => void;
  toggleFullscreen: (rootEl: HTMLElement | null, player: IPlayer) => void;
  changeFullscreen: (isFullscreen: boolean) => void;
  play: () => void;
  pause: () => void;
  waiting: () => void;
  mute: () => void;
  unmute: () => void;
  playing: () => void;
  canPlay: () => void;
  canPlayThrough: () => void;
  startAlarm: () => void;
  stopAlarm: () => void;
  startAudio: () => void;
  stopAudio: () => void;
  setError: (payload: IPlayerError | null) => void;
  setIsAudioNotSupport: () => void;
  setPeerConnectionIssue: (payload: number) => void;
  setIsControlBar: (active: boolean) => void;
  setIsPopover: (active: boolean) => void;
}

export interface ICamProps {
  classifiedListId?: string;
  position?: number;
  name: string;
  status: ECameraStatus;
  id: string;
  isSupportAudio?: boolean;
  isSupportPtz?: boolean;
  isEnabledMic?: boolean;
  isEnabledPtz?: boolean;
  isEnabledSpeaker?: boolean;
}

export interface IPlayerProps {
  actions: IActions;
  cam: ICamProps;
  wsOpen: boolean;
  // isTheaterMode?: boolean;
  // isFollowDisabled?: boolean;
  // isHideControlBar?: boolean;
  // isPtzSmall?: boolean;
  manager: IManager | Manager;
  player: IPlayer;
  store: Store;
  order?: number;
  key?: string;
  // onClickTheaterMode?: () => void;
  // onClickMode?: () => void;
  // isHideTheaterMode?: boolean;
  // station?: IStation | null;
  controlBar?: IPlayerControlBar;
}

export interface IPlayerControlBar {
  hide?: boolean;
  hidePlay?: boolean;
  // hideMode?: boolean;
  hideRefresh?: boolean;
  hideSnapshot?: boolean;
  hideFullscreen?: boolean;
  // hideAlarm?: boolean;
  isHover?: boolean;
  speaker?: {
    status?: boolean;
    toggleSpeaker?: () => void;
  };
  mic?: {
    status?: boolean;
    toggleMic?: () => void;
  };
  light?: {
    status: ELightStatus;
    toggleLight: () => void;
  };
  alarm?: {
    toggleAlarm: () => void;
  };
  ptz?: {
    togglePTZ?: () => void;
    ptzCPN: ReactNode;
  };
  modeOptions?: {
    changeStreamMode: (id: string) => void;
  };
  follow?: {
    unFollow: () => void;
  };
  theaterMode?: {
    isTheaterMode: boolean;
    toggleTheaterMode: () => void;
  };
}

export enum ELightStatus {
  ON = 'ON',
  OFF = 'OFF',
}

declare global {
  interface Window {
    localStream?: MediaStream;
  }
}
