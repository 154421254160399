import { ArrowBack, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Container, IconButton, Tab, Tabs, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import { useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useRouteMatch } from 'src/hooks'
import { useAppDispatch } from 'src/redux_store'
import SubscriptionStations from './subscription_stations'
import Info from './info'

const SubscriptionDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const orgId = useParams().organization_id || ''
  const subscriptionId = useParams().subscription_id || ''

  const routeMatch = useRouteMatch(['/organizations/:organization_id/subscriptions/:subscription_id/:sub_tab'])
  const currentTab = routeMatch?.params?.sub_tab || ''

  useEffect(() => {}, [])

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue)
  }

  return (
    <Box height={'100%'} flex={1}>
      <Scrollbars>
        <Container>
          <Box py={2}>
            {/* <IconButton onClick={() => navigate(`/organizations/${orgId}/settings/subscriptions`)}>
              <ArrowBack />
            </IconButton> */}
            <Box mb={2}>
              <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                <Typography
                  component={Link}
                  to="../subscriptions"
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{
                    color: indigo[500],
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  {t('subscription.plans_and_subscriptions')}
                </Typography>

                <Typography fontWeight={600} variant="subtitle1" color="text.primary">
                  {t('subscription.details')}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={currentTab} onChange={handleChange} aria-label="subscription tabs">
                <Tab
                  label={t('subscription.info')}
                  value={''}
                  component={Link}
                  to=""
                  sx={{ textTransform: 'none', fontWeight: 600 }}
                />
                <Tab
                  label={t('subscription.stations')}
                  value={'stations'}
                  component={Link}
                  to="stations"
                  sx={{ textTransform: 'none', fontWeight: 600 }}
                />
              </Tabs>
            </Box>
          </Box>
          <Box py={2}>
            <Outlet />
            <Routes>
              <Route path="" element={<Info orgId={orgId} subscriptionId={subscriptionId} />} />
              <Route path="stations" element={<SubscriptionStations orgId={orgId} subscriptionId={subscriptionId} />} />
            </Routes>
          </Box>
        </Container>
      </Scrollbars>
    </Box>
  )
}

export default SubscriptionDetail
