import { omit } from 'lodash';
import { ICamera } from 'src/types/camera';
import {
  IAddOrderMembersRequest,
  ICreateCustomerRequest,
  ICustomer,
  ICustomerData,
  ICustomerFilter,
  ICustomerMembers,
  ICustomerPatch,
  IDeleteOrderMember,
  IInviteCustomerMember,
  IInviteOrderMemberRequest,
  IInviteOwnerRequest,
  IOrderDetailView,
  IOrderMember,
  IOrderView,
  IProduct,
  IProductPackage,
  IRemoveCustomerOwner,
  IResendInviteOrderMemberRequest,
} from 'src/types/customer';
import { IRole } from 'src/types/role';
import { IStation } from 'src/types/station';
import { IGetCustomerByUser, IUpdateCustomerUser } from 'src/types/customer';
import ClientBase from './base';
import { IUser } from 'src/types/auth';

export interface ClientCustomerMix {
  validationCustomerData: (payload: ICustomer) => Promise<ICustomer>;
  createCustomer: (payload: ICreateCustomerRequest) => Promise<ICustomer>;
  inviteCustomerOwner: (payload: IInviteOwnerRequest) => Promise<IUser>;
  removeCustomerOwner: (payload: IRemoveCustomerOwner) => Promise<void>;
  // getCustomerManage: (userId?: string) => Promise<{ id: string; name: string }[]>;
  getCustomers: ({ name }: { name: string }) => Promise<ICustomer[]>;
  filterCustomer: (data: ICustomerFilter) => Promise<ICustomerData>;
  getSingleCustomer: (id: string) => Promise<ICustomer>;
  updateCustomer: (id: string, data: ICustomerPatch) => Promise<ICustomer>;
  deleteCustomer: (id: string) => Promise<void>;

  //customer members
  getCustomerMembers: (customerId: string) => Promise<ICustomerMembers[]>;
  inviteCustomerMember: (payload: IInviteCustomerMember) => Promise<ICustomerMembers>;
  deleteCustomerMembers: (customerId: string, memberId: string) => Promise<void>;

  //order
  addOrder: (customerId: string, packageId: string, expiresAt: string) => Promise<any>;
  getOrders: (customerId: string, productId: string) => Promise<IOrderView[]>;
  getOrder: (customerId: string, orderId: string) => Promise<IOrderDetailView>;
  getCustomerOrders: (customerId: string) => Promise<IOrderView[]>;
  getCustomerOrder: (customerId: string, orderId: string) => Promise<IOrderDetailView>;
  deleteCustomerOrder: (customerId: string, orderId: string) => Promise<void>;

  //order camera
  addOrderCameras: (customerId: string, orderId: string, cameraIds: string[]) => Promise<ICamera[]>;
  getOrderCameras: (customerId: string, orderId: string) => Promise<ICamera[]>;
  getCameraWithoutCustomer: (searchKeyword: string) => Promise<ICamera[]>;
  deleteOrderCamera: (customerId: string, orderId: string, cameraId: string) => Promise<void>;

  //order station
  addOrderStations: (
    customerId: string,
    orderId: string,
    stations: string[],
  ) => Promise<IStation[]>;
  getStationWithoutCustomer: (searchKeyword: string) => Promise<IStation[]>;
  getOrderStations: (customerId: string, orderId: string) => Promise<IStation[]>;
  deleteOrderStation: (customerId: string, orderId: string, stationId: string) => Promise<void>;

  //order members
  addOrderMembers: (payload: IAddOrderMembersRequest) => Promise<IOrderMember>;
  inviteOrderMember: (payload: IInviteOrderMemberRequest) => Promise<IOrderMember>;
  resendInviteOrderMember: (payload: IResendInviteOrderMemberRequest) => Promise<void>;
  getOrderMembers: (customerId: string, orderId: string) => Promise<IOrderMember[]>;
  getOrderMembersNotBelongOrder: (customerId: string, orderId: string) => Promise<IOrderMember[]>;
  deleteOrderMembers: (payload: IDeleteOrderMember) => Promise<void>;

  //order roles
  getOrderRoles: (customerId: string, orderId: string) => Promise<IRole[]>;
  editOrderRole: (customerId: string, orderId: string, roleId: string) => Promise<IRole>;
  deleteOrderRole: (customerId: string, orderId: string, roleId: string) => Promise<void>;

  getProducts: () => Promise<IProduct[]>;

  // getProductPackages: () => Promise<IProductPackage[]>;
  getPackagesByProductId: (productId: string) => Promise<IProductPackage[]>;

  getCustomerManage: ({
    userId,
    name,
  }: {
    userId: string;
    name?: string;
  }) => Promise<{ id: string; name: string }[]>;

  getCustomerByUser: ({
    userId,
    name,
  }: {
    userId: string;
    name?: string;
  }) => Promise<IGetCustomerByUser>;

  updateCustomerUser: ({
    userId,
    customerInfo,
  }: {
    userId: string;
    customerInfo: IUpdateCustomerUser;
  }) => Promise<{ message: string }>;
}

const ClientCustomer = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientCustomerMix {
    // getCameraRoute = () => `http://10.49.46.54:9003/api/v1/admin/cameras`;
    // getCustomerRoute = () => {
    //   this.requestHeaders.user_id = '647e9e853fc3585ca9a9cfc7';
    //   return `http://10.49.46.54:9003/api/v1/admin/customers`;
    // };
    // getStationRoute = () => `http://10.49.46.54:9003/api/v1/admin/stations`;
    // getProductRoute = () => `http://10.49.46.54:9003/api/v1/products`;

    getCustomerRoute = () => `${this.getBaseRoute()}/admin/customers`;
    getCameraRoute = () => `${this.getBaseRoute()}/admin/cameras`;
    getStationRoute = () => `${this.getBaseRoute()}/admin/stations`;
    getProductRoute = () => `${this.getBaseRoute()}/products`;

    validationCustomerData = (data: ICustomer) => {
      return this.doFetch<ICustomer>(`${this.getCustomerRoute()}/check_create_customer`, {
        method: 'post',
        data,
      });
    };

    createCustomer = (data: ICreateCustomerRequest) => {
      return this.doFetch<ICustomer>(`${this.getCustomerRoute()}`, {
        method: 'post',
        data,
      });
    };

    inviteCustomerOwner = (payload: IInviteOwnerRequest) =>
      this.doFetch<IUser>(`${this.getCustomerRoute()}/${payload.customerId}/invite_owner`, {
        method: 'post',
        data: omit(payload, ['customerId']),
      });

    removeCustomerOwner = (payload: IRemoveCustomerOwner) =>
      this.doFetch<void>(`${this.getCustomerRoute()}/${payload.customerId}/owner`, {
        method: 'delete',
        data: omit(payload, ['customerId']),
      });

    // getCustomerManage = (userId = 'me') =>
    //   this.doFetch<{ id: string; name: string }[]>(`${this.getCustomerRoute()}/${userId}/manage`, {
    //     method: 'get',
    //   });

    getCustomerManage = ({ userId, name }: { userId: string; name?: string }) => {
      return this.doFetch<{ id: string; name: string }[]>(
        `${this.getCustomerRoute()}/${userId}/manage${name ? '?name=' + name : ''}`,
        {
          method: 'get',
        },
      );
    };

    getCustomers = ({ name }: { name: string }) =>
      this.doFetch<ICustomer[]>(`${this.getCustomerRoute()}/getAll?name=${name}`, {
        method: 'get',
      });

    filterCustomer = (data: ICustomerFilter) =>
      this.doFetch<ICustomerData>(
        `${this.getCustomerRoute()}?searchKeyword=${data.name}&page=${data.page}&limit=${
          data.limit
        }`,
        {
          method: 'get',
        },
      );

    getSingleCustomer = (id: string) =>
      this.doFetch<ICustomer>(`${this.getCustomerRoute()}/${id}`, {
        method: 'get',
      });

    updateCustomer = (id: string, data: ICustomerPatch) =>
      this.doFetch<ICustomer>(`${this.getCustomerRoute()}/${id}`, {
        method: 'put',
        data,
      });

    deleteCustomer = (id: string) =>
      this.doFetch<void>(`${this.getCustomerRoute()}/${id}`, {
        method: 'delete',
      });

    //customer members
    getCustomerMembers = (customerId: string) =>
      this.doFetch<ICustomerMembers[]>(`${this.getCustomerRoute()}/${customerId}/members`, {
        method: 'get',
      });

    inviteCustomerMember = (payload: IInviteCustomerMember) =>
      this.doFetch<ICustomerMembers>(`${this.getCustomerRoute()}/${payload.customerId}/invite`, {
        method: 'post',
        data: omit(payload, ['customerId']),
      });

    deleteCustomerMembers = (customerId: string, memberId: string) =>
      this.doFetch<void>(`${this.getCustomerRoute()}/${customerId}/members/${memberId}`, {
        method: 'delete',
      });

    // ===== order =========
    addOrder = (customerId: string, packageId: string, expiresAt: string) =>
      this.doFetch<any>(`${this.getCustomerRoute()}/${customerId}/orders`, {
        method: 'post',
        data: {
          packageId,
          expiresAt,
        },
      });

    getOrders = (customerId: string, productId: string) =>
      this.doFetch<IOrderView[]>(
        `${this.getCustomerRoute()}/${customerId}/products/${productId}/orders`,
        {
          method: 'get',
        },
      );

    getOrder = (customerId: string, orderId: string) =>
      this.doFetch<IOrderDetailView>(`${this.getCustomerRoute()}/${customerId}/orders/${orderId}`, {
        method: 'get',
      });

    getCustomerOrders = (customerId: string) =>
      this.doFetch<IOrderView[]>(`${this.getCustomerRoute()}/${customerId}/orders`, {
        method: 'get',
      });

    getCustomerOrder = (customerId: string, orderId: string) =>
      this.doFetch<IOrderDetailView>(`${this.getCustomerRoute()}/${customerId}/orders/${orderId}`, {
        method: 'get',
      });

    deleteCustomerOrder = (customerId: string, orderId: string) =>
      this.doFetch<void>(`${this.getCustomerRoute()}/${customerId}/orders/${orderId}`, {
        method: 'delete',
      });

    // ====== order camera ========
    addOrderCameras = (customerId: string, orderId: string, cameraIds: string[]) =>
      this.doFetch<ICamera[]>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/cameras`,
        {
          method: 'post',
          data: { cameraIds },
        },
      );

    getOrderCameras = (customerId: string, orderId: string) =>
      this.doFetch<ICamera[]>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/cameras`,
        {
          method: 'get',
        },
      );

    getCameraWithoutCustomer = (searchKeyword: string) =>
      this.doFetch<ICamera[]>(`${this.getCameraRoute()}/free?searchKeyword=${searchKeyword}`, {
        method: 'get',
      });

    deleteOrderCamera = (customerId: string, orderId: string, cameraId: string) =>
      this.doFetch<void>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/cameras/${cameraId}`,
        {
          method: 'delete',
        },
      );

    // ====== order station =====
    addOrderStations = (customerId: string, orderId: string, stationIds: string[]) =>
      this.doFetch<IStation[]>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/stations`,
        {
          method: 'post',
          data: { stationIds },
        },
      );

    getOrderStations = (customerId: string, orderId: string) =>
      this.doFetch<IStation[]>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/stations`,
        {
          method: 'get',
        },
      );

    getStationWithoutCustomer = (searchKeyword: string) =>
      this.doFetch<IStation[]>(`${this.getStationRoute()}/free?searchKeyword=${searchKeyword}`, {
        method: 'get',
      });

    deleteOrderStation = (customerId: string, orderId: string, stationId: string) =>
      this.doFetch<void>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/stations/${stationId}`,
        {
          method: 'delete',
        },
      );

    //order members
    addOrderMembers = (payload: IAddOrderMembersRequest) =>
      this.doFetch<IOrderMember>(
        `${this.getCustomerRoute()}/${payload.customerId}/orders/${payload.orderId}/members`,
        {
          method: 'post',
          data: { userIds: payload.userIds, roleId: payload.roleId },
        },
      );

    inviteOrderMember = (payload: IInviteOrderMemberRequest) =>
      this.doFetch<IOrderMember>(
        `${this.getCustomerRoute()}/${payload.customerId}/orders/${payload.orderId}/members`,
        {
          method: 'post',
          data: omit(payload, ['customerId', 'orderId']),
        },
      );

    resendInviteOrderMember = (payload: IResendInviteOrderMemberRequest) =>
      this.doFetch<void>(
        `${this.getCustomerRoute()}/${payload.customerId}/orders/${
          payload.orderId
        }/members/re-invite`,
        {
          method: 'post',
          data: omit(payload, ['customerId', 'orderId']),
        },
      );

    getOrderMembers = (customerId: string, orderId: string) =>
      this.doFetch<IOrderMember[]>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/members`,
        {
          method: 'get',
        },
      );

    getOrderMembersNotBelongOrder = (customerId: string, orderId: string) =>
      this.doFetch<IOrderMember[]>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/members/not_belong_order`,
        {
          method: 'get',
        },
      );

    deleteOrderMembers = (payload: IDeleteOrderMember) =>
      this.doFetch<void>(
        `${this.getCustomerRoute()}/${payload.customerId}/orders/${payload.orderId}/members/${
          payload.memberId
        }`,
        {
          method: 'delete',
          data: { alternateOwnerId: payload.alternateOwnerId },
        },
      );

    //order roles
    getOrderRoles = (customerId: string, orderId: string) =>
      this.doFetch<IRole[]>(`${this.getCustomerRoute()}/${customerId}/orders/${orderId}/roles`, {
        method: 'get',
      });

    editOrderRole = (customerId: string, orderId: string, roleId: string) =>
      this.doFetch<IRole>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/roles/${roleId}`,
        {
          method: 'put',
        },
      );

    deleteOrderRole = (customerId: string, orderId: string, roleId: string) =>
      this.doFetch<void>(
        `${this.getCustomerRoute()}/${customerId}/orders/${orderId}/roles/${roleId}`,
        {
          method: 'delete',
        },
      );

    // product and packages
    getProducts = () =>
      this.doFetch<IProduct[]>(`${this.getProductRoute()}`, {
        method: 'get',
      });

    // getProductPackages = () =>
    //   this.doFetch<IProductPackage[]>(`${this.getCustomerRoute()}/product_packages`, {
    //     method: 'get',
    //   });

    getPackagesByProductId = (productId: string) =>
      this.doFetch<IProductPackage[]>(`${this.getProductRoute()}/${productId}/packages`, {
        method: 'get',
      });

    getCustomerByUser = ({ userId, name }: { userId: string; name?: string }) => {
      return this.doFetch<IGetCustomerByUser>(
        `${this.getCustomerRoute()}/${userId}/manage_config${name ? '?name=' + name : ''}`,
        {
          method: 'GET',
        },
      );
    };

    updateCustomerUser = ({
      userId,
      customerInfo,
    }: {
      userId: string;
      customerInfo: IUpdateCustomerUser;
    }) => {
      return this.doFetch<{ message: string }>(
        `${this.getCustomerRoute()}/${userId}/manage_config`,
        {
          method: 'PUT',
          data: customerInfo,
        },
      );
    };
  };

export default ClientCustomer;
