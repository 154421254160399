import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { listSubscriptionPayments } from 'src/redux_store/organizations/slice'
import { RequestStatusOptions } from 'src/types'
import TransactionRow from './transaction_row'

interface Props {
  orgId: string
  subscriptionId: string
}

const SubscriptionPayments = ({ orgId, subscriptionId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { requests, payments } = useAppSelector((state) => state.organizations)

  useEffect(() => {
    dispatch(listSubscriptionPayments({ orgId, subscriptionId }))
  }, [dispatch, orgId, subscriptionId])

  if (requests.listSubscriptionPayments.status === RequestStatusOptions.PENDING) {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="100%" p={3}>
        <CircularProgress />
      </Box>
    )
  }

  if (requests.listSubscriptionPayments.status === RequestStatusOptions.REJECTED) {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="100%" p={3}>
        <Typography variant="h6">Something wrong</Typography>
      </Box>
    )
  }

  if (!payments.length) {
    return (
      <Box p={3}>
        <Typography textAlign={'center'}>No payment transactions</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <TableContainer sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>{t('payment.date')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('payment.description')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('payment.payment_method')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('payment.status')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('payment.amount')}</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment, index) => (
              <TransactionRow key={index} payment={payment} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SubscriptionPayments
