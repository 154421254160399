import { Box, CircularProgress, Container, Typography } from '@mui/material'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import MemberRow from './member_row'
import { listOrganizationMembers } from 'src/redux_store/organizations/slice'
import { RequestStatusOptions } from 'src/types'

const Members = () => {
  const { t } = useTranslation()
  const orgId = useParams().organization_id || ''
  const dispatch = useAppDispatch()
  const { members, requests } = useAppSelector((state) => state.organizations)

  useEffect(() => {
    dispatch(listOrganizationMembers({ orgId }))
  }, [dispatch, orgId])

  if (requests.listOrganizationMembers.status === RequestStatusOptions.PENDING) {
    return (
      <Box display={'flex'} p={3} flex={1} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Scrollbars>
      <Box p={2}>
        <Container>
          <Typography variant="h6">{t('label.members.main')}</Typography>
          <Box maxWidth={'sm'}>
            {members.map((member, index) => {
              return <MemberRow key={index} member={member} orgId={orgId} />
            })}
          </Box>
        </Container>
      </Box>
    </Scrollbars>
  )
}

export default Members
