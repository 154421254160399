import qs from 'query-string';
import {
  IChangeNotificationSound,
  IChangeNotificationSource,
  IChangePopupStatus,
  INotification,
  INotificationSetting,
  IPayloadNotification,
} from 'src/types/notification';
import ClientBase from './base';

export interface ClientNotificationMix {
  getAllNotification: (payload: IPayloadNotification) => Promise<INotification[]>;
  getInfoNotification: (id: string) => Promise<INotification>;
  markNotificationSeen: (id: string) => Promise<string>;
  markNotificationRead: (id: string) => Promise<string>;
  markNotificationUnread: (id: string) => Promise<string>;
  deleteNotification: (id: string) => Promise<string>;
  getNotificationSetting: () => Promise<INotificationSetting>;
  changeNotificationSound: (payload: IChangeNotificationSound) => Promise<any>;
  changeNotificationSource: (payload: IChangeNotificationSource) => Promise<any>;
  changeNotificationPopup: (payload: IChangePopupStatus) => Promise<any>;
  unseenNotification: () => Promise<{ numberOfNotifications: number }>;
  markAllAsSeen: () => Promise<any>;
}

const ClientNotification = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientNotificationMix {
    getAllNotification = async (payload: IPayloadNotification) => {
      const params = qs.stringify(payload, { arrayFormat: 'comma' });
      return this.doFetch<INotification[]>(`${this.getNotificationRoute()}?${params}`, {
        method: 'GET',
      });
    };

    getInfoNotification = async (id: string) => {
      return this.doFetch<INotification>(`${this.getNotificationRoute()}/${id}`, {
        method: 'GET',
      });
    };

    markNotificationSeen = async (id: string) => {
      return this.doFetch<string>(`${this.getNotificationRoute()}/${id}/seen`, {
        method: 'GET',
      });
    };

    markNotificationRead = async (id: string) => {
      return this.doFetch<string>(`${this.getNotificationRoute()}/${id}/read`, {
        method: 'GET',
      });
    };

    markNotificationUnread = async (id: string) => {
      return this.doFetch<string>(`${this.getNotificationRoute()}/${id}/unread`, {
        method: 'GET',
      });
    };

    deleteNotification = async (id: string) => {
      return this.doFetch<string>(`${this.getNotificationRoute()}/${id}`, {
        method: 'DELETE',
      });
    };

    getNotificationSetting = async () => {
      return this.doFetch<INotificationSetting>(`${this.getNotificationRoute()}/settings`, {
        method: 'GET',
      });
    };

    changeNotificationSound = async (data: IChangeNotificationSound) => {
      return this.doFetch(`${this.getNotificationRoute()}/settings`, {
        method: 'PATCH',
        data,
      });
    };

    changeNotificationSource = async (data: IChangeNotificationSource) => {
      return this.doFetch(`${this.getNotificationRoute()}/settings`, {
        method: 'PATCH',
        data,
      });
    };
    changeNotificationPopup = async (data: IChangePopupStatus) => {
      return this.doFetch(`${this.getNotificationRoute()}/settings`, {
        method: 'PATCH',
        data,
      });
    };

    unseenNotification = async () => {
      return this.doFetch<{ numberOfNotifications: number }>(
        `${this.getNotificationRoute()}/unseen`,
        {
          method: 'GET',
        },
      );
    };

    markAllAsSeen = async () => {
      return this.doFetch(`${this.getNotificationRoute()}/mark_all_as_seen`, {
        method: 'GET',
      });
    };
  };

export default ClientNotification;
