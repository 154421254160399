import { createSlice } from '@reduxjs/toolkit';
import { ISystemBackup } from 'src/types/system';
import { getBackup, getBackupSpaceUsage, updateBackup } from './backup_action';

const initialState = {
  backup: {} as ISystemBackup,
  backupSpaceUsage: [],
};

const backupSlice = createSlice({
  name: 'backup',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBackup.fulfilled, (state, action) => {
        state.backup = action.payload;
      })
      .addCase(updateBackup.fulfilled, (state, action) => {
        state.backup = action.payload;
      })
      .addCase(getBackupSpaceUsage.fulfilled, (state, action) => {
        state.backupSpaceUsage = action.payload;
      });
  },
});

export default backupSlice.reducer;
