import React from 'react';
import { Box } from '@mui/material';

import FollowToggle from './follow_toggle';
import FullscreenToggle from './fullscreen_toggle';
import PlayToggle from './play_toggle';
import Refresh from './refresh';
import Snapshot from './snapshot';
import Volume from './volume';
import ModeOptions from './mode_options/mode_options';
import PTZToggle from './ptz_toggle';
import TheaterMode from './theater_mode';
import MicToggle from './mic_toggle';
// import AlarmToggle from './alarm_toggle';
import { mergeAndSortChildren } from '../../utils/video';
import { useStyles } from './styles';
// import StationToggle from './station_toggle';
import LightToggle from './light_toggle';

function ControlBar(props: any) {
  const classes = useStyles();

  const getDefaultLeftChildren = () => {
    return [
      <PlayToggle key="play-toggle" order={1} />,
      <Refresh key="refresh" order={2} />,
      <ModeOptions key="mode" order={3} />,
    ];
  };

  const getDefaultRightChildren = () => {
    return [
      <LightToggle key="light-toggle" order={5} />,
      // <AlarmToggle key="alarm-toggle" order={7} />,
      <Volume key="volume" order={8} />,
      <MicToggle key="audio-toggle" order={6} />,
      // <StationToggle key="station-toggle" order={9} />,
      <PTZToggle key="ptz-toggle" order={10} />,
      <Snapshot key="snapshot" order={11} />,
      <TheaterMode key="theater-mode" order={12} />,
      <FollowToggle key="follow-toggle" order={13} />,
      <FullscreenToggle key="fullscreen-toggle" order={14} />,
    ];
  };

  const getLeftChildren = () => {
    const children = React.Children.toArray(props.children);
    const defaultChildren = getDefaultLeftChildren();
    const { ...parentProps } = props;

    return mergeAndSortChildren(defaultChildren, children, parentProps);
  };

  const getRightChildren = () => {
    const children = React.Children.toArray(props.children);
    const defaultChildren = getDefaultRightChildren();
    const { ...parentProps } = props;

    return mergeAndSortChildren(defaultChildren, children, parentProps);
  };

  // if (slice.isHideControlBar) return null;

  const leftChildren = getLeftChildren();
  const rightChildren = getRightChildren();

  return (
    <Box className={classes.controlBar}>
      <Box>{leftChildren}</Box>
      <Box>{rightChildren}</Box>
    </Box>
  );
}

export default ControlBar;
