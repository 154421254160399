import { Box, Container, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CustomTabPanel from '../custom_tab_panel'
import ActiveSubscriptions from './active_subscriptions'
import UnpaidSubscriptions from './unpaid_subscriptions'
import ExpiredSubscriptions from './expired_subscriptions'

const StationSubscriptions = () => {
  const { t } = useTranslation()

  const orgId = useParams().organization_id || ''

  const [value, setValue] = useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box>
      <Container>
        <Box mb={2}>
          <Typography variant="h6">{t('subscription.station')}</Typography>
        </Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={value} onChange={handleChange} aria-label="station plan tabs">
              <Tab label={t('subscription.active')} sx={{ textTransform: 'none', fontWeight: 600 }} />
              <Tab label={t('subscription.unpaid')} sx={{ textTransform: 'none', fontWeight: 600 }} />
              <Tab label={t('subscription.expired')} sx={{ textTransform: 'none', fontWeight: 600 }} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ActiveSubscriptions orgId={orgId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <UnpaidSubscriptions orgId={orgId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ExpiredSubscriptions orgId={orgId} />
          </CustomTabPanel>
        </Box>
      </Container>
    </Box>
  )
}

export default StationSubscriptions
