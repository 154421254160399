import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  customBadge: {
    minWidth: '10px !important',
    minHeight: '10px !important',
    borderRadius: '50% !important',
    // marginRight: '10px',
  },
  SUCCESS: {
    background: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  INFO: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  ERROR: {
    background: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  WARNING: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.main,
  },
  CRITICAL: {
    background: '#ac430c',
    color: '#ac430c',
  },
  hightLightText: {
    color: theme.palette.grey[900],
    // color: 'black',
  },
  normalText: {
    color: theme.palette.grey[700],
  },

  boxLoading: {
    background: theme.palette.common.white,
    height: '100%',
    width: '100%',
  },
  row: {
    cursor: 'pointer ',
    borderBottom: '1px solid #ececec',
    background: '#fff',
    '@media (max-width: 1366px)': {
      fontSize: 12,
    },
  },
  rowActive: {
    background: '#dcdcdc',
    outline: 'none',
  },
}));
