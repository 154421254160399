import { has, isEmpty } from 'lodash';
import { UseFormSetError } from 'react-hook-form';
import toast from 'react-hot-toast';
import i18n from 'src/i18n';
import { IErrors } from 'src/types/error';

export const toastMessage = {
  success: (message: string) => toast.success(message),
  error: (message: string) => toast.error(message || i18n.t('message.systemError')),
  custom: (content: JSX.Element) => toast.custom(content),
  setErrors: (error: IErrors, setError?: UseFormSetError<any>) => {
    if (has(error, 'errors') && !isEmpty(error.errors)) {
      for (const key in error.errors) {
        for (const err of error.errors[key]) {
          if (!setError) return;
          setError(key, { message: err.message });
        }
      }
    } else {
      toast.error(error?.message || i18n.t('message.systemError'));
    }
  },
};
