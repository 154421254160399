import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';

import { ISystemNode, ISystemNodeDetail } from 'src/types/system';
import { toastMessage } from 'src/utils/toast';

export const getNodes = createAsyncThunk<ISystemNode[], void>(
  'system/getNodes',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getNodes();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getNode = createAsyncThunk<ISystemNodeDetail, string>(
  'system/getNode',
  async (node, { rejectWithValue }) => {
    try {
      const data = await client.getNode(node);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
