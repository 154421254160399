import { createSlice } from '@reduxjs/toolkit';

import { IProduct } from 'src/types/customer';
import { getProducts } from './actions';

interface IProductState {
  products: IProduct[];
}

const initialState: IProductState = {
  products: [],
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
  },
});

const { reducer } = productSlice;

export default reducer;
