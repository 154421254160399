import React from 'react';
import { Box } from '@mui/material';

import { mergeAndSortChildren } from '../../utils/video';
import { IPlayerProps } from '../../types/player';
import FollowToggle from './follow_toggle';
import FullscreenToggle from './fullscreen_toggle';
import PlayToggle from './play_toggle';
import Refresh from './refresh';
import Snapshot from './snapshot';
// import PTZToggle from './ptz_toggle';
import TheaterMode from './theater_mode';
import ModeOptions from './mode_options';
import { useStyles } from './styles';

function ControlBar(props: any) {
  const classes = useStyles();
  const slice = { ...props } as IPlayerProps;

  const getDefaultLeftChildren = () => {
    return [
      <PlayToggle key="play-toggle" />,
      <Refresh key="refresh" />,
      <ModeOptions key="mode" />,
    ];
  };

  const getDefaultRightChildren = () => {
    return [
      <Snapshot key="snapshot" />,
      // <PTZToggle key="ptz-toggle" />,
      <FollowToggle key="follow-toggle" />,
      <TheaterMode key="theater-mode" />,
      <FullscreenToggle key="fullscreen-toggle" />,
    ];
  };

  const getLeftChildren = () => {
    const children = React.Children.toArray(props.children);
    const defaultChildren = getDefaultLeftChildren();
    const { ...parentProps } = slice;

    return mergeAndSortChildren(defaultChildren, children, parentProps);
  };

  const getRightChildren = () => {
    const children = React.Children.toArray(props.children);
    const defaultChildren = getDefaultRightChildren();
    const { ...parentProps } = slice;

    return mergeAndSortChildren(defaultChildren, children, parentProps);
  };

  if (slice.isHideControlBar) return null;

  const leftChildren = getLeftChildren();
  const rightChildren = getRightChildren();

  return (
    <Box display="flex" justifyContent="space-between" className={classes.controlBar}>
      <Box display="flex">{leftChildren}</Box>
      <Box display="flex">{rightChildren}</Box>
    </Box>
  );
}

export default ControlBar;
