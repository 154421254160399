import React from 'react';
import { CameraAltOutlined } from '@mui/icons-material';

import ButtonWrapper from '../button_wrapper';
import { snapshot } from '../../utils/video';
import i18n from 'src/i18n';

function Snapshot() {
  const { t } = i18n;
  const controlText = t('stream.controlBar.snapshot');

  const handleSnapshot = () => {
    const { hls } = window;
    if (!hls) return;
    snapshot(hls.media);
  };

  return (
    <ButtonWrapper title={controlText} icon={CameraAltOutlined} handleClick={handleSnapshot} />
  );
}

export default Snapshot;
