import React from 'react';

import TypographyTooltip from 'src/components/tooltip/typography_tooltip';

type Props = {
  gpsWaveStrength: number;
  title: string;
  color: string;
};

function StationGPSIconSmall({ gpsWaveStrength, color, title }: Props) {
  const renderOpacity = (minRange: number) => {
    if (gpsWaveStrength >= minRange) return 1;
    return 0.6;
  };

  return (
    <TypographyTooltip title={title}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00847 4.1184C7.22025 4.33018 7.53286 4.39949 7.82312 4.32566C9.9467 3.7855 12.2926 4.34634 13.9545 6.00819C15.6163 7.67004 16.1772 10.016 15.637 12.1396C15.5632 12.4298 15.6325 12.7424 15.8443 12.9542V12.9542C16.2354 13.3454 16.8951 13.2192 17.0438 12.6864C17.7872 10.0213 17.111 7.0434 15.0151 4.94753C12.9193 2.85167 9.94135 2.17546 7.27631 2.9189C6.74347 3.06754 6.61731 3.72724 7.00847 4.1184V4.1184Z"
          fill={color}
          fillOpacity={renderOpacity(80)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.52673 6.45597C6.74781 6.67705 7.07694 6.74159 7.37913 6.66138C8.9915 6.23341 10.7818 6.65169 12.0463 7.91622C13.3109 9.18074 13.7292 10.9711 13.3012 12.5834C13.221 12.8856 13.2855 13.2148 13.5066 13.4358V13.4358C13.8873 13.8166 14.5287 13.7114 14.6861 13.1965C15.3495 11.0269 14.8231 8.57164 13.107 6.85556C11.3909 5.13947 8.93565 4.6131 6.7661 5.27645C6.25118 5.43389 6.14599 6.07522 6.52673 6.45597V6.45597Z"
          fill={color}
          fillOpacity={renderOpacity(40)}
        />
        <mask id="path-3-inside-1_6624_76971" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.13862 8.66294C7.32117 8.84549 7.57676 8.92889 7.83488 8.93356C8.64776 8.94828 9.45616 9.26578 10.0764 9.88606C10.6967 10.5063 11.0142 11.3148 11.0289 12.1276C11.0336 12.3857 11.117 12.6413 11.2996 12.8239V12.8239C11.7433 13.2676 12.4861 13.0853 12.5217 12.4588C12.5961 11.1532 12.1345 9.82284 11.1371 8.8254C10.1397 7.82797 8.80928 7.36642 7.50371 7.44076C6.87717 7.47643 6.69488 8.21919 7.13862 8.66294V8.66294Z"
          />
        </mask>
        <path
          d="M9.88536 14.2381C10.6664 15.0191 11.9327 15.0191 12.7138 14.2381C13.4948 13.457 13.4948 12.1907 12.7138 11.4097L9.88536 14.2381ZM12.5217 12.4588L14.5185 12.5725L12.5217 12.4588ZM7.83488 8.93356L7.79868 10.9332L7.83488 8.93356ZM11.4907 8.47185C10.4897 7.47087 9.18092 6.95761 7.87109 6.93389L7.79868 10.9332C8.11459 10.939 8.42264 11.0607 8.66223 11.3003L11.4907 8.47185ZM13.0286 12.0914C13.0049 10.7816 12.4916 9.47283 11.4907 8.47185L8.66223 11.3003C8.90182 11.5399 9.02355 11.8479 9.02927 12.1638L13.0286 12.0914ZM14.5185 12.5725C14.6239 10.722 13.9685 8.8284 12.5513 7.41119L9.72289 10.2396C10.3005 10.8173 10.5683 11.5845 10.525 12.3451L14.5185 12.5725ZM12.5513 7.41119C11.1341 5.99398 9.24054 5.33863 7.39001 5.44399L7.6174 9.43753C8.37802 9.39422 9.14524 9.66196 9.72289 10.2396L12.5513 7.41119ZM7.39001 5.44399C6.17392 5.51324 5.27799 6.33969 4.99317 7.36211C4.72706 8.31737 5.00303 9.35577 5.72441 10.0772L8.55284 7.24872C8.83047 7.52636 8.97572 7.97151 8.84645 8.43553C8.78055 8.67211 8.63789 8.92357 8.39752 9.12314C8.14869 9.32973 7.86365 9.4235 7.6174 9.43753L7.39001 5.44399ZM9.88536 14.2381C10.6067 14.9595 11.6451 15.2354 12.6004 14.9693C13.6228 14.6845 14.4493 13.7886 14.5185 12.5725L10.525 12.3451C10.539 12.0989 10.6328 11.8138 10.8394 11.565C11.0389 11.3246 11.2904 11.182 11.527 11.1161C11.991 10.9868 12.4361 11.132 12.7138 11.4097L9.88536 14.2381ZM9.02927 12.1638C9.04081 12.8011 9.24901 13.6018 9.88536 14.2381L12.7138 11.4097C12.985 11.6809 13.0264 11.9704 13.0286 12.0914L9.02927 12.1638ZM7.87109 6.93389C7.99208 6.93608 8.28159 6.97748 8.55284 7.24872L5.72441 10.0772C6.36075 10.7135 7.16145 10.9217 7.79868 10.9332L7.87109 6.93389Z"
          fill={color}
          mask="url(#path-3-inside-1_6624_76971)"
          fillOpacity={renderOpacity(1)}
        />
        <circle cx="5.99995" cy="15" r="3.53571" stroke={color} strokeWidth="1.5" />
        <circle cx="6" cy="14.9269" r="2" fill={color} />
        <rect x="5.28577" y="10" width="1.42857" height="1.42857" fill={color} />
        <rect x="5.28577" y="18.5714" width="1.42857" height="1.42857" fill={color} />
        <rect
          x="9.57141"
          y="15.7143"
          width="1.42857"
          height="1.42857"
          transform="rotate(-90 9.57141 15.7143)"
          fill={color}
        />
        <rect
          x="1"
          y="15.7143"
          width="1.42857"
          height="1.42857"
          transform="rotate(-90 1 15.7143)"
          fill={color}
        />
      </svg>
    </TypographyTooltip>
  );
}

export default StationGPSIconSmall;
