import React from 'react';
import { RefreshRounded } from '@mui/icons-material';

import { IPlayerProps } from '../../../types/player';
import i18n from 'src/i18n';
import { ECameraStatus } from 'src/types/camera';
import ButtonWrapper from '../button_wrapper';

function Refresh(props: any) {
  const { controlBar, actions, manager, player, cam } = props as IPlayerProps;
  const { t } = i18n;

  if (
    cam.status === ECameraStatus.ERROR ||
    cam.status === ECameraStatus.OFFLINE ||
    controlBar?.hideRefresh
  )
    return null;

  const handleClick = () => {
    const { video } = manager;
    if (!video || player.waiting) return;

    if (props?.player.error) {
      actions.setError(null);
    } else {
      video.stopPlayer();
    }

    video.refreshPlayer();
  };

  const controlText = t('stream.controlBar.refresh');

  return (
    <ButtonWrapper
      title={controlText}
      icon={RefreshRounded}
      handleClick={handleClick}
      disablePortal
    />
  );
}

export default Refresh;
