import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import clsx from 'clsx';

import { resetState, toggleMediaPlay } from 'src/redux_store/video/video_slice';
import { IViolationTime } from './types/violation';
import { useStyles } from './styles';
import { KeyCodeEnum } from './types/video';
import { TimelineChart } from './chart_timeline';
import ControlTimeline from './control_timeline';
import MediaPlayer from './media_player';
import ControlBar from './control_bar';
import { useAppDispatch, useAppSelector } from 'src/redux_store';

interface IPlayerWithTimeline {
  violationTime?: IViolationTime;
  autoPlay?: boolean;
  isHideTheaterMode?: boolean;
}

function RewindLayer({ children }: { children: ReactNode }) {
  return (
    <>
      <Box display="flex" alignItems="center">
        {children}
      </Box>
      <Typography>10 giây</Typography>
    </>
  );
}

function PlayerWithTimeline(props: IPlayerWithTimeline) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const mediaWrapper = useRef<any>(null);
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const [rewind, setRewind] = useState<null | 'next' | 'previous'>(null);
  const { videoLinks, isPlay } = mediaPlayer;
  const [timeline, setTimeline] = useState<TimelineChart | null>(null);
  const { violationTime, autoPlay = true, isHideTheaterMode = false } = props;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rewind) {
      setTimeout(() => {
        setRewind(null);
      }, 550);
    }
  }, [rewind]);

  const getTimeline = (newTimeline: TimelineChart) => {
    setTimeline(newTimeline);
  };

  const renderRewind = () => {
    if (!rewind) return;
    if (rewind === 'previous')
      return (
        <RewindLayer>
          <ArrowLeft fontSize="small" />
          <ArrowLeft fontSize="medium" />
          <ArrowLeft fontSize="large" />
        </RewindLayer>
      );
    return (
      <RewindLayer>
        <ArrowRight fontSize="large" />
        <ArrowRight fontSize="medium" />
        <ArrowRight fontSize="small" />
      </RewindLayer>
    );
  };

  const handleKeyPress = (e: any) => {
    if (!videoLinks.length || !window.hls) return;
    const { hls } = window;

    switch (e.keyCode) {
      case KeyCodeEnum.SPACE:
        dispatch(toggleMediaPlay({ isPlay: !isPlay }));
        if (!isPlay) {
          hls.media.handlePlay();
        } else {
          hls.media.handlePause();
        }
        break;
      case KeyCodeEnum.NAVIGATE_NEXT:
        if (!timeline) return;
        timeline.onKeyPressNavigateNext();
        setRewind('next');
        break;
      case KeyCodeEnum.NAVIGATE_PREVIOUS:
        if (!timeline) return;
        timeline.onKeyPressNavigatePrevious();
        setRewind('previous');
        break;
      default:
        return;
    }
  };

  return (
    <Box className={classes.root} onKeyDown={handleKeyPress} tabIndex={0}>
      <MediaPlayer autoPlay={autoPlay} mediaWrapper={mediaWrapper} violationTime={violationTime}>
        <Box
          className={clsx(classes.rewind, {
            [classes.next]: rewind === 'next',
            [classes.previous]: rewind === 'previous',
          })}
        >
          {renderRewind()}
        </Box>
        <ControlBar
          mediaWrapper={mediaWrapper}
          violationTime={violationTime}
          isHideTheaterMode={isHideTheaterMode}
        />
      </MediaPlayer>
      <ControlTimeline violationTime={violationTime} getTimeline={getTimeline} />
    </Box>
  );
}

export default PlayerWithTimeline;
