import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CenterFocusStrong,
  Loop,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';

import { useAppDispatch } from 'src/redux_store';
import { autoFocus, startPTZControl, stopPTZControl } from 'src/redux_store/ptz/ptz_action';
import { ICamera } from 'src/types/camera';
import { useStyles } from './styles';
import { PtzControlOptions, ptzSpeedOs } from './utils/ptz';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const ControlPTZ = ({
  stationId,
  selectedCamera,
  isPtzSmall,
}: {
  stationId?: string;
  selectedCamera: ICamera;
  isPtzSmall?: boolean;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [ptzControlState, setPtzControlState] = useState({
    ...PtzControlOptions.default,
  });

  const [speedType, setSpeedType] = useState('default');
  const [isStart, setIsStart] = useState(false);

  const { pan, tilt, zoom } = ptzControlState;

  const handleUpdateSpeed = (option: any, type: string) => {
    setPtzControlState({
      ...option,
    });
    setSpeedType(type);
  };

  const handleMouseDown = (e: any, pan: string, tilt: string, zoom: string) => {
    if (e.button !== 0 || !selectedCamera?.id) return;
    dispatch(
      startPTZControl({ cameraId: selectedCamera.id, data: { pan, tilt, zoom }, stationId }),
    );
  };

  const handleMouseUp = (e: any) => {
    if (e.button !== 0 || !selectedCamera?.id) return;
    dispatch(stopPTZControl({ cameraId: selectedCamera.id, stationId }));
  };

  const handlePtzControl = (pan: string, tilt: string, zoom: string) => {
    if (!selectedCamera?.id) return;
    if (isStart) {
      dispatch(stopPTZControl({ cameraId: selectedCamera.id, stationId }));
    } else {
      dispatch(
        startPTZControl({ cameraId: selectedCamera.id, data: { pan, tilt, zoom }, stationId }),
      );
    }
    setIsStart(!isStart);
  };

  const handleAutoFocus = () => {
    if (!selectedCamera?.id) return;
    dispatch(autoFocus({ cameraId: selectedCamera.id, stationId }));
  };

  const PtzPanTiltOs = [
    {
      icon: <ArrowDropUpOutlined />,
      pan: pan.left,
      tilt: tilt.up,
      zoom: zoom.default,
      title: t('label.ptz.upLeft'),
    },
    {
      icon: <ArrowDropUpOutlined />,
      pan: pan.default,
      tilt: tilt.up,
      zoom: zoom.default,
      title: t('label.ptz.up'),
    },
    {
      icon: <ArrowDropUpOutlined />,
      pan: pan.right,
      tilt: tilt.up,
      zoom: zoom.default,
      title: t('label.ptz.upRight'),
    },
    {
      icon: <ArrowLeftOutlined />,
      pan: pan.left,
      tilt: tilt.default,
      zoom: zoom.default,
      title: t('label.ptz.left'),
    },
    {
      icon: <Loop />,
      pan: pan.loopLeft,
      tilt: tilt.default,
      zoom: zoom.default,
      loop: true,
      title: t('label.ptz.autoSpin'),
    },
    {
      icon: <ArrowRightOutlined />,
      pan: pan.right,
      tilt: tilt.default,
      zoom: zoom.default,
      title: t('label.ptz.right'),
    },
    {
      icon: <ArrowDropDownOutlined />,
      pan: pan.left,
      tilt: tilt.down,
      zoom: zoom.default,
      title: t('label.ptz.downLeft'),
    },
    {
      icon: <ArrowDropDownOutlined />,
      pan: pan.default,
      tilt: tilt.down,
      zoom: zoom.default,
      title: t('label.ptz.down'),
    },
    {
      icon: <ArrowDropDownOutlined />,
      pan: pan.right,
      tilt: tilt.down,
      zoom: zoom.default,
      title: t('label.ptz.downRight'),
    },
  ];

  const PtzZoomOs = [
    {
      icon: <ZoomOut />,
      pan: pan.default,
      tilt: tilt.default,
      zoom: zoom.out,
      title: t('label.ptz.zoomFar'),
    },
    {
      icon: <ZoomIn />,
      pan: pan.default,
      tilt: tilt.default,
      zoom: zoom.in,
      title: t('label.ptz.zoomNear'),
    },
  ];

  return (
    <Box
      className={classNames({
        [classes.rootSmall]: Boolean(isPtzSmall),
        [classes.root]: !isPtzSmall,
      })}
    >
      {ptzSpeedOs.map((speed, index) => {
        const { type, speedOs, speed: newSpeed } = speed;
        return (
          <IconButton
            onClick={() => handleUpdateSpeed(speedOs, type)}
            size="small"
            className={classNames({
              [classes.btnActive]: speedType === type,
            })}
            key={index}
          >
            x{newSpeed}
          </IconButton>
        );
      })}
      {PtzZoomOs.map((item, index) => {
        const { pan, tilt, zoom, icon } = item;
        return (
          <IconButton
            onMouseDown={(e) => handleMouseDown(e, pan, tilt, zoom)}
            onMouseUp={handleMouseUp}
            size="small"
            key={index}
          >
            {icon}
          </IconButton>
        );
      })}
      <IconButton size="small" onClick={handleAutoFocus}>
        {<CenterFocusStrong />}
      </IconButton>
      {PtzPanTiltOs.map((item, index) => {
        const { pan, tilt, zoom, icon, loop } = item;
        if (loop) {
          return (
            <IconButton size="small" key={index} onClick={() => handlePtzControl(pan, tilt, zoom)}>
              {icon}
            </IconButton>
          );
        } else {
          return (
            <IconButton
              size="small"
              key={index}
              onMouseDown={(e) => handleMouseDown(e, pan, tilt, zoom)}
              onMouseUp={handleMouseUp}
            >
              {icon}
            </IconButton>
          );
        }
      })}
    </Box>
  );
};

export default ControlPTZ;
