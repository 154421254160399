import React, { CSSProperties, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  IconButton,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { ClearOutlined } from '@mui/icons-material';
import theme from 'src/theme';

interface IDeleteOptionProps {
  name: string;
  label: string;
  control: any;
  options: object[];
  optionLabel: string;
  style?: CSSProperties;
  sx?: SxProps;
  size?: 'small' | 'medium' | undefined;
  ml?: string | number;
  mr?: string | number;
  isDisableClearable?: boolean;
  isMultiple?: boolean; //Nếu là true thì defaultValue là [] | [...] còn false thì phải là {} | {...} | null;
  value?: object | null;
  helperText?: string;
  handleOnChange?: (name: string, value: any) => void;
  onDelete: (id: string) => void;
  limitTags?: number;
  isDisabled?: boolean;
  isNewOption?: boolean;
  isRequired?: boolean;
  isDeleting: boolean;
}

function DeleteOption(props: IDeleteOptionProps) {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const {
    name,
    label,
    control,
    isMultiple = false,
    options = [],
    optionLabel,
    style,
    size = 'small',
    ml = 'auto',
    mr = 'auto',
    isDisableClearable = false,
    value,
    handleOnChange,
    onDelete,
    limitTags = 2,
    sx,
    isDisabled,
    helperText,
    isRequired = false,
    isDeleting = false,
  } = props;

  const { t } = useTranslation();

  const handleDelete = (e: React.BaseSyntheticEvent, id: string) => {
    e.stopPropagation();
    onDelete(id);
    setSelectedId(id);
  };

  return (
    <FormControl
      margin="dense"
      fullWidth
      style={{
        background: theme.palette.background.paper,
        marginLeft: ml,
        marginRight: mr,
        ...style,
      }}
      sx={sx}
    >
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value) => {
            return isRequired ? !_.isEmpty(value) : true;
          },
        }}
        render={({ field, field: { onChange }, fieldState: { error } }) => (
          <Autocomplete
            multiple={isMultiple}
            filterSelectedOptions={isMultiple}
            options={options}
            getOptionLabel={(option: any) => option.title || t(option[optionLabel]) || ''}
            size={size}
            value={value !== undefined ? value : field.value}
            limitTags={limitTags}
            disableClearable={isDisableClearable}
            disabled={isDisabled}
            onChange={(e, option) => {
              onChange(option);
              if (handleOnChange) {
                handleOnChange(name, option);
              }
            }}
            noOptionsText={t('labe.noSelect')}
            isOptionEqualToValue={(option, value) => option?.[optionLabel] === value?.[optionLabel]}
            renderOption={(props, option, state) => (
              <li
                {...props}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  wordWrap: 'break-word',
                }}
              >
                <Typography
                  sx={{
                    overflow: 'auto',
                  }}
                >
                  {option.title || t(option[optionLabel])}
                </Typography>
                {option.id === 'all' ? null : isDeleting && selectedId === option.id ? (
                  <CircularProgress size={22} />
                ) : (
                  <IconButton size="small" onClick={(e) => handleDelete(e, option?.id)}>
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                )}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                label={label}
                variant="outlined"
                error={!!error}
                disabled={isDisabled}
                required={isRequired}
                helperText={error ? error.message || helperText : null}
              />
            )}
          />
        )}
      />
    </FormControl>
  );
}

export default DeleteOption;
