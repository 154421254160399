import { useEffect, useState } from 'react';
import { signaling } from 'src/components/player_rtc/controller/signaling';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { setWsReconnection } from 'src/redux_store/stream/stream_slice';

export const useSignaling = () => {
  const dispatch = useAppDispatch();
  const { wsReconnect } = useAppSelector(({ streamSlice }) => streamSlice);
  const [wsOpen, setWsOpen] = useState(false);

  useEffect(() => {
    signaling.connect(false);
    // signalingReconnect();
    if (signaling.ws) {
      signaling.ws.onopen = () => {
        setWsOpen(true);
        signaling.wsAuth();
      };

      signaling.ws.onerror = () => {
        console.log('ws onerror');
      };

      signaling.ws.onclose = () => {
        console.log('ws onclose');
        setWsOpen(false);
      };
    }

    return () => {
      signaling.close();
      setWsOpen(false);
      dispatch(setWsReconnection(false));
    };
  }, []);

  useEffect(() => {
    if (wsReconnect) {
      signaling.connect(false);
      if (signaling.ws) {
        signaling.ws.onopen = () => {
          setWsOpen(true);
          signaling.wsAuth();
          dispatch(setWsReconnection(false));
        };
      }
    }
  }, [wsReconnect]);

  return wsOpen;
};
