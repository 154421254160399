export const PATH = {
  map: '/map',
  cameraMap: '/cameras',
  stationMap: '/stations',
  stationDetail: '/stations/:id',
  login: '/login',
  register: '/register',
  verifyPassword: '/change_password',
  cameraManagement: '/camera_management',
  userManagement: '/user_management',
  userManagementDetail: '/user_management/:userId',
  roleManagement: '/user_management/role',
  roleManagementDetail: '/user_management/role/:roleId',
  // roleManagement: '/role_management',
  stationManagement: '/station_management',
  customerManagement: '/customer_management',
  customerDetail: '/customer_management/:customerId',
  customerOrder: '/customer_management/:customerId/orders/:orderId',
  watchList: '/watch_list',
  aiEvent: '/ai_event',
  systemManagement: '/system_management',
  notification: '/notification',
  eventDetail: '/event_detail',
  blacklistManagement: '/blacklist_management',
  notFound: '*',
};
