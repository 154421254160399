import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: 150,
    backgroundColor: 'rgba(0,0,0,0.6)',
    padding: '8px 8px 0px 8px',
    '& button': {
      color: 'white',
      transformStyle: 'preserve-3d',
      backgroundColor: '#1c1c1ce6',
      fontSize: 14,
      borderRadius: 8,
      minWidth: 40,
      minHeight: 40,
      marginBottom: 8,
      '&:nth-child(7)': {
        '& svg:first-child': {
          transform: 'rotate(-45deg)',
        },
      },
      '&:nth-child(9)': {
        '& svg:first-child': {
          transform: 'rotate(45deg)',
        },
      },
      '&:nth-child(13)': {
        '& svg:first-child': {
          transform: 'rotate(45deg)',
        },
      },
      '&:nth-child(15)': {
        '& svg:first-child': {
          transform: 'rotate(-45deg)',
        },
      },
      '&:hover': {
        color: theme.palette.common.white,
        boxShadow: `0 0 0 1px ${theme.palette.common.white}`,
      },
    },
  },

  rootSmall: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: 110,
    backgroundColor: 'rgba(0,0,0,0.6)',
    padding: '4px 4px 0px 4px',
    '& > button': {
      color: 'white',
      transformStyle: 'preserve-3d',
      backgroundColor: '#1c1c1ce6',
      fontSize: 13,
      borderRadius: 4,
      minWidth: 30,
      minHeight: 30,
      width: 30,
      height: 30,
      marginBottom: 4,
      '&:nth-child(7)': {
        '& svg:first-child': {
          transform: 'rotate(-45deg)',
        },
      },
      '&:nth-child(9)': {
        '& svg:first-child': {
          transform: 'rotate(45deg)',
        },
      },
      '&:nth-child(13)': {
        '& svg:first-child': {
          transform: 'rotate(45deg)',
        },
      },
      '&:nth-child(15)': {
        '& svg:first-child': {
          transform: 'rotate(-45deg)',
        },
      },
      '&:hover': {
        color: theme.palette.common.white,
        boxShadow: `0 0 0 1px ${theme.palette.common.white}`,
      },
    },
  },

  btnActive: {
    color: theme.palette.common.white,
    boxShadow: `0 0 0 1px ${theme.palette.common.white}`,
  },
}));
