import { Box, Button, List, ListItem, ListItemText, Paper, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import i18n from 'src/i18n';
import { changeVideoSpeed } from 'src/redux_store/video/video_slice';
import { useStyles } from './styles';
import { useAppDispatch, useAppSelector } from 'src/redux_store';

const speedList = ['0.25', '0.5', '1', '2', '3'].reverse();

function SpeedOptions() {
  const { t } = i18n;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [isSpeedOpen, setIsSpeedOpen] = useState(false);
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);

  const controlText = t('stream.controlBar.speed');

  const onOpenMenu = () => {
    setIsSpeedOpen((prev) => !prev);
  };

  const handleSetSpeed = (speed: string) => {
    if (!window.hls) return;
    const { hls } = window;

    hls.media.playbackRate = Number(speed);

    setIsSpeedOpen((prev) => !prev);
    dispatch(changeVideoSpeed(Number(speed)));
  };

  return (
    <Box>
      <Box>
        <Tooltip title={controlText} placement="top">
          <Button size="small" onClick={onOpenMenu} className={classes.btnSpeed}>
            {mediaPlayer.speed}X
          </Button>
        </Tooltip>
        {isSpeedOpen && (
          <Paper className={classes.menu}>
            <List component="nav" aria-label="contacts">
              {speedList.map((speed, index) => (
                <ListItem
                  button
                  key={index}
                  className={classes.speedItem}
                  onClick={() => handleSetSpeed(speed)}
                >
                  <ListItemText primary={`${speed}X`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Box>
    </Box>
  );
}

export default SpeedOptions;
