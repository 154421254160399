import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: 560,
  },
  buttons: {
    textAlign: 'center',
    paddingTop: 12,
    '& button': {
      marginLeft: 8,
    },
    '& button:first-child': {
      marginLeft: 0,
    },
  },
  shareLink: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonCp: {
    flex: 'none',
    marginLeft: 8,
  },
}));
