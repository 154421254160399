import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { Plan, PlanType, SubscriptionPlan } from 'src/types'
import ShortLogo from 'src/assets/images/short-logo-256x256.png'
import { CheckRounded, Clear } from '@mui/icons-material'
import {
  delay,
  getDevices,
  isHasAnnotation360Image,
  isHasCompareImageConstraint,
  isHasShareVideosAndImages,
} from '../utils'
import { listOrganizationSubscriptions, listPlans, listSubscriptionPlans } from 'src/redux_store/organizations/slice'
import BorderLinearProgress from './border_linear_progress'

interface Props {
  orgId: string
}

const OrganizationSubscription = ({ orgId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { organizationSubscriptions } = useAppSelector((state) => state.organizations)

  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const [currentPlan, setCurrentPlan] = useState<Plan | null>(null)
  const subscriptionPlans = {} as { [key: string]: SubscriptionPlan[] }
  const fetchData = async () => {
    try {
      const rplans = await dispatch(listPlans()).unwrap()
      for (const plan of rplans) {
        const subPlans = await dispatch(listSubscriptionPlans({ planId: plan.id })).unwrap()
        subscriptionPlans[plan.id] = subPlans
      }

      const subscriptions = await dispatch(
        listOrganizationSubscriptions({ orgId, type: PlanType.Organization, status: 'active' })
      ).unwrap()

      const plan = rplans.find((p) => {
        const subPlan = subscriptionPlans[p.id]?.find((s) => s.id === subscriptions?.[0]?.items[0].subscriptionPlanId)
        if (subPlan) return true
        return false
      })

      if (plan) setCurrentPlan(plan)

      /**
       * need to delay to avoid flickering
       */
      await delay(100)
    } catch (error) {
      setHasError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (hasError) {
    return (
      <Box p={2}>
        <Typography textAlign={'center'}>Something went wrong!</Typography>
      </Box>
    )
  }

  if (loading) {
    return (
      <Box display={'flex'} justifyContent={'center'} mt={10}>
        <CircularProgress />
      </Box>
    )
  }

  if (!currentPlan) return <Typography textAlign={'center'}>Not found current plan</Typography>

  return (
    <Box p={2}>
      <Container>
        <Box mb={2}>
          <Typography variant="h6">{t('subscription.organization')}</Typography>
        </Box>
        <Box>
          {organizationSubscriptions.map((subscription, index) => {
            return (
              <Box key={index}>
                <Box
                  p={2}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  border={1}
                  borderBottom={0}
                  borderColor="divider"
                  borderRadius="8px 8px 0 0"
                >
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box px={1} display={'flex'} alignItems={'center'}>
                      <img src={ShortLogo} width={40} height={40} />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                      <Typography variant="h6" fontWeight={'bold'}>
                        {currentPlan.name}
                      </Typography>
                      <Typography variant="body2">{currentPlan.description}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box border={1} borderColor="divider" borderRadius={'0 0 8px 8px'} p={2}>
                  {currentPlan.constraints['seats'] ? (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Box mr={1}>
                        <CheckRounded color="success" fontWeight={600} />
                      </Box>
                      <Typography>
                        {currentPlan.constraints['seats'] > 1000
                          ? t('plan.unlimited')
                          : currentPlan.constraints['seats']}{' '}
                        seats
                      </Typography>
                    </Box>
                  ) : null}
                  {currentPlan.constraints['devices'] ? (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Box mr={1}>
                        <CheckRounded color="success" fontWeight={600} />
                      </Box>
                      <Typography>{getDevices(currentPlan.constraints['devices'])}</Typography>
                    </Box>
                  ) : null}
                  {isHasCompareImageConstraint([currentPlan]) ? (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Box mr={1}>
                        {currentPlan.constraints['compareImageSideBySide'] ? (
                          <CheckRounded color="success" fontWeight={600} />
                        ) : (
                          <Clear color="error" />
                        )}
                      </Box>
                      <Typography>{t('plan.organization.compare_image_side_by_side')}</Typography>
                    </Box>
                  ) : null}

                  {isHasShareVideosAndImages([currentPlan]) ? (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Box mr={1}>
                        {currentPlan.constraints['shareVideosAndImages'] ? (
                          <CheckRounded color="success" fontWeight={600} />
                        ) : (
                          <Clear color="error" />
                        )}
                      </Box>
                      <Typography>{t('plan.organization.share_videos_and_images')}</Typography>
                    </Box>
                  ) : null}
                  {isHasAnnotation360Image([currentPlan]) ? (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Box mr={1}>
                        {currentPlan.constraints['annotation360Image'] ? (
                          <CheckRounded color="success" fontWeight={600} />
                        ) : (
                          <Clear color="error" />
                        )}
                      </Box>
                      <Typography>{t('plan.organization.annotation_360_image')}</Typography>
                    </Box>
                  ) : null}
                </Box>
                <Box py={2}>
                  <Box></Box>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Typography fontWeight={'bold'}>
                      {subscription.metadata['filledSeats']} / {subscription.metadata['seats']} seats
                    </Typography>
                  </Box>
                  <Box py={1}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={(subscription.metadata['filledSeats'] / subscription.metadata['seats']) * 100}
                    />
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Container>
    </Box>
  )
}

export default OrganizationSubscription
