import React, { ReactNode, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { LoadingButton } from '@mui/lab';

// import i18n from 'src/i18n';
import { onVideoSeeked } from 'src/redux_store/video/video_slice';

import { useIsRequestError, useIsRequestPending, useIsRequestSuccess } from 'src/hooks';
import { getVideoHls } from 'src/redux_store/video/video_action';
import { renderMediaSource } from '../utils/video';
import { useStyles } from './styles';
import { IViolationTime } from '../types/violation';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { toastMessage } from 'src/utils/toast';
import { useTranslation } from 'react-i18next';

interface IMediaProps {
  mediaWrapper: any;
  children: ReactNode;
  autoPlay?: boolean;
  violationTime?: IViolationTime;
}

function MediaPlayer(props: IMediaProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const isLoading = useIsRequestPending('video', 'getVideoHls');
  const isLoaded = useIsRequestSuccess('video', 'getVideoHls');
  const isLoadFailure = useIsRequestError('video', 'getVideoHls');
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const { isSeeking, videoLinks, isRefresh, date, cameraId, isMuted } = mediaPlayer;
  const { mediaWrapper, children, autoPlay = true, violationTime } = props;

  useEffect(() => {
    if (!cameraId) return;
    getVideo(cameraId);
  }, [cameraId]);

  useEffect(() => {
    if (!videoLinks.length && cameraId) {
      if (!window.hls) return;
      window.hls.destroy();
    }

    if (!videoLinks.length || (!cameraId && !violationTime) || isRefresh) return;

    const todaysDate = new Date();

    if (todaysDate.setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
      renderMediaSource(videoLinks[videoLinks.length - 1], null, autoPlay);
    } else {
      renderMediaSource(videoLinks[0], null, autoPlay);
    }
  }, [videoLinks, isRefresh, cameraId, date]);

  useEffect(() => {
    let ms: NodeJS.Timeout | null = null;
    if (isSeeking) {
      ms = setTimeout(() => {
        dispatch(onVideoSeeked());
        toastMessage.error(t('stream.messages.errorWhenDownloadingVideoFile'));
      }, 30000);
    }

    return () => {
      if (!ms) return;
      clearTimeout(ms);
    };
  }, [isSeeking]);

  useEffect(() => {
    return () => {
      if (window.hls) {
        window.hls.destroy();
      }
    };
  }, []);

  const getVideo = (cameraId: string) => {
    dispatch(getVideoHls({ date, cameraId }))
      .unwrap()
      .catch(() => toastMessage.error(t('message.error.getListVideo')));
  };

  const renderVideo = () => {
    if (!videoLinks.length) {
      if (isLoadFailure)
        return (
          <Box className={classes.videoEmpty}>
            <Typography color="white" textAlign="center" mb={1}>
              {t('message.error.loadingData')}
            </Typography>
            <LoadingButton
              onClick={() => getVideo(cameraId)}
              loading={isLoading}
              variant="contained"
              size="small"
            >
              {t('button.retry')}
            </LoadingButton>
          </Box>
        );
      if (isLoaded)
        return (
          <Box className={classes.videoEmpty}>
            <Typography color="white" textAlign="center">
              {t('label.emptyData.main')}
            </Typography>
          </Box>
        );
    }

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        id="videoHls"
        preload="none"
        style={{ width: '100%', height: '100%' }}
        className={classes.video}
        muted={isMuted}
      ></video>
    );
  };

  return (
    <Box className={classes.root}>
      <Grid item className={classes.media} ref={mediaWrapper}>
        <Box
          className={classNames(classes.layer, {
            [classes.seeking]: isLoading || isSeeking,
          })}
        >
          <CircularProgress classes={{ root: classes.loading }} size={60} />
        </Box>
        <>
          {children}
          {renderVideo()}
        </>
      </Grid>
    </Box>
  );
}

export default MediaPlayer;
