import { createSlice } from '@reduxjs/toolkit';

import { ICamera } from 'src/types/camera';
import { ICustomerMembers } from 'src/types/customer';
import { deleteCustomerMembers, getCustomerMembers, inviteCustomerMember } from './actions';

interface ICustomerMembersState {
  camerasWithoutCustomer: ICamera[];
  members: ICustomerMembers[];
}

const initialState: ICustomerMembersState = {
  camerasWithoutCustomer: [],
  members: [],
};

const customerMembersSlice = createSlice({
  name: 'customerMembers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(inviteCustomerMember.fulfilled, (state, action) => {
        state.members.push(action.payload);
      })
      .addCase(getCustomerMembers.fulfilled, (state, action) => {
        state.members = action.payload;
      })
      .addCase(deleteCustomerMembers.fulfilled, (state, action) => {
        state.members = state.members.filter(
          (member) => member.customerMemberId !== action.payload,
        );
      });
  },
});

const { reducer } = customerMembersSlice;
export default reducer;
