import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  menu: {
    position: 'absolute',
    bottom: 33,
    right: 0,
  },
  btnSpeed: {
    minWidth: '0 !important',
    padding: '2px 10px !important',
    color: '#fff !important',
    fontWeight: 700,
    fontSize: 14,
    '&:hover': {},
  },
  btnSpeedEmpty: {
    background: '#5c5c5c',
  },
  speedItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});
