import { MoreVertOutlined, SettingsRounded } from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/redux_store'
import { Subscription } from 'src/types'
import { getSubscriptionPlan } from '../../../utils'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

interface Props {
  orgId: string
  subscription: Subscription
}

const ActiveSubscriptionRow = ({ subscription }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { subscriptionPlans } = useAppSelector((state) => state.organizations)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCellClick = () => {
    navigate(subscription.id)
  }

  const subPlan = getSubscriptionPlan(subscriptionPlans, subscription?.items?.[0]?.subscriptionPlanId)
  if (!subPlan)
    return (
      <Box>
        <Typography textAlign={'center'}>Something wrong!</Typography>
      </Box>
    )

  return (
    <TableRow sx={{ cursor: 'pointer' }} hover>
      <TableCell onClick={handleCellClick}>
        <Chip label={subPlan.plan.name} size="small" variant="outlined" />
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography
          fontWeight={600}
          textAlign={'end'}
        >{`${subscription.metadata['filledStations']} / ${subscription.metadata['stations']}`}</Typography>
        <BorderLinearProgress
          variant="determinate"
          value={(subscription.metadata['filledStations'] / subscription.metadata['stations']) * 100}
        />
      </TableCell>
      <TableCell onClick={handleCellClick}>
        {subPlan.interval === 1 && <Chip label={t('subscription.Month')} size="small" variant="outlined" />}
        {subPlan.interval === 2 && <Chip label={t('subscription.Year')} size="small" variant="outlined" />}
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography>{moment(subscription.createdAt).format('HH:mm:ss DD-MM-YYYY')}</Typography>
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography>
          {subscription.currentPeriodStart ? moment(subscription.currentPeriodStart).format('HH:mm:ss DD-MM-YYYY') : ''}
        </Typography>
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography>
          {subscription.currentPeriodEnd ? moment(subscription.currentPeriodEnd).format('HH:mm:ss DD-MM-YYYY') : ''}
        </Typography>
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Chip label="Active" size="small" color="success" />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleClick}>
          <MoreVertOutlined />
        </IconButton>
        <Menu
          id="active-subscriptions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={1}
          sx={{ minWidth: 480 }}
        >
          <MenuItem sx={{ width: 200 }} onClick={() => navigate(`${subscription.id}/stations`)}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flex={1}>
              <Typography>{t('subscription.manage_stations')}</Typography>
              <SettingsRounded />
            </Box>
          </MenuItem>
          {/* <MenuItem disabled onClick={() => {}}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flex={1}>
              <Typography>Renew </Typography>
              <AutorenewSharp />
            </Box>
          </MenuItem> */}
        </Menu>
      </TableCell>
    </TableRow>
  )
}

export default ActiveSubscriptionRow
