import { Notifications, NotificationsNone } from '@mui/icons-material';
import { IIconTopBar } from 'src/types/common';

const TOP_BAR_LIST: IIconTopBar[] = [
  // {
  //   id: 'notification',
  //   title: 'Thông báo',
  //   icon: NotificationsNone,
  //   activeIcon: Notifications,
  // },
  // {
  //   id: 'downloadApp',
  //   title: 'Tải ứng dụng',
  //   icon: InstallMobileOutlined,
  //   activeIcon: InstallMobile,
  // },
  // {
  //   id: 'settingTheme',
  //   title: 'Thiết lập giao diện',
  //   icon: DisplaySettingsOutlined,
  //   activeIcon: DisplaySettings,
  // },
  // {
  //   id: 'help',
  //   title: 'Trợ giúp',
  //   icon: HelpOutlineOutlined,
  //   activeIcon: HelpOutline,
  // },
  // {
  //   id: 'whatNews',
  //   title: 'Có gì mới',
  //   icon: CampaignOutlined,
  //   activeIcon: Campaign,
  // },
];

export default TOP_BAR_LIST;
