import React, { useState } from 'react';
import { Badge, Box, Typography } from '@mui/material';

import i18n from 'src/i18n';
import { TStreamMode } from 'src/types/stream';
import { IPlayerProps } from '../../../types/player';
import { useStyles } from './styles';

type IColor = 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning';

interface IDotWithText {
  color?: IColor;
  text: string;
  id: TStreamMode;
  changeStreamMode: (id: string) => void;
}

function DotWithText(props: IDotWithText) {
  const { id, color, text, changeStreamMode } = props;
  const [optionId, setOptionId] = useState('');
  const classes = useStyles();

  const handleClick = () => {
    if (optionId === id) return;

    setOptionId(id);

    return changeStreamMode(id);
  };

  return (
    <Box onClick={handleClick} className={classes.root}>
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        overlap="circular"
        color={color}
        variant="dot"
        classes={{ badge: !color ? classes.disabledBadge : undefined }}
      />
      <Typography variant="body2" ml={1} color="white">
        {text}
      </Typography>
    </Box>
  );
}

function ModeOptions(props: any) {
  const { controlBar } = props as IPlayerProps;
  const { t } = i18n;

  if (!controlBar?.modeOptions) return null;

  const { changeStreamMode } = controlBar.modeOptions;

  const playbackTitle = t('stream.controlBar.mode.record');
  const liveTitle = t('stream.controlBar.mode.live');

  return (
    <Box display="flex" alignItems="center">
      <DotWithText text={playbackTitle} id="playback" changeStreamMode={changeStreamMode} />
      <DotWithText text={liveTitle} id="live" color="error" changeStreamMode={changeStreamMode} />
    </Box>
  );
}

export default ModeOptions;
