import React from 'react';
import { FullscreenExitOutlined, FullscreenOutlined } from '@mui/icons-material';

import { IPlayerProps } from '../../../types/player';
import i18n from 'src/i18n';
import ButtonWrapper from '../button_wrapper';

function FullscreenToggle(props: any) {
  const { actions, player, manager, controlBar } = props as IPlayerProps;
  const { t } = i18n;

  if (controlBar?.hideFullscreen) return null;

  const controlText = player.isFullscreen
    ? t('stream.controlBar.fullScreen.true')
    : t('stream.controlBar.fullScreen.false');

  const handleClick = () => {
    const { video, rootElement } = manager;
    // debugLog({ rootElement, video, player });
    if (player.error || !video?.rtc?.pc) return;

    actions.toggleFullscreen(rootElement, player);
  };

  return (
    <ButtonWrapper
      title={controlText}
      icon={player.isFullscreen ? FullscreenExitOutlined : FullscreenOutlined}
      handleClick={handleClick}
      placement="top-end"
      // disablePortal
    />
  );
}

export default FullscreenToggle;
