import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    background: theme.palette.secondary.light,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
}));
