import React from 'react';
import { VisibilityOffOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { IPlayerProps } from 'src/components/player_hls/types/player';
import { removeSingleCamFromWatchlist } from 'src/redux_store/watchlist/watchlist_action';

import { debugLog } from '../../../utils/log';
import store from 'src/redux_store';
import { toastMessage } from 'src/utils/toast';
import { useTranslation } from 'react-i18next';

const dispatch = store.dispatch;

function FollowToggle(props: any) {
  const slice = { ...props } as IPlayerProps;
  const { cam } = slice;
  const { t } = useTranslation();

  const controlText = t('stream.controlBar.unFollow');

  const handleClick = () => {
    if (!cam.classifiedListId || !cam.position) return;
    if (slice.isFollowDisabled)
      return toastMessage.error(t('message.validate.watchList.cannotRemoveInAll'));
    dispatch(
      removeSingleCamFromWatchlist({ position: cam.position, groupId: cam.classifiedListId }),
    );

    debugLog('🚀 ~ CPN.video.FollowToggle');
  };

  if (!cam.classifiedListId || !cam.position) return null;

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          <VisibilityOffOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default FollowToggle;
