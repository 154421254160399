import { Box } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useIsRequestPending } from 'src/hooks';
import { useAppSelector } from 'src/redux_store';
import { TimelineChart } from '../chart_timeline';
import { IViolationTime } from '../types/violation';
import { useStyles } from './styles';

const ChartJsOption = { responsive: false };

interface IControlTimeline {
  violationTime?: IViolationTime;
  getTimeline: (timeline: TimelineChart) => void;
}

function ControlTimeline(props: IControlTimeline) {
  const classes = useStyles();
  const timelineCanvas = useRef(null);
  const isLoading = useIsRequestPending('video', 'getVideoHls');
  const [timeline, setTimeline] = useState<TimelineChart | null>(null);
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const { isRefresh, isSeeking, date } = mediaPlayer;
  const { violationTime, getTimeline } = props;

  useEffect(() => {
    if (!timelineCanvas?.current || isRefresh) return;

    const newTimeLine = new TimelineChart(timelineCanvas.current, violationTime, ChartJsOption);

    setTimeline(newTimeLine);
    getTimeline(newTimeLine);
  }, [isLoading, isRefresh, violationTime]);

  useEffect(() => {
    return () => {
      if (!timeline) return;
      timeline.destroy();

      if (!timeline.refreshVideoHlsId) return;
      timeline.clearTimeout();
      setTimeline(null);
    };
  }, [timeline, date]);

  return (
    <Box className={classes.root}>
      {isLoading && !isRefresh ? (
        <SkeletonTheme baseColor="#3f51b5" highlightColor="#495ccb">
          <Skeleton height={55} />
        </SkeletonTheme>
      ) : (
        <Box
          id="wrapTimeline"
          className={classNames(classes.wrapTimeLine, { [classes.seeking]: isSeeking })}
        >
          <canvas id="timeline" ref={timelineCanvas} height="55" className={classes.timeline}>
            Your browser does not support canvas
          </canvas>
        </Box>
      )}
    </Box>
  );
}

export default ControlTimeline;
