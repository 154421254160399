import * as React from 'react';
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { toggleViewDetail } from 'src/redux_store/notification/notification_slice';

export default function ViewDetailNotificationDrawer() {
  const { openViewDetail } = useAppSelector((state) => state.notificationSlice);
  const dispatch = useAppDispatch();

  const toggleDrawer = () => () => {
    dispatch(toggleViewDetail({ open: false, type: '' }));
  };

  const list = () => (
    <Box sx={{ width: 800 }} role="presentation">
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Drawer anchor={'right'} open={openViewDetail} onClose={toggleDrawer()}>
        {list()}
      </Drawer>
    </React.Fragment>
  );
}
