import { ICameraFilter } from 'src/types/camera';
import { IStationFilter } from 'src/types/station';

export const initCameraFilter: ICameraFilter = {
  searchKeyword: '',
  province: '',
  district: '',
  commune: '',
  customer: '',
  status: '',
  station: '',
};

export const initStationFilter: IStationFilter = {
  searchKeyword: '',
  province: '',
  district: '',
  commune: '',
  customer: '',
  status: '',
};
