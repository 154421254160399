// import { IUser } from './auth';

export const initRoleFilter: IRoleFilter = {
  limit: 10,
  page: 1,
  searchKeyword: '',
};

export interface IRole {
  id: string;
  name: string;
  level: number;
  description: string;
  schemes?: string;
  builtIn: boolean;
  permissions: string[];
}

export interface IRoleFilter {
  searchKeyword?: string;
  page: number;
  limit: number;
}

export interface IRoleData extends IRole {
  totalUsers: number;
  level: number;
  users?: any;
  permissionsName?: { key: string; displayName: string }[];
}
