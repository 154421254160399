import { SolarPowerOutlined } from '@mui/icons-material';
import React from 'react';
import { EStationStatus } from 'src/types/station';
import { getColorIconStation } from './utils';

type Props = {
  stationStatus: EStationStatus;
};

function StationPowerSolar({ stationStatus }: Props) {
  return <SolarPowerOutlined style={{ color: getColorIconStation(stationStatus) }} />;
}

export default StationPowerSolar;
