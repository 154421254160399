import { DoneOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButtonTooltip from 'src/components/tooltip/icon_button_tooltip';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import {
  // changeTotalPageAndTotalData,
  deleteNotificationLocal,
} from 'src/redux_store/notification/notification_slice';
import theme from 'src/theme';
import { markItemNotification } from 'src/utils/notification';

type Props = {
  id: string;
  isRead: boolean;
};

const MarkNotificationItem = (props: Props) => {
  const { id, isRead } = props;
  const { payload } = useAppSelector((state) => state.notificationSlice);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleToggleMarkNotification = () => {
    if (isRead) {
      markItemNotification(dispatch, 'unread', id);
    } else {
      if (payload.type.includes('UNREAD')) {
        dispatch(deleteNotificationLocal(id));
        // dispatch(changeTotalPageAndTotalData('minus'));
      }
      markItemNotification(dispatch, 'read', id);
    }
  };
  return (
    <Grid item>
      <IconButtonTooltip
        title={isRead ? t('notification.button.unread') : t('notification.button.read')}
        icon={<DoneOutlined fontSize="medium" />}
        size="medium"
        style={{ color: theme.palette.secondary.main }}
        onClick={() => handleToggleMarkNotification()}
      />
    </Grid>
  );
};

export default MarkNotificationItem;
