import { PowerOutlined } from '@mui/icons-material';
import { EStationStatus } from 'src/types/station';
import { getColorIconStation } from './utils';

type Props = {
  stationStatus: EStationStatus;
};

const StationElectricLine = ({ stationStatus }: Props) => {
  return <PowerOutlined style={{ color: getColorIconStation(stationStatus) }} />;
};

export default StationElectricLine;
