import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { ICamera, ICameraFilter } from 'src/types/camera';
import { IRequestError } from 'src/types/error';
import { IStation, IStationFilter } from 'src/types/station';
import { RootState } from '..';
import { toastMessage } from 'src/utils/toast';
import i18n from 'src/i18n';

export const getCamerasMap = createAsyncThunk<
  ICamera[],
  ICameraFilter,
  { state: RootState; rejectValue: IRequestError }
>('map/getCamerasMap', async (filter, { rejectWithValue }) => {
  try {
    const data = await client.getCameras(filter);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getStationsMap = createAsyncThunk<IStation[], IStationFilter>(
  'map/getStationsMap',
  async (filter, { rejectWithValue }) => {
    try {
      const data = await client.getStations(filter);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const toggleStationLight = createAsyncThunk<IStation, { stationId: string }>(
  'map/toggleStationLight',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.toggleLightStatus(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message || i18n.t('message.systemError'));
      return rejectWithValue(error);
    }
  },
);

export const getStationDetail = createAsyncThunk<IStation, string>(
  'map/getStationDetail',
  async (stationId, { rejectWithValue }) => {
    try {
      const data = await client.getStationGeneral(stationId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
