import { createSlice } from '@reduxjs/toolkit';

import { IStorageSpaceUsage, ISystemStorage } from 'src/types/system';
import {
  getStorage,
  getStorageSpaceUsage,
  getVideoStorageGroup,
  updateStorage,
} from './storage_action';

interface ISystemVideoStorageState {
  group: { name: string; maxKeepDay: number }[];
  cameras: any[];
  inGroup: any[];
}

const initVideoStorageState: ISystemVideoStorageState = {
  group: [],
  cameras: [],
  inGroup: [],
};

const initialState = {
  storage: {} as ISystemStorage,
  spaceUsage: [] as IStorageSpaceUsage[],
  videoStorage: initVideoStorageState,
};

const storageState = createSlice({
  initialState,
  reducers: {},
  name: 'system/storage',
  extraReducers(builder) {
    builder
      .addCase(getStorage.fulfilled, (state, action) => {
        state.storage = action.payload;
      })
      .addCase(updateStorage.fulfilled, (state, action) => {
        state.storage = action.payload;
      })
      .addCase(getStorageSpaceUsage.fulfilled, (state, action) => {
        state.spaceUsage = action.payload;
      })
      .addCase(getVideoStorageGroup.fulfilled, (state, action) => {
        state.videoStorage.group = action.payload;
      });
  },
});

export default storageState.reducer;
