import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EStationStatus, IStation } from 'src/types/station';
import { getOrders } from '../orders/actions';
import {
  addOrderStations,
  deleteOrderStation,
  getStationWithoutCustomer,
  getOrderStations,
} from './actions';

interface IOrderStationState {
  stationsWithoutCustomer: IStation[];
  stations: IStation[];
}

const initialState: IOrderStationState = {
  stationsWithoutCustomer: [],
  stations: [],
};

const orderStationSlice = createSlice({
  name: 'orderCameras',
  initialState,
  reducers: {
    addOrderStationSocket: (state: IOrderStationState, action: PayloadAction<IStation>) => {
      const currentStations = [...state.stations];
      const currentStationWithoutCustomer = [...state.stationsWithoutCustomer];
      const stationsAdded = action.payload;

      const index = currentStations.findIndex((item) => item.id === stationsAdded.id);

      if (index === -1) {
        currentStations.push(stationsAdded);
      }

      const indexCurrent = currentStationWithoutCustomer.findIndex(
        (item) => item.id === stationsAdded.id,
      );

      if (indexCurrent !== -1) currentStationWithoutCustomer.splice(index, 1);

      state.stations = currentStations;
      state.stationsWithoutCustomer = currentStationWithoutCustomer;
    },

    updateStationStatusOfOrderStation: (
      state: IOrderStationState,
      action: PayloadAction<{ stationId: string; status: EStationStatus }>,
    ) => {
      const { stationId, status } = action.payload;
      const currentStations = [...state.stations];

      const index = currentStations.findIndex((item) => item.id === stationId);

      if (index !== -1) {
        const item = { ...currentStations[index], status };

        currentStations.splice(index, 1, item);
        state.stations = currentStations;
      }
    },

    deletedOrderStationSocket: (state: IOrderStationState, action: PayloadAction<string>) => {
      state.stations = [...state.stations].filter((station) => station.id !== action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addOrderStations.fulfilled, (state, action) => {
        state.stations.push(...action.payload);
      })
      .addCase(getStationWithoutCustomer.fulfilled, (state, action) => {
        state.stationsWithoutCustomer = action.payload;
      })
      .addCase(getOrderStations.fulfilled, (state, action) => {
        state.stations = action.payload;
      })

      .addCase(deleteOrderStation.fulfilled, (state, action) => {
        state.stations = state.stations.filter((camera) => camera.id !== action.payload);
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.stations.length = 0;
        }
      });
  },
});

const { reducer, actions } = orderStationSlice;

export const {
  addOrderStationSocket,
  deletedOrderStationSocket,
  updateStationStatusOfOrderStation,
} = actions;

export default reducer;
