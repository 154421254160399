import React from 'react';
import { Grid, Divider } from '@mui/material';
import clsx from 'clsx';

import PlayToggle from './play_toggle';
import FullscreenToggle from './fullscreen_toggle';
import Snapshot from './snapshot';
import SpeedOptions from './speed_options';
import TheaterMode from './theater_mode';
import ModeOptions from './mode_options';
import Calendar from './calendar';
import Extract from './extract';
import Refresh from './refresh';
import Volume from './volume';
import { IViolationTime } from '../types/violation';
import { useAppSelector } from 'src/redux_store';
import { useStyles } from './styles';

interface IControlBar {
  mediaWrapper: any;
  violationTime?: IViolationTime;
  isHideTheaterMode?: boolean;
}

export default function ControlBar(props: IControlBar) {
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const classes = useStyles();
  const { mediaWrapper, violationTime, isHideTheaterMode } = props;
  const { isRefresh, videoLinks } = mediaPlayer;

  return (
    <Grid
      container
      className={clsx(classes.controlBar, {
        [classes.emptyData]: !videoLinks.length && !isRefresh,
      })}
    >
      <Grid item md={2} className={classes.controlLeft}>
        <PlayToggle />
        {!violationTime && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              style={{ background: '#fff', margin: '0 8px' }}
            />
            <Refresh />
            <ModeOptions />
          </>
        )}
      </Grid>
      <Grid item md={10} className={classes.controlRight}>
        {!violationTime && <Calendar />}
        <Volume />
        <Extract />
        <Snapshot />
        <SpeedOptions />
        {!violationTime && !isHideTheaterMode && <TheaterMode />}
        <FullscreenToggle mediaWrapper={mediaWrapper} />
      </Grid>
    </Grid>
  );
}
