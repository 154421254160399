import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  wrapTimeLine: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#3f51b5',
    position: 'relative',
  },
  seeking: {
    cursor: 'wait',
  },
  layoutLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1200,
  },
  timeline: {
    width: '100%',
    position: 'relative',
  },
  buttonActions: {
    textAlign: 'center',
    display: 'flex',
    padding: '3px 0px',
    background: '#000',
  },
});
