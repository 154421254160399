import React from 'react';
import { Crop169Outlined, MapOutlined } from '@mui/icons-material';

import { IPlayerProps } from 'src/components/player_rtc/types/player';
import i18n from 'src/i18n';
import ButtonWrapper from '../button_wrapper';

function TheaterMode(props: any) {
  const { controlBar } = props as IPlayerProps;
  const { t } = i18n;

  if (!controlBar?.theaterMode) return null;

  const { isTheaterMode, toggleTheaterMode } = controlBar.theaterMode;

  const handleClick = () => {
    toggleTheaterMode();
  };

  const controlText = !isTheaterMode
    ? t('stream.controlBar.theaterMode.false')
    : t('stream.controlBar.theaterMode.true');

  return (
    <ButtonWrapper
      title={controlText}
      icon={isTheaterMode ? MapOutlined : Crop169Outlined}
      placement="top-start"
      handleClick={handleClick}
      disablePortal
    />
  );
}

export default TheaterMode;
