import {
  IPagination,
  Organization,
  OrganizationInvitation,
  OrganizationMember,
  Payment,
  Plan,
  Subscription,
  SubscriptionPlan,
  SubscriptionStation,
} from 'src/types'
import ClientBase from './base'

export interface ClientOrganizationMix {
  listPlans(): Promise<Plan[]>
  listSubscriptionPlans(planId: string): Promise<SubscriptionPlan[]>
  listOrganizations({
    searchKeyword,
    page,
    limit,
  }: {
    searchKeyword: string
    page: number
    limit: number
  }): Promise<IPagination<Organization>>
  getOrganization(orgId: string): Promise<Organization>
  updateOrganization(
    orgId: string,
    data: { name: string; email: string; phone: string; address: string; description: string }
  ): Promise<Organization>
  createOrganizationAsAdmin(): Promise<Organization>
  listOrganizationStations(orgId: string): Promise<any[]>
  listOrganizationSubscriptions(orgId: string, type: number, status: string): Promise<Subscription[]>
  getOrganizationSubscription(orgId: string, subscriptionId: string): Promise<Subscription>
  listSubscriptionStations(orgId: string, subscriptionId: string): Promise<SubscriptionStation[]>
  listSubscriptionPayments(orgId: string, subscriptionId: string): Promise<Payment[]>
  listOrganizationPayments(orgId: string): Promise<Payment[]>
  listOrganizationMembers(orgId: string): Promise<OrganizationMember[]>
  listOrganizationInvitations(orgId: string): Promise<OrganizationInvitation[]>
}

const ClientOrganization = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientOrganizationMix {
    getOganizationsRoute() {
      return `${this.getBaseRoute()}/admin/organizations`
    }

    getOrganizationRoute(orgId: string) {
      return `${this.getOganizationsRoute()}/${orgId}`
    }

    listPlans() {
      const url = `${this.getBaseRoute()}/plans`
      return this.doFetch<Plan[]>(url, { method: 'get' })
    }

    listSubscriptionPlans(planId: string) {
      const url = `${this.getBaseRoute()}/plans/${planId}/subscription_plans`
      return this.doFetch<SubscriptionPlan[]>(url, { method: 'get' })
    }

    listOrganizations({ searchKeyword, page, limit }: { searchKeyword: string; page: number; limit: number }) {
      const url = `${this.getOganizationsRoute()}`
      return this.doFetch<IPagination<Organization>>(url, { method: 'get', params: { searchKeyword, page, limit } })
    }

    getOrganization(orgId: string) {
      const url = `${this.getOrganizationRoute(orgId)}`
      return this.doFetch<Organization>(url, { method: 'get' })
    }

    updateOrganization(
      orgId: string,
      data: { name: string; email: string; phone: string; address: string; description: string }
    ): Promise<Organization> {
      const url = `${this.getOrganizationRoute(orgId)}`
      return this.doFetch<Organization>(url, { method: 'put', data })
    }

    createOrganizationAsAdmin() {
      const url = `${this.getOganizationsRoute()}`
      return this.doFetch<Organization>(url, { method: 'post' })
    }

    listOrganizationSubscriptions(orgId: string, type: number, status: string) {
      const url = `${this.getOrganizationRoute(orgId)}/subscriptions`
      return this.doFetch<Subscription[]>(url, { method: 'get', params: { type, status } })
    }

    getOrganizationSubscription(orgId: string, subscriptionId: string): Promise<Subscription> {
      const url = `${this.getOrganizationRoute(orgId)}/subscriptions/${subscriptionId}`
      return this.doFetch<Subscription>(url, { method: 'get' })
    }

    listSubscriptionPayments(orgId: string, subscriptionId: string): Promise<Payment[]> {
      const url = `${this.getOrganizationRoute(orgId)}/subscriptions/${subscriptionId}/payments`
      return this.doFetch<Payment[]>(url, { method: 'get' })
    }

    listSubscriptionStations(orgId: string, subscriptionId: string) {
      const url = `${this.getOrganizationRoute(orgId)}/subscriptions/${subscriptionId}/stations`
      return this.doFetch<SubscriptionStation[]>(url, { method: 'get' })
    }

    listOrganizationPayments(orgId: string) {
      const url = `${this.getOrganizationRoute(orgId)}/payments`
      return this.doFetch<Payment[]>(url, { method: 'get' })
    }

    listOrganizationMembers(orgId: string) {
      const url = `${this.getOrganizationRoute(orgId)}/members`
      return this.doFetch<OrganizationMember[]>(url, { method: 'get' })
    }

    listOrganizationInvitations(orgId: string) {
      const url = `${this.getOrganizationRoute(orgId)}/invitations`
      return this.doFetch<OrganizationInvitation[]>(url, { method: 'get' })
    }

    listOrganizationStations(orgId: string) {
      const url = `${this.getOrganizationRoute(orgId)}/stations`
      return this.doFetch<any[]>(url, { method: 'get' })
    }

    addStationToOrganization(orgId: string, stationId: string) {
      const url = `${this.getOrganizationRoute(orgId)}/stations`
      return this.doFetch(url, { method: 'post', data: { stationId } })
    }

    removeStationFromOrganization(orgId: string, stationId: string) {
      const url = `${this.getOrganizationRoute(orgId)}/stations`
      return this.doFetch(url, { method: 'delete', data: { stationId } })
    }
  }

export default ClientOrganization
