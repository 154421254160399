export type TLevelLog = 'INFO' | 'WARNING' | 'ERROR' | 'CRITICAL';

export enum ELeverLog {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
}

export enum ELeverLogFilter {
  INFO = '0',
  WARNING = '1',
  ERROR = '2',
  CRITICAL = '3',
  ALL = '4',
}

export interface ILogFilter {
  level: string;
  startTime: string;
  endTime: string;
  simpleMode: boolean;
  page: number;
}
