import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  body: { display: 'flex', flex: 1, height: '100%' },

  panel: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: 350,
    background: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    background: theme.palette.background.paper,
    padding: '8px 0',
    height: 48,
  },
  headerTitle: {
    fontWeight: '600 !important',
    flex: '1',
  },
}));
