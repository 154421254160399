import TypographyTooltip from 'src/components/tooltip/typography_tooltip';

type Props = {
  gpsWaveStrength: number;
  title: string;
  color: string;
};

function StationGPSIconMedium({ gpsWaveStrength, title, color }: Props) {
  const renderOpacity = (minRange: number) => {
    if (gpsWaveStrength >= minRange) return 1;
    return 0.6;
  };

  return (
    <TypographyTooltip title={title}>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5343_111203)">
          <g clipPath="url(#clip1_5343_111203)">
            <path
              d="M7.99996 13.3333C6.52663 13.3333 5.33329 14.5267 5.33329 16C5.33329 17.4733 6.52663 18.6667 7.99996 18.6667C9.47329 18.6667 10.6666 17.4733 10.6666 16C10.6666 14.5267 9.47329 13.3333 7.99996 13.3333ZM13.96 15.3333C13.6533 12.5533 11.4466 10.3467 8.66663 10.04V8.66666H7.33329V10.04C4.55329 10.3467 2.34663 12.5533 2.03996 15.3333H0.666626V16.6667H2.03996C2.34663 19.4467 4.55329 21.6533 7.33329 21.96V23.3333H8.66663V21.96C11.4466 21.6533 13.6533 19.4467 13.96 16.6667H15.3333V15.3333H13.96ZM7.99996 20.6667C5.41996 20.6667 3.33329 18.58 3.33329 16C3.33329 13.42 5.41996 11.3333 7.99996 11.3333C10.58 11.3333 12.6666 13.42 12.6666 16C12.6666 18.58 10.58 20.6667 7.99996 20.6667Z"
              fill={color}
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2642 2.21422C10.478 2.42799 10.7954 2.49557 11.0851 2.4092C13.9805 1.54602 17.2452 2.2576 19.5316 4.54394C21.8179 6.83027 22.5295 10.095 21.6663 12.9904C21.5799 13.2801 21.6475 13.5975 21.8613 13.8113C22.2477 14.1978 22.8999 14.0741 23.0633 13.5525C24.1406 10.1138 23.3169 6.20795 20.5922 3.48327C17.8676 0.758601 13.9618 -0.065077 10.523 1.01224C10.0014 1.17563 9.87776 1.82778 10.2642 2.21422Z"
            fill={color}
            fillOpacity={renderOpacity(40)}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.66593 5.1741C9.88934 5.39752 10.2242 5.46004 10.5249 5.36297C12.773 4.6372 15.3382 5.16691 17.1234 6.95211C18.9086 8.7373 19.4383 11.3026 18.7126 13.5507C18.6155 13.8514 18.678 14.1862 18.9014 14.4096C19.2769 14.7851 19.9093 14.6824 20.0836 14.1808C21.0591 11.3745 20.4259 8.13323 18.1841 5.89145C15.9423 3.64966 12.7011 3.01649 9.8947 3.99191C9.39314 4.16624 9.29046 4.79864 9.66593 5.1741Z"
            fill={color}
            fillOpacity={renderOpacity(80)}
          />
          <path
            d="M14.7748 15.1246C15.5559 15.9056 16.8222 15.9056 17.6033 15.1246C18.3843 14.3435 18.3843 13.0772 17.6033 12.2961L14.7748 15.1246ZM15.9407 12.983L17.9343 13.1432L15.9407 12.983ZM11.0926 8.13485L10.9324 6.14128L11.0926 8.13485ZM16.0515 8.02411C14.6485 6.62119 12.7611 5.9943 10.9324 6.14128L11.2528 10.1284C11.9627 10.0714 12.684 10.3135 13.223 10.8525L16.0515 8.02411ZM17.9343 13.1432C18.0813 11.3145 17.4544 9.42703 16.0515 8.02411L13.223 10.8525C13.7621 11.3916 14.0042 12.1128 13.9471 12.8228L17.9343 13.1432ZM19.3936 13.6136C19.6876 11.2542 18.9285 8.77982 17.1121 6.96345L14.2837 9.79187C15.1907 10.6989 15.5723 11.9313 15.4243 13.1191L19.3936 13.6136ZM17.1121 6.96345C15.2957 5.14707 12.8213 4.38795 10.4619 4.68194L10.9565 8.65124C12.1443 8.50324 13.3766 8.88483 14.2837 9.79187L17.1121 6.96345ZM10.4619 4.68194C9.30206 4.82645 8.4799 5.65091 8.21936 6.63005C7.97115 7.56287 8.23392 8.58365 8.951 9.30073L11.7794 6.47231C12.0692 6.76209 12.2042 7.21026 12.0849 7.65862C11.9532 8.1533 11.5167 8.58144 10.9565 8.65124L10.4619 4.68194ZM14.7748 15.1246C15.4919 15.8416 16.5127 16.1044 17.4455 15.8562C18.4247 15.5957 19.2491 14.7735 19.3936 13.6136L15.4243 13.1191C15.4941 12.5589 15.9223 12.1223 16.4169 11.9907C16.8653 11.8714 17.3135 12.0064 17.6033 12.2961L14.7748 15.1246ZM13.9471 12.8228C13.8871 13.5697 14.1015 14.4512 14.7748 15.1246L17.6033 12.2961C17.8971 12.59 17.9513 12.9313 17.9343 13.1432L13.9471 12.8228ZM10.9324 6.14128C11.1443 6.12425 11.4856 6.17846 11.7794 6.47231L8.951 9.30073C9.62436 9.97409 10.5059 10.1885 11.2528 10.1284L10.9324 6.14128Z"
            fill={color}
            fillOpacity={renderOpacity(1)}
            mask="url(#path-4-inside-1_5343_111203)"
          />
          <mask id="path-4-inside-1_5343_111203" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.3652 7.88652C10.555 8.07628 10.8251 8.15635 11.0926 8.13485C12.3619 8.03283 13.6662 8.46732 14.6372 9.43832C15.6082 10.4093 16.0427 11.7137 15.9407 12.983C15.9192 13.2505 15.9993 13.5206 16.189 13.7104C16.6163 14.1376 17.3343 13.966 17.409 13.3664C17.63 11.5928 17.0596 9.73937 15.6979 8.37766C14.3362 7.01595 12.4828 6.44559 10.7092 6.66659C10.1096 6.7413 9.93792 7.45923 10.3652 7.88652Z"
            />
          </mask>
        </g>
        <defs>
          <clipPath id="clip0_5343_111203">
            <rect width="24" height="24" fill="white" />
          </clipPath>
          <clipPath id="clip1_5343_111203">
            <rect width="16" height="16" fill="white" transform="translate(0 8)" />
          </clipPath>
        </defs>
      </svg>
    </TypographyTooltip>
  );
}

export default StationGPSIconMedium;
