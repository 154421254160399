import { createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  isDirty: boolean;
}

const initialState: IInitialState = {
  isDirty: false,
};

const promptSlice = createSlice({
  name: 'modalState',
  initialState,
  reducers: {
    changeIsDirty: (state, action) => {
      state.isDirty = action.payload;
    },
  },
});

export const { changeIsDirty } = promptSlice.actions;
export default promptSlice.reducer;
