import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { PERMISSION_LEVEL } from 'src/constants/permissions';
import { IUser } from 'src/types/auth';
import { IPagination } from 'src/types/common';
import { IPermission, IPermissionScheme } from 'src/types/permission';
import { formatObjectRole, getPermissionsName } from 'src/utils/role';
import { toastMessage } from 'src/utils/toast';
import { getPermissions } from '../permission/permission_action';
import { IRole, IRoleData, IRoleFilter } from './../../types/role';
import { isEmpty } from 'lodash';

export const createRole = createAsyncThunk<
  IRole,
  Pick<IRole, 'name' | 'permissions' | 'schemes' | 'description'>
>('roles/createRole', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.createRole(payload);
    return data;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const searchRoles = createAsyncThunk<
  IPagination<IRoleData>,
  { filter: IRoleFilter; tab?: string }
>('roles/searchRoles', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const data = await client.searchRoles(payload.filter);

    if (payload.tab && payload.tab === 'role') {
      if (data.data.length) {
        for (const role of data.data) {
          const result = (await dispatch(getUsersInRole(role.id as string))).payload as IUser[];
          role.users = result;
        }
        const permissions = await dispatch(getPermissions(PERMISSION_LEVEL.SYSTEM));

        const permissionDetail = permissions.payload as IPermission;

        if (!isEmpty(permissions.payload)) {
          const payload: IPermissionScheme[] = permissionDetail.scheme;

          if (!isEmpty(payload)) {
            for (const role of data.data) {
              const { permissionsName } = getPermissionsName(payload, role.permissions as any, '');
              role.permissionsName = permissionsName;
            }
          }
        }
      }
    }

    return data;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const getAllRoles = createAsyncThunk<IRole[], void>(
  'roles/getAllRoles',
  async (_, { rejectWithValue }) => {
    try {
      const data = await client.getAllRoles();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getUsersInRole = createAsyncThunk<IUser[], string>(
  'roles/getUsersInRole',
  async (roleId, { rejectWithValue }) => {
    try {
      const data = await client.getUsersInRole(roleId);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getRole = createAsyncThunk<IRole, string>(
  'roles/getRole',
  async (roleId, { rejectWithValue }) => {
    try {
      const data = await client.getRole(roleId);
      const formatData = formatObjectRole(data);
      return formatData;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updateRole = createAsyncThunk<
  IRole,
  { roleId: string; data: Pick<IRole, 'name' | 'permissions' | 'schemes' | 'description'> }
>('roles/updateRole', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.updateRole(payload.roleId, payload.data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteRole = createAsyncThunk<string, string>(
  'roles/deleteRole',
  async (payload, { rejectWithValue }) => {
    try {
      await client.deleteRole(payload);
      return payload;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
