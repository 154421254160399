import React from 'react';
import { RefreshRounded } from '@mui/icons-material';

import i18n from 'src/i18n';

import { renderMediaSource } from '../../utils/video';
import ButtonWrapper from '../button_wrapper';
import { useAppSelector } from 'src/redux_store';

function Refresh() {
  const { t } = i18n;

  const {
    mediaPlayer: { videoLinks = [], date, isPlay },
  } = useAppSelector(({ videoSlice }) => videoSlice);

  const handleClick = () => {
    if (!videoLinks.length) return;

    const todaysDate = new Date();

    if (todaysDate.setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
      renderMediaSource(videoLinks[videoLinks.length - 1], 0, isPlay);
    } else {
      renderMediaSource(videoLinks[0], 0, isPlay);
    }
  };

  const controlText = t('stream.controlBar.refresh');

  return (
    <ButtonWrapper
      title={controlText}
      placement="top-start"
      icon={RefreshRounded}
      handleClick={handleClick}
    />
  );
}

export default Refresh;
