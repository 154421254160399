import { ControlCamera } from '@mui/icons-material';
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import { IPlayerProps } from 'src/components/player_rtc/types/player';
import { toastMessage } from 'src/utils/toast';
import i18n from 'src/i18n';

function PTZToggle(props: any) {
  const { cam, actions, player, manager, controlBar } = props as IPlayerProps;
  const { t } = i18n;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  if (!controlBar?.ptz || !cam.isEnabledPtz) return null;

  const { togglePTZ, ptzCPN } = controlBar.ptz;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!cam.isSupportPtz) return toastMessage.error(t('stream.controlBar.ptz.isNotSupported'));
    setAnchorEl(event.currentTarget);
    actions.setIsPopover(true);

    togglePTZ && togglePTZ();
  };

  const handleClose = () => {
    setAnchorEl(null);
    actions.setIsPopover(false);
  };

  const controlText = t('stream.controlBar.ptz.isSupported');

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top-start"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          <ControlCamera />
        </IconButton>
      </Tooltip>
      <Popover
        container={player.isFullscreen ? manager.rootElement : null}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& > .MuiPaper-root': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {ptzCPN}
        {/* <ControlPTZ
          isPtzSmall={slice.player.isFullscreen ? false : slice.isPtzSmall}
          selectedCamera={cam as ICamera}
          stationId={slice.station?.id}
        /> */}
      </Popover>
    </Box>
  );
}

export default PTZToggle;
