import React from 'react';
import { MapOutlined } from '@mui/icons-material';

import { toggleTheaterMode } from 'src/redux_store/stream/stream_slice';
import { changeHlsVideoFilterParams, changeVideoSpeed } from 'src/redux_store/video/video_slice';
import i18n from 'src/i18n';
import ButtonWrapper from '../button_wrapper';
import { useAppDispatch } from 'src/redux_store';

function TheaterMode() {
  const { t } = i18n;
  const dispatch = useAppDispatch();
  const controlText = t('stream.controlBar.theaterMode.true');

  const handleClick = () => {
    dispatch(toggleTheaterMode());
    dispatch(changeHlsVideoFilterParams({ date: new Date() }));
    dispatch(changeVideoSpeed(1));
  };

  return <ButtonWrapper title={controlText} icon={MapOutlined} handleClick={handleClick} />;
}

export default TheaterMode;
