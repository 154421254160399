import { ICameraFilter } from 'src/types/camera';
import {
  IRtcStream,
  IStreamCreate,
  IStreamFilterRequest,
  IStreamFilterResponse,
} from 'src/types/stream';
import ClientBase from './base';

export interface ClientClassifiedMix {
  getLiveStreamingCams: (
    data: IStreamFilterRequest,
    cameraFilter?: ICameraFilter,
  ) => Promise<IStreamFilterResponse>;
  addSingleCamToWatchlist: (data: IStreamCreate) => Promise<IRtcStream>;
  removeSingleCamFromWatchlist: (position: number, groupId: string) => Promise<any>;
  getFollowGroups: () => Promise<{ id: string; name: string }[]>;
  addFollowGroup1: (group: string) => Promise<{ data: string }>;
  addFollowGroup: (listName: string) => Promise<{ id: string; name: string }>;
  deleteFollowGroup: (id: string) => Promise<string>;
}

const ClientClassified = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientClassifiedMix {
    getLiveStreamingCams = async (
      data: IStreamFilterRequest,
      cameraFilter?: ICameraFilter | undefined,
    ) => {
      let params = {
        page: data.page,
        limit: data.listSize,
      };

      if (cameraFilter) {
        params = {
          ...params,
          ...cameraFilter,
        };
      }

      return this.doFetch<IStreamFilterResponse>(
        `${this.getClassifiedListRoute()}/${data.groupId}/cameras`,
        {
          method: 'GET',
          params,
        },
      );
    };
    addSingleCamToWatchlist = async ({ data, groupId }: IStreamCreate) => {
      return this.doFetch<IRtcStream>(`${this.getClassifiedListRoute()}/${groupId}/cameras`, {
        method: 'POST',
        data,
      });
    };
    removeSingleCamFromWatchlist = async (position: number, groupId: string) => {
      return this.doFetch(`${this.getClassifiedListRoute()}/${groupId}/position/${position}`, {
        method: 'DELETE',
      });
    };
    getFollowGroups = async () => {
      return this.doFetch<{ id: string; name: string }[]>(`${this.getClassifiedListRoute()}`, {
        method: 'GET',
      });
    };
    addFollowGroup1 = async (group: string) => {
      const groups = JSON.parse(localStorage.getItem('group') as string) || [];
      groups.push(group);

      localStorage.setItem('group', JSON.stringify(groups));

      return { data: group };
    };
    addFollowGroup = async (listName: string) => {
      return this.doFetch<{ id: string; name: string }>(`${this.getClassifiedListRoute()}`, {
        method: 'POST',
        data: { listName },
      });
    };
    deleteFollowGroup = async (id: string) => {
      return this.doFetch<string>(`${this.getClassifiedListRoute()}/${id}`, {
        method: 'DELETE',
      });
    };
  };

export default ClientClassified;
