import React from 'react';
import { VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { toggleVideoMuted } from 'src/redux_store/video/video_slice';
import ButtonWrapper from '../button_wrapper';
import { useTranslation } from 'react-i18next';

export default function Volume() {
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const { isMuted, videoLinks } = mediaPlayer;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (!videoLinks.length) return;
    dispatch(toggleVideoMuted());
  };

  // const controlText = isMuted ? 'Bật âm thanh' : 'Tắt âm thanh';
  const controlText = isMuted
    ? t('stream.controlBar.volume.on')
    : t('stream.controlBar.volume.off');
  return (
    <ButtonWrapper
      title={controlText}
      icon={isMuted ? VolumeOffOutlined : VolumeUpOutlined}
      handleClick={handleClick}
    />
  );
}
