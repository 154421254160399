export const RequestStatusOptions = {
  IDLE: 'idle',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
} as const

export type RequestStatusOption = (typeof RequestStatusOptions)[keyof typeof RequestStatusOptions]

export type RequestStatus = {
  status: RequestStatusOption
  error: null | Record<string, any>
}
