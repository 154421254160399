let isDebug = false;

export function initDebugLog(newDebug = false) {
  isDebug = newDebug;
}

export function debugLog(text: any) {
  if (!isDebug) return;
  console.log(text);
}
