import { ChevronLeft } from '@mui/icons-material';
import { Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useNavigate, useParams } from 'react-router-dom';
import EmptyMessage from 'src/components/empty_message';
import ErrorMessage from 'src/components/error_message';
import Loading from 'src/components/loading';
import IconButtonTooltip from 'src/components/tooltip/icon_button_tooltip';
import { useSignaling } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getStationDetail } from 'src/redux_store/map/map_action';
import { initPTZControl } from 'src/redux_store/ptz/ptz_action';
import { resetStationFunctions } from 'src/redux_store/station/station_slice';
import { resetTheaterMode, togglePTZ } from 'src/redux_store/stream/stream_slice';
import { changeHlsVideoFilterParams, clearVideoState } from 'src/redux_store/video/video_slice';
import { PATH } from 'src/routes/path';
import { ICamera } from 'src/types/camera';
import { TRequestStatusOption } from 'src/types/request_status';
import { isStreamMode } from 'src/utils/stream';
import Cameras from './cameras';
import General from './general';
import Player from './player';
import { useStyles } from './styles';
import Logs from './logs';
import { useTranslation } from 'react-i18next';

const StationDetail = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const wsOpen = useSignaling();

  const { t } = useTranslation();

  const { isPtz, mode } = useAppSelector((state) => state.streamSlice);
  const stationDetail = useAppSelector((state) => state.mapSlice.stationDetail);

  const [tabValue, setTabValue] = React.useState<number>(0);

  const [status, setStatus] = useState<TRequestStatusOption>('not_started');
  const [camSelected, setCamSelected] = useState<ICamera | null>(null);
  const [cam, setCam] = useState<ICamera | null>(null);

  const getStationById = async (stationId: string) => {
    setStatus('pending');
    try {
      const station = await dispatch(getStationDetail(stationId)).unwrap();
      if (station) {
        const cameras = isArray(station?.cameras) ? station?.cameras : [];
        if (cameras.findIndex((item) => item.id === cam?.id) === -1) {
          const camera = cameras.length ? cameras[0] : null;
          setCameraStream(camera, stationId);
        }

        setStatus('fulfilled');
      }
    } catch (error) {
      setCameraStream(null, stationId);
      setStatus('rejected');
    }
  };

  useEffect(() => {
    if (id) {
      // dispatch(toggleTheaterMode());
      getStationById(id);
    }
    return () => {
      dispatch(resetTheaterMode());
      dispatch(clearVideoState());
      dispatch(resetStationFunctions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!camSelected) return;
    dispatch(changeHlsVideoFilterParams({ cameraId: camSelected.id }));
    setCam(camSelected);
  }, [camSelected, dispatch]);

  useEffect(() => {
    const stationCameras = stationDetail?.cameras ? stationDetail.cameras : [];

    if (stationCameras.length) {
      setCameraStream(stationCameras[0], stationDetail?.id || '');

      return;
    }
    setCameraStream(null, stationDetail?.id || '');
  }, [stationDetail?.cameras?.length]);

  const setCameraStream = (cam: ICamera | null, stationId: string) => {
    if (!cam) {
      // dispatch(resetTheaterMode()); // dẫn đến lỗi khi click vào xem lại từ small player
      setCamSelected(null);
      setCam(null);
      return;
    }

    if (!camSelected?.id || camSelected.id !== cam.id) {
      setCam(null);
      setCamSelected(cam);
    }

    if (cam.isSupportPtz && cam.isEnabledPtz && isStreamMode(mode)) {
      dispatch(
        initPTZControl({
          cameraId: cam.id,
          stationId,
        }),
      );
    }

    if (isPtz) {
      dispatch(togglePTZ());
    }
  };

  const renderHeaderTitle = () => {
    if (status === 'pending') {
      return t('label.loading');
    }
    if (status === 'rejected') {
      return t('message.error.station.getData');
    }
    if (!stationDetail) {
      return t('label.emptyData.station');
    }

    return stationDetail.name;
  };

  const renderTabContent = () => {
    if (status === 'pending') {
      return <Loading sx={{ width: '100%', textAlign: 'center' }} />;
    }
    if (status === 'rejected') {
      return (
        <ErrorMessage
          sx={{ width: '100%', textAlign: 'center' }}
          title={t('message.error.station.getData')}
          onClick={() => id && getStationById(id)}
        />
      );
    }
    if (!stationDetail) {
      return (
        <EmptyMessage
          sx={{ width: '100%', textAlign: 'center' }}
          title={t('label.emptyData.station')}
        />
      );
    }

    if (tabValue === 0) {
      return <General station={stationDetail} />;
    }

    if (tabValue === 1) {
      return <Cameras station={stationDetail} camera={cam} setCamera={setCameraStream} />;
    }

    if (tabValue === 2) {
      return <Logs stationId={stationDetail.id} />;
    }
  };

  const goBack = () => {
    if (!id) return;

    navigate(`${PATH.stationMap}?stationId=${id}`);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    if (id) {
      getStationById(id);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.panel}>
        <Box className={classes.header}>
          <IconButtonTooltip title={t('button.back')} onClick={goBack} icon={<ChevronLeft />} />
          <Typography variant="subtitle1" className={classes.headerTitle} noWrap>
            {renderHeaderTitle()}
          </Typography>
        </Box>
        <Divider />

        <Stack flex={1}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab sx={{ fontWeight: 700 }} value={0} label={t('label.station.info')} />
              <Tab sx={{ fontWeight: 700 }} value={1} label={t('label.camera.main')} />
              <Tab
                sx={{ fontWeight: 700 }}
                value={2}
                label={t('label.camera.configuration.tabs.logs')}
              />
            </Tabs>
          </Box>
          {renderTabContent()}
        </Stack>
      </Box>
      <Divider orientation="vertical" />
      <Scrollbars>
        <Box className={classes.body}>
          <Player station={stationDetail} cam={cam} wsOpen={wsOpen} />
        </Box>
      </Scrollbars>
    </Box>
  );
};

export default StationDetail;
