import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButtonTooltip from 'src/components/tooltip/icon_button_tooltip';
import { useAppDispatch, useAppSelector } from 'src/redux_store';

import {
  deleteNotification,
  getAllNotification,
} from 'src/redux_store/notification/notification_action';
import {
  // changeTotalPageAndTotalData,
  deleteNotificationLocal,
  incrementalNotificationList,
} from 'src/redux_store/notification/notification_slice';
import theme from 'src/theme';
import { toastMessage } from 'src/utils/toast';

type Props = {
  id: string;
  setExpandedId?: React.Dispatch<React.SetStateAction<string>>;
};

const DeleteNotification = (props: Props) => {
  const { id, setExpandedId } = props;
  const { payload, notificationList } = useAppSelector((state) => state.notificationSlice);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDeleteNotification = (e: any) => {
    if (setExpandedId) {
      setExpandedId('');
    }
    e.stopPropagation();
    dispatch(deleteNotificationLocal(id));

    dispatch(deleteNotification(id))
      .unwrap()
      .then(() => {
        toastMessage.success('Xóa thông báo thành công');
        dispatch(
          getAllNotification({
            ...payload,
            limit: 1,
            after: notificationList[notificationList.length - 1]?.id,
          }),
        )
          .unwrap()
          .then((data) => {
            dispatch(incrementalNotificationList({ data }));
          });
      });
  };
  return (
    <Grid item>
      <IconButtonTooltip
        title={t('notification.button.delete')}
        icon={<DeleteOutlineOutlined fontSize="medium" />}
        size="medium"
        style={{ color: theme.palette.secondary.main }}
        onClick={(e) => handleDeleteNotification(e)}
      />
    </Grid>
  );
};

export default DeleteNotification;
