import React from 'react';
import { Box, Grid } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { useIsRequestError, useIsRequestPending } from 'src/hooks';
import { IPayloadNotification } from 'src/types/notification';
import ErrorMessage from 'src/components/error_message';
import { changePayload } from 'src/redux_store/notification/notification_slice';
import { initPayloadNotification } from 'src/constants/notification';
import TableNotification from './table';
import Loading from 'src/components/loading';

type TProps = {
  asyncGetALlNotification: (newPayload: IPayloadNotification, isReset?: boolean) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationTable = (props: TProps) => {
  const { asyncGetALlNotification, isLoading } = props;
  const dispatch = useAppDispatch();
  const { notificationList, payload, isLoadMoreNotification } = useAppSelector(
    (state) => state.notificationSlice,
  );

  const isLoadingGetNotifications = useIsRequestPending('notification', 'getAll');

  const isErrorGetNotifications = useIsRequestError('notification', 'getAll');

  // const handleSelectNotificationItem = (item: INotification) => {
  //   console.log(item);

  //   if (!item.isRead) {
  //     markItemNotification(dispatch, 'read', item.id);
  //   }

  //   if (!idNotificationSelected || item.id !== idNotificationSelected) {
  //     dispatch(selectNotificationItem(item.id));
  //     dispatch(getInfoNotification(item.id));
  //   } else {
  //     dispatch(hiddenInfoSelect());
  //   }
  // };

  const handleScrollStop = ({
    clientHeight,
    scrollHeight,
    scrollTop,
  }: {
    clientHeight: number;
    scrollHeight: number;
    scrollTop: number;
  }) => {
    if (scrollHeight - clientHeight === scrollTop && !isLoadingGetNotifications) {
      if (isLoadMoreNotification) {
        const lastNotification = notificationList[notificationList.length - 1];
        const afterId = lastNotification?.id || '';
        asyncGetALlNotification({ ...payload, after: afterId });
        dispatch(changePayload({ ...payload, after: afterId }));
      }
    }
  };

  const handleRetryRequest = () => {
    asyncGetALlNotification(initPayloadNotification, true);
  };

  if (isErrorGetNotifications)
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', background: '#fff' }}>
        <ErrorMessage onClick={handleRetryRequest} />
      </Box>
    );
  return (
    <>
      <Grid container flexDirection="column" flex={1}>
        <TableNotification
          isLoading={isLoading}
          handelScrollTop={handleScrollStop}
          bodyData={notificationList}
        />
      </Grid>
      {isLoading && notificationList.length && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '0%',
            zIndex: 100,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Loading />
        </Box>
      )}
    </>
  );
};

export default NotificationTable;
