export interface PaymentMethod {
  id: string
  code: string
  name: string
  description: string
  avatarUrl: string
  countryCode: string
  currency: string
}

export interface PaymentGateway {
  id: string
  code: string
  name: string
  description: string
  avatarUrl: string
  countryCode: string
  currency: string
}

export enum PlanType {
  Organization = 1,
  Station,
}

export interface SubscriptionPlan {
  id: string
  planId: string
  plan: Plan
  interval: number
  intervalCount: number
  tier: number | null
  countryCode: string
  price: number
  currency: string
}

export interface Plan {
  id: string
  name: string
  description: string
  code: string
  type: PlanType
  tier: number
  constraints: { [key: string]: any }
}
export const SubscriptionItemStatuses = {
  ACTIVE: 'active',
  UNPAID: 'unpaid',
  WAITING: 'waiting',
  CANCELED: 'canceled',
  IN_SERIVCE: 'in_service',
  EXPIRED: 'expired',
} as const

export type SubscriptionItemStatus = (typeof SubscriptionItemStatuses)[keyof typeof SubscriptionItemStatuses]
export interface SubscriptionItem {
  id: string
  subscriptionId: string
  quantity: number
  subscriptionPlanId: string
  subscriptionPlanPrice: number
  subscriptionPeriodStart: string | null
  subscriptionPeriodEnd: string | null
  status: SubscriptionItemStatus
  dependentSubscriptionItemId: string | null
  createdAt: string
  updatedAt: string
}

export interface Subscription {
  id: string
  type: PlanType
  organizationId: string
  createdAt: Date
  canceledAt: Date | null
  currentPeriodStart: Date | null
  currentPeriodEnd: Date | null
  items: SubscriptionItem[]
  metadata: { [key: string]: any }
  // payments: Payment[]
}

export interface SubscriptionStation {
  id: string
  subscriptionId: string
  organizationId: string
  stationId: string
  organizationStationId: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export const PaymentStatuses = {
  PENDING: 'pending',
  FAILED: 'failed',
  SUCCESS: 'success',
} as const

export type PaymentStatus = (typeof PaymentStatuses)[keyof typeof PaymentStatuses]

export interface Payment {
  id: string
  createdAt: string
  currency: string
  amount: number
  status: string
  description: string
  subscriptionId: string
  subscriptionPlanId: string
  subscriptionPlanPrice: number
}
