import { createSlice } from '@reduxjs/toolkit';
import { IOrderMember } from 'src/types/customer';

import {
  addOrderMembers,
  deleteOrderMembers,
  getOrderMembers,
  getOrderMembersNotBelongOrder,
  inviteOrderMember,
} from './actions';

interface IOrderMembersState {
  memberNotBelongOrder: IOrderMember[];
  members: IOrderMember[];
}

const initialState: IOrderMembersState = {
  memberNotBelongOrder: [],
  members: [],
};

const orderMembers = createSlice({
  name: 'orderMembers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addOrderMembers.fulfilled, (state, action) => {
        state.members.push(action.payload);
      })
      .addCase(inviteOrderMember.fulfilled, (state, action) => {
        state.members.push(action.payload);
      })
      .addCase(getOrderMembers.fulfilled, (state, action) => {
        state.members = action.payload;
      })
      .addCase(getOrderMembersNotBelongOrder.fulfilled, (state, action) => {
        state.memberNotBelongOrder = action.payload;
      })
      .addCase(deleteOrderMembers.fulfilled, (state, action) => {
        state.members = state.members.filter((member) => member.orderMemberId !== action.payload);
      });
  },
});

const { reducer } = orderMembers;
export default reducer;
