import { combineReducers } from '@reduxjs/toolkit';

import backup_slice from './backup/backup_slice';
import database_slice from './database/database_slice';
import general_info_slice from './general_info/general_info_slice';
import gpus_slice from './gpus/gpus_slice';
import node_slice from './node/node_slice';
import storage_slice from './storage/storage_slice';
import { actions as nodeActions } from './node/node_slice';

const systemActions = {
  ...nodeActions,
};

const systemReducer = combineReducers({
  generalInfo: general_info_slice,
  gpus: gpus_slice,
  nodes: node_slice,
  storageState: storage_slice,
  databases: database_slice,
  backupState: backup_slice,
});

export const { closeDrawer } = systemActions;
export default systemReducer;
