import {
  IStationBatteryUpdated,
  IStationNetworkUpdated,
  IStationUpdateStatus,
} from 'src/types/notification';
import store from 'src/redux_store';
import {
  addStationsLocalInMap,
  deleteCameraInMap,
  deleteStationLocalInMap,
  stationBatteryDisconnectedInMap,
  stationBatteryUpdatedInMap,
  stationGPSConnectedInMap,
  stationGpsDisconnectedInMap,
  stationMobileNetworkDisconnectedInMap,
  stationMobileNetworkUpdatedInMap,
  updateCameraStatusInMap,
  updateLightStatus,
  updateStationStatusInMap,
  updateStationWorkingModeSocketInMap,
} from 'src/redux_store/map/map_slice';
import {
  deleteStationLocal,
  stationBatteryDisconnected,
  stationBatteryUpdated,
  stationGPSConnected,
  stationGpsDisconnected,
  stationMobileNetworkDisconnected,
  stationMobileNetworkUpdated,
  updateStationStatus,
} from 'src/redux_store/station/station_slice';
import { EGpsStatus, ENotificationEvent, ERoleLevel } from 'src/types/enum';
import {
  ELightStatus,
  EMobileNetworkStatus,
  EPowerBatteryStatus,
  EStationStatus,
  IStation,
  IUpdateStationGPS,
} from 'src/types/station';
import {
  changeCameraStatus,
  deleteCameraLocal,
  removeCamerasByListCameraId,
} from 'src/redux_store/camera/camera_slice';
import { removeWatchListPositionByListCameraId } from 'src/redux_store/watchlist/watchlist_slice';
import config from 'src/config';
import { ECameraStatus } from 'src/types/camera';
import {
  changeIsAutoLogoutEventSocket,
  changeIsReloadPageEventSocket,
} from 'src/redux_store/my_account/my_account_slice';
import { TAutoLogout } from 'src/types/user';
import { getStationGeneral } from 'src/redux_store/station/station_action';
import {
  addOrderStationSocket,
  deletedOrderStationSocket,
  updateStationStatusOfOrderStation,
} from 'src/redux_store/customer/order_stations/slice';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';

const MaxWebsocketFails = 7;
const MinWebsocketRetryTime = 3000; // 3 sec
const MaxWebsocketRetryTime = 300000; // 5 minutes

export default class WebSocketClient {
  conn: WebSocket | null;
  //   private connectionUrl: string | null;
  private connectFailCount: number;

  private timerKeepConnectServer: any;

  constructor() {
    this.conn = null;
    // this.connectionUrl = null;
    this.connectFailCount = 0;
    this.timerKeepConnectServer = null;
  }

  initialize(token?: string) {
    if (this.conn) {
      return;
    }

    this.conn = new WebSocket(config.notificationUrl);
    // this.conn = new WebSocket('ws://10.49.46.54:8080/notifications?type=admin');

    this.conn.onopen = () => {
      console.log('[WS] websocket open');
      if (token) {
        this.sendMessage({
          data: { token },
          action: 'auth',
        });
      }
    };

    this.conn.onclose = () => {
      this.conn = null;
      if (this.timerKeepConnectServer) {
        clearInterval(this.timerKeepConnectServer);
      }
      if (this.connectFailCount === 0) {
        console.log('[WS] websocket closed');
      }

      this.connectFailCount++;

      let retryTime = MinWebsocketRetryTime;
      if (this.connectFailCount > MaxWebsocketFails) {
        retryTime = MinWebsocketRetryTime * this.connectFailCount * this.connectFailCount;
        if (retryTime > MaxWebsocketRetryTime) {
          retryTime = MaxWebsocketRetryTime;
        }
      }

      retryTime += Math.random() * 2000;

      setTimeout(() => {
        this.initialize(token);
      }, retryTime);
    };

    this.ping();

    this.conn.onerror = () => {
      if (this.connectFailCount <= 1) {
        console.log('[WS websocket error');
      }
    };

    this.conn.onmessage = (event) => {
      const dataJSON: { event: ENotificationEvent; data: any } = JSON.parse(event.data);
      const { event: typeEvent, data } = dataJSON;

      const dispatch = store.dispatch;

      switch (typeEvent) {
        case ENotificationEvent.STATION_STATUS_UPDATED:
          this.updateStationStatus(
            {
              stationId: data.stationId,
              status: data.status || EStationStatus.OFFLINE,
              timestamp: data.timestamp,
            },
            dispatch,
          );
          break;

        case ENotificationEvent.STATION_GPS_DISCONNECTED:
          this.stationGPSDisconnect(data, dispatch);
          break;

        case ENotificationEvent.STATION_GPS_UPDATED:
          this.stationGPSUpdated(data, dispatch);
          break;

        case ENotificationEvent.STATION_BATTERY_DISCONNECTED:
          this.stationBatteryDisconnected(data, dispatch);
          break;

        case ENotificationEvent.STATION_BATTERY_UPDATED:
          this.batteryUpdated(data, dispatch);
          break;

        case ENotificationEvent.STATION_MOBILE_NETWORK_DISCONNECTED:
          this.stationMobileNetworkDisconnected(data, dispatch);

          break;

        case ENotificationEvent.STATION_MOBILE_NETWORK_UPDATED:
          this.mobileNetworkUpdate(data, dispatch);
          break;

        case ENotificationEvent.STATION_LIGHT_TURN_ON:
          dispatch(
            updateLightStatus({
              stationId: data.stationId,
              status: ELightStatus.ON,
            }),
          );
          break;

        case ENotificationEvent.STATION_LIGHT_TURN_OFF:
          dispatch(
            updateLightStatus({
              stationId: data.stationId,
              status: ELightStatus.OFF,
            }),
          );
          break;

        case ENotificationEvent.STATION_DELETED:
          this.deleteStation(
            { stationId: data.stationId, cameraIds: data.cameraIds || [] },
            dispatch,
          );
          break;

        case ENotificationEvent.STATION_WORKING_MODE_UPDATED:
          dispatch(
            updateStationWorkingModeSocketInMap({
              stationId: data.stationId,
              timestamp: data.timestamp,
              newWorkingMode: {
                workingMode: data.workingMode,
                workingSchedule: data.workingSchedule,
              },
            }),
          );
          break;

        //camera
        case ENotificationEvent.CAMERA_CONNECTED:
          this.cameraUpdateStatus(data, ECameraStatus.NORMAL, dispatch);
          break;
        case ENotificationEvent.CAMERA_DISCONNECTED:
          this.cameraUpdateStatus(data, ECameraStatus.ERROR, dispatch);
          break;
        case ENotificationEvent.CAMERA_ENABLED:
          this.cameraUpdateStatus(data, ECameraStatus.NORMAL, dispatch);
          break;
        case ENotificationEvent.CAMERA_DISABLED:
          this.cameraUpdateStatus(data, ECameraStatus.OFFLINE, dispatch);
          break;
        case ENotificationEvent.CAMERA_DELETED:
          this.cameraDeleted(data.cameraId, dispatch);
          break;
        case ENotificationEvent.ADMIN_MEMBER_RESOURCE_UPDATED:
          this.reloadPage(dispatch);
          break;

        case ENotificationEvent.ADMIN_MEMBER_ROLE_UPDATED:
          this.autoLogoutUser('update_role', dispatch);
          break;
        case ENotificationEvent.USER_REVOKED_SESSION:
          this.autoLogoutUser('user_revoked_session', dispatch);
          break;

        case ENotificationEvent.ORDER_STATION_ADDED:
          this.orderStationAdded(data, dispatch);
          break;
        case ENotificationEvent.ORDER_STATION_DELETED:
          this.orderStationDeleted(data, dispatch);
          break;

        default:
          break;
      }
    };
  }

  close = () => {
    this.connectFailCount = 0;
    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.onclose = () => {};
      this.conn.close();
      this.conn = null;
      console.log('[WS] websocket closed');
    }
  };

  sendMessage = (data: any) => {
    if (this.conn && this.conn.readyState === 1) {
      console.log('[WS] Send', JSON.stringify(data));
      this.conn.send(JSON.stringify(data));
    } else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
      this.conn = null;
      this.initialize();
    }
  };

  ping() {
    if (this.timerKeepConnectServer) {
      clearInterval(this.timerKeepConnectServer);
    }

    this.timerKeepConnectServer = setInterval(() => {
      this.sendMessage({ action: 'ping' });
    }, 1000 * 45);
  }

  private deleteStation(
    { stationId, cameraIds }: { stationId: string; cameraIds: string[] },
    dispatch: any,
  ) {
    dispatch(deleteStationLocalInMap(stationId));
    dispatch(deleteStationLocal(stationId));
    dispatch(closeModal({ modalId: `modalDownloadQrCode_${stationId}` }));
    dispatch(
      closeModal({
        modalId: `station_delete_${stationId}`,
      }),
    );
    if (cameraIds.length) {
      dispatch(removeCamerasByListCameraId(cameraIds));
      dispatch(removeWatchListPositionByListCameraId(cameraIds));
    }
  }

  private batteryUpdated(data: any, dispatch: any) {
    const payload: IStationBatteryUpdated = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      newBattery: {
        powerBattery: data.powerBattery,
        powerBatteryCapacityStatus: data.powerBatteryCapacityStatus,
        powerBatteryChargeStatus: data.powerBatteryChargeStatus,
        powerBatteryLastUpdatedAt: data.powerBatteryLastUpdatedAt,
        powerBatteryConnectionStatus: EPowerBatteryStatus.CONNECT,
      },
    };

    dispatch(stationBatteryUpdated(payload));
    dispatch(stationBatteryUpdatedInMap(payload));
  }

  private mobileNetworkUpdate(data: any, dispatch: any) {
    const payload: IStationNetworkUpdated = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      newNetwork: {
        mobileNetwork: data.mobileNetwork,
        mobileNetworkConnectionStatus:
          data.mobileNetworkConnectionStatus || EMobileNetworkStatus.CONNECT,
        mobileNetworkDataUsed: data.mobileNetworkDataUsed,
        mobileNetworkLastUpdatedAt: data.mobileNetworkLastUpdatedAt,
        mobileNetworkOperator: data.mobileNetworkOperator,
        mobileNetworkWaveStrength: data.mobileNetworkWaveStrength,
      },
    };
    dispatch(stationMobileNetworkUpdated(payload));
    dispatch(stationMobileNetworkUpdatedInMap(payload));
  }

  updateStationStatus(data: IStationUpdateStatus, dispatch: any) {
    dispatch(updateStationStatus(data));
    dispatch(updateStationStatusInMap(data));
    dispatch(updateStationStatusOfOrderStation({ stationId: data.stationId, status: data.status }));
  }

  private stationGPSDisconnect(data: any, dispatch: any) {
    const payload = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      gpsStatus: EGpsStatus.DISCONNECT,
    };

    dispatch(stationGpsDisconnected(payload));
    dispatch(stationGpsDisconnectedInMap(payload));
  }

  private stationMobileNetworkDisconnected(data: any, dispatch: any) {
    const payload = { stationId: data.stationId, timestamp: data.timestamp };

    dispatch(stationMobileNetworkDisconnected(payload));
    dispatch(stationMobileNetworkDisconnectedInMap(payload));
  }

  private stationBatteryDisconnected(data: any, dispatch: any) {
    const payload = { stationId: data.stationId, timestamp: data.timestamp };
    dispatch(stationBatteryDisconnected(payload));
    dispatch(stationBatteryDisconnectedInMap(payload));
  }

  private stationGPSUpdated(data: any, dispatch: any) {
    const payload: IUpdateStationGPS = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      newGPS: {
        lat: data.lat,
        lng: data.lng,
        province: data.province,
        district: data.district,
        commune: data.commune,
        address: data.address,
        gpsConnectionStatus: EGpsStatus.CONNECT,
        gpsWaveStrength: data.gpsWaveStrength,
        gpsLastUpdatedAt: data.gpsLastUpdatedAt,
      },
    };

    dispatch(stationGPSConnected(payload));
    dispatch(stationGPSConnectedInMap(payload));
  }

  private cameraUpdateStatus(data: any, status: ECameraStatus, dispatch: any) {
    const cameraId = data.cameraId;
    const timestamp = data.timestamp;
    dispatch(updateCameraStatusInMap({ cameraId, timestamp, status }));
    dispatch(changeCameraStatus({ cameraId, status }));
  }

  private cameraDeleted(cameraId: string, dispatch: any) {
    dispatch(deleteCameraInMap(cameraId));
    dispatch(deleteCameraLocal(cameraId));
    dispatch(removeWatchListPositionByListCameraId([cameraId]));
  }

  private reloadPage(dispatch: any) {
    dispatch(changeIsReloadPageEventSocket(true));
    // dispatch(changeIsReloadPageEventSocket(false));
  }

  private autoLogoutUser(type: TAutoLogout, dispatch: any) {
    dispatch(changeIsAutoLogoutEventSocket({ isAutoLogout: true, type }));
  }

  private orderStationAdded(data: any, dispatch: any) {
    const stationIds: string[] = data.stationIds;
    const customerId = data.customerId;
    const orderId = data.orderId;

    const urlParams = window.location.pathname.split('/').splice(1);

    const customerIdRouter = urlParams[1];
    const orderIdRouter = urlParams[3];

    if (
      (customerId === customerIdRouter && orderId === orderIdRouter) ||
      store.getState().myAccountSlice.role.level === ERoleLevel.SYSTEM_USER
    ) {
      for (let i = 0; i < stationIds.length; i++) {
        const stationId = stationIds[i];
        dispatch(getStationGeneral(stationId))
          .unwrap()
          .then((station: IStation) => {
            if (customerId === customerIdRouter && orderId === orderIdRouter) {
              dispatch(
                addOrderStationSocket({ ...station, totalCamera: station.cameras?.length || 0 }),
              );
            }

            if (store.getState().myAccountSlice.role.level === ERoleLevel.SYSTEM_USER) {
              dispatch(addStationsLocalInMap(station));
            }
          });
      }
    }
  }

  private async orderStationDeleted(data: any, dispatch: any) {
    const { stationId, cameraIds, customerId, orderId } = data;
    const urlParams = window.location.pathname.split('/').splice(1);

    const customerIdRouter = urlParams[1];
    const orderIdRouter = urlParams[3];

    if (customerId === customerIdRouter && orderId === orderIdRouter) {
      dispatch(deletedOrderStationSocket(stationId));
    }

    if (store.getState().myAccountSlice.role.level === ERoleLevel.SYSTEM_USER) {
      this.deleteStation({ stationId, cameraIds }, dispatch);
    }
  }
}

export const ws = new WebSocketClient();
