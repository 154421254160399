import Joi from 'joi';

interface IConfig {
  isDevEnv: boolean;
  baseUrl: string;
  nodeEnv: string;
  prefix: string;
  iceServers: {
    turnAddress: string;
    turnUsername: string;
    turnPassword: string;
  };
  signalingUrl: string;
  notificationUrl: string;
  projectName: 'CAMERA_HOI_AN' | 'VMS_CLOUD';
  playerType: 'WebRTC' | 'HLS';
  provinceCode?: string;
  districtCode?: string;
  hideCustomer: boolean;
  accountWebApp: string;
  googleMapApiKey: string;
  domain: string;
}

const envVarsSchema = Joi.object({
  // Node environment
  REACT_APP_NODE_ENV: Joi.string().required(),
  REACT_APP_API_PREFIX: Joi.string().required(),
  // Production server
  REACT_APP_API_URL: Joi.string().required(),
  // Dev server
  REACT_APP_DEV_API_URL: Joi.string().required(),

  //iceServers
  REACT_APP_TURN_ADDRESS: Joi.string().required(),
  REACT_APP_TURN_USERNAME: Joi.string().required(),
  REACT_APP_TURN_PASSWORD: Joi.string().required(),
  REACT_APP_SIGNALING_SERVER_ADDRESS: Joi.string().required(),
  REACT_APP_GOOGLE_MAP_API_KEY: Joi.string().required(),
  REACT_APP_ACCOUNT_WEB_APP: Joi.string().required(),

  //HOI_AN
  REACT_APP_PROJECT_NAME: Joi.string().required(),

  //notification server

  REACT_APP_NOTIFICATION_SERVER_ADDRESS: Joi.string().required(),
})
  .unknown()
  .required();

const { value } = envVarsSchema.validate(process.env);

const isDevEnv = value.REACT_APP_NODE_ENV === 'development' ? true : false;
const provinceCode = value.REACT_APP_PROJECT_NAME === 'CAMERA_HOI_AN' ? '49' : undefined;
const districtCode = value.REACT_APP_PROJECT_NAME === 'CAMERA_HOI_AN' ? '503' : undefined;
const hideCustomer = value.REACT_APP_PROJECT_NAME === 'CAMERA_HOI_AN' ? true : false;
const config: IConfig = {
  isDevEnv,
  nodeEnv: value.REACT_APP_NODE_ENV,
  prefix: value.REACT_APP_API_PREFIX,
  baseUrl: isDevEnv ? value.REACT_APP_DEV_API_URL : value.REACT_APP_API_URL,
  iceServers: {
    turnAddress: value.REACT_APP_TURN_ADDRESS,
    turnUsername: value.REACT_APP_TURN_USERNAME,
    turnPassword: value.REACT_APP_TURN_PASSWORD,
  },
  signalingUrl: value.REACT_APP_SIGNALING_SERVER_ADDRESS,
  notificationUrl: value.REACT_APP_NOTIFICATION_SERVER_ADDRESS,
  projectName: value.REACT_APP_PROJECT_NAME || 'VMS_CLOUD',
  provinceCode,
  districtCode,
  hideCustomer,
  playerType: value.REACT_APP_PLAYER_TYPE || 'WebRTC',
  accountWebApp: value.REACT_APP_ACCOUNT_WEB_APP,
  googleMapApiKey: value.REACT_APP_GOOGLE_MAP_API_KEY,
  domain: value.REACT_APP_DOMAIN,
};

export default config;
