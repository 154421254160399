import { createAsyncThunk } from '@reduxjs/toolkit';

import { MODAL_IDS } from 'src/constants/modal';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import client from 'src/clients/http';

import { IStation } from 'src/types/station';
import { toastMessage } from 'src/utils/toast';
import i18n from 'src/i18n';

export const getOrderStations = createAsyncThunk<
  IStation[],
  { customerId: string; orderId: string }
>('orderStations/getOrderStations', async (payload, { rejectWithValue }) => {
  try {
    const stations = await client.getOrderStations(payload.customerId, payload.orderId);
    return stations;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getStationWithoutCustomer = createAsyncThunk<IStation[], string>(
  'orderStations/getStationWithoutCustomer',
  async (searchKeyword, { rejectWithValue }) => {
    try {
      const stations = await client.getStationWithoutCustomer(searchKeyword);
      return stations;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const addOrderStations = createAsyncThunk<
  IStation[],
  { customerId: string; orderId: string; stations: string[] }
>('orderStations/addOrderStations', async (payload, { rejectWithValue }) => {
  try {
    const customers = await client.addOrderStations(
      payload.customerId,
      payload.orderId,
      payload.stations,
    );
    return customers;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteOrderStation = createAsyncThunk<
  string,
  { customerId: string; orderId: string; stationId: string }
>('orderStations/deleteOrderStation', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await client.deleteOrderStation(payload.customerId, payload.orderId, payload.stationId);
    toastMessage.success(i18n.t('message.deleteStationFromOrder.success'));
    dispatch(closeModal({ modalId: MODAL_IDS.confirmDelete }));
    return payload.stationId;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});
