import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { IUser } from 'src/types/auth';
import { IRole } from 'src/types/role';
import { toastMessage } from 'src/utils/toast';

export const getMe = createAsyncThunk<IUser, void>(
  'myAccount/getMe',
  async (_, { rejectWithValue }) => {
    try {
      const data = await client.getMe();
      return {
        ...data,
        avatarUrl: !data.avatarUrl
          ? 'https://images.pexels.com/photos/4059469/pexels-photo-4059469.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
          : data.avatarUrl,
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updatePassword = createAsyncThunk<
  any,
  { userId: string; password: string; newPassword: string }
>('myAccount/updatePassword', async (payload, { rejectWithValue }) => {
  try {
    await client.updatePassword(payload.userId, payload.password, payload.newPassword);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const logout = createAsyncThunk('myAccount/logout', async (_, { rejectWithValue }) => {
  try {
    await client.logout();
    return;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const getMyRole = createAsyncThunk<IRole, string>(
  'myAccount/getMyRole',
  async (roleId, { rejectWithValue }) => {
    try {
      const data = await client.getRole(roleId);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);

export const updateUserLocale = createAsyncThunk<
  void,
  { userId: string; data: { locale: string } }
>('myaAccount/updateUserLocale', async (payload, { rejectWithValue }) => {
  try {
    await client.patchUser(payload.userId, payload.data);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
