import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  controlBar: {
    alignItems: 'center',
    bottom: 0,
    // left: '50%',
    position: 'absolute',
    // bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 999,
    '& svg': {
      color: 'white !important',
    },
    padding: '3px 16px',
  },
  emptyData: {
    '& svg': {
      color: '#5c5c5c',
    },
  },
  controlLeft: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0px',
  },
  controlRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > div': {
      // marginLeft: '10px !important',
      position: 'relative',
    },
    '& > div:first-child': {
      marginLeft: '0px !important',
    },
  },
  menu: {
    position: 'absolute',
    bottom: 33,
    right: 0,
  },
  btnSpeed: {
    minWidth: 0,
    padding: '2px 10px',
    color: '#fff',
    fontWeight: 700,
    fontSize: 14,
  },
  btnSpeedEmpty: {
    background: '#5c5c5c',
  },
  speedItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});
