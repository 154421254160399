import { IUser } from 'src/types/auth';
import ClientBase from './base';
import { IUpdateUserInfo } from 'src/types/user';

export interface ClientMyAccountMix {
  getMe: () => Promise<IUser>;
  logout: () => Promise<any>;
  updatePassword: (userId: string, password: string, newPassword: string) => Promise<any>;
  patchUser: (userId: string, data: IUpdateUserInfo) => Promise<IUser>;
}

const ClientMyAccount = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientMyAccountMix {
    getMe = async () => {
      return this.doFetch<IUser>(`${this.getBaseRoute()}/users/me`, {
        method: 'get',
      });
    };

    logout = async () => {
      return this.doFetch(`${this.getBaseRoute()}/users/logout`, {
        method: 'post',
        data: null,
      });
    };

    updatePassword = async (userId: string, password: string, newPassword: string) => {
      return this.doFetch<any>(`${this.getUserRoute()}/${userId}/password`, {
        method: 'PUT',
        data: { password, newPassword },
      });
    };

    patchUser = async (userId: string, data: IUpdateUserInfo) => {
      return this.doFetch<IUser>(`${this.getBaseRoute()}/users/${userId}`, {
        method: 'PATCH',
        data: data,
      });
    };
  };

export default ClientMyAccount;
