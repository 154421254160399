import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';
import client from 'src/clients/http';
import { ISystemDatabaseResponse } from 'src/types/system';
import { toastMessage } from 'src/utils/toast';

export const getDatabases = createAsyncThunk<ISystemDatabaseResponse, any>(
  'system/getDatabases',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getDatabases(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteDatabase = createAsyncThunk<any, string>(
  'system/deleteDatabase',
  async (id, { rejectWithValue }) => {
    try {
      const data = await client.deleteDatabase(id);
      toastMessage.success(data.message);
      return id;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const databaseDoBackup = createAsyncThunk<{ message: string }, void>(
  'system/databaseDoBackup',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.databaseDoBackup();
      toastMessage.success(data.message);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const downloadDatabase = createAsyncThunk<any, string>(
  'system/downloadDatabase',
  async (id, { rejectWithValue }) => {
    try {
      const data: any = await client.downloadDatabase(id);
      const blob = new Blob([data.data], { type: 'application/zip' });
      const filename =
        data?.headers?.['content-disposition'] || `database-${moment().format()}.zip`;

      return saveAs(blob, filename);
    } catch (error: any) {
      const message = JSON.parse(await error.text()).message;
      toastMessage.error(message);

      return rejectWithValue(error);
    }
  },
);
