import { configureStore, Store } from '@reduxjs/toolkit';

import { IActions } from './types/player';
import { actions as playerActionsInSlice } from './redux_store/player/player_slice';
import * as playerActions from './redux_store/player/player_action';
import reducer from './redux_store/root_reducer';
import Video from './components/video';

export default class Manager {
  store: Store;
  video: Video | null;
  rootElement: HTMLElement | null;

  constructor() {
    this.store = configureStore({ reducer, devTools: false });
    this.video = null;
    this.rootElement = null;
  }

  getState() {
    return this.store.getState();
  }

  getActions() {
    const { dispatch } = this.store;

    const actions = {
      ...playerActionsInSlice,
      ...playerActions,
    };

    const bindActionCreator = (actionCreator: any) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      return function bindAction(...args: any) {
        const action = actionCreator.apply(self, args);
        if (typeof action !== 'undefined') dispatch(action);
      };
    };

    return Object.keys(actions)
      .filter((key) => typeof actions[key as keyof IActions] === 'function')
      .reduce((boundActions, key) => {
        boundActions[key as keyof IActions] = bindActionCreator(actions[key as keyof IActions]);
        return boundActions;
      }, {} as IActions);
  }

  subscribeToStateChange(listener: (state: any, preState: any) => void, getState: () => void) {
    if (!getState) {
      getState = this.getState.bind(this);
    }

    let preState = getState();

    const handleChange = () => {
      const state = getState();

      if (state === preState) return;

      const prevStateCopy = preState;
      preState = state;

      listener(state, prevStateCopy);
    };

    this.store.subscribe(handleChange);
  }

  subscribeToPlayerStateChange(listener: () => void) {
    return this.subscribeToStateChange(listener, () => this.getState().playerSlice);
  }
}
