import i18n from 'src/i18n';
import VIFlag from '../assets/images/vi-flag.png';
import EN_US_Flag from '../assets/images/en-us-flag.png';

export const LOCALE_LANGUAGE_LIST = (t: any) => [
  {
    id: 'vi',
    name: t('label.language.vi'),
    flag: VIFlag,
    timezone: 'Asia/Ho_Chi_Minh',
  },
  {
    id: 'en',
    name: t('label.language.en'),
    flag: EN_US_Flag,
    timezone: 'America/New_York',
  },
];
