/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import ErrorMessage from 'src/components/error_message';
import LogsItem from 'src/components/logs/logs_item';
import SkeletonLoading from 'src/components/notification/skeleton_loading';
import { useAppDispatch } from 'src/redux_store';
import { getCameraLogs } from 'src/redux_store/camera/camera_action';
import { ICamera } from 'src/types/camera';
import { INotification } from 'src/types/notification';
import { formatNotificationList } from 'src/utils/notification';
import { useStyles } from './styles';

interface ILogsListProps {
  camera: ICamera | null;
}

const LogsList = ({ camera }: ILogsListProps) => {
  const classes = useStyles();
  const scrollRef = useRef<Scrollbars | null>(null);
  const [data, setData] = useState<INotification[]>([]);
  const [isPaginate, setIsPaginate] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const fetching = async (id = '') => {
    if (!camera) {
      return;
    }

    setIsError(false);
    setIsLoading(true);
    try {
      const result = await dispatch(
        getCameraLogs({
          cameraId: camera.id,
          params: {
            after: id,
            limit: 20,
          },
        }),
      ).unwrap();
      const notifications = formatNotificationList(result);

      if (id) {
        setData((prev) => prev.concat(notifications));
      } else {
        setData(notifications);
      }

      if (notifications.length < 20) {
        setIsPaginate(false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetching();
    return () => {
      setData([]);
      setIsPaginate(true);
      setIsLoading(false);
      setIsError(false);
      if (scrollRef.current) {
        scrollRef.current.scrollTop(0);
      }
    };
  }, [camera]);

  const handleScroll = () => {
    if (!scrollRef.current || isLoading) {
      return;
    }
    const current = scrollRef.current;
    const { top } = current.getValues();

    const roundingTop = Math.ceil(top * 100);

    if (roundingTop === 100 && isPaginate) {
      const item = data.pop();
      if (item) {
        fetching(item.id);
      }
    }
  };

  const renderContent = () => {
    if (isError) {
      return (
        <ErrorMessage
          onClick={() => {
            if (scrollRef.current) {
              scrollRef.current.scrollTop(0);
            }
            fetching();
          }}
        />
      );
    }

    return (
      <Scrollbars ref={scrollRef} onScroll={handleScroll} autoHide>
        <Box className={classes.container}>
          {data.map((item) => {
            return <LogsItem notification={item} key={item.id} />;
          })}
          {isLoading && <SkeletonLoading numberShow={data.length === 0 ? 15 : 2} width="100%" />}
        </Box>
      </Scrollbars>
    );
  };

  return <Box className={classes.root}>{renderContent()}</Box>;
};

export default LogsList;
