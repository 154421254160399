import { styled } from '@mui/styles';

export const VideoWrapper = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  outline: 'none',
  top: 0,
  left: 0,
});

export const VideoStyled = styled('video')({
  display: 'block',
  width: '100%',
  height: '100%',
  position: 'absolute',
  outline: 0,
  '&::-webkit-media-controls': {
    display: 'none !important',
  },
});
