import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: '100%',
    background: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
  },

  container: {
    padding: `0 ${theme.spacing(1)} ${theme.spacing(1)}`,
    height: '100%',
  },
  buttonControl: {
    background: theme.palette.primary.contrastText,
  },
}));
