import React from 'react';
import { Pause, PlayArrow } from '@mui/icons-material';

import { toggleMediaPlay } from 'src/redux_store/video/video_slice';
import ButtonWrapper from '../button_wrapper';
import i18n from 'src/i18n';
import { useAppDispatch, useAppSelector } from 'src/redux_store';

function PlayToggle() {
  const dispatch = useAppDispatch();
  const { t } = i18n;
  const { mediaPlayer } = useAppSelector(({ videoSlice }) => videoSlice);
  const { isPlay, videoLinks } = mediaPlayer;

  const controlText = !isPlay
    ? t('stream.controlBar.play.false')
    : t('stream.controlBar.play.true');

  const handleClick = () => {
    if (!videoLinks.length || !window.hls) return;
    const { hls } = window;

    if (!isPlay) {
      hls.media.handlePlay();
      dispatch(toggleMediaPlay({ isPlay: true }));
    } else {
      hls.media.handlePause();
      dispatch(toggleMediaPlay({ isPlay: false }));
    }
  };

  return (
    <ButtonWrapper
      title={controlText}
      placement="top-start"
      icon={!isPlay ? PlayArrow : Pause}
      handleClick={handleClick}
    />
  );
}

export default PlayToggle;
