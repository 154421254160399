import { ContentCopy, MoreVert } from '@mui/icons-material'
import { Box, Chip, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'src/hooks/use_copy'

import { Payment } from 'src/types'

interface Props {
  payment: Payment
}

const TransactionRow = ({ payment }: Props) => {
  const { t } = useTranslation()
  const { copyText } = useCopyToClipboard()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCopyId = () => {
    handleClose()
    copyText(payment.id, t('message.copy.success'))
  }

  return (
    <TableRow>
      <TableCell>
        <Typography>{moment(payment.createdAt).format('HH:mm DD-MM-YYYY')}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{payment.description}</Typography>
      </TableCell>
      <TableCell>
        <Box display={'flex'} flexDirection={'row'}>
          <Box pr={1}>
            <img src="/vietqr_logo_short.png" alt="VietQR" width={20} height={20} />
          </Box>

          {/* <Typography>{payment.paymentGateway}</Typography> */}
          <Typography>{'VietQR'}</Typography>
        </Box>
      </TableCell>
      <TableCell>{getStatus(payment.status)}</TableCell>
      <TableCell>
        <Typography>
          {new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: payment.currency,
          }).format(payment.amount)}{' '}
        </Typography>
      </TableCell>
      <TableCell>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>

        <Menu
          id="transaction-history-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={1}
          sx={{ minWidth: 480 }}
        >
          <MenuItem sx={{ width: 150 }} onClick={handleCopyId}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flex={1}>
              <Typography>Copy ID</Typography>
              <ContentCopy />
            </Box>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  )
}

function getStatus(status: string) {
  if (status === 'success') return <Chip size="small" label="Success" color="success" />
  if (status === 'pending') return <Chip size="small" label="Pending" color="warning" />
  if (status === 'canceled') return <Chip size="small" label="Canceled" color="error" />
  return <></>
}

export default TransactionRow
