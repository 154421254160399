import React, { ReactNode } from 'react';
import { toastMessage } from 'src/utils/toast';
import i18n from 'src/i18n';
import { IPlayerProps } from '../types/player';

export function isVideoChild(c: any) {
  if (c.props && c.props.isVideoChild) return true;

  return c.type === 'source' || c.type === 'track';
}

const find = (elements: any, func: any) => elements.filter(func)[0];

// check if two components are the same type
const isTypeEqual = (component1: any, component2: any) => {
  const type1 = component1.type;
  const type2 = component2.type;

  if (typeof type1 === 'string' || typeof type2 === 'string') {
    return type1 === type2;
  }

  if (typeof type1 === 'function' && typeof type2 === 'function') {
    return type1.displayName === type2.displayName;
  }

  return false;
};

export function mergeAndSortChildren(
  defaultChildren: ReactNode[],
  _children: ReactNode,
  _parentProps: IPlayerProps,
  defaultOrder = 1,
) {
  const children = React.Children.toArray(_children);
  const { ...parentProps } = _parentProps; // ignore order from parent
  return children
    .filter((e: any) => !e.props.disabled) // filter the disabled components
    .concat(
      defaultChildren.filter(
        (c: ReactNode) => !find(children, (component: ReactNode) => isTypeEqual(component, c)),
      ),
    )
    .map((element: any) => {
      const defaultComponent = find(defaultChildren, (c: any) => isTypeEqual(c, element));

      const defaultProps = defaultComponent ? defaultComponent.props : {};
      const props = {
        ...parentProps, // inherit from parent component
        ...defaultProps, // inherit from default component
        ...element.props, // element's own props
      };
      const e = React.cloneElement(element, props, element.props.children);
      return e;
    })
    .sort((a, b) => (a.props.order || defaultOrder) - (b.props.order || defaultOrder));
}

export function getErrorMessage({
  pc,
  playerError,
}: {
  pc?: RTCPeerConnection | null;
  playerError: {
    message: string;
    detail?: string | undefined;
  } | null;
}) {
  if (playerError) return toastMessage.error(i18n.t('message.player.error'));
  if (!pc) return toastMessage.error(i18n.t('message.rtc.error'));

  return;
}

export const getAudioDevice = async () => {
  try {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
      },
    });
    window.localStream = localStream;
    return localStream;
  } catch (error) {
    toastMessage.error(i18n.t('stream.controlBar.audio.isDeviceNotFound'));
    console.log({ error });
  }
};
