import { createAsyncThunk } from '@reduxjs/toolkit';

import { IRequestError } from 'src/types/error';
import { RootState } from 'src/redux_store';
import { IShareVideo, IVideoDownload, IVideoHls, IVideoHlsBody } from 'src/types/video';
import client from 'src/clients/http';
import { toastMessage } from 'src/utils/toast';

export const getVideoHls = createAsyncThunk<
  IVideoHls[],
  IVideoHlsBody,
  { state: RootState; rejectValue: IRequestError }
>('video/getVideoHls', async (_payload, { rejectWithValue }) => {
  try {
    const data = await client.getVideoHls(_payload);
    return data;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const shareVideo = createAsyncThunk<
  string,
  IShareVideo,
  { state: RootState; rejectValue: IRequestError }
>('video/shareVideo', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.shareVideo(payload);
    return data;
  } catch (error: any) {
    if (error) {
      toastMessage.error(error?.message);
    }

    return rejectWithValue(error);
  }
});

export const downloadVideo = createAsyncThunk<
  string,
  IVideoDownload,
  { state: RootState; rejectValue: IRequestError }
>('video/downloadVideo', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.checkExportVideo(payload);
    if (data === 'OK') {
      await client.downloadVideo(payload);
    }

    return 'OK';
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});
