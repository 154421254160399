import { Autorenew, MoreVertOutlined } from '@mui/icons-material'
import { Box, Chip, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { Subscription } from 'src/types'
import { getSubscriptionPlan } from '../../../utils'
import { openModal } from 'src/redux_store/common/modal/modal_slice'
import { useTranslation } from 'react-i18next'
// import { ModalIdentifiers } from 'src/constants/modal'
// import SubscriptionPaymentModal from '../subscription_payment_modal'

const ExpiredSubscriptionRow = ({ subscription }: { orgId: string; subscription: Subscription }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { subscriptionPlans } = useAppSelector((state) => state.organizations)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCellClick = () => {
    navigate(subscription.id)
  }

  //   const handlePayment = () => {
  //     handleClose()
  //     dispatch(
  //       openModal({
  //         modalId: ModalIdentifiers.SUBSCRIPTION_PAYMENT,
  //         dialogComponent: (
  //           <SubscriptionPaymentModal subscription={subscription} orgId={subscription.organizationId} renew={true} />
  //         ),
  //       })
  //     )
  //   }

  const subPlan = getSubscriptionPlan(subscriptionPlans, subscription?.items?.[0]?.subscriptionPlanId)
  if (!subPlan) return
  return (
    <TableRow sx={{ cursor: 'pointer' }}>
      <TableCell onClick={handleCellClick}>
        <Chip label={subPlan.plan.name} size="small" variant="outlined" />
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography fontWeight={600}>{subscription.metadata['stations']}</Typography>
      </TableCell>
      <TableCell onClick={handleCellClick}>
        {subPlan.interval === 1 && <Chip label={t('subscription.Month')} size="small" variant="outlined" />}
        {subPlan.interval === 2 && <Chip label={t('subscription.Year')} size="small" variant="outlined" />}
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography>{moment(subscription.createdAt).format('HH:mm:ss DD-MM-YYYY')}</Typography>
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography>
          {subscription.currentPeriodStart ? moment(subscription.currentPeriodStart).format('HH:mm:ss DD-MM-YYYY') : ''}
        </Typography>
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Typography>
          {subscription.currentPeriodEnd ? moment(subscription.currentPeriodEnd).format('HH:mm:ss DD-MM-YYYY') : ''}
        </Typography>
      </TableCell>
      <TableCell onClick={handleCellClick}>
        <Chip label="Expired" size="small" color="error" />
      </TableCell>
      {/* <TableCell>
        <IconButton onClick={handleClick}>
          <MoreVertOutlined />
        </IconButton>
        <Menu
          id="unpaid-subscriptions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={1}
          sx={{ minWidth: 480 }}
        >
          <MenuItem sx={{ width: 150 }} onClick={handlePayment}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flex={1}>
              <Typography>Renew</Typography>
              <Autorenew />
            </Box>
          </MenuItem>
        </Menu>
      </TableCell> */}
    </TableRow>
  )
}

export default ExpiredSubscriptionRow
