import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMediaPlayer, IVideoHls } from 'src/types/video';
import { getVideoHls } from './video_action';

const INIT_MEDIA_PLAYER = {
  videoLinks: [],
  date: new Date(),
  today: new Date(),
  isPlay: true,
  isSeeking: false,
  isMuted: true,
  speed: 1,
  cameraId: '',
  isRefresh: false,
  isLoading: false,
  exportCameraId: '',
  exportTime: null,
};

interface IVideoState {
  mediaPlayer: IMediaPlayer;
}

const initialState: IVideoState = {
  mediaPlayer: { ...INIT_MEDIA_PLAYER },
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    onVideoSeeking: (state: IVideoState) => {
      state.mediaPlayer.isSeeking = true;
    },
    onVideoSeeked: (state: IVideoState) => {
      state.mediaPlayer.isSeeking = false;
    },
    toggleMediaPlay: (state: IVideoState, action: PayloadAction<{ isPlay: boolean }>) => {
      state.mediaPlayer.isPlay = action.payload.isPlay;
    },
    toggleVideoMuted: (state) => {
      state.mediaPlayer.isMuted = !state.mediaPlayer.isMuted;
    },
    refreshTimeline: (state: IVideoState, action: PayloadAction<boolean>) => {
      state.mediaPlayer.isRefresh = action.payload;
    },
    changeVideoSpeed: (state: IVideoState, action: PayloadAction<number>) => {
      state.mediaPlayer.speed = action.payload;
    },
    changeHlsVideoFilterParams: (state, action) => {
      state.mediaPlayer = {
        ...state.mediaPlayer,
        ...action.payload,
      };
      state.mediaPlayer.isPlay = true;
    },
    resetState: (state) => {
      state.mediaPlayer.videoLinks.length = 0;
      state.mediaPlayer.isLoading = false;
      state.mediaPlayer.isRefresh = false;
      state.mediaPlayer.isSeeking = false;
      state.mediaPlayer.isPlay = true;
      state.mediaPlayer.exportCameraId = '';
      state.mediaPlayer.isMuted = true;
    },
    clearVideoState: (state: IVideoState) => {
      state.mediaPlayer = {
        ...INIT_MEDIA_PLAYER,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getVideoHls.pending, (state) => {
        if (!state.mediaPlayer.isRefresh) {
          state.mediaPlayer.videoLinks.length = 0;
        }
      })
      .addCase(getVideoHls.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.mediaPlayer.isPlay = false;
        }
        state.mediaPlayer.videoLinks = convertVideos(action.payload);
      })
      .addCase(getVideoHls.rejected, (state) => {
        state.mediaPlayer.isRefresh = false;
        state.mediaPlayer.isPlay = false;
      });
  },
});

export const convertVideos = (payload: IVideoHls[]) => {
  if (!payload.length) return [];
  const videos: IVideoHls[] = [];
  payload.forEach((element, index) => {
    const cloneElement = { ...element };
    cloneElement.start = new Date(element.start);
    cloneElement.ms = cloneElement.start.getMilliseconds();
    cloneElement.end = !element.end ? new Date(payload[index + 1].start) : new Date(element.end);

    videos.push(cloneElement);
  });

  videos[0] = checkOvernightVideo(videos[0], 'start');
  videos[videos.length - 1] = checkOvernightVideo(videos[videos.length - 1], 'end');

  return videos;
};

const checkOvernightVideo = (video: IVideoHls, type: 'start' | 'end') => {
  if (video.end.getDate() === video.start.getDate()) return { ...video };
  if (type === 'start') {
    const date = new Date(video.end).setHours(0, 0, 0, 0);
    const newStart = new Date(date);
    const videoSeekDefault = newStart.getTime() / 1000 - video.start.getTime() / 1000;
    return { ...video, start: new Date(date), isOvernight: true, videoSeekDefault };
  }
  if (type === 'end') {
    const date = new Date(video.start).setHours(23, 59, 59, 999);
    return {
      ...video,
      end: new Date(date),
      isOvernight: true,
      videoSeekDefault: 0,
      isEndDay: true,
    };
  }
  return video;
};

const { actions, reducer } = videoSlice;

export const {
  onVideoSeeking,
  onVideoSeeked,
  toggleMediaPlay,
  toggleVideoMuted,
  refreshTimeline,
  changeVideoSpeed,
  clearVideoState,
  resetState,
  changeHlsVideoFilterParams,
} = actions;
export default reducer;
