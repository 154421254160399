import websocket from 'src/clients/websocket';
import { ThunkAppDispatch } from 'src/redux_store';

import {
  markNotificationRead,
  markNotificationUnread,
} from 'src/redux_store/notification/notification_action';
import { markNotification } from 'src/redux_store/notification/notification_slice';
import { INotification } from 'src/types/notification';

export const syncNumberOfNotification = (userId: string) => {
  websocket.syncNumberOfNotification(userId);
};

export const markAllNotification = (dispatch: ThunkAppDispatch, isSocket?: boolean) => {
  dispatch(markNotification({ status: true, id: 'all' }));
  if (!isSocket) {
    dispatch(markNotificationRead('all'));
  }
};

export const markItemNotification = (
  dispatch: ThunkAppDispatch,
  type: 'read' | 'unread',
  id: string,
  isSocket?: boolean,
) => {
  if (type === 'read') {
    dispatch(markNotification({ status: true, id: id }));

    if (!isSocket) {
      dispatch(markNotificationRead(id));
    }
  } else {
    dispatch(markNotification({ status: false, id: id }));
    if (!isSocket) {
      dispatch(markNotificationUnread(id));
    }
  }
};

export const formatNotificationItem = (objectCurrent: { data: any; content: string }) => {
  const { data, content } = objectCurrent;

  let cloneText = content;

  for (const key in data) {
    const index = cloneText.indexOf(`{${key}}`);

    const newText = `<b>${data[key as keyof any]}</b>`;

    if (index !== -1) {
      cloneText = cloneText.replace(`{${key}}`, newText);
    }
  }

  return {
    ...objectCurrent,
    content: cloneText,
  };
};

export const formatNotificationList = (currentData: INotification[]) => {
  const newData: INotification[] = [];
  currentData.map((item) => {
    const { title, description } = item;

    const newTitle = formatNotificationItem(title);
    const newDescription = formatNotificationItem(description);

    newData.push({
      ...item,
      description: newDescription,
      title: newTitle,
    });
  });

  return newData;
};
