import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import client from 'src/clients/http';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getMe, getMyRole } from 'src/redux_store/my_account/my_account_action';
import { changeUserSession } from 'src/redux_store/my_account/my_account_slice';

const Authentication = () => {
  const dispatch = useAppDispatch();
  const token = Cookies.get('USER_SESSION');
  const { isReload, userSession } = useAppSelector((state) => state.myAccountSlice);
  // const [authToken, setAuthToken] = useState(token);

  let intervalCookie: any;

  const checkCookie = () => {
    const currentToken = Cookies.get('USER_SESSION');

    if (currentToken !== userSession && isReload) {
      dispatch(changeUserSession(currentToken));
      window.location.reload();
      clearInterval(intervalCookie);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalCookie = setInterval(checkCookie, 5000);
    return () => clearInterval(intervalCookie);
  }, [isReload]);

  useEffect(() => {
    if (token) {
      client.setToken(token);
      Promise.all([dispatch(getMe()), dispatch(getMyRole('me'))]);
    }
  }, [token, dispatch]);

  return <React.Fragment />;
};

export default Authentication;
