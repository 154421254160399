import {
  AccessTime,
  Close,
  ManageHistory,
  NetworkCheck,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import 'moment/locale/vi'; // without this line it didn't work
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyMessage from 'src/components/empty_message';
import StationBattery from 'src/components/icons/station_battery';
import StationElectricLine from 'src/components/icons/station_electric_line';
import StationGPSIcon from 'src/components/icons/station_gps';
import StationNetwork from 'src/components/icons/station_network';
import StationPowerSolar from 'src/components/icons/station_power_solar';
import { getColorIconStation } from 'src/components/icons/utils';
import { Mode } from 'src/constants/station';
import {
  EBatteryChargeStatus,
  EPowerBatteryStatus,
  EWorkingMode,
  IStation,
} from 'src/types/station';
import { momentLocale } from 'src/utils/common';
import { useStyles } from './styles';
import WorkingDayInfo from './working_day_info';

const TIMER_RE_RENDER = 1000 * 60 * 1;

const StationInfoCard = ({ station }: { station: IStation }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openMode, setOpenMode] = useState(false);

  const [lastTimeNetworkUpdate, setLastTimeNetworkUpdate] = useState<string>(
    momentLocale(station.mobileNetworkLastUpdatedAt).fromNow(),
  );
  const [lastTimeGPSUpdate, setLastTimeGPSUpdate] = useState<string>(
    momentLocale(station.gpsLastUpdatedAt).fromNow(),
  );
  const [lastTimeBatteryUpdate, setLastTimeBatteryUpdate] = useState<string>(
    momentLocale(station.powerBatteryLastUpdatedAt).fromNow(),
  );

  //interval Battery
  useEffect(() => {
    setLastTimeNetworkUpdate(momentLocale(station.mobileNetworkLastUpdatedAt).fromNow());

    const timer = setInterval(() => {
      setLastTimeBatteryUpdate(momentLocale(station.powerBatteryLastUpdatedAt).fromNow());
    }, TIMER_RE_RENDER);

    return () => {
      clearInterval(timer);
    };
  }, [station.powerBatteryLastUpdatedAt]);
  //interval network
  useEffect(() => {
    setLastTimeGPSUpdate(momentLocale(station.gpsLastUpdatedAt).fromNow());
    const timer = setInterval(() => {
      setLastTimeNetworkUpdate(momentLocale(station.mobileNetworkLastUpdatedAt).fromNow());
    }, TIMER_RE_RENDER);

    return () => {
      clearInterval(timer);
    };
  }, [station.mobileNetworkLastUpdatedAt]);

  //interval GPS
  useEffect(() => {
    setLastTimeBatteryUpdate(momentLocale(station.powerBatteryLastUpdatedAt).fromNow());
    const timer = setInterval(() => {
      setLastTimeGPSUpdate(momentLocale(station.gpsLastUpdatedAt).fromNow());
    }, TIMER_RE_RENDER);

    return () => {
      clearInterval(timer);
    };
  }, [station.gpsLastUpdatedAt]);

  console.log({ lastTimeGPSUpdate });

  if (_.isEmpty(station) || !station) {
    return (
      <Box p={2}>
        <EmptyMessage title={t('label.emptyData.station')} />
      </Box>
    );
  }

  const renderPowerBatteryDescription = () => {
    if (!station.powerBattery) return t('label.battery.noConnect');
    if (station.powerBatteryConnectionStatus === EPowerBatteryStatus.DISCONNECT)
      return t('label.battery.disconnect');

    let charge = '';
    if (
      station.powerBatteryChargeStatus == EBatteryChargeStatus.CHARGE &&
      station.powerBatteryCapacityStatus < 100
    )
      charge = `- ${t('label.battery.charge')}`;

    return `${station?.powerBatteryCapacityStatus || 0}% ${charge}`;
  };

  const renderPowerBatteryTooltip = () => {
    if (station.powerBatteryConnectionStatus === EPowerBatteryStatus.DISCONNECT)
      return t('label.battery.disconnect');

    // return `Dung lượng pin: ${station?.powerBatteryCapacityStatus || 0}%`;
    return t('label.battery.capacity', {
      batteryCapacity: station?.powerBatteryCapacityStatus || 0,
    });
  };

  return (
    <Fragment>
      <Box className={classes.box}>
        <Typography my={1} component="h6" variant="subtitle2" fontWeight="bold" noWrap>
          {t('label.power.main')}
        </Typography>
        <Box className={classes.list}>
          {station.powerBattery && (
            <Box className={classes.item}>
              <Tooltip title={renderPowerBatteryTooltip()}>
                <StationBattery
                  stationStatus={station.status}
                  battery={station.powerBatteryCapacityStatus}
                  batteryChargeStatus={station.powerBatteryChargeStatus}
                  batteryConnectionStatus={station.powerBatteryConnectionStatus}
                  powerBattery={station.powerBattery}
                />
              </Tooltip>

              <Typography noWrap>{renderPowerBatteryDescription()}</Typography>
            </Box>
          )}

          {station.powerSolar && (
            <Box className={classes.item}>
              <StationPowerSolar stationStatus={station.status} />
              <Typography noWrap>{t('label.power.solar.haveConnect')}</Typography>
            </Box>
          )}

          {station.powerElectricLine && (
            <Box className={classes.item}>
              <StationElectricLine stationStatus={station.status} />
              <Typography noWrap>{t('label.power.electric.haveConnect')}</Typography>
            </Box>
          )}
          <Box className={classes.item}>
            <AccessTime sx={{ color: getColorIconStation(station.status) }} />
            <Typography noWrap>
              {t('label.lastUpdated', {
                lastUpdated: lastTimeBatteryUpdate,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.box}>
        <Typography noWrap my={1} component="h6" variant="subtitle2" fontWeight="bold">
          {t('label.location')}
        </Typography>
        <Box className={classes.list}>
          <Box className={classes.item}>
            <StationGPSIcon
              status={station.gpsConnectionStatus}
              gpsWaveStrength={station.gpsWaveStrength}
              stationStatus={station.status}
              gpsLastUpdatedAt={momentLocale(station.gpsLastUpdatedAt).toString()}
            />
            <Typography
              component="a"
              href={`https://www.google.com/maps/place/${`${station.lat},${station.lng}`}`}
              target="_blank"
              noWrap
              color="text.primary"
              sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
            >{`${station.lat} , ${station.lng}`}</Typography>
          </Box>
          <Box className={classes.item}>
            <AccessTime sx={{ color: getColorIconStation(station.status) }} />
            <Typography noWrap>
              {t('label.lastUpdated', {
                lastUpdated: lastTimeGPSUpdate,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.box}>
        <Typography noWrap my={1} component="h6" variant="subtitle2" fontWeight="bold">
          {/* Kết nối mạng */}
          {t('label.networkConnection')}
        </Typography>
        <Box className={classes.list}>
          <Box className={classes.item}>
            <StationNetwork
              stationStatus={station.status}
              type={station.mobileNetwork}
              mobileNetworkWaveStrength={station.mobileNetworkWaveStrength}
              mobileNetworkOperator={station?.mobileNetworkOperator}
              mobileNetworkConnectionStatus={station.mobileNetworkConnectionStatus}
            />
            <Typography noWrap>
              {station.mobileNetworkOperator
                ? station.mobileNetworkOperator
                : t('label.noConnection')}
            </Typography>
          </Box>
          <Box className={classes.item}>
            <NetworkCheck sx={{ color: getColorIconStation(station.status) }} />
            {/* <Typography noWrap>{`Đã dùng: ${station.mobileNetworkDataUsed}`}</Typography> */}
            <Typography noWrap>
              {t('label.mobileNetwork.used', { dataUsed: station.mobileNetworkDataUsed })}
            </Typography>
          </Box>
          <Box className={classes.item}>
            <AccessTime sx={{ color: getColorIconStation(station.status) }} />
            <Typography noWrap>
              {t('label.lastUpdated', {
                lastUpdated: lastTimeNetworkUpdate,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Box className={classes.box}>
        <Typography noWrap my={1} component="h6" variant="subtitle2" fontWeight="bold">
          {t('label.workingMode.main')}
        </Typography>
        <Box className={classes.list}>
          <Box className={classes.item}>
            <ManageHistory sx={{ color: getColorIconStation(station.status) }} />
            <Typography noWrap flex={1}>
              {Mode(t).find((item) => item.id === station.workingMode)?.name}
            </Typography>

            {station.workingMode === EWorkingMode.SCHEDULED && (
              <Tooltip title={t('label.time.viewDetail')}>
                <VisibilityOutlined
                  onClick={() => setOpenMode(true)}
                  sx={{ mr: 1, cursor: 'pointer', color: getColorIconStation(station.status) }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      {station.workingMode === EWorkingMode.SCHEDULED && (
        <Dialog
          keepMounted
          onClose={() => setOpenMode(false)}
          open={openMode}
          maxWidth="sm"
          fullWidth
          // sx={{
          //   '.MuiPaper-root': { height: 'calc(100% - 64px)' },
          // }}
        >
          <DialogTitle sx={{ m: 0, p: 1, position: 'relative', textAlign: 'center' }}>
            {/* Thời gian lưu video */}
            {t('label.time.record')}
            <IconButton
              onClick={() => setOpenMode(false)}
              sx={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              // display: 'flex',
              // height: '100%',
            }}
            dividers
          >
            {/* <Scrollbars> */}
            <Box flex={1} p={1}>
              <WorkingDayInfo schedule={station.workingSchedule} />
            </Box>
            {/* </Scrollbars> */}
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};

export default StationInfoCard;
