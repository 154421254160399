import { SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

export interface IStyles {
  [x: string]: SxProps;
}

export interface ILocation {
  lat: number;
  lng: number;
}

export interface IModal {
  modalId: string;
  dialogComponent?: ReactNode;
}

export interface IModalState {
  [modalId: string]: {
    open: boolean;
    dialogComponent: ReactNode;
  };
}
export interface ITabItem {
  key: string;
  label: string;
  component: ReactNode;
  typeTab?: string;
}

// export interface IPagination<T> {
//   totalData: number;
//   data: T[];
// }

export interface IPagination<T> {
  data: T[];
  page: number;
  total: number;
  totalPage: number;
}

export interface IPaginationRole<T> {
  data: T[];
  page?: number;
  totalData?: number;
  totalPages?: number;
}

export type TMuiSize = 'small' | 'medium' | 'large';

export type TMuiColor =
  | 'warning'
  | 'default'
  | 'error'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'success'
  | undefined;

export enum ESort {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IIconTopBar {
  id: 'notification' | 'downloadApp' | 'settingTheme' | 'help' | 'whatNews';
  title: string;
  icon: any;
  activeIcon: any;
}

export interface ISvgIconProps {
  size?: number;
  color?: string;
  value?: string | number;
}

export enum EMapPanel {
  STATION = 'station',
  CAMERA = 'camera',
  CLOSE = 'close',
}

export type TI18n = TFunction<'translation', undefined>;
