export function hhmmss(value: number, fixedDigits: number) {
  const h = (value / 3600) | 0;
  const m = ((value / 60) | 0) % 60;
  const s = value % 60;
  const newH = h ? (h > 9 ? h : `0${h}`) : '00';
  const newS = fixedDigits ? s.toFixed(fixedDigits) : s;

  return `${newH}:${pad(m, 2)}:${pad(newS, 2)}`;
}

function pad(str: number | string, length: number) {
  str = '' + Number(str).toFixed(0);
  while (str.length < length) {
    str = '0' + str;
  }

  return str;
}

export function convertTimeToSecond(date: Date, ms = 0) {
  if (!date) return 0;
  const h = date.getHours(),
    m = date.getMinutes(),
    s = date.getSeconds();
  // ms = date.getMilliseconds()

  return h * 60 * 60 + m * 60 + s + ms / 1000;
}

export function getCurrentDateToSeconds(date: Date) {
  return date.getTime() / 1000;
}

export function convertPosPixelToTime(timeArray: string[], date: Date, ms = 0) {
  const newDate = new Date(date).setHours(
    Number(timeArray[0]),
    Number(timeArray[1]),
    Number(timeArray[2]),
    ms,
  );
  return new Date(newDate);
}
