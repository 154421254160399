import { createSlice } from '@reduxjs/toolkit';
import { IUser } from 'src/types/auth';
import { IPagination } from 'src/types/common';
import { IUserFilter } from 'src/types/user';
import {
  getUserInfo,
  getUsers,
  updateUserInfo,
  updateUserRole,
  verifyEmailUser,
} from './user_actions';
import { ICustomerInfo } from 'src/types/customer';
import { IStationCard } from 'src/types/station';

interface IDataState {
  cameraList: any[];
  cameraSelectedList: any[];
  customerList: any[];
  customerSelectedList: ICustomerInfo[];

  stationList: IStationCard[];
  stationSelectedList: IStationCard[];
}

interface IPayloadTransfer {
  payload: {
    fieldFromTransfer: string;
    fieldToTransfer: string;
    dataTransfer: any[];
  };
}

interface IUserSliceState {
  filter: IUserFilter;
  users: IPagination<IUser>;
  infoUserSelect: IUser;
  idUserSelected: string;
  data: IDataState;
  trackAllStation: boolean;
  trackAllCustomer: boolean;
  trackAllCameras: boolean;
  typeTab: 'generate' | 'permission' | 'customer';
}

const initialState: IUserSliceState = {
  filter: {
    page: 1,
    limit: 50,
    searchKeyword: '',
    inactive: false,
  },
  users: {
    data: [],
    page: 0,
    total: 0,
    totalPage: 0,
  },
  infoUserSelect: {} as IUser,
  idUserSelected: '',

  data: {
    cameraList: [],
    cameraSelectedList: [],
    customerList: [],
    customerSelectedList: [],

    stationList: [],
    stationSelectedList: [],
  },

  trackAllStation: false,
  trackAllCustomer: false,
  trackAllCameras: false,

  typeTab: 'generate',
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.infoUserSelect = action.payload;
    },
    changeFilter: (state, action) => {
      const newPayload = action.payload;
      state.filter = newPayload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
    resetUserData: (state) => {
      state.users = {} as IPagination<IUser>;
      state.infoUserSelect = {} as IUser;
      state.idUserSelected = '';
      state.filter = initialState.filter;
    },
    setIdUserSelected: (state, action: { payload: string }) => {
      state.idUserSelected = action.payload;
    },
    changeTrackAllStation: (state, action: { payload: boolean }) => {
      state.trackAllStation = action.payload;
    },
    setStationList: (state, action) => {
      const data = action.payload;
      state.data.stationList = data;
    },
    setStationSelectDefault: (state, action) => {
      const data = action.payload;
      state.data.stationSelectedList = data;
    },
    transferData: (state, action: IPayloadTransfer) => {
      const { fieldFromTransfer, fieldToTransfer, dataTransfer } = action.payload;
      const cloneDataList = [...state.data[fieldFromTransfer as keyof IDataState]];

      dataTransfer?.map((item: any) => {
        const index = cloneDataList.findIndex((data) => data.id === item.id);
        cloneDataList.splice(index, 1);
      });

      const cloneDataListSelected = [...state.data[fieldToTransfer as keyof IDataState]];
      const newDataSelectedList = cloneDataListSelected.concat(dataTransfer);

      state.data[fieldFromTransfer as keyof IDataState] = cloneDataList;
      state.data[fieldToTransfer as keyof IDataState] = newDataSelectedList;
    },
    resetTransferData: (state) => {
      state.data.cameraList = [];
      state.data.cameraSelectedList = [];
      state.data.customerList = [];
      state.data.customerSelectedList = [];
      state.data.stationList = [];
      state.data.stationSelectedList = [];
    },
    changeFollowAllCustomer: (state, action) => {
      state.trackAllCustomer = action.payload;
    },
    setCustomerList: (state, action) => {
      const data = action.payload;
      state.data.customerList = data;
    },
    setCustomerSelectDefault: (state, action) => {
      const data = action.payload;

      state.data.customerSelectedList = data;
    },
    setCameraList: (state, action) => {
      const data = action.payload;
      state.data.cameraList = data;
    },
    changeFollowAllCamera: (state, action) => {
      state.trackAllCameras = action.payload;
    },
    setCameraSelectDefault: (state, action) => {
      const data = action.payload;
      state.data.cameraSelectedList = data;
    },
    changeTab: (state, action) => {
      const data = action.payload;
      state.typeTab = data;
    },
  },
  extraReducers(builder) {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const data = action.payload;

      state.users = data;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      const userInfo = action.payload;
      state.infoUserSelect = userInfo;
    });
    builder.addCase(updateUserInfo.fulfilled, (state, action) => {
      const newInfo = action.payload;

      state.infoUserSelect = newInfo;
    });
    builder.addCase(verifyEmailUser.fulfilled, (state) => {
      state.infoUserSelect.emailVerified = true;
    });
    builder.addCase(updateUserRole.fulfilled, (state, action) => {
      state.infoUserSelect.role = action.payload;
    });
  },
});

export const {
  changeFilter,
  resetFilter,
  resetUserData,
  setIdUserSelected,
  changeTrackAllStation,
  setUserDetail,
  transferData,
  setStationList,
  setStationSelectDefault,
  resetTransferData,
  setCustomerList,
  setCustomerSelectDefault,
  changeFollowAllCustomer,
  setCameraList,
  changeFollowAllCamera,
  setCameraSelectDefault,
  changeTab,
} = userSlice.actions;

export default userSlice.reducer;
