import React, { useCallback, useEffect } from 'react';
import { VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { isBoolean } from 'lodash';

import i18n from 'src/i18n';
import ButtonWrapper from '../button_wrapper';
import { IPlayerProps } from '../../../types/player';
import { toastMessage } from 'src/utils/toast';
import { getErrorMessage } from 'src/components/player_rtc/utils/video';

function Volume(props: any) {
  const { actions, player, manager, cam, controlBar } = props as IPlayerProps;
  const { t } = i18n;

  const handleClick = useCallback(
    (insideClick?: boolean) => {
      const { video } = manager;

      if (!video) return;

      const errorMessage = getErrorMessage({ pc: video?.rtc?.pc, playerError: player.error });

      if (!controlBar?.speaker || errorMessage) return;

      const { toggleSpeaker } = controlBar.speaker;

      if (player.isMuted) {
        if (!cam.isSupportAudio)
          return toastMessage.error(t('stream.controlBar.audio.isNotSupported'));
        actions.unmute();
        video.muted = false;
      } else {
        video.muted = true;
        actions.mute();
      }

      toggleSpeaker && insideClick && toggleSpeaker();
    },
    [player.isMuted],
  );

  useEffect(() => {
    if (isBoolean(controlBar?.speaker?.status)) {
      handleClick(false);
    }
  }, [controlBar?.speaker?.status]);

  if (!cam.isEnabledSpeaker || !controlBar?.speaker) return null;

  // const { status } = controlBar.speaker;

  // const handleClick = async () => {
  //   const { video } = manager;
  //   if (player.error || !video?.rtc?.pc) return;

  //   if (player.isMuted) {
  //     if (!cam.isSupportAudio)
  //       return toastMessage.error(t('stream.controlBar.audio.isNotSupported'));
  //     actions.unmute();
  //     video.muted = false;

  //     toggleSpeaker && toggleSpeaker();
  //   } else {
  //     video.muted = true;
  //     actions.mute();

  //     toggleSpeaker && toggleSpeaker();
  //   }
  // };

  const controlText = player.isMuted
    ? t('stream.controlBar.volume.on')
    : t('stream.controlBar.volume.off');

  return (
    <ButtonWrapper
      title={controlText}
      icon={player.isMuted ? VolumeOffOutlined : VolumeUpOutlined}
      handleClick={() => handleClick(true)}
      disablePortal
    />
  );
}

export default Volume;
