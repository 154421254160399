import React from 'react';
import { Fullscreen } from '@mui/icons-material';

import ButtonWrapper from '../button_wrapper';
import { fullscreenElementToggle } from '../../utils/video';
import i18n from 'src/i18n';

function FullscreenToggle({ mediaWrapper }: { mediaWrapper: any }) {
  const { t } = i18n;
  const handleFullScreen = () => {
    const { hls } = window;
    if (!mediaWrapper || !hls) return;
    fullscreenElementToggle(mediaWrapper.current);
  };

  const controlText = t('stream.controlBar.fullScreen.false');

  return (
    <ButtonWrapper
      title={controlText}
      placement="top-start"
      icon={Fullscreen}
      handleClick={handleFullScreen}
    />
  );
}

export default FullscreenToggle;
