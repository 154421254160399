import { Close } from '@mui/icons-material'
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { indigo } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { listOrganizations } from 'src/redux_store/organizations/slice'
import { RequestStatusOptions } from 'src/types'

const Organizations = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { organizations, requests } = useAppSelector((state) => state.organizations)

  const [term, setTerm] = useState('')
  const [page, setPage] = useState(1)
  const [limit] = useState(20)

  useEffect(() => {
    dispatch(listOrganizations({ searchKeyword: term, page, limit }))
  }, [dispatch, limit, page, term])

  const handleChangeTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1)
    setTerm(e.target.value)
  }

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage)
  }

  // if (requests.listOrganizations.status === RequestStatusOptions.PENDING) {
  //   return (
  //     <Box display={'flex'} justifyContent={'center'} flex={1} p={3}>
  //       <CircularProgress />
  //     </Box>
  //   )
  // }

  if (requests.listOrganizations.status === RequestStatusOptions.REJECTED) {
    return (
      <Box display={'flex'} justifyContent={'center'} flex={1}>
        <Typography>Something wrong!</Typography>
      </Box>
    )
  }

  return (
    <Box display={'flex'} flex={1}>
      <Container>
        <Box display={'flex'} flexDirection={'column'} height={'100%'}>
          <Box py={2}>
            <TextField
              sx={{ width: 300 }}
              size="small"
              placeholder="Search"
              value={term}
              onChange={handleChangeTerm}
              InputProps={{
                endAdornment: term ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setTerm('')}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Box>
          {requests.listOrganizations.status === RequestStatusOptions.PENDING ? (
            <Box display={'flex'} justifyContent={'center'} flex={1} p={3}>
              <CircularProgress />
            </Box>
          ) : (
            <Box display={'flex'} flexDirection={'column'} flex={1}>
              <Box flex={1}>
                <TableContainer sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {t('organization')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {t('organization.email')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {t('organization.phone')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {t('organization.address')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {t('organization.description')}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {organizations.data.map((org, index) => {
                        return (
                          <TableRow key={index} hover>
                            <TableCell>
                              <Box
                                component={Link}
                                to={org.id}
                                display={'flex'}
                                gap={1}
                                alignItems={'center'}
                                sx={{
                                  color: indigo[500],
                                  textDecoration: 'none',
                                  '&:hover': { textDecoration: 'underline' },
                                }}
                              >
                                <Avatar src={org.avatarUrl} />
                                <Typography
                                  // component={Link}
                                  // to={org.id}
                                  variant="subtitle1"
                                  fontWeight={600}
                                  sx={{
                                    color: indigo[500],
                                    textDecoration: 'none',
                                    '&:hover': { textDecoration: 'underline' },
                                  }}
                                >
                                  {org.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>{org.email}</TableCell>
                            <TableCell>{org.phone}</TableCell>
                            <TableCell>{org.address}</TableCell>
                            <TableCell>{org.description}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {!organizations.data.length && (
                  <Box display={'flex'} flex={1} p={2} width={'100%'} justifyContent={'center'}>
                    <Typography textAlign={'center'}>No organizations</Typography>
                  </Box>
                )}
              </Box>
              <Box py={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                {!!organizations.total && (
                  <Pagination page={page} count={organizations.totalPage} color="primary" onChange={handleChangePage} />
                )}
                {!!organizations.total && (
                  <Typography variant="subtitle1" fontWeight={600}>
                    Total: {organizations.total}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default Organizations
