import { createSlice } from '@reduxjs/toolkit';
import { ICamera } from 'src/types/camera';

import { getOrders } from '../orders/actions';
import {
  addOrderCameras,
  deleteOrderCamera,
  getCameraWithoutCustomer,
  getOrderCameras,
} from './actions';

interface IOrderCameraState {
  camerasWithoutCustomer: ICamera[];
  cameras: ICamera[];
}

const initialState: IOrderCameraState = {
  camerasWithoutCustomer: [],
  cameras: [],
};

const orderCameraSlice = createSlice({
  name: 'orderCameras',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCameraWithoutCustomer.fulfilled, (state, action) => {
        state.camerasWithoutCustomer = action.payload;
      })
      .addCase(getOrderCameras.fulfilled, (state, action) => {
        state.cameras = action.payload;
      })
      .addCase(addOrderCameras.fulfilled, (state, action) => {
        state.cameras.push(...action.payload);
      })
      .addCase(deleteOrderCamera.fulfilled, (state, action) => {
        state.cameras = state.cameras.filter((camera) => camera.id !== action.payload);
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.cameras.length = 0;
        }
      });
  },
});

const { reducer } = orderCameraSlice;
export default reducer;
