import React, { CSSProperties, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FormControl, SxProps } from '@mui/material';
import moment from 'moment';

interface IFormDateTimePickerProps {
  name: string;
  label: string;
  control: any;
  disabled?: boolean;
  size?: 'small' | 'medium' | undefined;
  sx?: SxProps;
  style?: CSSProperties;
  margin?: 'none' | 'dense' | 'normal';
  maxDate?: any;
  minDate?: any;
  inputFormat?: string;
  handleOnChange?: (name: string, value: object) => void;
  handleChangeClick?: (name: string, value: object) => void;
  handleSetError?: (name: string, value: boolean) => void;
  handleOnBlur?: (name: string) => void;
}

const FormDateTimePicker = ({
  name,
  control,
  label,
  size = 'small',
  disabled,
  style,
  sx,
  margin = 'dense',
  maxDate,
  minDate,
  inputFormat = 'DD/MM/YYYY HH:mm',
  handleOnChange,
  handleChangeClick,
  handleSetError,
  handleOnBlur,
}: IFormDateTimePickerProps) => {
  const [isAccept, setIsAccept] = useState<boolean>(false);

  const handleBlur = (e: any) => {
    if (!handleOnBlur) return;

    const value = e.target.value;
    const isInvalid = !moment(value, 'DD/MM/YYYY HH:mm', true).isValid();

    if (isInvalid && value) {
      handleOnBlur(e.target.getAttribute('name'));
    }
  };

  return (
    <FormControl size={size} fullWidth style={style} sx={sx} margin={margin}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <DateTimePicker
            value={value}
            label={label}
            inputFormat={inputFormat}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            onOpen={() => setIsAccept(true)}
            onClose={() => isAccept && setIsAccept(false)}
            onChange={(newValue) => {
              onChange(newValue);
              if (handleSetError && handleChangeClick) {
                if (new Date(newValue).getTime() && !isAccept) {
                  // case no open datetimepicker
                  handleChangeClick(name, newValue);
                  handleSetError(name, false);
                } else if (!newValue) {
                  // case newValue === null
                  handleSetError(name, false);
                  handleChangeClick(name, newValue);
                }

                if (!new Date(newValue).getTime() && newValue) {
                  // case format time not "DD/MM/YYYY HH:mm"
                  handleSetError(name, true);
                }
              }
            }}
            onAccept={(newValue) => {
              if (handleOnChange) {
                handleOnChange(name, newValue);
              }
              if (handleSetError) {
                handleSetError(name, false);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size={size || 'medium'}
                error={!!error}
                disabled={disabled}
                helperText={error && error?.message}
                onBlur={handleBlur}
                name={name}
                type="datetime-local"
                autoComplete="off"
              />
            )}
            dayOfWeekFormatter={(day) => day}
          />
        )}
      />
    </FormControl>
  );
};

export default FormDateTimePicker;
