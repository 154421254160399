import { Plan, Subscription, SubscriptionPlan } from 'src/types'

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isHasSeatConstraint(plans: Plan[]) {
  let result = false
  plans.forEach((plan) => {
    if (plan.constraints['seats'] !== undefined) result = true
  })

  return result
}

export function getSeats(seats: number) {
  if (seats > 1000) return 'Unlimited'
  return seats.toString()
}

export function getDevices(devices: string[]) {
  if (devices.includes('web') && devices.includes('mobile')) {
    return 'Mobile + Web'
  }
  if (devices.includes('web')) {
    return 'Web'
  }
  if (devices.includes('mobile')) {
    return 'Mobile'
  }

  return ''
}

export function getTimelapse(milliseconds: number) {
  const seconds = Math.floor(milliseconds / 1000 / 60)
  return seconds
}

export function isHasTimelapseConstraint(plans: Plan[]) {
  let result = false
  plans.forEach((plans) => {
    if (plans.constraints['timelapse']) result = true
  })

  return result
}

export function isHasDevicesConstraint(plans: Plan[]) {
  let result = false
  plans.forEach((plan) => {
    if (plan.constraints['devices'] !== undefined) result = true
  })

  return result
}

export function isHasCompareImageConstraint(plans: Plan[]) {
  let result = false
  plans.forEach((plan) => {
    if (plan.constraints['compareImageSideBySide'] !== undefined) result = true
  })

  return result
}

export function isHasShareVideosAndImages(plans: Plan[]) {
  let result = false
  plans.forEach((plan) => {
    if (plan.constraints['shareVideosAndImages'] !== undefined) result = true
  })

  return result
}

export function isHasAnnotation360Image(plans: Plan[]) {
  let result = false
  plans.forEach((plan) => {
    if (plan.constraints['annotation360Image'] !== undefined) result = true
  })

  return result
}

export const Resolution = {
  1080: 'FullHD',
  1440: '2K',
  2160: '4K',
}

export function getSubscriptionPlan(
  subscriptionPlans: { [x: string]: SubscriptionPlan[] },
  subPlanId: string
): SubscriptionPlan | undefined {
  let result: SubscriptionPlan | undefined = undefined

  Object.keys(subscriptionPlans).forEach((planId) => {
    const data = subscriptionPlans[planId].find((subPlan) => {
      return subPlan.id === subPlanId
    })

    if (data) {
      result = data
    }
  })
  if (!result) {
    // throw new Error(`No subscription plan found with id ${subPlanId}`)
  }

  return result
}

export const isExpiredSubscription = (subscription: Subscription) => {
  return subscription.currentPeriodEnd && new Date(subscription.currentPeriodEnd) < new Date()
}
