import React from 'react';
import { EMobileNetworkStatus, EStationStatus } from 'src/types/station';
import StationNetworkSmall from './network_small';
import { getColorIconStation } from '../utils';
import StationNetworkMedium from './network_medium';
import theme from 'src/theme';
import { isStationLevelWarning } from 'src/utils/station';
import { useTranslation } from 'react-i18next';

type Props = {
  type: '3G' | '4G' | '5G';

  mobileNetworkOperator: string;
  mobileNetworkWaveStrength: number;
  stationStatus: EStationStatus;
  mobileNetworkConnectionStatus: EMobileNetworkStatus;
  size?: 'small' | 'medium';
};

export default function StationNetwork({
  type,
  mobileNetworkOperator,
  mobileNetworkWaveStrength,
  stationStatus,
  mobileNetworkConnectionStatus,
  size,
}: Props) {
  const { t } = useTranslation();
  const networkTooltip = mobileNetworkOperator
    ? t('label.mobileNetwork.operator', { operator: mobileNetworkOperator })
    : '';

  const getColorNetwork = () => {
    const stationColor = getColorIconStation(stationStatus);
    if (isStationLevelWarning(stationStatus)) return stationColor;
    if (
      stationStatus !== EStationStatus.OFFLINE &&
      mobileNetworkConnectionStatus === EMobileNetworkStatus.DISCONNECT
    )
      return theme.palette.error.main;

    return stationColor;
  };

  if (size === 'small')
    return (
      <StationNetworkSmall
        type={type}
        stationStatus={stationStatus}
        mobileNetworkWaveStrength={mobileNetworkWaveStrength}
        color={getColorNetwork()}
        title={networkTooltip}
        mobileNetworkConnectionStatus={mobileNetworkConnectionStatus}
      />
    );
  return (
    <StationNetworkMedium
      type={type}
      stationStatus={stationStatus}
      mobileNetworkWaveStrength={mobileNetworkWaveStrength}
      color={getColorNetwork()}
      title={networkTooltip}
      mobileNetworkConnectionStatus={mobileNetworkConnectionStatus}
    />
  );
}
