import React, { Fragment, useCallback, useEffect } from 'react';
import { MicOffOutlined, MicOutlined } from '@mui/icons-material';
import { isBoolean } from 'lodash';

import { IPlayerProps } from '../../../types/player';
import { toastMessage } from 'src/utils/toast';
import { getAudioDevice, getErrorMessage } from 'src/components/player_rtc/utils/video';
import { setError } from 'src/components/player_rtc/redux_store/player/player_slice';
import { store as playerStore } from './../../../redux_store/store';

import i18n from 'src/i18n';
import ButtonWrapper from '../button_wrapper';

function MicToggle(props: any) {
  const { actions, player, manager, cam, controlBar } = props as IPlayerProps;
  const { t } = i18n;

  const {
    playerSlice: { error },
  } = playerStore.getState();

  const dispatch = playerStore.dispatch;

  const handleClick = useCallback(
    async (insideClick?: boolean) => {
      const { video } = manager;

      const errorMessage = getErrorMessage({ pc: video?.rtc?.pc, playerError: player.error });

      if (!controlBar?.mic || errorMessage) return;

      const { toggleMic } = controlBar.mic;

      if (error?.id === 'audioError') {
        toggleMic && !insideClick && toggleMic();
        dispatch(setError(null));
        return toastMessage.error(error.message);
      }

      if (player.isAudioStarting) {
        actions.stopAudio();
        dispatch(setError(null));
      } else {
        if (!cam.isSupportAudio)
          return toastMessage.error(t('stream.controlBar.audio.isNotSupported'));

        if (insideClick) {
          const lcStream = await getAudioDevice();
          if (!lcStream) return;
        }

        actions.startAudio();
      }

      toggleMic && insideClick && toggleMic();
    },
    [player.isAudioStarting],
  );

  useEffect(() => {
    if (isBoolean(controlBar?.mic?.status)) {
      handleClick(false);
    }
  }, [controlBar?.mic?.status]);

  useEffect(() => {
    const { isAudioStarting } = player;
    if (error?.id === 'audioError' && isAudioStarting) {
      console.log('useEffect handleClick');
      handleClick(true);
    }
  }, [player, error]);

  if (!cam.isEnabledMic || !controlBar?.mic) return null;

  const controlText = player.isAudioStarting
    ? t('stream.controlBar.audio.off')
    : t('stream.controlBar.audio.on');

  return (
    <Fragment>
      <ButtonWrapper
        title={controlText}
        icon={player.isAudioStarting ? MicOutlined : MicOffOutlined}
        handleClick={() => handleClick(true)}
        disablePortal
      />
    </Fragment>
  );
}

export default MicToggle;
