import { createSlice } from '@reduxjs/toolkit';
import { IBlacklist, IBlacklistHistoryFilter, IBlackListItem } from 'src/types/blacklist';
import { ITransportationList } from 'src/types/transportation';

import {
  getBlackList,
  getTransportationByPlate,
  createBlackListItem,
  updateBlacklistById,
  deleteBlacklistById,
} from './blacklist_action';

interface IBlacklistState {
  selectedTransportation: string;
  selectedBlacklistItem: string;
  transportationList: ITransportationList;
  filters: IBlacklistHistoryFilter;
  blacklist: IBlacklist;
  filtersBlacklist: {
    plate: string;
    page: number;
    limit: number;
  };
  expanded: boolean;
}

const LIMIT = 20;
const initialState: IBlacklistState = {
  filtersBlacklist: {
    page: 1,
    plate: '',
    limit: LIMIT,
  },
  selectedTransportation: '',
  selectedBlacklistItem: '',
  transportationList: {
    data: [],
    totalPage: 0,
    totalRecord: 0,
  },
  filters: {
    endDate: null,
    startDate: null,
    plate: '',
    cameraIds: [],
    page: 1,
    limit: LIMIT,
  },
  expanded: true,
  blacklist: {
    data: [],
    totalPage: 0,
    totalRecord: 0,
  },
};

const blacklistSlice = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {
    setSelectedTransportation: (state, action) => {
      state.selectedTransportation = action.payload;
    },
    setExpanded: (state, action) => {
      state.expanded = action.payload;
    },
    setSelectedBlacklistItem: (state, action) => {
      state.selectedBlacklistItem = action.payload;
    },

    changeFilters: (state, action) => {
      state.filters = action.payload;
    },

    changeFiltersBlacklist: (state, action) => {
      state.filtersBlacklist = action.payload;
      state.transportationList = initialState.transportationList;
    },

    resetState: (state, action) => {
      if (action.payload) {
        state.filters = initialState.filters;
      }
      state.selectedTransportation = initialState.selectedTransportation;
      state.selectedBlacklistItem = initialState.selectedBlacklistItem;
      state.transportationList = initialState.transportationList;
    },

    setBlacklist: (state, action) => {
      state.blacklist.data.unshift(action.payload);
    },

    updateBlacklist: (state, action: { payload: IBlackListItem }) => {
      const { id } = action.payload;
      const cloneData = [...state.blacklist.data];
      const index = cloneData.findIndex((item) => item.id === id);
      if (index === -1) return;

      cloneData[index] = action.payload;

      state.blacklist.data = cloneData;
    },

    deleteBlacklistItem: (state, action) => {
      const cloneData = [...state.blacklist.data];
      const newData = cloneData.filter((item) => item.id !== action.payload);
      state.blacklist.data = newData;
      state.blacklist.totalPage = Math.ceil((state.blacklist.totalRecord - 1) / LIMIT);
      state.blacklist.totalRecord -= 1;
      state.selectedTransportation = '';
      state.selectedBlacklistItem = '';
      state.transportationList = initialState.transportationList;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getBlackList.fulfilled, (state, action) => {
        state.blacklist = action.payload;
        // state.selectedBlacklistItem = '';
      })
      .addCase(getTransportationByPlate.fulfilled, (state, action) => {
        state.transportationList = action.payload;
      })
      .addCase(createBlackListItem.fulfilled, (state, action) => {
        state.blacklist.data.unshift(action.payload);
        state.blacklist.totalRecord += 1;

        state.blacklist.totalPage = Math.ceil(state.blacklist.totalRecord / LIMIT);
        state.blacklist.data = state.blacklist.data.slice(0, LIMIT);
      })
      .addCase(deleteBlacklistById.fulfilled, (state, action) => {
        const cloneArray = [...state.blacklist.data];
        const newData = cloneArray.filter((item) => item.id !== action.payload);

        state.blacklist.data = newData;
      })
      .addCase(updateBlacklistById.fulfilled, (state, action) => {
        const { id } = action.payload;
        const cloneData = [...state.blacklist.data];
        const index = cloneData.findIndex((item) => item.id === id);
        if (index === -1) return;

        cloneData[index] = action.payload;

        state.blacklist.data = cloneData;
      });
  },
});

export const { actions, reducer } = blacklistSlice;
export const {
  setSelectedTransportation,
  setExpanded,
  changeFilters,
  resetState,
  setSelectedBlacklistItem,
  setBlacklist,
  updateBlacklist,
  deleteBlacklistItem,
  changeFiltersBlacklist,
} = actions;
export default reducer;
