import { ButtonProps, Theme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { EStationStatus } from 'src/types/station';
import { getColorIconStation } from '../icons/utils';

interface Props {
  status: EStationStatus;
}

interface ButtonBaseProps extends ButtonProps {
  status: EStationStatus;
}

export const CircleButton = styled(ButtonBase)<ButtonBaseProps>(({ theme, status }) => ({
  position: 'relative',
  height: 48,
  width: 48,
  borderRadius: 24,
  backgroundColor: theme.palette.common.white,
  color: getColorIconStation(status),
  borderColor: getColorIconStation(status),
  borderWidth: 1,
  borderStyle: 'solid',
}));

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  action: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    display: 'flex',
    gap: theme.spacing(2.5),
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  active: {
    backgroundColor: ({ status }) => `${getColorIconStation(status)} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));
