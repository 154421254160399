import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Container, TextField, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { getOrganization, updateOrganization } from 'src/redux_store/organizations/slice'
import { RequestStatusOptions } from 'src/types'
import { toastMessage } from 'src/utils/toast'

const Profile = () => {
  const { t } = useTranslation()
  const orgId = useParams().organization_id || ''
  const dispatch = useAppDispatch()
  const { requests } = useAppSelector((state) => state.organizations)

  const { control, setValue, setError, handleSubmit } = useForm({
    defaultValues: { name: '', email: '', phone: '', address: '', description: '' },
  })

  useEffect(() => {
    dispatch(getOrganization({ orgId }))
      .unwrap()
      .then((organization) => {
        const { name, email, phone, address, description } = organization
        setValue('name', name)
        setValue('email', email)
        setValue('phone', phone)
        setValue('address', address)
        setValue('description', description)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data: any) => {
    await dispatch(updateOrganization({ orgId, data }))
      .unwrap()
      .then((organization) => {
        const { name, email, phone, address, description } = organization
        setValue('name', name)
        setValue('email', email)
        setValue('phone', phone)
        setValue('address', address)
        setValue('description', description)
      })
      .then(() => {
        toastMessage.success(t('Success'))
      })
      .catch((err: any) => {
        if (!isEmpty(err?.errors)) {
          for (const key in err.errors) {
            setError(key as any, { message: err.errors[key]?.[0].message })
          }
        }
      })
  }

  if (requests.getOrganization.status === RequestStatusOptions.PENDING) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={10}>
        <CircularProgress />
      </Box>
    )
  }

  if (requests.getOrganization.status === RequestStatusOptions.REJECTED) {
    return (
      <Box>
        <Typography textAlign={'center'}>Something wrong!</Typography>
      </Box>
    )
  }

  return (
    <Box display={'flex'} flex={1}>
      <Container>
        <Box maxWidth={'sm'}>
          <Box p={2}>
            <Box>
              <Typography variant="h6">{t('label.general')}</Typography>
            </Box>
            <Box py={2}>
              <Box
                component={'form'}
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                mb={2}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <TextField
                      {...field}
                      label={t('organization.name')}
                      size="small"
                      error={invalid}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <TextField
                      {...field}
                      label={t('organization.email')}
                      size="small"
                      error={invalid}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <TextField
                      {...field}
                      label={t('organization.phone')}
                      size="small"
                      error={invalid}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <TextField
                      {...field}
                      label={t('organization.address')}
                      size="small"
                      error={invalid}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <TextField
                      {...field}
                      label={t('organization.description')}
                      size="small"
                      error={invalid}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
                <Box>
                  <LoadingButton type="submit" variant="contained">
                    {t('Update')}
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Profile
