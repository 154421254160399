import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    '& *': {
      color: 'white !important',
      border: 'none !important',
      textAlign: 'right',
    },
    '& label': {
      display: 'none',
    },
  },
}));
