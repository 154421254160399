import moment from 'moment';
import {
  IBlackListItem,
  IBlacklist,
  IBlacklistHistoryFilter,
  ITransportationHistorySearch,
} from 'src/types/blacklist';
import ClientBase from './base';
import { ITransportationList } from 'src/types/transportation';

export interface ClientBlacklistMix {
  getBlackList: (payload: { plate: string; page: number }) => Promise<IBlacklist>;
  getBlacklistHistory: (payload: IBlacklistHistoryFilter) => Promise<ITransportationList>;
  createBlackListItem: (payload: IBlackListItem) => Promise<IBlackListItem>;
  updateBlacklistById: (id: string, payload: IBlackListItem) => Promise<IBlackListItem>;
  getTransportationByPlate: (payload: ITransportationHistorySearch) => Promise<ITransportationList>;
  deleteBlacklistById: (id: string) => Promise<any>;
}

const ClientBlacklist = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientBlacklistMix {
    getBlackList = async (data: { plate: string; page: number }) => {
      return this.doFetch<IBlacklist>(`${this.getBlacklistRoute()}/blacklist`, {
        method: 'POST',
        data,
      });
    };

    getBlacklistHistory = async (data: IBlacklistHistoryFilter) => {
      return this.doFetch<ITransportationList>(`${this.getBlacklistRoute()}/blacklist/history`, {
        method: 'POST',
        data,
      });
    };

    createBlackListItem = async (data: IBlackListItem) => {
      return this.doFetch<IBlackListItem>(`${this.getBlacklistRoute()}/blacklist/add`, {
        method: 'POST',
        data: {
          ...data,
          lastDetected: moment().format(),
        },
      });
    };

    updateBlacklistById = async (id: string, data: IBlackListItem) => {
      return this.doFetch<IBlackListItem>(`${this.getBlacklistRoute()}/blacklist/${id}`, {
        method: 'PUT',
        data,
      });
    };

    getTransportationByPlate = async (data: ITransportationHistorySearch) => {
      return this.doFetch<ITransportationList>(`${this.getBlacklistRoute()}`, {
        method: 'POST',
        data,
      });
    };

    deleteBlacklistById = async (id: string) => {
      return this.doFetch(`${this.getBlacklistRoute()}/blacklist/${id}`, {
        method: 'DELETE',
      });
    };
  };

export default ClientBlacklist;
