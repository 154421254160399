import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material';

type TMuiButton = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

const MuiButton: TMuiButton = {
  styleOverrides: {
    root: {
      fontSize: '0.875rem',
      boxShadow: 'none',
      fontWeight: 600,
      textTransform: 'none',
    },
    containedInherit: {
      backgroundColor: 'white',
      color: '#3f50b5',
      borderColor: '#3f50b5',
    },
  },
};

export default MuiButton;
