import React from 'react';
import { GetAppOutlined } from '@mui/icons-material';

import { MODAL_IDS } from 'src/constants/modal';
import { useAppDispatch } from 'src/redux_store';
import { openModal } from 'src/redux_store/common/modal/modal_slice';
import { convertPosPixelToTime, hhmmss } from '../../chart_timeline/chart_horizontal_bar';
import ButtonWrapper from '../button_wrapper';
import ExportVideoModal from './export_video_modal';
import { useTranslation } from 'react-i18next';

function Extract() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleBackup = () => {
    const dateTime = getDateTime();
    dispatch(
      openModal({
        modalId: MODAL_IDS.player.exportVideo,
        dialogComponent: (
          <ExportVideoModal modalId={MODAL_IDS.player.exportVideo} dateTime={dateTime} />
        ),
      }),
    );
  };

  const getDateTime = () => {
    if (!window) return;
    const { currentTimePos = 0, startTime } = window.hls.media;
    const timeArraySplit = hhmmss(currentTimePos, 0).split(':');
    return convertPosPixelToTime(timeArraySplit, startTime, 0);
  };

  const controlText = t('button.export');
  return <ButtonWrapper title={controlText} icon={GetAppOutlined} handleClick={handleBackup} />;
}

export default Extract;
