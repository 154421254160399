import { createAsyncThunk } from '@reduxjs/toolkit';

import client from 'src/clients/http';
import { IRole } from 'src/types/role';
import { toastMessage } from 'src/utils/toast';

export const getOrderRoles = createAsyncThunk<IRole[], { customerId: string; orderId: string }>(
  'orderRoles/getOrderRoles',
  async ({ customerId, orderId }, { rejectWithValue }) => {
    try {
      const roles = await client.getOrderRoles(customerId, orderId);
      return roles;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const editOrderRole = createAsyncThunk<
  string,
  { customerId: string; orderId: string; orderRoleId: string }
>('orderRoles/editOrderRole', async ({ customerId, orderId, orderRoleId }, { rejectWithValue }) => {
  try {
    await client.editOrderRole(customerId, orderId, orderRoleId);
    return orderRoleId;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteOrderRole = createAsyncThunk<
  string,
  { customerId: string; orderId: string; orderRoleId: string }
>(
  'orderRoles/deleteOrderRole',
  async ({ customerId, orderId, orderRoleId }, { rejectWithValue }) => {
    try {
      await client.deleteOrderRole(customerId, orderId, orderRoleId);
      return orderRoleId;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
