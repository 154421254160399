/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import {
  IChangeNotificationSound,
  INotificationLevel,
  TAudioSoundType,
} from 'src/types/notification';
import { Grid, Typography, Switch, FormControlLabel } from '@mui/material';

import theme from 'src/theme';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { soundList } from 'src/constants/media';

import { useForm } from 'react-hook-form';
import { changeNotificationSound } from 'src/redux_store/notification/notification_action';
import { FormAutocomplete } from 'src/components/hook_form';
type Props = {
  audioSetting: { name: string; type: TAudioSoundType };
};

type TSound = {
  id: string;
  name: string;
  source: string;
};

const NotificationSettingAudioItem = (props: Props) => {
  const { audioSetting } = props;
  const {
    notificationSetting: { notificationLevels },
  } = useAppSelector((state) => state.notificationSlice);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const dispatch = useAppDispatch();
  const [soundDefault, setSoundDefult] = useState<TSound>(soundList[0]);
  const [checked, setChecked] = useState<boolean>(false);

  const { control, setValue } = useForm({
    defaultValues: {
      sound: soundList[0] as TSound,
    },
  });
  useEffect(() => {
    const currentNotificationLevel =
      notificationLevels[audioSetting.type as keyof INotificationLevel];

    const soundId = currentNotificationLevel.soundRingtone;
    const soundItem = soundList.find((sound) => sound.id === soundId);

    setSoundDefult(soundItem || soundList[0]);
    setValue('sound', soundItem || soundList[0]);
    setChecked(currentNotificationLevel.status || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSound = (name: string, value: TSound) => {
    const current = audioRef.current;
    if (current) {
      current.src = value.source;
      current.play();
    }

    const currentNotificationLevel =
      notificationLevels[audioSetting.type as keyof INotificationLevel];

    const payload: IChangeNotificationSound = {
      data: {
        notificationLevel: {
          [audioSetting.type]: {
            soundRingtone: value.id,
            status: currentNotificationLevel.status,
          },
        },
      },
    };

    dispatch(changeNotificationSound(payload));
  };

  const handleChangeStatusAudio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setChecked(checked);

    const currentNotificationLevel =
      notificationLevels[audioSetting.type as keyof INotificationLevel];

    const payload: IChangeNotificationSound = {
      data: {
        notificationLevel: {
          [audioSetting.type]: {
            soundRingtone: currentNotificationLevel.soundRingtone,
            status: checked,
          },
        },
      },
    };

    dispatch(changeNotificationSound(payload));
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p={1}
      pr={0}
      borderBottom={`1px solid ${theme.palette.secondary.light}`}
    >
      <Grid item md={10}>
        <Grid container columnSpacing={1} alignItems="center">
          <Grid item md={2}>
            <Typography>{audioSetting.name}</Typography>
          </Grid>
          <Grid item md={4}>
            <FormAutocomplete
              control={control}
              label="Âm thanh thông báo"
              name="sound"
              labelOption="name"
              keyOption="id"
              options={soundList}
              disableClearable
              handleChange={handleChangeSound}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChangeStatusAudio} />}
          label={checked ? 'Bật' : 'Tắt'}
          labelPlacement="start"
        />
      </Grid>
      <audio src={soundDefault.source} style={{ display: 'none' }} ref={audioRef}>
        <source src={soundDefault.source} />
      </audio>
    </Grid>
  );
};

export default NotificationSettingAudioItem;
