import React from 'react';
import { HighlightOffOutlined, InfoOutlined, WarningAmberOutlined } from '@mui/icons-material/';
import { Box, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import parse from 'html-react-parser';
import moment from 'moment';
import { MdOutlineNewReleases } from 'react-icons/md';

import { INotification } from 'src/types/notification';
import { useStyles } from './styles';
import { momentLocale } from 'src/utils/common';

interface ILogsItemProps {
  notification: INotification;
}

const LogsItem = (props: ILogsItemProps) => {
  const { notification } = props;

  const classes = useStyles();

  const renderIconNotification = () => {
    if (!notification) return;
    const { level } = notification;
    if (level === 'ERROR') return <HighlightOffOutlined fontSize="large" color="error" />;
    if (level === 'INFO') return <InfoOutlined fontSize="large" color="primary" />;
    if (level === 'CRITICAL') return <MdOutlineNewReleases size={36} color="#ac430c" />;
    return <WarningAmberOutlined fontSize="large" color="warning" />;
  };

  return (
    <Box className={classNames(classes.root, classes.flex)}>
      <Box minHeight={50} position="relative">
        <Grid container justifyContent="space-between">
          <Grid item md={11}>
            <Grid container alignItems="center">
              <Grid item md={2}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  {renderIconNotification()}
                </Box>
              </Grid>
              <Grid item md={10}>
                <Box>
                  <Typography
                    className={!notification.isRead ? classes.hightLightText : classes.normalText}
                  >
                    {parse(notification.title.content || '')}
                  </Typography>
                </Box>

                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  wrap="nowrap"
                  mt={0.5}
                  columnSpacing={0.5}
                >
                  <Grid item>
                    <Typography variant="caption">
                      {momentLocale(notification.time).format('DD-MM-yyyy HH:mm:ss')}
                    </Typography>
                  </Grid>
                  {notification.description.content && (
                    <Grid item>
                      <InfoOutlined fontSize="small" color="primary" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LogsItem;
