import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'src/components/modal/confirm_dialog';

import { MODAL_IDS } from 'src/constants/modal';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import {
  deleteNotification,
  getAllNotificationMain,
} from 'src/redux_store/notification/notification_action';
import {
  hiddenInfoSelect,
  // selectNotificationItem,
} from 'src/redux_store/notification/notification_slice';
import { toastMessage } from 'src/utils/toast';

const DeleteNotificationModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { idNotificationSelected, mainPayload } = useAppSelector(
    (state) => state.notificationSlice,
  );

  const handleDelete = () => {
    dispatch(deleteNotification(idNotificationSelected))
      .unwrap()
      .then(() => {
        toastMessage.success(t('notification.label.deleteSuccess'));
        dispatch(closeModal({ modalId: MODAL_IDS.deleteNotification }));
        dispatch(hiddenInfoSelect());

        dispatch(getAllNotificationMain(mainPayload));
      });
  };

  return (
    <ConfirmationDialog
      modalId={MODAL_IDS.deleteNotification}
      sliceName="notification"
      functionName="deleteNotification"
      describe={t('notification.label.delete')}
      callback={handleDelete}
      okLabel={t('button.agree')}
      //   cancelLabel="Hủy bỏ"
    />
  );
};

export default DeleteNotificationModal;
