export interface IVideoELClass extends HTMLVideoElement {
  currentTimeOvernightDefault: number;
  currentTimePos: number;
  endTime: Date;
  isEndDay?: boolean;
  isOvernight?: boolean;
  ms?: number;
  path: string;
  startTime: Date;
  videoStartTimeDefault: number;
  handlePause: () => void;
  handlePlay: () => void;
}

export enum KeyCodeEnum {
  SPACE = 32,
  NAVIGATE_NEXT = 39,
  NAVIGATE_PREVIOUS = 37,
}
