import React from 'react';
import { Box } from '@mui/material';
import { Navigate, Route, Routes as Router } from 'react-router-dom';

import Loading from 'src/components/loading';
import { ROUTE_LIST } from './route_list';
import { IRoute } from 'src/types/route';
import { useAppSelector } from 'src/redux_store';
import _ from 'lodash';
import { ROLE_LEVEL } from 'src/constants/role';
import NotFound from 'src/components/not_found';
import { PATH } from './path';
import { getParamToNavigate } from 'src/utils/helper';

const Routes = () => {
  const { me, role } = useAppSelector((state) => state.myAccountSlice);
  const renderRouter = (route: IRoute) => {
    const Page = route.component;
    const permissions = role?.permissions || [];
    const isShow = !_.isEmpty(_.intersection(route.permission, permissions));

    if (
      route.isHaveRoute &&
      (role?.level === ROLE_LEVEL.SUPER_ADMIN || !route.permission || (isShow && route.permission))
    ) {
      return <Page />;
    }

    return <NotFound />;
  };

  return (
    <Router>
      {_.isEmpty(me) || _.isEmpty(role) ? (
        <Route path="/" element={<Navigate to={PATH.login} replace />} />
      ) : (
        <Route path="/" element={<Navigate to={getParamToNavigate(me, role)} replace />} />
      )}
      {ROUTE_LIST.map((route) => {
        const Layout = route.layout ? route.layout : React.Fragment;

        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Layout>
                <React.Suspense
                  fallback={
                    <Box flex={1}>
                      <Loading />
                    </Box>
                  }
                >
                  {renderRouter(route)}
                </React.Suspense>
              </Layout>
            }
          />
        );
      })}
    </Router>
  );
};

export default Routes;
