import React from 'react';
import { CameraAltOutlined } from '@mui/icons-material';

import { IPlayerProps } from '../../../types/player';
import i18n from 'src/i18n';
import ButtonWrapper from '../button_wrapper';

function Snapshot(props: any) {
  const { actions, player, manager, controlBar } = props as IPlayerProps;
  const { video } = manager;
  const { t } = i18n;

  if (controlBar?.hideSnapshot) return null;

  const handleClick = () => {
    if (player.error || !video?.rtc?.pc) return;
    actions.snapshot();
  };

  const controlText = t('stream.controlBar.snapshot');
  return <ButtonWrapper title={controlText} icon={CameraAltOutlined} handleClick={handleClick} />;
}

export default Snapshot;
