import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { initStreamFormToolbar } from 'src/constants/watchlist';
import { ICameraFilter } from 'src/types/camera';
import { IRtcStream, IStreamFormToolbar } from 'src/types/stream';
import {
  addFollowGroup,
  addSingleCamToWatchlist,
  deleteFollowGroup,
  getFollowGroups,
  getLiveStreamingCams,
  removeSingleCamFromWatchlist,
} from './watchlist_action';
import { initCameraFilter } from 'src/constants/filter';
import i18n from 'src/i18n';

interface IPlayerError {
  networkIssue?: boolean;
  peerConnectionLimit?: boolean;
  rapidClick?: boolean;
}

interface IWatchlistSlice {
  cameras: any[];
  cameraFollowed: IRtcStream[];
  totalPage: number;
  groups: { id: string; name: string }[];
  toolbar: IStreamFormToolbar;
  cameraFilter: ICameraFilter;
  lastClickTime: number;
  timeline: {
    dateTime: Date;
    posPixel: number;
  };
  error: IPlayerError;
}

const initialState: IWatchlistSlice = {
  cameras: [],
  cameraFollowed: [],
  totalPage: 1,
  groups: [],
  lastClickTime: 0,
  toolbar: { ...initStreamFormToolbar },
  cameraFilter: initCameraFilter,
  timeline: {
    dateTime: new Date(new Date().setHours(0, 0, 0, 0)),
    posPixel: 0,
  },
  error: {
    networkIssue: false,
    peerConnectionLimit: false,
    rapidClick: false,
  },
};

const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    changeParamsFormToolbar: (state, action) => {
      state.toolbar = { ...state.toolbar, ...action.payload };
      const currentTime = Date.now();

      if (currentTime - state.lastClickTime < 1000) {
        state.error.rapidClick = true;
      } else {
        state.error.rapidClick = false;
      }

      state.lastClickTime = currentTime;
    },
    resetWatchlistState: (state) => {
      state.cameraFollowed = [];
      state.groups.length = 0;
      state.toolbar = { ...initStreamFormToolbar };
    },
    resetCameraFollowed: (state) => {
      state.cameraFollowed.length = 0;
    },
    addTotalPage: (state, action) => {
      if (action.payload) {
        state.totalPage = action.payload;
      } else {
        state.totalPage += 1;
      }
      state.toolbar.page = state.totalPage;
      state.cameraFollowed.length = 0;
    },
    changeParamsCameraFilter: (state, action) => {
      state.cameraFilter = isEmpty(action.payload)
        ? initialState.cameraFilter
        : { ...state.cameraFilter, ...action.payload };
    },

    setTotalPage: (state, action) => {
      state.totalPage = action.payload || 1;
    },

    setTimeline(
      state,
      action: PayloadAction<{
        dateTime: Date;
        posPixel: number;
      }>,
    ) {
      state.timeline.dateTime = action.payload.dateTime;
      state.timeline.posPixel = action.payload.posPixel;
    },

    setError: (state, action: PayloadAction<IPlayerError>) => {
      state.error = action.payload;
    },

    removeWatchListPositionByListCameraId: (
      state: IWatchlistSlice,
      action: PayloadAction<string[]>,
    ) => {
      const currentData = [...state.cameraFollowed];
      const cameraIds = action.payload;
      const newData = currentData.filter((item) => !cameraIds.includes(item.camera?.id));

      state.cameraFollowed = newData;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLiveStreamingCams.pending, (state) => {
        state.error.rapidClick = false;
      })
      .addCase(getLiveStreamingCams.fulfilled, (state, action) => {
        state.cameraFollowed = action.payload.data;
        // state.totalPage = action.payload.totalPage || 1;
      })
      .addCase(addSingleCamToWatchlist.fulfilled, (state, action) => {
        state.cameraFollowed.push(action.payload);
      })
      .addCase(removeSingleCamFromWatchlist.fulfilled, (state, action) => {
        state.cameraFollowed = state.cameraFollowed.filter(
          (camera) =>
            camera.position !== action.payload.position &&
            camera.classifiedListId === action.payload.groupId,
        );
      })
      .addCase(getFollowGroups.pending, (state) => {
        state.error.rapidClick = false;
      })
      .addCase(getFollowGroups.fulfilled, (state, action) => {
        state.groups = [{ id: 'all', name: i18n.t('label.level.all') }, ...action.payload];
      })
      .addCase(addFollowGroup.fulfilled, (state, action) => {
        state.groups.push(action.payload);
        state.toolbar.page = 1;
        state.toolbar.group = action.payload;
        state.totalPage = 1;
        state.cameraFollowed.length = 0;
      })
      .addCase(deleteFollowGroup.fulfilled, (state, action) => {
        state.groups = state.groups.filter((group) => group.id !== action.payload);

        if (state.groups.length) {
          state.toolbar.page = 1;
          state.toolbar.group = state.groups[0];
        }
      });
  },
});

const { actions, reducer } = watchlistSlice;
export const {
  changeParamsFormToolbar,
  resetWatchlistState,
  changeParamsCameraFilter,
  addTotalPage,
  setTimeline,
  resetCameraFollowed,
  setError,
  removeWatchListPositionByListCameraId,
  setTotalPage,
} = actions;
export default reducer;
