// import { IFilterStation } from 'src/types/station';
import { EScheduledDay, EStationStatus, EWorkingMode } from 'src/types/station';
import { isStationLevelWarning } from 'src/utils/station';

export const initFilterStation: any = {
  keyword: '',
  province: [],
  district: [],
  commune: [],
  customerId: [],
};

export const ScheduledDays = (t: any) => [
  {
    id: EScheduledDay.MONDAY,
    name: t('label.workingMode.monday'),
  },
  {
    id: EScheduledDay.TUESDAY,
    name: t('label.workingMode.tuesday'),
  },
  {
    id: EScheduledDay.WEDNESDAY,
    name: t('label.workingMode.wednesday'),
  },
  {
    id: EScheduledDay.THURSDAY,
    name: t('label.workingMode.thursday'),
  },
  {
    id: EScheduledDay.FRIDAY,
    name: t('label.workingMode.friday'),
  },
  {
    id: EScheduledDay.SATURDAY,
    name: t('label.workingMode.saturday'),
  },
  {
    id: EScheduledDay.SUNDAY,
    name: t('label.workingMode.sunday'),
  },
];

export const Mode = (t: any) => [
  {
    id: EWorkingMode.FULLTIME,
    name: t('label.workingMode.fulltime'),
  },
  {
    id: EWorkingMode.SCHEDULED,
    name: t('label.workingMode.scheduled'),
  },
  {
    id: EWorkingMode.CHECKIN,
    name: t('label.workingMode.checkin'),
  },
];

export const getStatusStationColor = (status?: EStationStatus) => {
  if (!status) return 'default';

  if (status === EStationStatus.NORMAL) return 'success';
  if (isStationLevelWarning(status)) return 'warning';
  if (status === EStationStatus.OFFLINE) return 'default';
};
