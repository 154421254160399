import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  title?: string;
  sx?: SxProps;
}

const EmptyMessage = ({ title, sx }: IProps) => {
  const { t } = useTranslation();
  return (
    <Box textAlign="center" p={2} sx={sx}>
      <Typography component="p">{title || `-- ${t('label.emptyData.main')}--`} </Typography>
    </Box>
  );
};

export default EmptyMessage;
