import React from 'react';
import { Typography } from '@mui/material';

import { VideoWrapper } from '../styles';
import { ECameraStatus } from 'src/types/camera';
import { useTranslation } from 'react-i18next';

function VideoStatus({ status, error }: { status: ECameraStatus; error?: { message: string } }) {
  const { t } = useTranslation();
  let des = '';

  if (status === ECameraStatus.ERROR) {
    des = t('label.status.camera.error');
  }

  if (status === ECameraStatus.OFFLINE) {
    des = t('label.status.camera.offline');
  }

  if (error) {
    des = error.message;
  }

  return (
    <VideoWrapper>
      <Typography
        position="absolute"
        top="50%"
        left="50%"
        color="white"
        variant="body2"
        sx={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        {des}
      </Typography>
    </VideoWrapper>
  );
}

export default VideoStatus;
