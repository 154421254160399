import { Box } from '@mui/material';
import CameraList from 'src/components/list/camera_list';
import { ICamera } from 'src/types/camera';
import { IStation } from 'src/types/station';

interface IProps {
  station: IStation;
  camera: ICamera | null;
  setCamera: (cam: ICamera | null, stationId: string) => void;
}

const Cameras = ({ station, camera, setCamera }: IProps) => {
  return (
    <Box display="flex" flex={1} py={1}>
      <CameraList
        cameras={station.cameras || []}
        selectedId={camera ? camera.id : ''}
        isLoading={false}
        isError={false}
        selectCamera={(cam) => setCamera(cam, station.id)}
        onRefresh={() => {}}
      />
    </Box>
  );
};

export default Cameras;
