import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import SwitchWithText from 'src/components/switch_with_text';
import theme from 'src/theme';

import {
  MainNotificationSetting,
  SubNotificationSetting,
  AccordionNotificationSetting,
} from './styles';
import { INotificationSource, INotINotificationSourceStatus } from 'src/types/notification';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/redux_store';
import { changeNotificationSource } from 'src/redux_store/notification/notification_action';
import { isVMSCloud } from 'src/utils/common';

type Props = {
  settingItem: INotificationSource;
  expanded: string | false;
  index: number | string;
  setExpanded: React.Dispatch<React.SetStateAction<string | false>>;
};

function NotificationSettingSourceItem(props: Props) {
  const { settingItem, expanded, index, setExpanded } = props;
  const { t } = useTranslation();
  const [status, setStatus] = useState<INotINotificationSourceStatus>({
    emailNotification: false,
    mobileNotification: false,
    sound: false,
    webNotification: false,
    title: '',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const { sound, emailNotification, mobileNotification, webNotification, title } = settingItem;
    const cloneSettingItem = {
      sound,
      emailNotification,
      mobileNotification,
      webNotification,
      title,
    };
    for (const key in cloneSettingItem) {
      setStatus((prev) => ({
        ...prev,
        [key]: cloneSettingItem[key as keyof INotINotificationSourceStatus],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeExpanded = () => () => {
    if (expanded === `panel${index}`) {
      setExpanded(false);
    } else {
      setExpanded(`panel${index}`);
    }
  };

  const handleChangeStatusSource = (name: string, checked: boolean) => {
    const newStatus = { ...status, [name]: checked };

    setStatus(newStatus);

    const newSettingItem = { ...settingItem, [name]: checked };

    const payload = {
      data: {
        notificationSource: newSettingItem,
      },
    };

    dispatch(changeNotificationSource(payload));
  };

  const getSettingItem = () => {
    const { sound, emailNotification, mobileNotification, webNotification, source } = settingItem;

    if (isVMSCloud) return { sound, webNotification, emailNotification, mobileNotification };

    if (source === 'Blacklist') return { sound, webNotification, emailNotification };
    return { sound, webNotification };
  };

  const renderSettingItem = () => {
    const cloneSettingItem = getSettingItem();
    const element = [];

    for (const key in cloneSettingItem) {
      const statusSwitch = status[key as keyof INotINotificationSourceStatus];

      if (typeof statusSwitch !== 'boolean') return;
      element.push(
        <Grid key={key} item borderBottom={`2px solid ${theme.palette.secondary.light}`}>
          <SwitchWithText
            title={t(`sourceNotification.${key}`)}
            statusSwitch={statusSwitch}
            name={key}
            onChange={handleChangeStatusSource}
          />
        </Grid>,
      );
    }

    return element;
  };

  return (
    <AccordionNotificationSetting
      sx={{
        boxShadow: 'none',
        margin: '8px 0px',
      }}
      square
      expanded={expanded === `panel${index}`}
      onChange={handleChangeExpanded()}
    >
      <MainNotificationSetting
        expandIcon={<ExpandMore />}
        aria-controls={`panel${index}a-content`}
        id="panel1a-header"
      >
        <Typography>{settingItem.title}</Typography>
      </MainNotificationSetting>
      <SubNotificationSetting>
        <Box mb={theme.spacing(3)}>
          <Grid container flexDirection="column" spacing={1}>
            {/* <Grid item borderBottom={`2px solid ${theme.palette.secondary.light}`}>
              <SwitchWithText title="Thông báo qua web" statusSwitch={true} />
            </Grid>
            <Grid item borderBottom={`2px solid ${theme.palette.secondary.light}`}>
              <SwitchWithText title="Email" statusSwitch={false} />
            </Grid>

            <Grid item borderBottom={`2px solid ${theme.palette.secondary.light}`}>
              <SwitchWithText
                title="Âm thanh"
                statusSwitch={true}
              
              />

          
            </Grid>
            <Grid item borderBottom={`2px solid ${theme.palette.secondary.light}`}>
              <SwitchWithText title="Thông báo đẩy" statusSwitch={true} />
            </Grid> */}
            {renderSettingItem()}
          </Grid>
        </Box>
      </SubNotificationSetting>
    </AccordionNotificationSetting>
  );
}

export default NotificationSettingSourceItem;
