import { IPagination } from 'src/types/common';
import ClientBase from './base';
import { IRole, IRoleData, IRoleFilter } from 'src/types/role';
import { IUser } from 'src/types/auth';

export interface ClientRoleMix {
  createRole: (
    data: Pick<IRole, 'name' | 'permissions' | 'schemes' | 'description'>,
  ) => Promise<IRole>;
  getAllRoles: () => Promise<IRole[]>;
  searchRoles: (data: IRoleFilter) => Promise<IPagination<IRoleData>>;
  getUsersInRole: (roleId: string) => Promise<IUser[]>;
  getRole: (roleId: string) => Promise<IRole>;
  updateRole: (
    roleId: string,
    data: Pick<IRole, 'name' | 'permissions' | 'schemes' | 'description'>,
  ) => Promise<IRole>;
  deleteRole: (roleId: string) => Promise<string>;
}

const ClientRole = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientRoleMix {
    createRole = async (data: Pick<IRole, 'name' | 'permissions' | 'schemes' | 'description'>) => {
      const { permissions, ...rest } = data;
      return this.doFetch<IRole>(`${this.getRoleRoute()}`, {
        method: 'post',
        data: {
          ...rest,
          permissions: permissions,
        },
      });
    };
    searchRoles = async (data: IRoleFilter) => {
      return this.doFetch<IPagination<IRoleData>>(`${this.getRoleRoute()}`, {
        method: 'get',
        params: data,
      });
    };
    getAllRoles = async () => {
      return this.doFetch<IRole[]>(`${this.getRoleRoute()}`, {
        method: 'get',
        params: { page: 1, limit: 100 },
      });
    };
    getUsersInRole = async (roleId: string) => {
      return this.doFetch<IUser[]>(`${this.getRoleRoute()}/${roleId}/users`, {
        method: 'get',
      });
    };
    getRole = async (roleId: string) => {
      return this.doFetch<IRole>(`${this.getRoleRoute()}/${roleId}`, {
        method: 'get',
      });
      // return this.doFetch<IRole>(`http://10.49.46.54:9003/api/v1/admin/roles/${roleId}`, {
      //   method: 'get',
      // });
    };
    updateRole = async (
      roleId: string,
      data: Pick<IRole, 'name' | 'permissions' | 'schemes' | 'description'>,
    ) => {
      return this.doFetch<IRole>(`${this.getRoleRoute()}/${roleId}`, {
        method: 'put',
        data: {
          ...data,
          permissions: data.permissions,
        },
      });
    };
    deleteRole = async (roleId: string) => {
      return this.doFetch<string>(`${this.getRoleRoute()}/${roleId}`, {
        method: 'delete',
      });
    };
  };

export default ClientRole;
