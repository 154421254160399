import { Box, Divider, FormLabel, Stack, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduledDays } from 'src/constants/station';
import { WorkingSchedule } from 'src/types/station';

interface IProps {
  schedule: WorkingSchedule;
}

const WorkingDayInfo = ({ schedule }: IProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {ScheduledDays(t).map((item) => (
        <Box width="100%" key={item.id}>
          <Box display="flex" p={1}>
            <Box flexShrink={0} width={120}>
              {/* <FormControlLabel
                // disabled={true}
                control={<Checkbox checked={schedule[item.id].length > 0} />}
                label={item.name}
              /> */}
              <FormLabel sx={{ color: 'text.primary' }}>{item.name}</FormLabel>
            </Box>
            {schedule[item.id].length > 0 && (
              <Stack gap={1} flex={1}>
                {schedule[item.id].map((field, index) => {
                  const start = moment()
                    .set({
                      hour: field.startHour,
                      minute: field.startMinute,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString();
                  const end = moment()
                    .set({
                      hour: field.endHour,
                      minute: field.endMinute,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString();

                  return (
                    <Box key={index} display="flex" alignItems="center" gap={1}>
                      <Box display="flex" flex={1} gap={1}>
                        <TimePicker
                          // disabled={true}
                          readOnly
                          views={['hours', 'minutes']}
                          value={start}
                          ampm={false}
                          onChange={() => {}}
                          renderInput={(props: any) => (
                            <TextField
                              {...props}
                              size="small"
                              // disabled={true}
                              fullWidth
                              type="text"
                              label={t('label.start')}
                              variant="outlined"
                            />
                          )}
                        />

                        <TimePicker
                          views={['hours', 'minutes']}
                          value={end}
                          onChange={() => {}}
                          // disabled={true}
                          readOnly
                          ampm={false}
                          renderInput={(props: any) => (
                            <TextField
                              {...props}
                              size="small"
                              // disabled={true}
                              fullWidth
                              type="text"
                              label={t('label.end')}
                              variant="outlined"
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Stack>
            )}
          </Box>
          <Divider />
        </Box>
      ))}
    </Fragment>
  );
};

export default WorkingDayInfo;
