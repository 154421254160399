/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import { Box, CircularProgress, Divider } from '@mui/material';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Cookies from 'js-cookie';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import BoxRightContent from 'src/components/box_right_content';
import NotificationItem from 'src/components/notification/notification_item';
import ViewDetailNotificationDrawer from 'src/components/notification/view_detail_drawer';
import Sidebar from 'src/components/sidebar';
import SidebarSmall from 'src/components/sidebar_small';
import Topbar from 'src/components/topbar';
import { soundList } from 'src/constants/media';
import { ROLE_LEVEL } from 'src/constants/role';
import { useIsRequestPending } from 'src/hooks';
import store, { useAppDispatch, useAppSelector } from 'src/redux_store';
import { openModal, resetModal } from 'src/redux_store/common/modal/modal_slice';
import { closeBoxRightContent } from 'src/redux_store/common/topbar/topbar_slice';
import { markAllAsSeenNotification } from 'src/redux_store/notification/notification_action';
import { PATH } from 'src/routes/path';
import { ROUTE_LIST } from 'src/routes/route_list';
import { INotificationLevel } from 'src/types/notification';
import { isVMSCloud } from 'src/utils/common';
import { useStyles } from './style';
import { ws } from 'src/clients/websocket_new';
import { MODAL_IDS } from 'src/constants/modal';
import ModalReloadPage from 'src/components/modal/reload_page';
import ModalAutoLogout from 'src/components/modal/auto_lougout';

const AdminLayout = (props: PropsWithChildren) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const token = Cookies.get('USER_SESSION');

  const {
    role,
    token: userToken,
    isReloadPageEventSocket,
    autoLogout: { isAutoLogout },
  } = useAppSelector((state) => state.myAccountSlice);
  const { openRightContent, typeContentShow } = useAppSelector((state) => state.topBarSlice);
  const { numberOfNotifications, infoNotificationToast, notificationSetting } = useAppSelector(
    (state) => state.notificationSlice,
  );

  const isLoadingGetMe = useIsRequestPending('myAccount', 'getMe');
  const isLoadingGetMyRole = useIsRequestPending('myAccount', 'getMyRole');

  const [count, setCount] = useState(0);
  const [tabHasFocus, setTabHasFocus] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const toggleSidebar = () => setOpen((prev) => !prev);

  useEffect(() => {
    if (userToken) {
      ws.initialize(userToken);
    }
  }, [userToken]);

  useEffect(() => {
    if (isReloadPageEventSocket) {
      dispatch(openModal({ modalId: MODAL_IDS.reloadPage, dialogComponent: <ModalReloadPage /> }));
    }
  }, [isReloadPageEventSocket]);

  useEffect(() => {
    if (isAutoLogout) {
      dispatch(openModal({ modalId: MODAL_IDS.autoLogout, dialogComponent: <ModalAutoLogout /> }));
    }
  }, [isAutoLogout]);

  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
      }
      dispatch(closeBoxRightContent());
    };
  }, []);

  useEffect(() => {
    return () => {
      const { modalSlice } = store.getState();

      if (!_.isEmpty(modalSlice)) {
        dispatch(resetModal());
      }
    };
  }, [location]);

  const checkPlaySound = (): boolean => {
    if (_.isEmpty(infoNotificationToast) || _.isEmpty(notificationSetting)) return false;

    const { placeOfOrigin } = infoNotificationToast;
    const { notificationSources } = notificationSetting;
    const notificationItemSource = notificationSources.find(
      (item) => item.source === placeOfOrigin,
    );

    if (!_.isEmpty(notificationItemSource)) {
      const sound = notificationItemSource.sound;

      return sound;
    }

    return true;
  };

  const handleShowPopup = () => {
    const { popupStatus } = notificationSetting;

    if (!popupStatus) return;

    toast(<NotificationItem notification={infoNotificationToast} isToastNotification />);
  };

  const handleClickSound = async () => {
    if (audioRef.current) {
      const levelNotification = infoNotificationToast.level.toLowerCase();
      const notificationLevels = notificationSetting.notificationLevels;
      const notificationLevelItem =
        notificationLevels[levelNotification as keyof INotificationLevel];

      if (
        notificationLevelItem.status &&
        notificationLevelItem.soundRingtone !== 'OFF' &&
        checkPlaySound()
      ) {
        const sound =
          soundList.find((item) => item.id === notificationLevelItem.soundRingtone) || soundList[0];
        audioRef.current.src = sound.source;
        await audioRef.current?.play();
      }
    }
  };

  const renderTitleSystem = () => {
    document.title = isVMSCloud ? 'DANAPOD' : 'Camera Hội An';
  };

  const handleResetTitle = () => {
    renderTitleSystem();
    setCount(0);
  };

  const handleFocus = () => {
    setTabHasFocus(true);
  };

  const handleBlur = () => {
    setTabHasFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    renderTitleSystem();
  }, []);

  useEffect(() => {
    let timer: any;
    if (tabHasFocus) {
      handleResetTitle();
    } else {
      if (numberOfNotifications > 0) {
        timer = setInterval(() => {
          if (count % 2 === 0) {
            document.title = `Bạn có ${numberOfNotifications} thông báo mới`;
          } else {
            renderTitleSystem();
          }

          setCount(count + 1);
        }, 1500);
      } else {
        handleResetTitle();
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [numberOfNotifications, count, tabHasFocus]);

  useEffect(() => {
    if (!_.isEmpty(infoNotificationToast)) {
      handleClickSound();
      handleShowPopup();
      if (
        (openRightContent && typeContentShow === 'notification') ||
        location.pathname === PATH.notification
      ) {
        dispatch(markAllAsSeenNotification());
      }
    }
  }, [infoNotificationToast]);

  const getTitle = () => {
    if (!location.pathname) return '';
    const path = location.pathname;
    const route = ROUTE_LIST.find((route) => route.path === path);
    const titleKey = route?.name;

    const permission = route?.permission;
    const isShow = _.isEmpty(_.intersection(permission, role.permissions));

    if (
      (role.level === ROLE_LEVEL.SUPER_ADMIN || !isShow || _.isEmpty(route?.permission)) &&
      titleKey
    ) {
      return t(titleKey);
    }

    return '';
  };

  // loading get me action
  if (isLoadingGetMe || isLoadingGetMyRole) {
    return (
      <Box className={classes.center}>
        <CircularProgress color="error" />
      </Box>
    );
  }

  // if token not found redirect to login page
  if (!token) {
    return <Navigate to={PATH.login} state={{ from: location }} replace />;
  }

  return (
    <Box className={classes.root}>
      <Topbar title={getTitle()} />
      <Divider />
      <Box className={classes.container}>
        {open ? (
          <Sidebar toggleSidebar={toggleSidebar} />
        ) : (
          <SidebarSmall toggleSidebar={toggleSidebar} />
        )}
        <Divider orientation="vertical" />
        <Box className={classes.wrapper}>
          <Box className={classes.page}>{props.children}</Box>
          {openRightContent && <BoxRightContent />}
        </Box>
      </Box>

      <audio ref={audioRef} style={{ display: 'none' }}>
        <source src="" type="audio/mp3" />
      </audio>

      <ViewDetailNotificationDrawer />
    </Box>
  );
};

export default AdminLayout;
