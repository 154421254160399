import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#000',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'space-between',
  },
  rewind: {
    width: 100,
    height: 100,
    background: '#00000082',
    zIndex: 999,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '15%',
    borderRadius: '50%',
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    '& > div': {
      '& svg:nth-child(1)': {
        animation: '$rewindIcon 0.6s ease-in-out infinite',
      },
      '& svg:nth-child(2)': {
        animation: '$rewindIcon 0.8s ease-in-out infinite',
      },
      '& svg:nth-child(3)': {
        animation: '$rewindIcon 1s ease-in-out infinite',
      },
    },
  },
  next: {
    right: '15%',
    display: 'flex',
  },
  previous: {
    left: '15%',
    display: 'flex',
  },

  '@keyframes rewindIcon': {
    '0%': {
      opacity: 0.5,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.5,
    },
  },
}));
