export const MODE_USER_OPTIONS: { value: string; name: string }[] = [
  {
    name: '',
    value: 'system',
  },
  {
    name: '',
    value: 'customer',
  },
];

export const ROLE_LEVEL = {
  SUPER_ADMIN: 0,
  SYSTEM_ADMIN: 1,
  SYSTEM_USER: 2,
  CUSTOMER_ADMIN: 3,
  CUSTOMER_USER: 4,
};
