import { ILogFilter } from 'src/types/log'
import {
  IConfigUserStation,
  IFilterStation,
  IPaginationStationLogs,
  IStation,
  IStationCard,
  IStationFilter,
  IStationFunction,
  IStationGeneral,
  IStationLog,
  IStationWorkingMode,
} from 'src/types/station'
import ClientBase from './base'

export interface ClientStationMix {
  getMyStation: (payload: IFilterStation) => Promise<IStationCard[]>
  getUserStationConfig: (userId: string) => Promise<{ trackAll: boolean; stationList: IStationCard[] }>

  getStations: (filter: IStationFilter) => Promise<IStation[]>
  getStationManage: (filter?: IStationFilter) => Promise<IStation[]>
  getStationBaseInfo: (stationId: string) => Promise<IStation>
  getStationGeneral: (stationId: string) => Promise<IStation>
  getStationFunction: (stationId: string) => Promise<IStationFunction>
  getActiveCodeStations: (stationId: string) => Promise<string>
  getStationLogs: (stationId: string, params: ILogFilter) => Promise<IPaginationStationLogs<IStationLog>>

  createStation: (data: Omit<IStationGeneral, 'id'>) => Promise<IStation>
  createStationLight: (stationId: string, name: string) => Promise<{ id: string; name: string }>
  updateStationGeneral: (stationId: string, data: Omit<IStationGeneral, 'id'>) => Promise<IStation>
  updateStationFunction: (
    stationId: string,
    data: {
      functionEnabled: boolean
    }
  ) => Promise<IStation>
  updateStationLight: (stationId: string, lightId: string, name: string) => Promise<{ id: string; name: string }>
  updateWorkingMode: (stationId: string, data: IStationWorkingMode) => Promise<string>
  deleteStation: (stationId: string) => Promise<string>
  deleteStationLight: (stationId: string, lightId: string) => Promise<string>
  configUserStation: ({ userId, payload }: IConfigUserStation) => Promise<string>

  toggleLightStatus: (data: { stationId: string }) => Promise<IStation>
  checkStationBelongToCustomer: (stationId: string) => Promise<string>
}

const ClientStation = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientStationMix {
    getMyStation = async (payload: IFilterStation) => {
      return this.doFetch<IStationCard[]>(`${this.getUserStationRoute()}/user_station/me/station`, {
        method: 'post',
        data: payload,
      })
    }

    getUserStationConfig = async (userId: string) => {
      return this.doFetch<{ trackAll: boolean; stationList: IStationCard[] }>(
        `${this.getStationRoute()}/${userId}/manage_config`,
        {
          method: 'get',
        }
      )
    }

    createStation = async (data: Omit<IStationGeneral, 'id'>) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}`, {
        method: 'POST',
        data,
      })
    }

    createStationLight = async (stationId: string, name: string) => {
      return this.doFetch<{ id: string; name: string }>(`${this.getStationRoute()}/${stationId}/light`, {
        method: 'POST',
        data: { name },
      })
    }

    getStations = async (params: IStationFilter) => {
      return this.doFetch<IStation[]>(`${this.getStationRoute()}`, {
        method: 'GET',
        params,
      })
    }

    getStationGeneral = async (stationId: string) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/general`, {
        method: 'GET',
      })
    }

    getActiveCodeStations = async (stationId: string) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}/active_code`, {
        method: 'GET',
      })
    }

    getStationFunction = async (stationId: string) => {
      // const url = `${this.getStationRoute()}/${stationId}/function`
      const url = `http://localhost:9004/api/v1/admin/stations/${stationId}/function`
      return this.doFetch<IStationFunction>(url, {
        headers: { user_id: '646ac7a4f7dbb639e1e8c965' },
        method: 'GET',
      })
    }

    getStationManage = async (params?: IStationFilter) => {
      return this.doFetch<IStation[]>(`${this.getStationRoute()}/me/manage`, {
        method: 'GET',
        params,
      })
    }

    getStationBaseInfo = async (stationId: string) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/base_info`, {
        method: 'GET',
      })
    }

    getStationLogs = async (stationId: string, params: ILogFilter) => {
      return this.doFetch<IPaginationStationLogs<IStationLog>>(`${this.getStationRoute()}/${stationId}/logs`, {
        method: 'GET',
        params,
      })
    }

    updateStationGeneral = async (stationId: string, data: Omit<IStationGeneral, 'id'>) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/general`, {
        method: 'PUT',
        data,
      })
    }

    updateStationFunction = async (
      stationId: string,
      data: {
        functionEnabled: boolean
      }
    ) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/function`, {
        method: 'PUT',
        data,
      })
    }

    updateStationLight = async (stationId: string, lightId: string, name: string) => {
      return this.doFetch<{ id: string; name: string }>(`${this.getStationRoute()}/${stationId}/light/${lightId}`, {
        method: 'PUT',
        data: {
          name,
        },
      })
    }

    updateWorkingMode = async (stationId: string, data: IStationWorkingMode) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}/working_mode`, {
        method: 'PUT',
        data,
      })
    }

    deleteStation = async (stationId: string) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}`, {
        method: 'DELETE',
      })
    }

    deleteStationLight = async (stationId: string, lightId: string) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}/light/${lightId}`, {
        method: 'DELETE',
      })
    }

    configUserStation = async ({ userId, payload }: IConfigUserStation) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${userId}/manage_config`, {
        method: 'PUT',
        data: payload,
      })
    }

    toggleLightStatus = async ({ stationId }: { stationId: string }) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/light/toggle`, {
        method: 'PUT',
      })
    }

    checkStationBelongToCustomer = async (stationId: string) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}/check_belong_to_customer`, {
        method: 'GET',
      })
    }
  }

export default ClientStation
