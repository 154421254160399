import React from 'react';
import { Skeleton, Grid, Box } from '@mui/material';

type TProps = {
  numberShow: number;
  width?: string | number;
};

const SkeletonLoading = (props: TProps) => {
  const { numberShow, width } = props;
  const renderSkeleton = () => {
    const arr = new Array(numberShow).fill(0);

    return arr.map((key, index) => {
      return (
        <Grid
          key={index}
          container
          sx={{
            width: width ? width : 280,
            height: 50,
            marginBottom: '10px',
            background: '#fff',
            padding: '5px',
          }}
        >
          <Grid item md={2}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Skeleton animation="wave" variant="circular" width={40} height={40} />
            </Box>
          </Grid>
          <Grid item md={10}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={'100%'}
              height={15}
              style={{ marginBottom: 5 }}
            />
            <Skeleton animation="wave" variant="rounded" width={'100%'} height={15} />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      {renderSkeleton()}
      {/* <Grid
        container
        sx={{
          width: 280,
          height: 50,
          marginBottom: '10px',
          background: '#fff',
          padding: '5px',
        }}
      >
        <Grid item md={2}>
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
        </Grid>
        <Grid item md={10}>
          <Skeleton
            animation="wave"
            variant="rounded"
            width={'100%'}
            height={15}
            style={{ marginBottom: 5 }}
          />
          <Skeleton animation="wave" variant="rounded" width={'100%'} height={15} />
        </Grid>
      </Grid>
      <Grid container sx={{ width: 280, height: 50, background: '#fff', padding: '5px' }}>
        <Grid item md={2}>
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
        </Grid>
        <Grid item md={10}>
          <Skeleton
            animation="wave"
            variant="rounded"
            width={'100%'}
            height={15}
            style={{ marginBottom: 5 }}
          />
          <Skeleton animation="wave" variant="rounded" width={'100%'} height={15} />
        </Grid>
      </Grid> */}
    </>
  );
};

export default SkeletonLoading;
