import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';

import { IStorageSpaceUsage, ISystemStorage } from 'src/types/system';
import { toastMessage } from 'src/utils/toast';

export const getStorage = createAsyncThunk<ISystemStorage, void>(
  'system/getStorage',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getStorage();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updateStorage = createAsyncThunk<ISystemStorage, ISystemStorage>(
  'system/updateStorage',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateStorage(payload);
      toastMessage.success(data.message);
      return payload;
    } catch (error) {
      // toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getVideoStorageGroup = createAsyncThunk<any, void>(
  'system/getVideoStorageGroup',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getVideoStorageGroup();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getStorageSpaceUsage = createAsyncThunk<IStorageSpaceUsage[], void>(
  'system/getStorageSpaceUsage',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getStorageSpaceUsage();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
