import { createSlice } from '@reduxjs/toolkit';

import { ISystemGeneralInfo } from 'src/types/system';
import { getGeneralInfo, updateGeneralInfoName } from './general_info_action';

const initialState: ISystemGeneralInfo = {
  address: '',
  contact: '',
  copyright: '',
  name: '',
  version: '',
};

const generalInfoSlice = createSlice({
  initialState,
  name: 'system/generalInfo',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGeneralInfo.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateGeneralInfoName.fulfilled, (state, action) => {
        state.name = action.payload;
      });
  },
});

export default generalInfoSlice.reducer;
