import { IChangeNotificationSource, IChangePopupStatus } from './../../types/notification';
import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import {
  IChangeNotificationSound,
  INotification,
  INotificationSetting,
  IPayloadNotification,
} from 'src/types/notification';
import { toastMessage } from 'src/utils/toast';

export const getAllNotification = createAsyncThunk<INotification[], IPayloadNotification>(
  'notification/getAll',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getAllNotification(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getAllNotificationMain = createAsyncThunk<INotification[], IPayloadNotification>(
  'notification/getAllMain',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getAllNotification(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const markNotificationRead = createAsyncThunk<string, string>(
  'notification/markRead',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.markNotificationRead(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const markNotificationUnread = createAsyncThunk<string, string>(
  'notification/markUnread',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.markNotificationUnread(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteNotification = createAsyncThunk<string, string>(
  'notification/deleteNotification',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.deleteNotification(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getInfoNotification = createAsyncThunk<INotification, string>(
  'notification/getInfoNotification',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getInfoNotification(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getNotificationSetting = createAsyncThunk<INotificationSetting>(
  'notification/getNotificationSetting',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getNotificationSetting();
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const changeNotificationSound = createAsyncThunk<any, IChangeNotificationSound>(
  'notification/changeNotificationSound',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.changeNotificationSound(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const changeNotificationSource = createAsyncThunk<any, IChangeNotificationSource>(
  'notification/changeNotificationSource',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.changeNotificationSource(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const changePopupStatus = createAsyncThunk<any, IChangePopupStatus>(
  'notification/changePopupStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.changeNotificationPopup(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const unseenNotification = createAsyncThunk<{ numberOfNotifications: number }>(
  'notification/unseenNotification',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.unseenNotification();
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const markAllAsSeenNotification = createAsyncThunk<any>(
  'notification/markAllAsSeenNotification',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.markAllAsSeen();
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
