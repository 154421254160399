import { createSlice } from '@reduxjs/toolkit';
import { ISystemDatabaseResponse } from 'src/types/system';
import { deleteDatabase, getDatabases } from './database_action';

const databases = createSlice({
  name: 'database',
  initialState: {} as ISystemDatabaseResponse,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDatabases.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(deleteDatabase.fulfilled, (state, action) => {
        state.data = state.data.filter((item) => item.id !== action.payload);
      });
  },
});

export default databases.reducer;
