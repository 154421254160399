import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';

interface IButtonWrapper {
  title: string;
  icon: any;
  handleClick: () => void;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'top-start'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end';
}

function ButtonWrapper(props: IButtonWrapper) {
  const { title, icon: Icon, placement = 'top', handleClick } = props;
  return (
    <Box>
      <Tooltip title={title} placement={placement}>
        <IconButton onClick={handleClick}>
          <Icon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default ButtonWrapper;
