import _ from 'lodash';
import { ECameraStatus, ICamera, ICameraFilter } from 'src/types/camera';
import { EGpsStatus } from 'src/types/enum';
import {
  IStationBatteryUpdated,
  IStationNetworkUpdated,
  IStationUpdateStatus,
} from 'src/types/notification';
import {
  EMobileNetworkStatus,
  EPowerBatteryStatus,
  IFilterStation,
  IStation,
} from 'src/types/station';

import { EStationStatus, IUpdateStationGPS } from './../types/station';
import { toastMessage } from './toast';
import i18n from 'src/i18n';

export const formatStationFilter = (data: ICameraFilter) => {
  const formatFilter: IFilterStation = {
    province: data.province ? data.province.split(',').map((code) => Number(code)) : [],
    commune: data.commune ? data.commune.split(',').map((code) => Number(code)) : [],
    district: data.district ? data.district.split(',').map((code) => Number(code)) : [],
    customerId: data.customer ? data.customer.split(',') : [],
    searchKeyword: data.searchKeyword,
  };
  return formatFilter;
};

export const stationMobileNetworkUpdatedUtil = (
  stationsCurrent: IStation[],
  currentStationInfo: IStation | null,
  { stationId, newNetwork }: IStationNetworkUpdated,
) => {
  if (stationsCurrent.length) {
    const index = stationsCurrent.findIndex((station) => station.id === stationId);

    if (index !== -1) {
      let stationItem = stationsCurrent[index];
      stationItem = Object.assign(stationItem, newNetwork);

      stationsCurrent.splice(index, 1, stationItem);
    }
  }
  if (!_.isEmpty(currentStationInfo) && currentStationInfo.id === stationId) {
    currentStationInfo = Object.assign(currentStationInfo, newNetwork);
  }

  return { stationsCurrent, currentStationInfo };
};

export const stationBatteryUpdatedUtil = (
  stationsCurrent: IStation[],
  currentStationInfo: IStation | null,
  { stationId, newBattery }: IStationBatteryUpdated,
) => {
  const index = stationsCurrent.findIndex((station) => station.id === stationId);

  if (stationsCurrent.length) {
    if (index !== -1) {
      let stationItem = stationsCurrent[index];
      stationItem = Object.assign(stationItem, newBattery);

      stationsCurrent.splice(index, 1, stationItem);
    }
  }

  if (!_.isEmpty(currentStationInfo) && currentStationInfo.id === stationId) {
    currentStationInfo = Object.assign(currentStationInfo, newBattery);
  }

  return { stationsCurrent, currentStationInfo };
};

export const updateStationStatusUtil = (
  stationsCurrent: IStation[],
  currentStationInfo: IStation | null,
  { stationId, status }: IStationUpdateStatus,
) => {
  if (stationsCurrent.length) {
    const index = stationsCurrent.findIndex((station) => station.id === stationId);
    if (index !== -1) {
      const station = { ...stationsCurrent[index], status };
      stationsCurrent.splice(index, 1, station);
    }
  }

  if (!_.isEmpty(currentStationInfo) && currentStationInfo.id === stationId) {
    currentStationInfo = { ...currentStationInfo, status };
  }

  return { stationsCurrent, currentStationInfo };
};
export const updateStationMobileNetworkDisconnectedUtil = (
  stationsCurrent: IStation[],
  currentStationInfo: IStation | null,
  { stationId, timestamp }: { stationId: string; timestamp: number },
) => {
  const newMobileInfo = {
    mobileNetworkConnectionStatus: EMobileNetworkStatus.DISCONNECT,
    mobileNetworkLastUpdatedAt: new Date(timestamp).toDateString(),
  };
  if (stationsCurrent.length) {
    const index = stationsCurrent.findIndex((station) => station.id === stationId);

    if (index !== -1) {
      const stationItem: IStation = Object.assign(stationsCurrent[index], newMobileInfo);

      stationsCurrent.splice(index, 1, stationItem);
    }
  }

  if (!_.isEmpty(currentStationInfo) && currentStationInfo.id === stationId) {
    currentStationInfo = Object.assign(currentStationInfo, newMobileInfo);
  }

  return { stationsCurrent, currentStationInfo };
};

export const updateStationBatteryDisconnectedUtil = (
  stationsCurrent: IStation[],
  currentStationInfo: IStation | null,
  { stationId, timestamp }: { stationId: string; timestamp: number },
) => {
  const newPowerBatteryInfo = {
    powerBatteryConnectionStatus: EPowerBatteryStatus.DISCONNECT,
    powerBatteryCapacityStatus: 0,
    powerBatteryLastUpdatedAt: new Date(timestamp).toDateString(),
  };
  if (stationsCurrent.length) {
    const index = stationsCurrent.findIndex((station) => station.id === stationId);

    if (index !== -1) {
      const stationItem: IStation = Object.assign(stationsCurrent[index], newPowerBatteryInfo);

      stationsCurrent.splice(index, 1, stationItem);
    }
  }

  if (!_.isEmpty(currentStationInfo) && currentStationInfo.id === stationId) {
    currentStationInfo = Object.assign(currentStationInfo, newPowerBatteryInfo);
  }

  return { stationsCurrent, currentStationInfo };
};

export const stationGPSUpdatedUtil = (
  stationsCurrent: IStation[],
  currentStationInfo: IStation | null,
  { stationId, newGPS }: IUpdateStationGPS,
  isUpdateLatLng = false,
) => {
  const gpsUpdate: any = {
    province: newGPS.province,
    district: newGPS.district,
    commune: newGPS.commune,
    address: newGPS.address,
    gpsConnectionStatus: EGpsStatus.CONNECT,
    gpsWaveStrength: newGPS.gpsWaveStrength,
    gpsLastUpdatedAt: newGPS.gpsLastUpdatedAt,
    lat: newGPS.lat,
    lng: newGPS.lng,
  };

  if (isUpdateLatLng) {
    delete gpsUpdate['lat'];
    delete gpsUpdate['lng'];
  }

  if (stationsCurrent.length) {
    const index = stationsCurrent.findIndex((station) => station.id === stationId);

    if (index !== -1) {
      const stationItem: IStation = Object.assign(stationsCurrent[index], gpsUpdate);

      stationsCurrent.splice(index, 1, stationItem);
    }
  }

  if (!_.isEmpty(currentStationInfo) && currentStationInfo.id === stationId) {
    currentStationInfo = Object.assign(currentStationInfo, gpsUpdate);
  }

  return { currentStationInfo, stationsCurrent };
};

export const isStationLevelWarning = (status: EStationStatus) => {
  return (
    status === EStationStatus.ACTIVE ||
    status === EStationStatus.INACTIVE ||
    status === EStationStatus.CONNECTING ||
    status === EStationStatus.WARNING
  );
};

export const validateStationOrCameraStatus = (status: EStationStatus, cameras?: ICamera[]) => {
  if (status === EStationStatus.ERROR)
    return toastMessage.error(i18n.t('label.status.station.error'));
  if (status === EStationStatus.OFFLINE)
    return toastMessage.error(i18n.t('label.status.station.offline'));

  if (!cameras?.length) return;

  const { status: cameraStatus } = cameras[0];

  if (cameraStatus === ECameraStatus.OFFLINE)
    return toastMessage.error(i18n.t('label.status.camera.offline'));
  if (cameraStatus === ECameraStatus.ERROR)
    return toastMessage.error(i18n.t('label.status.camera.error'));

  return;
};
