import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import theme from 'src/theme';
import { ICamera } from 'src/types/camera';
import { IModalState } from 'src/types/modal';
import LogsList from '../logs_list';

interface ILogsDialogProps {
  modalId: string;
  camera: ICamera;
}

const LogsModal = ({ camera, modalId }: ILogsDialogProps) => {
  const modalState = useAppSelector(({ modalSlice }: { modalSlice: IModalState }) => modalSlice);

  const modal = modalState[modalId];

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(
      closeModal({
        modalId,
      }),
    );
  };

  return (
    <Dialog maxWidth="xs" fullWidth disableRestoreFocus open={modal.open} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, py: 1, px: 0, position: 'relative' }}>
        <Box textAlign="center" sx={{ px: 6 }}>
          <Typography variant="body1" fontWeight={700} color="primary">
            DANH SÁCH LOGS
          </Typography>
          <Typography variant="body2" fontWeight={700} color="primary">
            Camera: {camera.name}
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ height: 800, p: 0, m: 0, background: theme.palette.secondary.light }}
      >
        <LogsList camera={camera} />
      </DialogContent>
    </Dialog>
  );
};

export default LogsModal;
