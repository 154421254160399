import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { ICameraFilter } from 'src/types/camera';
import {
  IRtcStream,
  IStreamCreate,
  IStreamFilterRequest,
  IStreamFilterResponse,
} from 'src/types/stream';
import { toastMessage } from 'src/utils/toast';

export const getLiveStreamingCams = createAsyncThunk<
  IStreamFilterResponse,
  { streamFilter: IStreamFilterRequest; cameraFilter?: ICameraFilter }
>('watchlist/getLiveStreamingCams', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.getLiveStreamingCams(payload.streamFilter, payload.cameraFilter);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const addSingleCamToWatchlist = createAsyncThunk<IRtcStream, IStreamCreate>(
  'watchlist/addSingleCamToWatchlist',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.addSingleCamToWatchlist(payload);

      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const removeSingleCamFromWatchlist = createAsyncThunk<
  any,
  { position: number; groupId: string }
>('watchlist/removeSingleCamFromWatchlist', async (payload, { rejectWithValue }) => {
  try {
    await client.removeSingleCamFromWatchlist(payload.position, payload.groupId);
    return payload;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const getFollowGroups = createAsyncThunk<any, void>(
  'watchlist/getFollowGroups',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getFollowGroups();
      return data;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);

export const addFollowGroup = createAsyncThunk<{ id: string; name: string }, string>(
  'watchlist/addFollowGroup',
  async (groupName, { rejectWithValue }) => {
    try {
      const data = await client.addFollowGroup(groupName);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteFollowGroup = createAsyncThunk<string, string>(
  'watchlist/deleteFollowGroup',
  async (groupId, { rejectWithValue }) => {
    try {
      await client.deleteFollowGroup(groupId);
      return groupId;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);
