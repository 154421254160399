import React, { Component } from 'react';

import { signaling } from '../../controller/signaling';
import { IPlayerProps } from '../../types/player';
import { debugLog } from '../../utils/log';
import { VideoStyled, VideoWrapper } from './styles';
import { ECameraStatus } from 'src/types/camera';
import { setHasOnline } from 'src/redux_store/stream/stream_slice';
import RTC from '../../controller/rtc';
import fullscreen from '../../utils/fullscreen';
import VideoStatus from './status';
import store from 'src/redux_store';

// props type is IPlayerProps

const { ERROR, OFFLINE } = ECameraStatus;

interface IVideoState {
  hasOnline: boolean;
}

const dispatch = store.dispatch;

export default class Video extends Component<any> {
  video: HTMLVideoElement | null = null;
  rtc: RTC | null = null;
  rtcAudio: RTC | null = null;
  state: IVideoState = {
    hasOnline: true,
  };

  constructor(props: IPlayerProps) {
    super(props);
    this.rtc =
      props.cam.status === ERROR || props.cam.status === OFFLINE
        ? null
        : new RTC(this.props.cam.id, this.props.actions, undefined, this.props.cam.isSupportAudio);
  }

  componentDidMount() {
    if (!signaling.ws) return;
    if (signaling.ws.readyState === WebSocket.OPEN) {
      this.startPlayer();
    }
  }

  componentDidUpdate(prevProps: IPlayerProps) {
    if (prevProps.player.isAudioStarting !== this.props?.player?.isAudioStarting) {
      if (this.props?.player?.isAudioStarting) {
        this.rtcAudio = new RTC(this.props.cam.id, this.props.actions, true);
        this.startPlayer(this.rtcAudio.viewerId, this.rtcAudio);
      } else {
        if (!this.rtcAudio) return;
        // this.rtcAudio.toggleAudio('stopAudio');
        this.rtcAudio.close();
        this.rtcAudio.stopAudioStream();
      }
    }

    if (this.props?.player.error && prevProps.player.error !== this.props?.player.error) {
      this.stopPlayer();
    }

    if (this.props?.wsOpen && prevProps.wsOpen !== this.props?.wsOpen) {
      this.refreshPlayer();
    }

    if (this.state.hasOnline !== navigator.onLine) {
      console.log('navigator.onLine', navigator.onLine);
      this.setState({
        hasOnline: navigator.onLine,
      });

      dispatch(setHasOnline(navigator.onLine));

      // if (!navigator.onLine) {
      //   signaling.close();
      // }
    }
  }

  componentWillUnmount() {
    this.stopPlayer();
  }

  refreshPlayer = () => {
    if (!this.rtc) return;
    const viewerId = this.rtc.getViewerId();

    this.startPlayer(viewerId);
  };

  startPlayer = (viewerId?: string, rtc?: RTC) => {
    if (!this.rtc) return;

    signaling.addRtc(viewerId || this.rtc.viewerId, rtc || this.rtc);
    if (rtc) {
      rtc.initTrack(this.video as HTMLVideoElement);
    } else {
      this.rtc.initTrack(this.video as HTMLVideoElement);
    }
  };

  stopPlayer = () => {
    if (!this.rtc) return;
    debugLog({ 'Video.stopPlayer': this.rtc });
    this.rtc.close();
    this.rtcAudio?.close();
    this.rtcAudio?.stopAudioStream();
    this.handlePause();
  };

  handleFullscreenChange = (e: Event) => {
    if (e.target === this.props.manager.rootElement) {
      this.props.actions.changeFullscreen(fullscreen.isFullscreen);
    }
  };

  set muted(val: boolean) {
    if (!this.video) return;
    this.video.muted = val;
  }

  onPlay = () => this.handlePlay();

  onCanPlay = () => {
    if (!this.video) return;
    const { actions } = this.props;
    actions.canPlay();
  };

  onPlaying = () => {
    if (!this.video) return;
    const { actions } = this.props;

    actions.playing();
  };

  onCanPlayThrough = () => {
    if (!this.video) return;
    const { actions } = this.props;
    actions.canPlayThrough();
  };

  onPause = () => this.handlePause();

  handleError = () => {
    debugLog('CPN.video: handle error');
  };

  handleProgress = () => {
    const { actions } = this.props;
    if (!this.rtc?.pc) return;
    actions.waiting();
    this.handlePause();
  };

  handlePause = () => {
    const { player } = this.props;
    if (player.paused || !this.video) return;
    this.pause();
  };

  handlePlay = () => {
    if (!this.rtc?.pc) return;
    this.play();
  };

  play = () => {
    if (!this.video) return;
    const { actions } = this.props;

    actions.play();
    this.video.play();
  };

  pause = () => {
    if (!this.video) return;
    const { actions } = this.props;

    actions.pause();
    this.video.pause();
  };

  render() {
    const {
      player,
      wsOpen,
      cam: { status },
    } = this.props as IPlayerProps;

    // if ((status === NORMAL || status === WARNING) && !player.error && wsOpen)
    //   return (
    //     <VideoWrapper>
    //       <VideoStyled
    //         width={'100%'}
    //         height={220}
    //         ref={(c) => (this.video = c)}
    //         autoPlay
    //         onError={this.handleError}
    //         onPlay={this.onPlay}
    //         onPlaying={this.onPlaying}
    //         onCanPlay={this.onCanPlay}
    //         onPause={this.onPause}
    //         // onProgress={this.handleProgress}
    //         onCanPlayThrough={this.onCanPlayThrough}
    //         muted={player.isMuted}
    //       />
    //     </VideoWrapper>
    //   );

    // return <VideoStatus status={status} wsOpen={wsOpen} error={player.error} key="video" />;

    // console.log('navigator.onLine', navigator.onLine, 'this.state', this.state);

    return (
      <VideoWrapper>
        <VideoStyled
          width={'100%'}
          height={220}
          ref={(c) => (this.video = c)}
          autoPlay
          onError={this.handleError}
          onPlay={this.onPlay}
          onPlaying={this.onPlaying}
          onCanPlay={this.onCanPlay}
          onPause={this.onPause}
          // onProgress={this.handleProgress}
          onCanPlayThrough={this.onCanPlayThrough}
          muted={player.isMuted}
        ></VideoStyled>
        <VideoStatus
          status={status}
          hasOnline={this.state.hasOnline}
          wsOpen={wsOpen}
          error={player.error}
          actions={this.props.actions}
          key="video"
        />
      </VideoWrapper>
    );
  }
}
