import React from 'react';
import DialogWrapper from '../dialog_wrapper';
import { MODAL_IDS } from 'src/constants/modal';
import { Box, Button, Typography } from '@mui/material';
import { PATH } from 'src/routes/path';
import replacePageImage from 'src/assets/images/reload_page.jpg';
import { useTranslation } from 'react-i18next';

const ModalReloadPage = () => {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.href = PATH.stationMap;
  };

  return (
    <DialogWrapper
      isFullHeight
      isFullWidth
      isShowIconClose={false}
      isNotAutoClose
      modalId={MODAL_IDS.reloadPage}
      sx={{ height: '100%', width: '100%' }}
      maxWidthDialog={'lg'}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Box
            width="100%"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img width="50%" alt="Danapod" src={replacePageImage} />
          </Box>
          <Box>
            <Typography textAlign="center" fontWeight={600}>
              {t('label.data.changed')}
            </Typography>
          </Box>

          <Box mt={1} textAlign="center">
            <Button onClick={handleReload} variant="contained">
              {t('stream.controlBar.refresh')}
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default ModalReloadPage;
