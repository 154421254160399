import React from 'react';
import { Pause, PlayArrow } from '@mui/icons-material';

import { IPlayerProps } from '../../../types/player';
import i18n from 'src/i18n';
import ButtonWrapper from '../button_wrapper';

function PlayToggleCpn(props: any) {
  const { player, manager, controlBar } = props as IPlayerProps;
  const { t } = i18n;

  if (controlBar?.hidePlay) return null;

  const controlText = player.paused
    ? t('stream.controlBar.play.false')
    : t('stream.controlBar.play.true');

  const handleClick = () => {
    const { video } = manager;

    if (player.error || !video || !video.rtc?.pc) return;

    if (player.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  return (
    <ButtonWrapper
      title={controlText}
      icon={player.paused ? PlayArrow : Pause}
      handleClick={handleClick}
      placement="top-start"
      disablePortal
    />
  );
}

export default PlayToggleCpn;
