import { IPermissionScheme } from 'src/types/permission';
import { IRole, IRoleFilter } from 'src/types/role';

interface IPermissionConvert {
  key: string;
  displayName: string;
}

export const preSave = (data: Pick<IRole, 'name' | 'permissions' | 'schemes' | 'description'>) => {
  return data;
};

export const getPermissionsName = (
  permissions: IPermissionScheme[],
  rolePermissions: string[],
  displayName: string,
) => {
  let permissionConvert: IPermissionConvert[] = [];

  permissions.map((permission) => {
    if (permission?.scope) {
      permissionConvert = permissionConvert.concat(
        getPermissionsName(permission?.scope, rolePermissions, permission.displayName)
          .permissionConvert,
      );
    } else {
      if (permission.permissions && permission.permissions.length) {
        permission.permissions?.map((subPermission) => {
          if (rolePermissions.includes(subPermission)) {
            if (!displayName) {
              permissionConvert = permissionConvert.concat({
                displayName: '',
                key: permission.displayName,
              });
            } else {
              permissionConvert = permissionConvert.concat({
                displayName: permission.displayName,
                key: displayName,
              });
            }
          }
        });
      }
    }
  });

  const permissionsName: IPermissionConvert[] = [];

  if (permissionConvert.length) {
    permissionConvert.forEach((item) => {
      const index = permissionsName.findIndex((permission) => permission.key === item.key);
      if (index === -1) {
        permissionsName.push({ key: item.key, displayName: item.displayName });
      } else {
        if (permissionsName[index].displayName.search(item.displayName) === -1) {
          permissionsName[index] = {
            ...permissionsName[index],
            displayName: (permissionsName[index].displayName += `, ${item.displayName}`),
          };
        }
      }
    });
  }

  return { permissionConvert, permissionsName };
};

export const convertPermissions = (permissions: IPermissionScheme[], checkedList: string[]) => {
  let result: string[] = [];
  if (!permissions.length) return result;

  permissions.map((permission) => {
    if (permission.scope) {
      result = result.concat(convertPermissions(permission.scope, checkedList));
    } else {
      const isSelected = checkedList.includes(permission.id);
      if (isSelected) {
        result = result.concat(permission.permissions as string[]);
      }
    }
  });

  return result;
};
export const preFilter = (data: IRoleFilter) => {
  const { limit, page, searchKeyword } = data;
  const roleFilter: IRoleFilter = {
    searchKeyword,
    limit,
    page,
  };

  return roleFilter;
};

export const getCheckListAndRequirePermission = (
  defaultValues: any,
  array: IPermissionScheme[],
  key: string,
) => {
  const getIdFromPermission = (array: IPermissionScheme[], key: string) => {
    let checkedList: any[] = [];

    let _checkedListDisabled: any[] = [];
    if (!array.length) return { checkedList, checkedListDisabled: [] };

    array.map((item) => {
      if (item?.scope) {
        checkedList = checkedList.concat(getIdFromPermission(item?.scope, item.id).checkedList);

        _checkedListDisabled = _checkedListDisabled.concat(
          getIdFromPermission(item?.scope, item.id)._checkedListDisabled,
        );
      } else {
        if (item.permissions) {
          item.permissions.map((i) => {
            if (defaultValues?.permissions?.includes(i) && !checkedList?.includes(item.id)) {
              checkedList = checkedList.concat(item.id);
              _checkedListDisabled = _checkedListDisabled.concat({
                key,
                id: item.id,
                required: item.required,
              });
            }
          });
        }
      }
    });

    let newCheckedListDisabled: any[] = [];

    _checkedListDisabled.forEach((item) => {
      const index = newCheckedListDisabled.findIndex((value) => value?.key === item.key);
      if (index === -1) {
        newCheckedListDisabled.push({ ...item, count: 1 });
      } else {
        const obj = newCheckedListDisabled[index];
        newCheckedListDisabled[index] = {
          ...obj,
          count: obj.count + 1,
        };
        newCheckedListDisabled = [...newCheckedListDisabled];
      }
    });

    const checkedListDisabled: string[] = [];
    newCheckedListDisabled.forEach((item) => {
      if (item.required && item.count > 1) {
        checkedListDisabled.push(item.id);
      }
    });

    return { checkedList, _checkedListDisabled, checkedListDisabled };
  };

  return getIdFromPermission(array, key);
};

export const getExpandPermission = (permissions: IPermissionScheme[]) => {
  let expandedList: string[] = [];

  permissions?.map((permission) => {
    if (permission?.scope) {
      expandedList.push(permission.id);
      expandedList = expandedList.concat(getExpandPermission(permission?.scope));
    }
  });

  return expandedList;
};

export const formatObjectRole = (obj: any) => {
  const newObj = { ...obj };
  if (newObj.permissionsList) {
    newObj.permissions = newObj.permissionsList;
    delete newObj.permissionsList;
  }
  return newObj;
};

// export const removeEmptyScopeListInPermissionSchemeList = (
//   arr: IPermissionScheme[],
// ): IPermissionScheme[] => {
//   return arr.map((item: IPermissionScheme) => {
//     if (Array.isArray(item.scopeList) && item.scopeList.length === 0) {
//       const { scopeList, ...rest } = item;
//       return rest;
//     } else if (Array.isArray(item.scopeList)) {
//       return { ...item, scopeList: removeEmptyScopeListInPermissionSchemeList(item.scopeList) };
//     } else {
//       return item;
//     }
//   });
// };
