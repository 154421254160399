import React, { Component } from 'react';
import Hls from 'hls.js';

import { IPlayerProps } from '../../types/player';
import { debugLog } from '../../utils/log';
import { VideoStyled, VideoWrapper } from './styles';

import store from 'src/redux_store';
import VideoStatus from './status';
import { ECameraStatus } from 'src/types/camera';
import i18n from 'src/i18n';

const { ERROR, NORMAL, OFFLINE, WARNING } = ECameraStatus;

const hlsOption = {
  backBufferLength: 0,

  xhrSetup: (xhr: any, url: string) => {
    const {
      me: { id },

      token,
    } = store.getState().myAccountSlice;

    xhr.setRequestHeader('Authorization', 'Bearer=' + token);
    xhr.setRequestHeader('user_id', id);
    xhr.withCredentials = true;
    xhr.open('GET', url, true);
  },
  // debug: true
};

export default class Video extends Component<any> {
  video: HTMLVideoElement | null = null;
  slice = this.props as IPlayerProps;
  hls: Hls | null = null;

  constructor(props: IPlayerProps) {
    super(props);
    this.hls =
      props.cam.status === ERROR || props.cam.status === OFFLINE ? null : new Hls(hlsOption);
  }

  componentDidUpdate(prevProps: IPlayerProps) {
    // if (prevProps.cam.id !== this.props.cam.id) {
    //   this.handleHlsFullscreen(false);
    // }

    if (prevProps.player.isFullscreen !== this.props.player.isFullscreen) {
      this.handleHlsFullscreen(this.props.player.isFullscreen);
    }

    if (this.props?.player.error) {
      this.destroyHls();
    }
  }

  componentDidMount() {
    if (!this.hls) return;
    this.video?.addEventListener('pause', this.onPauseVideo);

    this.handleHlsFullscreen(false);

    if (Hls.isSupported()) {
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        if (!this.hls) return;
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              if (data.networkDetails?.status === 404) {
                this.slice.actions.setError({ message: i18n.t('message.file.notFound') });
                break;
              }
              console.log('fatal network error encountered, try to recover');
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('fatal media error encountered, try to recover', data);
              this.hls.recoverMediaError();
              break;
            default:
              console.log('fatal default', { data });
              this.hls.startLoad();
              // this.hls.destroy();
              break;
          }
        }
      });
    }
  }

  componentWillUnmount() {
    this.video?.removeEventListener('pause', this.onPauseVideo);
    this.destroyHls();
  }

  onPauseVideo = () => {
    if (document.hidden) {
      this.play();
    }
  };

  destroyHls = () => {
    if (this.hls) {
      this.hls.destroy();
    }
  };

  resetHls = () => {
    this.destroyHls();
    this.hls = new Hls(hlsOption);
  };

  handleHlsFullscreen = (isFullscreen: boolean) => {
    if (!this.hls) return;
    this.resetHls();

    const video = this.video;
    if (!video) return;

    if (Hls.isSupported()) {
      this.hls.detachMedia();
      this.hls.attachMedia(video);

      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        if (!this.hls) return;

        const path =
          isFullscreen || this.slice.isTheaterMode
            ? `${this.props.cam.id}`
            : `${this.props.cam.id}_sub`;
        this.hls.loadSource(`/video/livestream/${path}/index.m3u8`);
        video.play().catch((error) => console.log(error));
      });
    }

    this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
      const promise = video.play();
      if (promise === undefined) {
        console.log('🚀 video.play() undefined', isFullscreen);
      }
      if (promise !== undefined) {
        promise
          .catch((error) => console.log({ error }))
          .then(() => {
            console.log('🚀 Play');
          });
      }
    });
  };

  onPlay = () => this.handlePlay();

  onPause = () => this.handlePause();

  handleError = () => {
    debugLog('🚀 ~ CPN.video: handle error');
  };

  handlePause = () => {
    const { player } = this.slice;
    if (player.paused || !this.video) return;
    this.pause();
  };

  handlePlay = () => {
    if (!this.hls) return;
    this.play();
  };

  play = () => {
    if (!this.video) return;
    const { actions } = this.slice;

    const promise = this.video.play();
    if (promise !== undefined)
      promise
        .catch(() => {
          if (!this.video) return;
          this.video.pause();
          actions.pause();
        })
        .then(() => {
          actions.play();
        });
  };

  pause = () => {
    if (!this.video) return;
    const { actions } = this.slice;

    actions.pause();
    this.video.pause();
  };

  onSeeking = () => {
    debugLog('🚀 ~ CPN.video: onSeeking');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.seeking();
    this.video.play();
  };

  onSeeked = () => {
    debugLog('🚀 ~ CPN.video: onSeeked');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.seeked();
    this.play();
  };

  onEnded = () => {
    debugLog('🚀 ~ CPN.video: onEnded');
  };

  onWaiting = () => {
    debugLog('🚀 ~ CPN.video: onWaiting');
    const { actions } = this.slice;

    actions.waiting();
    this.handlePause();
  };

  onCanPlay = () => {
    debugLog('🚀 ~ CPN.video.onCanPlay');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.canPlay();
  };

  onPlaying = () => {
    debugLog('🚀 ~ CPN.video.onPlaying');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.playing();
  };

  onCanPlayThrough = (e: React.BaseSyntheticEvent) => {
    debugLog({ '🚀 ~ CPN.video.onCanPlayThrough': e });
    if (!this.video) return;
    const { actions } = this.slice;
    actions.canPlayThrough();
  };

  render() {
    const {
      player,
      cam: { status },
    } = this.props;

    if ((status === NORMAL || status === WARNING) && !player.error)
      return (
        <VideoWrapper>
          <VideoStyled
            crossOrigin="anonymous"
            ref={(c) => (this.video = c)}
            muted
            autoPlay
            onSeeking={this.onSeeking}
            onSeeked={this.onSeeked}
            onEnded={this.onEnded}
            onError={this.handleError}
            onPlay={this.onPlay}
            onCanPlay={this.onCanPlay}
            onPause={this.onPause}
            onWaiting={this.onWaiting}
            onPlaying={this.onPlaying}
            onCanPlayThrough={this.onCanPlayThrough}
          />
        </VideoWrapper>
      );

    return <VideoStatus status={status} error={player.error} key="video" />;
  }
}
