import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { ISystemGeneralInfo } from 'src/types/system';
import { toastMessage } from 'src/utils/toast';

export const getGeneralInfo = createAsyncThunk<ISystemGeneralInfo, void>(
  'system/getGeneralInfo',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getGeneralInfo();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updateGeneralInfoName = createAsyncThunk<string, string>(
  'system/updateGeneralInfoName',
  async (name, { rejectWithValue }) => {
    try {
      const data = await client.updateGeneralInfoName(name);
      toastMessage.success(data.message);
      return name;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
