import { debugLog } from './log';

class Fullscreen {
  request(elm: any) {
    debugLog('Fullscreen.request: request fullscree');
    if (elm.requestFullscreen) {
      elm.requestFullscreen();
    } else if (elm.webkitRequestFullscreen) {
      elm.webkitRequestFullscreen();
    } else if (elm.mozRequestFullscreen) {
      elm.mozRequestFullscreen();
    } else if (elm.msRequestFullscreen) {
      elm.msRequestFullscreen();
    }
  }

  exit() {
    debugLog('Fullscreen.exit: exit fullscreen');
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    // else if (document.webkitExitFullscreen) {
    //   document.webkitExitFullscreen();
    // } else if (document.mozCancelFullScreen) {
    //   document.mozCancelFullScreen();
    // } else if (document.msExitFullscreen) {
    //   document.msExitFullscreen();
    // }
  }

  get isFullscreen() {
    return document.fullscreenElement ? true : false;
    // document.webkitFullscreenElement ||
    // document.mozFullScreenElement ||
    // document.msFullscreenElement
  }

  get enabled() {
    return document.fullscreenEnabled;
    // document.webkitFullscreenEnabled ||
    // document.mozFullScreenEnabled ||
    // document.msFullscreenEnabled
  }

  addEventListener(handler: any) {
    document.addEventListener('fullscreenchange', handler);
    document.addEventListener('webkitfullscreenchange', handler);
    document.addEventListener('mozfullscreenchange', handler);
    document.addEventListener('MSFullscreenChange', handler);
  }

  removeEventListener(handler: any) {
    document.removeEventListener('fullscreenchange', handler);
    document.removeEventListener('webkitfullscreenchange', handler);
    document.removeEventListener('mozfullscreenchange', handler);
    document.removeEventListener('MSFullscreenChange', handler);
  }
}

export default new Fullscreen();
