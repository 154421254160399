import React from 'react';
import { Pause, PlayArrow } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IPlayerProps } from '../../../types/player';

function PlayToggleCpn(props: any) {
  const slice = { ...props } as IPlayerProps;

  const { t } = useTranslation();

  const { player, manager } = slice;
  const controlText = player.paused
    ? t('stream.controlBar.play.false')
    : t('stream.controlBar.play.true');

  const handleClick = () => {
    const { video } = manager;

    if (player.error || !video || !video.hls) return;

    if (player.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top-start"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>{player.paused ? <PlayArrow /> : <Pause />}</IconButton>
      </Tooltip>
    </Box>
  );
}

export default PlayToggleCpn;
