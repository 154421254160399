import { createSlice } from '@reduxjs/toolkit';
import { ISystemNode, ISystemNodeDetail } from 'src/types/system';
import { getNode, getNodes } from './node_action';

interface ISystemNodeState {
  nodeList: ISystemNode[];
  node: ISystemNodeDetail | null;
  open: boolean;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
}

const initNodeState: ISystemNodeState = {
  nodeList: [],
  node: null,
  open: false,
  anchor: 'right',
};

const nodes = createSlice({
  initialState: initNodeState,
  name: 'system/nodes',
  reducers: {
    closeDrawer: (state) => {
      state.node = null;
      state.open = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getNodes.fulfilled, (state, action) => {
        state.nodeList = action.payload;
      })
      .addCase(getNode.fulfilled, (state, action) => {
        state.open = true;
        state.node = action.payload;
      });
  },
});

export const { actions } = nodes;

export default nodes.reducer;
