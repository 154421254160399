import { Badge, Box, Grid, Link, Menu, MenuItem, MenuList, Popover, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Logout } from '@mui/icons-material'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import logo from 'src/assets/images/logo.png'
import config from 'src/config'
import { MODAL_IDS } from 'src/constants/modal'
import TOP_BAR_LIST from 'src/constants/topbar'
import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { closeModal, openModal } from 'src/redux_store/common/modal/modal_slice'
import { closeBoxRightContent, openBoxRightContent } from 'src/redux_store/common/topbar/topbar_slice'
import { logout, updateUserLocale } from 'src/redux_store/my_account/my_account_action'
import { changeIsReload, logoutLocal } from 'src/redux_store/my_account/my_account_slice'
import { markAllAsSeenNotification } from 'src/redux_store/notification/notification_action'
import { setNumberAndInfoOfNotification } from 'src/redux_store/notification/notification_slice'
import { PATH } from 'src/routes/path'
import theme from 'src/theme'
import { INotification } from 'src/types/notification'
import { syncNumberOfNotification } from 'src/utils/notification'
import en from '../../assets/images/en-us-flag-small.png'
import vi from '../../assets/images/vi-flag-small.png'
import ConfirmationDialog from '../modal/confirm_dialog'
import ModalChangeAvatar from '../modal/modal_change_avatar'
import IconButtonTooltip from '../tooltip/icon_button_tooltip'
import { useStyles } from './styles'

import moment from 'moment'
import { LOCALE_LANGUAGE_LIST } from 'src/constants/i18n'
import i18n from 'src/i18n'
interface IProps {
  title: string
}

const Topbar = ({ title }: IProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { me } = useAppSelector((state) => state.myAccountSlice)
  const { indexActive } = useAppSelector((state) => state.topBarSlice)
  const { numberOfNotifications } = useAppSelector((state) => state.notificationSlice)

  const [localeSelected, setLocaleSelected] = useState<string>('vi')

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [open, setOpen] = React.useState<{
    [x: string]: boolean
  }>({})

  // const LOCALE_LANGUAGE_LIST = [
  //   {
  //     id: 'vi',
  //     name: t('label.language.vi'),
  //     flag: VIFlag,
  //   },
  //   {
  //     id: 'en',
  //     name: t('label.language.en'),
  //     flag: EN_US_Flag,
  //   },
  // ];

  useEffect(() => {
    const locale = me.locale || 'en'
    setLocaleSelected(locale)
    moment.locale(locale)
  }, [me.locale])

  const handleOpen = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setAnchorEl(event.currentTarget)
    setOpen({ [name]: true })
  }

  const handleClose = (name: string) => {
    setAnchorEl(null)
    setOpen({ [name]: false })
  }

  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.logout,
        dialogComponent: (
          <ConfirmationDialog
            modalId={MODAL_IDS.logout}
            icon={<Logout fontSize="large" color="primary" />}
            describe={t('label.logout.description')}
            callback={() => {
              dispatch(changeIsReload(false))
              dispatch(logout())
                .unwrap()
                .finally(() => {
                  dispatch(logoutLocal())
                  dispatch(closeModal({ modalId: MODAL_IDS.logout }))
                  navigation(PATH.login)
                  dispatch(changeIsReload(true))
                })
            }}
            functionName="logout"
            sliceName="myAccount"
          />
        ),
      })
    )
  }

  const handleClickTopBarIcon = (index: number, typeId: string) => {
    if (typeId === 'notification') {
      syncNumberOfNotification(me.id)
      dispatch(
        setNumberAndInfoOfNotification({
          numberNotification: 0,
          data: {} as INotification,
        })
      )
      dispatch(markAllAsSeenNotification())
    }

    dispatch(openBoxRightContent({ index, typeId }))
  }

  const handleClickTopBarActiveIcon = () => {
    if (location.pathname !== PATH.notification) dispatch(closeBoxRightContent())
  }

  const handleChangeLocale = (locale: string) => {
    if (locale === localeSelected) return
    dispatch(updateUserLocale({ userId: me.id, data: { locale } }))
      .unwrap()
      .then(() => {
        localStorage.setItem('i18nextLng', locale)
        window.location.reload()
      })
  }

  const renderIconTopBar = () => {
    return TOP_BAR_LIST.map((icon, index) => {
      const Icon = icon.icon
      const ActiveIcon = icon.activeIcon

      return (
        <Grid item key={index}>
          {indexActive === index || `/admin/${icon.id}` === location.pathname ? (
            <IconButtonTooltip
              title={t(`common.topbar.${icon.id}`)}
              icon={
                icon.id === 'notification' ? (
                  <Badge badgeContent={numberOfNotifications} color="error">
                    <ActiveIcon fontSize="medium" color="primary" />
                  </Badge>
                ) : (
                  <ActiveIcon fontSize="medium" color="primary" />
                )
              }
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={handleClickTopBarActiveIcon}
            />
          ) : (
            <IconButtonTooltip
              title={t(`common.topbar.${icon.id}`)}
              icon={
                icon.id === 'notification' ? (
                  <Badge badgeContent={numberOfNotifications} color="error">
                    <Icon fontSize="medium" />
                  </Badge>
                ) : (
                  <Icon fontSize="medium" />
                )
              }
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={() => handleClickTopBarIcon(index, icon.id)}
            />
          )}
        </Grid>
      )
    })
  }

  const handleOpenModalChangeAvatar = () => {
    dispatch(openModal({ modalId: MODAL_IDS.changeAvatar, dialogComponent: <ModalChangeAvatar /> }))
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" flexWrap="nowrap">
        <Box
          className={classes.logo}
          onClick={() => {
            // navigation(PATH.customerManagement)
            navigation('/')
          }}
        >
          <img
            src={logo}
            style={{
              height: '100%',
              objectFit: 'contain',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
            alt="danateq.vn"
          />
        </Box>

        <Box className={classes.title}>
          <Typography fontWeight={600} variant="body1" textTransform="uppercase">
            {title}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.buttonAction}>
        <Grid container>
          <Grid item>
            <Box onClick={(e) => handleOpen(e, 'userLanguage')} sx={{ cursor: 'pointer' }}>
              <img
                src={localeSelected === 'vi' ? vi : en}
                alt="locate"
                style={{
                  width: '32px',
                  height: '32px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
              />
            </Box>

            <Popover
              id={'userLanguage'}
              open={Boolean(open?.userLanguage)}
              anchorEl={anchorEl}
              onClose={() => handleClose('userLanguage')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuList>
                {LOCALE_LANGUAGE_LIST(t).map((locale) => {
                  return (
                    <MenuItem
                      onClick={() => handleChangeLocale(locale.id)}
                      selected={locale.id === localeSelected}
                      key={locale.id}
                    >
                      <Box display={'flex'} gap={1.5} alignItems={'center'}>
                        <img width={'25px'} alt="Danapod" src={locale.flag} />
                        <Typography>{locale.name}</Typography>
                      </Box>
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Popover>
          </Grid>
          {renderIconTopBar()}
        </Grid>
        <Box>
          <Box sx={{ cursor: 'pointer' }} onClick={(e) => handleOpen(e, 'userSetting')}>
            <img
              src={me.avatarUrl}
              width={'40px'}
              height={'40px'}
              style={{ borderRadius: '50%', objectFit: 'cover' }}
              alt=""
            />
          </Box>
          <Menu
            id="userSetting"
            open={Boolean(open?.userSetting)}
            anchorEl={anchorEl}
            elevation={2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => handleClose('userSetting')}
          >
            <Box sx={{ width: '320px', padding: '0 5px' }}>
              <Box width={'100%'} mt={1} display={'flex'} alignItems={'center'}>
                <Box
                  // onClick={handleOpenModalChangeAvatar}
                  sx={{
                    position: 'relative',
                    width: '80px',
                    height: '80px',
                    ml: 1,
                    // cursor: 'pointer',
                    // '&:hover .activePhoto': {
                    //   display: 'flex',
                    // },
                  }}
                >
                  <img
                    src={me.avatarUrl}
                    width={'80px'}
                    height={'80px'}
                    style={{ objectFit: 'cover', borderRadius: '50%', position: 'absolute' }}
                    alt=""
                  />
                  <Box
                    className="activePhoto"
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '50%',
                      display: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#fff',
                    }}
                  >
                    <CameraAltOutlinedIcon />
                  </Box>
                </Box>
                <Box
                  p={0.5}
                  ml={1}
                  sx={{
                    width: '220px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'600'}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {me.name}
                  </Typography>
                  <Typography>{me.email}</Typography>
                  <Link href={`${config.accountWebApp}/login`} color={'#3f50b5'}>
                    {t('label.myAccount')}
                  </Link>
                </Box>
              </Box>

              <Box
                onClick={handleOpenModal}
                flex={1}
                height={'30px'}
                display={'flex'}
                textAlign={'center'}
                alignItems={'center'}
                sx={{
                  gap: '15px',
                  padding: '15px 10px',
                  cursor: 'pointer',
                  mt: 1,
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.08)' },
                }}
              >
                <Box
                  height={'30px'}
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <LogoutIcon sx={{ fontSize: '25px' }} />
                </Box>
                <Box
                  height={'30px'}
                  sx={{
                    cursor: 'pointer',
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <Typography>{t('label.logout.main')}</Typography>
                </Box>
              </Box>
            </Box>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}

export default Topbar
