import { Box, Typography } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';

import CameraCard from 'src/components/card/camera_card';
import ErrorMessage from 'src/components/error_message';
import Loading from 'src/components/loading';
import { ICamera } from 'src/types/camera';

interface IProps {
  cameras: ICamera[];
  isLoading: boolean;
  isError: boolean;
  selectedId?: string;
  onRefresh: () => void;
  selectCamera?: (camera: ICamera) => void;
  emptyMessage?: string;
  isShowLogsIcon?: boolean;
}

const CameraList = ({
  emptyMessage,
  isLoading,
  isError,
  cameras = [],
  isShowLogsIcon,
  selectedId,
  selectCamera,
  onRefresh,
}: IProps) => {
  const { t } = useTranslation();

  const renderCamera = () => {
    if (isLoading) {
      return (
        <Box width="100%">
          <Loading />
        </Box>
      );
    }

    if (isError) {
      return <ErrorMessage sx={{ flex: 1 }} textAlign="center" onClick={onRefresh} />;
    }

    return (
      <Scrollbars>
        <Box pl={1} pr={1.5}>
          {cameras.length === 0 ? (
            <Box>
              <Typography textAlign="center">
                {emptyMessage ? emptyMessage : t('label.emptyData.main')}
              </Typography>
            </Box>
          ) : (
            <Box>
              {cameras.map((camera, index) => (
                <CameraCard
                  key={index}
                  camera={camera}
                  selectCamera={selectCamera}
                  idSelected={selectedId}
                  isShowLogsIcon={isShowLogsIcon}
                />
              ))}
            </Box>
          )}
        </Box>
      </Scrollbars>
    );
  };

  return (
    <Box display="flex" flex="1">
      {renderCamera()}
    </Box>
  );
};

export default CameraList;
