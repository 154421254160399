import {
  styled,
  Theme,
  AccordionSummary,
  AccordionSummaryProps,
  AccordionDetails,
  AccordionDetailsProps,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

export const MainNotification = styled(AccordionSummary)<AccordionSummaryProps>({
  padding: 0,
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
  },
});
export const SubNotification = styled(AccordionDetails)<AccordionDetailsProps>({
  padding: 0,
});

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: ` ${theme.spacing(0.5)}`,
    background: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    marginBottom: `${theme.spacing(1)} !important`,
    transition: '.3s easy-in-out',
    cursor: 'pointer',

    // '&:hover': {
    //   background: '#ececec',
    // },
  },

  collapeContent: {
    background: theme.palette.common.white,
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  toast: {
    width: '300px',
  },
  customBadge: {
    minWidth: '10px !important',
    minHeight: '10px !important',
    borderRadius: '50% !important',
  },
  SUCCESS: {
    background: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  hightLightText: {
    color: theme.palette.grey[900],
    // color: 'black',
  },
  normalText: {
    color: theme.palette.grey[700],
  },

  fab: {
    minHeight: '0px !important',
    width: '20px !important',
    height: '20px !important',
  },
}));
