import qs from 'query-string';
import { IShareVideo, IVideoDownload, IVideoHls, IVideoHlsBody } from 'src/types/video';
import ClientBase from './base';

export interface ClientVideoMix {
  getVideoHls: (data: IVideoHlsBody) => Promise<IVideoHls[]>;
  shareVideo: (data: IShareVideo) => Promise<string>;
  checkExportVideo: (params: IVideoDownload) => Promise<string>;
  downloadVideo: (params: IVideoDownload) => Promise<void>;
}

const ClientVideo = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientVideoMix {
    getVideoHls = async (data: IVideoHlsBody) => {
      return this.doFetch<IVideoHls[]>(`${this.getVideoRoute()}/hls`, {
        method: 'POST',
        data,
      });
    };

    shareVideo = async (data: IShareVideo) => {
      return this.doFetch<string>(`${this.getVideoRoute()}/share`, {
        method: 'POST',
        data,
      });
    };

    checkExportVideo = async (params: IVideoDownload) => {
      return this.doFetch<string>(`${this.getVideoRoute()}/checkexport`, {
        method: 'GET',
        params,
      });
    };

    downloadVideo = async (params: IVideoDownload) => {
      const href = `${this.getVideoRoute()}/export?${qs.stringify(params)}`;

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `video.flv`);
      link.setAttribute('target', '_blank');

      document.body.appendChild(link);
      link.click();
      link.remove();
    };
  };

export default ClientVideo;
