import { MutableRefObject } from 'react';
import { EStationStatus, IStation } from './station';

export type TLevelNotification = 'INFO' | 'WARNING' | 'ERROR' | 'SUCCESS' | 'CRITICAL';

export type TNotificationSource =
  | 'SystemManagement'
  | 'UserManagement'
  | 'CameraManagement'
  | 'StationManagement'
  | 'PersonDetectionAi'
  | 'Record'
  | 'Publisher'
  | 'OtherSource'
  | 'AiEvent'
  | 'Blacklist';

export interface INotification {
  id: string;
  level: TLevelNotification;
  // source: 'CAMERA' | 'USER' | 'SYSTEM' | 'RECORD' | 'STREAM';
  source: any;
  type: ENotificationType | string;
  title: {
    content: string;
    data: object;
  };
  description: {
    content: string;
    data: object;
  };
  time: string;
  isRead: boolean;
  isSeen: boolean;
  placeOfOrigin: TNotificationSource;
  data: {
    [key: string]: string;
  };
}

export interface IPayloadNotification {
  after: string;
  limit: number;
  type: string;
  placeOfOrigin: string[];
}

export interface IFilterSourceNotification {
  id: 'CAMERA' | 'USER' | 'SYSTEM' | 'RECORD' | 'STREAM';
  value: string;
}

export interface INotificationType {
  id: string;
}

export type TAudioSoundType = 'info' | 'warning' | 'error' | 'critical';

export type TAudioButton = {
  id: TAudioSoundType;
  name: string;
  source: string;
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  audioRef: MutableRefObject<HTMLAudioElement | null>;
};

interface INotificationSoundLevel {
  status: boolean;
  soundRingtone: string;
}

export type INotificationLevel = {
  [x in TAudioSoundType]: INotificationSoundLevel;
};

export interface INotINotificationSourceStatus {
  sound: boolean;
  webNotification: boolean;
  emailNotification: boolean;
  mobileNotification: boolean;
  title: string;
}
export interface INotificationSource extends INotINotificationSourceStatus {
  source: TNotificationSource;
}

export interface INotificationSetting {
  _id: string;
  userId: string;
  notificationLevels: INotificationLevel;
  notificationSources: INotificationSource[];
  popupStatus: boolean;
}

export interface IChangeNotificationSound {
  data: {
    notificationLevel: any;
  };
}

export interface IChangeNotificationSource {
  data: {
    notificationSource: INotificationSource;
  };
}

export interface IChangePopupStatus {
  data: {
    popupStatus: boolean;
  };
}

export enum ENotificationType {
  AI_BLACKLIST_VEHICLE_DETECTIOn = 'ai_blacklist_vehicle_detection',
  AI_RED_LIGHT_VIOLATION_DETECTION = 'ai_redlight_violation_detection',
}

export interface IStationNetworkUpdated {
  stationId: string;
  timestamp: number;
  newNetwork: Pick<
    IStation,
    | 'mobileNetwork'
    | 'mobileNetworkDataUsed'
    | 'mobileNetworkLastUpdatedAt'
    | 'mobileNetworkOperator'
    | 'mobileNetworkWaveStrength'
    | 'mobileNetworkConnectionStatus'
  >;
}

export interface IStationUpdateStatus {
  stationId: string;
  timestamp: number;

  status: EStationStatus;
}

export interface IStationUpdateFunction {
  stationId: string;
  timestamp: number;
  enabled: boolean;
}

export interface IStationBatteryUpdated {
  stationId: string;
  timestamp: number;
  newBattery: Pick<
    IStation,
    | 'powerBattery'
    | 'powerBatteryCapacityStatus'
    | 'powerBatteryChargeStatus'
    | 'powerBatteryLastUpdatedAt'
    | 'powerBatteryConnectionStatus'
  >;
}
