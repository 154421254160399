import { Box } from '@mui/material'
import OrganizationSubscription from './organization_subscription'
import { useParams } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars-2'
import StationSubscriptions from './station_subscriptions'

const Subscriptions = () => {
  const orgId = useParams().organization_id || ''
  return (
    <Scrollbars>
      <Box>
        <OrganizationSubscription orgId={orgId} />
      </Box>
      <Box>
        <StationSubscriptions />
      </Box>
    </Scrollbars>
  )
}

export default Subscriptions
