import { Avatar, Box, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import {
  listOrganizationStations,
  listSubscriptionStations,
  getOrganizationSubscription,
} from 'src/redux_store/organizations/slice'
import { SubscriptionItemStatuses } from 'src/types'
// import { openModal } from 'src/redux_store/common/modal/modal_slice'
// import { ModalIdentifiers } from 'src/constants/modal'
// import ManageSubscriptionStationsModal from './manage_subscription_stations_modal'

interface Props {
  orgId: string
  subscriptionId: string
}

const SubscriptionStations = ({ orgId, subscriptionId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { stations, subscriptionStations, stationSubscriptions } = useAppSelector((state) => state.organizations)

  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const subscription = stationSubscriptions.find((sub) => sub.id === subscriptionId)
  console.log(subscription)
  const fetchData = async () => {
    try {
      await dispatch(getOrganizationSubscription({ orgId, subscriptionId }))
      await dispatch(listOrganizationStations({ orgId }))
      await dispatch(listSubscriptionStations({ orgId, subscriptionId }))
    } catch (error) {
      console.log(error)
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orgId, subscriptionId])

  // const handleClick = () => {
  //   dispatch(
  //     openModal({
  //       modalId: ModalIdentifiers.MANAGE_SUBSCRIPTION_STATIONS,
  //       dialogComponent: <ManageSubscriptionStationsModal subscriptionId={subscriptionId} orgId={orgId} />,
  //     })
  //   )
  // }

  // if (requests.listSubscriptionStations.status === RequestStatusOptions.PENDING) {
  //   return (
  //     <Box display={'flex'} alignItems={'center'} justifyContent={'center'} p={3}>
  //       <CircularProgress />
  //     </Box>
  //   )
  // }

  if (hasError) {
    return (
      <Box p={3}>
        <Typography textAlign={'center'}>Something wrong! !</Typography>
      </Box>
    )
  }

  if (loading) {
    return (
      <Box display={'flex'} justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    )
  }

  if (!subscription)
    return (
      <Box p={3}>
        <Typography textAlign={'center'}>Something wrong!</Typography>
      </Box>
    )

  if (subscription.currentPeriodEnd && new Date(subscription.currentPeriodEnd) < new Date()) {
    return (
      <Box p={3}>
        <Typography>Expired Subscription</Typography>
      </Box>
    )
  }

  if (subscription.items.length === 1 && subscription.items[0].status === SubscriptionItemStatuses.UNPAID)
    return (
      <Box p={3}>
        <Typography textAlign={'center'}>Unpaid Subscription</Typography>
      </Box>
    )

  if (subscription)
    return (
      <Box>
        <Box maxWidth="500px">
          {/* <Box mb={1} display={'flex'}>
            <Button onClick={handleClick} variant="contained">
              {t('subscription.manage_stations')}
            </Button>
          </Box> */}
          {!subscriptionStations.length && (
            <Box py={2}>
              <Typography textAlign={'center'}>{t('subscription.manage_stations.no_linked')}</Typography>
            </Box>
          )}
          {stations.map((station, key) => {
            const activeStation = subscriptionStations.find((s) => s.stationId === station.id)
            if (!activeStation) return null
            return (
              <Box key={key} display={'flex'} flexDirection={'row'} py={1} alignItems={'center'}>
                <Box mr={1}>
                  <Avatar />
                </Box>
                <Box>
                  <Typography fontWeight={600}>{station.name}</Typography>
                  <Typography>{station.address}</Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
}

export default SubscriptionStations
