import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';

export const initPTZControl = createAsyncThunk<void, { cameraId: string; stationId?: string }>(
  'ptz/initPTZControl',
  async ({ cameraId, stationId }, { rejectWithValue }) => {
    try {
      await client.initPTZ(cameraId, stationId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startPTZControl = createAsyncThunk<
  void,
  {
    cameraId: string;
    data: {
      pan: string;
      tilt: string;
      zoom: string;
    };
    stationId?: string;
  }
>('ptz/startPTZControl', async ({ cameraId, stationId, data }, { rejectWithValue }) => {
  try {
    await client.startPTZControl(cameraId, data, stationId);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const stopPTZControl = createAsyncThunk<void, { cameraId: string; stationId?: string }>(
  'ptz/stopPTZControl',
  async ({ cameraId, stationId }, { rejectWithValue }) => {
    try {
      await client.stopPTZControl(cameraId, stationId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const autoFocus = createAsyncThunk<void, { cameraId: string; stationId?: string }>(
  'ptz/autoFocus',
  async ({ cameraId, stationId }, { rejectWithValue }) => {
    try {
      await client.autoFocus(cameraId, stationId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const syncDatetime = createAsyncThunk<
  void,
  {
    cameraId: string;
    stationId?: string;
  }
>('ptz/syncDatetime', async ({ cameraId, stationId }, { rejectWithValue }) => {
  try {
    await client.syncDateTime(cameraId, stationId);
  } catch (error) {
    return rejectWithValue(error);
  }
});
