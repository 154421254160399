import { DeleteRounded, MoreVertOutlined } from '@mui/icons-material'
import { Badge, Box, Chip, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Station, StationStatus, StationStatuses } from 'src/types'

interface Props {
  station: Station
}

const StationRow = ({ station }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleDelete = () => {}
  const renderStatus = (status: StationStatus) => {
    let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'default'
    switch (status) {
      case StationStatuses.ACTIVE:
        color = 'primary'
        break

      case StationStatuses.CONNECTING:
        color = 'warning'
        break
      case StationStatuses.ERROR:
        color = 'error'
        break
      case StationStatuses.INACTIVE:
        color = 'secondary'
        break
      case StationStatuses.NORMAL:
        color = 'success'
        break
      case StationStatuses.OFFLINE:
        color = 'error'
        break
      case StationStatuses.WARNING:
        color = 'warning'
        break
      default:
    }

    return <Chip label={status} color={color} sx={{ textTransform: 'capitalize' }} />
  }

  return (
    <TableRow hover>
      <TableCell>
        <Box>
          <Typography
            title={station.name}
            fontWeight={600}
            component={Link}
            to={station.id}
            sx={{
              color: indigo[500],
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            {station.name}
          </Typography>
          <Typography overflow={'hidden'} textOverflow={'ellipsis'} title={station.address}>
            {station.address}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{renderStatus(station.status)}</TableCell>
      <TableCell align="center">
        <Badge badgeContent={station.cameras.length || 3} color="secondary"></Badge>
      </TableCell>
      <TableCell>
        <IconButton onClick={handleToggle}>
          <MoreVertOutlined />
        </IconButton>
        <Menu
          id="unpaid-subscriptions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disablePortal
          elevation={1}
          sx={{ minWidth: 480, px: 1 }}
        >
          <MenuItem sx={{ width: 150 }} onClick={handleDelete}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flex={1}>
              <Typography>Delete</Typography>
              <DeleteRounded />
            </Box>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  )
}
export default StationRow
