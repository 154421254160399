import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  video: {
    backgroundColor: 'black',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  videoEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  media: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
    // paddingTop: '56.25%',
  },
  loading: {
    color: 'white !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  layer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'none',
    zIndex: 999,
    top: 0,
  },
  seeking: {
    display: 'block',
  },
}));
