import { createSlice } from '@reduxjs/toolkit';

import { ICamera } from 'src/types/camera';
import { IRole } from 'src/types/role';
import { deleteOrderRole, getOrderRoles } from './actions';

interface ICustomerMembersState {
  camerasWithoutCustomer: ICamera[];
  roles: IRole[];
}

const initialState: ICustomerMembersState = {
  camerasWithoutCustomer: [],
  roles: [],
};

const orderRoles = createSlice({
  name: 'orderRoles',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOrderRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(deleteOrderRole.fulfilled, (state, action) => {
        state.roles = state.roles.filter((role) => role.id !== action.payload);
      });
  },
});

const { reducer } = orderRoles;
export default reducer;
