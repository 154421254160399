import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getCenter from 'geolib/es/getCenter';
import { initCameraFilter, initStationFilter } from 'src/constants/filter';
import { ConfigMapTypes } from 'src/constants/map';
import { ECameraStatus, ICamera, ICameraFilter } from 'src/types/camera';
import { EMapPanel, ILocation } from 'src/types/common';
import {
  ELightStatus,
  IStation,
  IStationFilter,
  IUpdateStationGPS,
  IUpdateWorkingModeSocket,
} from 'src/types/station';
import { getCamerasMap, getStationDetail, getStationsMap, toggleStationLight } from './map_action';
import _, { isArray } from 'lodash';
import {
  IStationBatteryUpdated,
  IStationNetworkUpdated,
  IStationUpdateStatus,
} from 'src/types/notification';
import {
  stationBatteryUpdatedUtil,
  stationGPSUpdatedUtil,
  stationMobileNetworkUpdatedUtil,
  updateStationBatteryDisconnectedUtil,
  updateStationMobileNetworkDisconnectedUtil,
  updateStationStatusUtil,
} from 'src/utils/station';
import { EGpsStatus } from 'src/types/enum';
import {
  deleteCameraOfCamerasUtil,
  deleteCameraUtil,
  updateStatusOfCameraUtil,
  updateStatusOfCamerasUtil,
} from 'src/utils/camera';

interface IMapState {
  mapPanel: EMapPanel;
  viewPanel: 'list' | 'map';
  camSelected: ICamera | null;
  stationSelected: IStation | null;
  stationDetail: IStation | null;
  defaultCenter: ILocation;
  stations: {
    data: IStation[];
    filter: IStationFilter;
    cameras: ICamera[];
  };
  cameras: {
    data: ICamera[];
    filter: ICameraFilter;
  };
}

const initialState: IMapState = {
  mapPanel: EMapPanel.CAMERA,
  viewPanel: 'map',
  camSelected: null,
  stationSelected: null,
  defaultCenter: ConfigMapTypes.location,
  stationDetail: null,
  stations: {
    data: [],
    cameras: [],
    filter: initStationFilter,
  },
  cameras: {
    data: [],
    filter: initCameraFilter,
  },
};

const MapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setViewPanel: (state: IMapState, action: PayloadAction<'list' | 'map'>) => {
      state.viewPanel = action.payload;
    },

    setMapPanel: (state: IMapState, action: PayloadAction<EMapPanel>) => {
      state.mapPanel = action.payload;
      state.camSelected = null;
      state.stationSelected = null;
      state.camSelected = null;
    },

    selectStationMap: (state: IMapState, action: PayloadAction<IStation | null>) => {
      state.stationSelected = action.payload;
    },

    selectCameraMap: (state: IMapState, action: PayloadAction<ICamera | null>) => {
      state.camSelected = action.payload;
    },

    resetStationMapView: (state: IMapState) => {
      state.stationSelected = initialState.stationSelected;
      state.camSelected = initialState.camSelected;
      state.cameras = initialState.cameras;
    },

    resetMapState: (state: IMapState) => {
      state.camSelected = initialState.camSelected;
      state.cameras = initialState.cameras;
      state.stations = initialState.stations;
      state.stationDetail = initialState.stationDetail;
      state.defaultCenter = initialState.defaultCenter;
    },

    updateLightStatus: (
      state: IMapState,
      action: PayloadAction<{
        stationId: string;

        status: ELightStatus;
      }>,
    ) => {
      const { stationId, status } = action.payload;
      let currentStationInfo = state.stationDetail ? { ...state.stationDetail } : null;
      if (!_.isEmpty(currentStationInfo) && stationId === currentStationInfo.id) {
        currentStationInfo = { ...currentStationInfo, lightStatus: status };
        state.stationDetail = currentStationInfo;
      }
    },

    stationBatteryUpdatedInMap: (
      state: IMapState,
      action: PayloadAction<IStationBatteryUpdated>,
    ) => {
      const { stationId, newBattery, timestamp } = action.payload;
      const stations = [...state.stations.data];

      const station = state.stationDetail ? { ...state.stationDetail } : null;
      const { stationsCurrent, currentStationInfo } = stationBatteryUpdatedUtil(stations, station, {
        stationId,
        newBattery,
        timestamp,
      });

      state.stations.data = stationsCurrent;
      state.stationDetail = currentStationInfo;
    },

    stationMobileNetworkUpdatedInMap: (
      state: IMapState,
      action: PayloadAction<IStationNetworkUpdated>,
    ) => {
      const { stationId, newNetwork, timestamp } = action.payload;
      const stations = [...state.stations.data];

      const station = state.stationDetail ? { ...state.stationDetail } : null;

      const { stationsCurrent, currentStationInfo } = stationMobileNetworkUpdatedUtil(
        stations,
        station,
        {
          stationId,
          newNetwork,
          timestamp,
        },
      );

      state.stations.data = stationsCurrent;
      state.stationDetail = currentStationInfo;
    },

    stationMobileNetworkDisconnectedInMap: (
      state: IMapState,
      action: PayloadAction<{ stationId: string; timestamp: number }>,
    ) => {
      const stations = [...state.stations.data];
      const stationDetail = state.stationDetail ? { ...state.stationDetail } : null;
      const { stationId, timestamp } = action.payload;

      const { currentStationInfo, stationsCurrent } = updateStationMobileNetworkDisconnectedUtil(
        stations,
        stationDetail,
        {
          stationId,
          timestamp,
        },
      );

      state.stations.data = stationsCurrent;
      state.stationDetail = currentStationInfo;
    },

    updateStationStatusInMap: (state: IMapState, action: PayloadAction<IStationUpdateStatus>) => {
      const { stationId, status, timestamp } = action.payload;

      const currentStations = [...state.stations.data];
      const currentInfo = state.stationDetail ? { ...state.stationDetail } : null;
      const { stationsCurrent, currentStationInfo } = updateStationStatusUtil(
        currentStations,
        currentInfo,
        {
          stationId,
          status,
          timestamp,
        },
      );

      state.stations.data = stationsCurrent;
      state.stationDetail = currentStationInfo;
    },

    deleteStationLocalInMap: (state: IMapState, action: PayloadAction<string>) => {
      const stationId = action.payload;
      const currentStations = [...state.stations.data];
      if (currentStations.length) {
        const index = currentStations.findIndex((station) => station.id === stationId);

        if (index !== -1) {
          currentStations.splice(index, 1);
          state.stations.data = currentStations;
        }
      }

      if (state.stationDetail && state.stationDetail.id === stationId) {
        state.stationDetail = null;
      }

      if (state.stationSelected && state.stationSelected.id === stationId) {
        state.stationSelected = null;
      }
    },

    addStationsLocalInMap: (state: IMapState, action: PayloadAction<IStation>) => {
      const currentStations = [...state.stations.data];
      const station = action.payload;

      const index = currentStations.findIndex((item) => item.id === station.id);
      if (index === -1) {
        currentStations.push(station);
      } else {
        currentStations.splice(index, 1, station);
      }

      state.stations.data = currentStations;
    },

    stationGpsDisconnectedInMap: (
      state: IMapState,
      action: PayloadAction<{ stationId: string; gpsStatus: EGpsStatus; timestamp: number }>,
    ) => {
      const { stationId, gpsStatus, timestamp } = action.payload;
      const currentStations = [...state.stations.data];
      let currentStationInfo = state.stationDetail ? { ...state.stationDetail } : null;

      if (currentStations.length) {
        const index = currentStations.findIndex((station) => station.id === stationId);
        if (index !== -1) {
          const newStationInfo: IStation = {
            ...currentStations[index],
            gpsConnectionStatus: gpsStatus,
            gpsLastUpdatedAt: new Date(timestamp).toDateString(),
          };

          currentStations.splice(index, 1, newStationInfo);
          state.stations.data = currentStations;
        }
      }

      if (!_.isEmpty(currentStationInfo) && currentStationInfo.id === stationId) {
        currentStationInfo = { ...currentStationInfo, gpsConnectionStatus: gpsStatus };

        state.stationDetail = currentStationInfo;
      }
    },

    stationBatteryDisconnectedInMap: (
      state: IMapState,
      action: PayloadAction<{ stationId: string; timestamp: number }>,
    ) => {
      const { stationId, timestamp } = action.payload;
      const stations = [...state.stations.data];
      const stationInfo = state.stationDetail ? { ...state.stationDetail } : null;

      const { stationsCurrent, currentStationInfo } = updateStationBatteryDisconnectedUtil(
        stations,
        stationInfo,
        {
          stationId,
          timestamp,
        },
      );
      state.stations.data = stationsCurrent;
      state.stationDetail = currentStationInfo;
    },

    stationGPSConnectedInMap: (state: IMapState, action: PayloadAction<IUpdateStationGPS>) => {
      const stations = [...state.stations.data];
      const station = state.stationDetail ? { ...state.stationDetail } : null;
      const { stationsCurrent, currentStationInfo } = stationGPSUpdatedUtil(
        stations,
        station,
        action.payload,
      );

      state.stations.data = stationsCurrent;
      state.stationDetail = currentStationInfo;
    },

    updateStationWorkingModeSocketInMap: (
      state: IMapState,
      action: PayloadAction<IUpdateWorkingModeSocket>,
    ) => {
      const currentStationDetail = state.stationDetail ? { ...state.stationDetail } : null;
      const { stationId, timestamp, newWorkingMode } = action.payload;

      if (currentStationDetail && currentStationDetail.id === stationId) {
        const newData = Object.assign(currentStationDetail, newWorkingMode);
        state.stationDetail = newData;
      }
    },

    updateCameraStatusInMap: (
      state: IMapState,
      action: PayloadAction<{ cameraId: string; timestamp: number; status: ECameraStatus }>,
    ) => {
      const { cameraId, status } = action.payload;

      //camera Of station
      const stationDetail = state.stationDetail ? { ...state.stationDetail } : null;

      if (stationDetail && stationDetail.cameras?.length) {
        const stationCameras = [...stationDetail.cameras];
        const newCameras = updateStatusOfCamerasUtil(stationCameras, cameraId, status);
        stationDetail.cameras = newCameras;

        state.stationDetail = stationDetail;
      }

      //camera In Map and Camera List

      const cameras = [...state.cameras.data];
      if (cameras.length) {
        const newCameras = updateStatusOfCamerasUtil(cameras, cameraId, status);
        state.cameras.data = newCameras;
      }

      const cameraSelected = state.camSelected ? { ...state.camSelected } : null;

      const newCamera = updateStatusOfCameraUtil(cameraSelected, cameraId, status);
      state.camSelected = newCamera;
    },

    deleteCameraInMap: (state: IMapState, action: PayloadAction<string>) => {
      const cameraId = action.payload;
      const stationDetail = state.stationDetail ? { ...state.stationDetail } : null;
      if (stationDetail && stationDetail.cameras?.length) {
        const stationCameras = [...stationDetail.cameras];
        const newCameras = deleteCameraOfCamerasUtil(stationCameras, cameraId);
        stationDetail.cameras = newCameras;

        state.stationDetail = stationDetail;
      }

      //camera In Map and Camera List

      const cameras = [...state.cameras.data];
      const newCameras = deleteCameraOfCamerasUtil(cameras, cameraId);
      state.cameras.data = newCameras;

      const cameraSelected = state.camSelected ? { ...state.camSelected } : null;

      const newCamera = deleteCameraUtil(cameraSelected, cameraId);
      state.camSelected = newCamera;
    },
  },

  extraReducers(builder) {
    builder.addCase(getCamerasMap.pending, (state, action) => {
      state.cameras.filter = action.meta.arg;
    });
    builder.addCase(getCamerasMap.fulfilled, (state, action) => {
      if (isArray(action.payload) && action.payload.length) {
        const coords = action.payload.map((item) => ({ latitude: item.lat, longitude: item.lng }));
        const center = getCenter(coords);

        if (!center) {
          state.defaultCenter = initialState.defaultCenter;
        } else {
          state.defaultCenter = {
            lat: center.latitude,
            lng: center.longitude,
          };
        }
      } else {
        state.defaultCenter = initialState.defaultCenter;
      }

      state.cameras.data = action.payload;
      state.cameras.filter = action.meta.arg;
    });
    builder.addCase(getStationsMap.pending, (state, action) => {
      state.stations.filter = action.meta.arg;
    });
    builder.addCase(getStationsMap.fulfilled, (state, action) => {
      const data = action.payload || [];
      state.stations.data = data;
      state.stations.filter = action.meta.arg;
      if (data.length === 0) {
        state.stationSelected = null;
        state.defaultCenter = initialState.defaultCenter;
      } else {
        const coords = data.map((item) => ({ latitude: item.lat, longitude: item.lng }));
        const center = getCenter(coords);

        if (!center) {
          state.defaultCenter = initialState.defaultCenter;
        } else {
          state.defaultCenter = {
            lat: center.latitude,
            lng: center.longitude,
          };
        }
      }
    });

    builder.addCase(getStationDetail.fulfilled, (state, action) => {
      state.stationDetail = action.payload;
    });

    builder.addCase(toggleStationLight.fulfilled, (state, action) => {
      const stationId = action.meta.arg.stationId;
      const lightStatus = action.payload.lightStatus;

      if (state.stationDetail && state.stationDetail.id === stationId) {
        state.stationDetail = {
          ...state.stationDetail,
          lightStatus,
        };
      }

      const index = state.stations.data.findIndex((station) => station.id === stationId);
      if (index !== -1) {
        const stations = [...state.stations.data];
        stations.splice(index, 1, { ...stations[index], lightStatus });
        state.stations.data = stations;
      }
    });
  },
});

export const {
  selectCameraMap,
  selectStationMap,
  setMapPanel,
  resetMapState,
  setViewPanel,
  updateLightStatus,
  resetStationMapView,
  stationBatteryUpdatedInMap,
  stationMobileNetworkUpdatedInMap,
  stationMobileNetworkDisconnectedInMap,
  updateStationStatusInMap,
  deleteStationLocalInMap,
  stationGpsDisconnectedInMap,
  stationBatteryDisconnectedInMap,
  stationGPSConnectedInMap,
  updateStationWorkingModeSocketInMap,
  updateCameraStatusInMap,
  deleteCameraInMap,
  addStationsLocalInMap,
} = MapSlice.actions;

export default MapSlice.reducer;
