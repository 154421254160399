import { ICommune, IDistrict, IProvince } from 'src/types/political';
import ClientBase from './base';

export interface ClientPoliticalMix {
  getProvinces: () => Promise<IProvince[]>;
  getDistricts: (provinceCode: string) => Promise<IDistrict[]>;
  getCommunes: (districtCode: string) => Promise<ICommune[]>;
}

const ClientPolitical = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientPoliticalMix {
    getProvinces = () => {
      return this.doFetch<IProvince[]>(`${this.getPoliticalRoute()}/provinces`, {
        method: 'GET',
      });
    };

    getDistricts = (province: string) => {
      return this.doFetch<IDistrict[]>(`${this.getPoliticalRoute()}/districts`, {
        method: 'GET',
        params: {
          province,
        },
      });
    };

    getCommunes = (district: string) => {
      return this.doFetch<ICommune[]>(`${this.getPoliticalRoute()}/communes`, {
        method: 'GET',
        params: {
          district,
        },
      });
    };
  };

export default ClientPolitical;
