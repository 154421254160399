import { createSlice } from '@reduxjs/toolkit';

import { IProductPackage } from 'src/types/customer';
import { getPackagesByProductId } from './actions';

interface IProductPackageState {
  productPackages: IProductPackage[];
}

const initialState: IProductPackageState = {
  productPackages: [],
};

const productPackageSlice = createSlice({
  name: 'productPackages',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPackagesByProductId.fulfilled, (state, action) => {
      state.productPackages = action.payload;
    });
  },
});

const { reducer } = productPackageSlice;

export default reducer;
