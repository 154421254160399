import { IPermission } from 'src/types/permission';
import ClientBase from './base';

export interface ClientPermissionMix {
  getPermission: (roleLevel: number) => Promise<IPermission>;
}

const ClientPermission = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientPermissionMix {
    getPermission = async (roleLevel: number) => {
      return this.doFetch<IPermission>(`${this.getRoleRoute()}/${roleLevel}/permissions`, {
        method: 'get',
      });
    };
  };

export default ClientPermission;
