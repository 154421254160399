import { StickyNote2Outlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { BiCctv } from 'react-icons/bi';

import { useDrag } from 'react-dnd';
import LogsModal from 'src/components/logs/logs_modal';
import IconButtonTooltip from 'src/components/tooltip/icon_button_tooltip';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { openModal } from 'src/redux_store/common/modal/modal_slice';
import theme from 'src/theme';
import { ECameraStatus, ICameraItemProps } from 'src/types/camera';
import { dragPlayerCollect, dragPlayerEnd } from 'src/utils/stream';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

function CameraCard(props: ICameraItemProps) {
  const { icon, camera, idSelected, selectCamera, isShowLogsIcon } = props;
  const classes = useStyles();

  const { cameraFollowed } = useAppSelector(({ watchlistSlice }) => watchlistSlice);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [, drag] = useDrag(() => ({
    type: 'box',
    item: props,
    end: (item, monitor) => dragPlayerEnd(item, monitor, dispatch),
    collect: (monitor) => dragPlayerCollect(monitor),
  }));

  const renderIconColor = (status: ECameraStatus) => {
    if (status === ECameraStatus.OFFLINE) return theme.palette.secondary.main;
    if (status === ECameraStatus.NORMAL) return theme.palette.success.main;
    if (status === ECameraStatus.ERROR) return theme.palette.error.main;
    if (status === ECameraStatus.WARNING) return theme.palette.warning.main;
  };

  const handleClickCamera = () => {
    if (selectCamera) {
      selectCamera(camera);
    }
  };

  const handleLogsClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    dispatch(
      openModal({
        modalId: 'logsModal',
        dialogComponent: <LogsModal camera={camera} modalId="logsModal" />,
      }),
    );
  };

  const isEqual = !selectCamera
    ? cameraFollowed.find((cameraFl) => {
        return cameraFl?.camera?.id === camera.id;
      })?.camera?.id
    : idSelected === camera.id;

  return (
    <Box
      onClick={handleClickCamera}
      className={classNames(classes.flex, classes.card, {
        [classes.activeCard]: isEqual,
      })}
      ref={!selectCamera ? drag : null}
    >
      <Box className={classes.flex}>
        {icon ? icon : <BiCctv color={renderIconColor(camera.status)} size={36} />}
      </Box>
      <Box className={classes.cardContent}>
        <Box display="flex" alignItems="center">
          <Typography
            flex={1}
            noWrap
            className={classes.textEllipsis}
            fontWeight={600}
            style={{ color: theme.palette.secondary.main }}
          >
            {camera.name}
          </Typography>
          {isShowLogsIcon && (
            <Box display="flex" paddingLeft={0.5}>
              <IconButtonTooltip
                title={t('label.logList')}
                icon={<StickyNote2Outlined />}
                onClick={handleLogsClick}
                style={{ color: theme.palette.secondary.main }}
              />
            </Box>
          )}
        </Box>

        <Typography
          noWrap
          style={{
            color: theme.palette.secondary.dark,
            margin: 0,
          }}
          title={camera.address}
        >
          {camera.address}
        </Typography>
      </Box>
    </Box>
  );
}

export default CameraCard;
