import { createSlice } from '@reduxjs/toolkit';
import { TStreamMode } from 'src/types/stream';

interface IStreamSliceState {
  mode: TStreamMode;
  isPtz: boolean;
  isTheaterMode: boolean;
  wsReconnect: boolean;
  hasOnline: boolean;
}

const initialState: IStreamSliceState = {
  mode: 'live',
  isPtz: false,
  isTheaterMode: false,
  wsReconnect: false,
  hasOnline: navigator.onLine || false,
};

const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    changeStreamMode: (state, action) => {
      state.mode = action.payload;
    },
    togglePTZ: (state) => {
      state.isPtz = !state.isPtz;
      if (state.isPtz) {
        state.isTheaterMode = true;
      }
    },
    toggleTheaterMode: (state) => {
      state.isTheaterMode = !state.isTheaterMode;
      if (!state.isTheaterMode) {
        state.mode = 'live';
        state.isPtz = false;
      }
    },
    resetTheaterMode: (state) => {
      state.isTheaterMode = false;
      state.mode = 'live';
      state.isPtz = false;
    },
    setWsReconnection: (state, action) => {
      state.wsReconnect = action.payload;
    },
    setHasOnline: (state, action) => {
      state.hasOnline = action.payload;
    },
  },
});

const { actions, reducer } = streamSlice;
export const {
  changeStreamMode,
  togglePTZ,
  toggleTheaterMode,
  resetTheaterMode,
  setWsReconnection,
  setHasOnline,
} = actions;
export default reducer;
