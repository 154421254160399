export const MODAL_IDS = {
  addRole: 'addRole',
  updateRole: 'updateRole',
  confirmDelete: 'confirmDelete',

  inviteUser: 'inviteUser',
  addFollowGroup: 'addFollowGroup',
  notificationSetting: 'notificationSetting',
  deleteNotification: 'deleteNotification',
  addUser: 'addUser',
  deleteUser: 'deleteUser',
  relayPassword: 'relayPassword',
  changeAvatar: 'changeAvatar',

  player: {
    exportVideo: 'exportVideo',
  },
  customer: {
    add: 'add',
    edit: 'edit',
    orders: {
      add: 'add',
      addCamera: 'addCamera',
      updateCamera: 'updateCamera',
      addStation: 'addStation',
      updateStation: 'updateStation',
      addMembers: 'addMembers',
      addOrderMembers: 'addOrderMembers',
      addRoles: 'addRoles',
    },
    inviteOwner: 'inviteOwner',
    removeOwner: 'removeOwner',
  },

  unmountConfirm: 'unmountConfirm',

  logout: 'logout',
  reloadPage: 'reloadPage',
  autoLogout: 'autoLogout',
};
