import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { IProductPackage } from 'src/types/customer';

export const getPackagesByProductId = createAsyncThunk<IProductPackage[], string>(
  'productPackages/getPackagesByProductId',
  async (productId, { rejectWithValue }) => {
    try {
      const productPackages = await client.getPackagesByProductId(productId);
      return productPackages;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
