import {
  Box,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { listOrganizationStations } from 'src/redux_store/organizations/slice'
import { RequestStatusOptions } from 'src/types'
import StationRow from './station_row'
import Scrollbars from 'react-custom-scrollbars-2'

const Stations = () => {
  const { t } = useTranslation()
  const orgId = useParams().organization_id || ''
  const dispatch = useAppDispatch()

  const { stations, requests } = useAppSelector((state) => state.organizations)

  useEffect(() => {
    dispatch(listOrganizationStations({ orgId }))
  }, [dispatch, orgId])

  if (requests.listOrganizationStations.status === RequestStatusOptions.PENDING)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={10}>
        <CircularProgress />
      </Box>
    )

  if (requests.listOrganizationStations.status === RequestStatusOptions.REJECTED) {
    return (
      <Box>
        <Typography>Something wrong!</Typography>
      </Box>
    )
  }

  return (
    <Scrollbars>
      <Box flex={1}>
        <Container>
          <Box p={2}>
            <Box mb={2}>
              <Typography variant="h6">{t('station')}</Typography>
            </Box>
            <Box>
              <TableContainer component={Box} sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {t('station')}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                    <Typography variant="subtitle1">{t('station.address')}</Typography>
                  </TableCell> */}
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {t('station.status')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {t('station.cameras')}
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stations.map((station, index) => (
                      <StationRow station={station} key={index} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!stations.length && (
                <Box p={3} width={'100%'}>
                  <Typography textAlign={'center'}>{t('label.station.empty')}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </Scrollbars>
  )
}

export default Stations
