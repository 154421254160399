import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { IUser } from 'src/types/auth';
import { IPagination } from 'src/types/common';
import { ICustomer } from 'src/types/customer';
import { IRole } from 'src/types/role';
import { IUpdateActive, IUpdateUserInfo, IUserFilter } from 'src/types/user';
import { toastMessage } from 'src/utils/toast';

export const getUsers = createAsyncThunk<IPagination<IUser>, IUserFilter>(
  'user/getUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getUsers(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getUserInfo = createAsyncThunk<IUser, string>(
  'user/getInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getUserInfo(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateActive = createAsyncThunk<{ message: string }, IUpdateActive>(
  'user/updateActive',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateActive(payload);

      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updateUserInfo = createAsyncThunk<IUser, { data: IUpdateUserInfo; userId: string }>(
  'user/updateInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateUserInfo(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateUserRole = createAsyncThunk<IRole, { userId: string; roleId: string }>(
  'user/updateUserRole',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateUserRole(payload.userId, payload.roleId);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteUser = createAsyncThunk<{ message: string }, string>(
  'user/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.deleteUser(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const verifyEmailUser = createAsyncThunk<
  { message: string },
  { email: string; userId: string }
>('user/verifyEmailUser', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.verifyEmailUser(payload);
    return data;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const revokeAllUserSessions = createAsyncThunk<{ message: string }, string>(
  'user/revokeAll',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.revokeAllUserSessions(payload);

      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const inviteUsers = createAsyncThunk<
  string,
  { userId: string; email: string; roleId: string }
>('user/inviteUsers', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.inviteUsers(payload);

    return data;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const getListCustomersWhichUser = createAsyncThunk<ICustomer[], string>(
  'user/getListCustomersWhichUser',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getListCustomersWhichUser(payload);

      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
