import React from 'react';
import { EBatteryChargeStatus, EPowerBatteryStatus, EStationStatus } from 'src/types/station';
import StationBatterySmall from './station_battery_small';
import StationBatteryMedium from './station_battery_medium';
import { getColorIconStation } from '../utils';
import theme from 'src/theme';
import { isStationLevelWarning } from 'src/utils/station';

type Props = {
  battery: number;
  batteryChargeStatus: EBatteryChargeStatus;
  stationStatus: EStationStatus;
  size?: 'medium' | 'small';
  batteryConnectionStatus: EPowerBatteryStatus;
  powerBattery: boolean;
};

const RANGE_BATTERY = 20;

function StationBattery({
  battery,
  batteryChargeStatus,
  batteryConnectionStatus,
  stationStatus,
  size,
  powerBattery,
}: Props) {
  const getColorBattery = () => {
    const stationColor = getColorIconStation(stationStatus);
    if (isStationLevelWarning(stationStatus)) return stationColor;
    if (stationStatus === EStationStatus.NORMAL) {
      if (batteryConnectionStatus === EPowerBatteryStatus.DISCONNECT || battery < RANGE_BATTERY)
        return theme.palette.error.main;
    }

    return stationColor;
  };

  const getStrokeColor = () => {
    const stationColor = getColorIconStation(stationStatus);

    if (isStationLevelWarning(stationStatus)) return stationColor;
    if (
      stationStatus !== EStationStatus.OFFLINE &&
      batteryConnectionStatus === EPowerBatteryStatus.DISCONNECT
    )
      return theme.palette.error.main;
    return stationColor;
  };

  if (size === 'small')
    return (
      <StationBatterySmall
        color={getColorBattery()}
        strokeColor={getStrokeColor()}
        battery={battery}
        batteryChargeStatus={batteryChargeStatus}
        stationStatus={stationStatus}
        batteryConnectionStatus={batteryConnectionStatus}
        powerBattery={powerBattery}
      />
    );

  return (
    <StationBatteryMedium
      color={getColorBattery()}
      strokeColor={getStrokeColor()}
      battery={battery}
      batteryChargeStatus={batteryChargeStatus}
      stationColor={getColorIconStation(stationStatus)}
      stationStatus={stationStatus}
      batteryConnectionStatus={batteryConnectionStatus}
      powerBattery={powerBattery}
    />
  );
}

export default StationBattery;
