import { v4 as uuidv4 } from 'uuid';

import config from 'src/config';
import { IRequestMessage } from '../types/message';
import { IActions } from '../types/player';
import { debugLog } from '../utils/log';
import { signaling } from './signaling';
import { setError } from 'src/redux_store/watchlist/watchlist_slice';
import store from 'src/redux_store';
import i18n from 'src/i18n';

let peerConnectionLimit = 0;
let peerConnectionIssue = 0;

const { turnAddress: urls, turnPassword: credential, turnUsername: username } = config.iceServers;

const RTCConfig: RTCConfiguration =
  urls && credential && username
    ? {
        iceServers: [
          {
            urls,
            username,
            credential,
          },
        ],
        iceTransportPolicy: 'all',
      }
    : {};

class RTC {
  pc?: RTCPeerConnection | null;
  video?: HTMLVideoElement;
  cameraId: string;
  viewerId: string;
  iceCandidateQueue: any[];
  actions: IActions;
  audioStarting?: boolean;
  isSupportAudio?: boolean;
  peerConnectionTimeoutId?: any;
  getNetWorkStatusId?: any;

  constructor(
    cameraId: string,
    actions: IActions,
    audioStarting?: boolean,
    isSupportAudio?: boolean,
  ) {
    this.cameraId = cameraId;
    this.viewerId = uuidv4();
    this.iceCandidateQueue = [];
    this.actions = actions;
    this.audioStarting = audioStarting;
    this.isSupportAudio = isSupportAudio;
    this.peerConnectionTimeoutId = undefined;
    this.getNetWorkStatusId = undefined;
  }

  getViewerId = () => {
    this.viewerId = uuidv4();
    return this.viewerId;
  };

  initTrack = (video?: HTMLVideoElement) => {
    debugLog(`RTC.initTrack, cameraId: ${this.cameraId}, viewerId: ${this.viewerId}`);

    this.video = video ? video : this.video;
    signaling.sendMessage({
      id: 'camera',
      cameraId: this.cameraId,
      viewerId: this.viewerId,
    });
  };

  getNetWorkStatus = () => {
    if (!this.pc) return;

    this.pc.getStats().then((stats: RTCStatsReport) => {
      stats.forEach((report) => {
        if (report.type === 'candidate-pair') {
          const {
            // currentRoundTripTime: rtt = 0,
            // availableOutgoingBitrate: sendBandwidth = 0,
            // availableIncomingBitrate: recvBandwidth = 0,
            currentRoundTripTime = 0,
          } = report;
          if (currentRoundTripTime >= 3) {
            peerConnectionIssue += 1;

            if (peerConnectionIssue >= 5) {
              store.dispatch(setError({ networkIssue: true }));
              peerConnectionIssue = 0;
            }
          }
        }

        // if (report.type === 'inbound-rtp') {
        //   console.log('viewerId:', this.viewerId, 'report.type === inbound-rtp', report);
        // }
        // if (report.type === 'outbound-rtp') {
        //   console.log('report.type === outbound-rtp', report);
        // }
      });
    });
  };

  startAudioStream = async () => {
    if (!window.localStream) return;
    const localStream: MediaStream = window.localStream;
    localStream.getAudioTracks().forEach((track) => this.pc?.addTrack(track, localStream));
  };

  stopAudioStream = () => {
    if (!window.localStream) return;
    const localStream: MediaStream = window.localStream;
    localStream.getAudioTracks().forEach((track) => track.stop());
  };

  createPcPeerConnection = async () => {
    try {
      this.pc = new RTCPeerConnection(RTCConfig);
      debugLog(`RTC.createPcPeerConnection created pc: ${RTCConfig}`);

      peerConnectionLimit += 1;

      if (!this.getNetWorkStatusId) {
        this.getNetWorkStatusId = setInterval(this.getNetWorkStatus, 10000);
      }

      if (this.audioStarting) {
        this.startAudioStream();
      }

      this.pc.onicecandidate = (e) => {
        if (!e.candidate) return;
        this.onIceCandidate(e.candidate);
      };

      this.pc.ontrack = (e) => {
        if (!this.video) return;
        this.video.srcObject = e.streams[0];
      };

      this.pc.onicecandidateerror = (ev: any) => {
        debugLog({
          'RTC.createPcPeerConnection.onicecandidateerror': ev,
        });

        // this.actions.setError({ message: ev.errorText || 'Lỗi không xác định' });
        this.actions.setError({
          message: ev.errorText || i18n.t('message.error.unknow'),
          id: 'rtcError',
        });
      };

      this.pc.oniceconnectionstatechange = (ev: any) => {
        let timeout = 0;
        if (this.pc?.iceConnectionState === 'disconnected') {
          debugLog({
            'RTC.createPcPeerConnection.oniceconnectionstatechange': ev,
          });

          if (this.peerConnectionTimeoutId) return;

          this.peerConnectionTimeoutId = setInterval(() => {
            timeout++;

            if (timeout >= 6) {
              // this.actions.setError({ message: 'Đã xảy ra lỗi khi kết nối đến máy chủ' });
              this.actions.setError({
                message: i18n.t('message.error.connect.server'),
                id: 'rtcError',
              });
            }
          }, 1000);

          // this.close();
          // this.initTrack(this.video as HTMLVideoElement);
        }
      };

      if (!this.audioStarting) {
        this.actions.waiting();
      }

      await this.createOffer();

      // if (!this.pc || !this.viewerId) return;
    } catch (error: any) {
      console.error({ error });
      if (error?.message && peerConnectionLimit >= 256) {
        this.actions.setError({
          // message: 'Không thể tạo kết nối',
          message: i18n.t('message.error.connect.notCreated'),
          detail: 'peerConnectionLimit',
          id: 'rtcError',
        });

        store.dispatch(setError({ peerConnectionLimit: true }));
      }
    }
  };

  createOffer = async () => {
    if (!this.pc) return;

    const offerToReceiveAudio = this.audioStarting ? false : this.isSupportAudio || false,
      offerToReceiveVideo = this.audioStarting ? false : true;

    const offer = await this.pc.createOffer({
      offerToReceiveAudio,
      offerToReceiveVideo,
    });

    const message: IRequestMessage = {
      id: 'startViewer',
      cameraId: this.cameraId,
      viewerId: this.viewerId,
      sdpOffer: offer.sdp,
    };

    signaling.sendMessage(message);
    this.pc.setLocalDescription(offer);
  };

  acceptOffer = async (type: RTCSdpType, sdp: string) => {
    debugLog({ 'RTC.acceptOffer': this.pc, cameraId: this.cameraId, viewerId: this.viewerId });

    if (!this.pc) return;

    await this.pc.setRemoteDescription({
      type,
      sdp,
    });

    if (this.iceCandidateQueue.length > 0) {
      while (this.iceCandidateQueue.length) {
        const candidate = this.iceCandidateQueue.shift();
        // debugLog({
        //   'Signaling.acceptOffer.iceCandidateQueue.length candidate': candidate,
        //   cameraId: this.cameraId,
        // });

        this.pc.addIceCandidate({
          candidate: candidate.candidate,
          sdpMid: candidate.sdpMid,
          sdpMLineIndex: candidate.sdpMLineIndex,
        });
      }
    }

    if (this.audioStarting) {
      this.toggleAudio('startAudio');
    }
  };

  onIceCandidate = (candidate: RTCIceCandidate) => {
    const message: IRequestMessage = {
      id: 'iceCandidate',
      cameraId: this.cameraId,
      viewerId: this.viewerId,
      iceCandidate: candidate,
    };

    signaling.sendMessage(message);
  };

  toggleAlarm = (id: 'startAlarm' | 'stopAlarm') => {
    const message: IRequestMessage = {
      id,
      cameraId: this.cameraId,
      viewerId: this.viewerId,
    };

    signaling.sendMessage(message);
  };

  toggleAudio = (id: 'startAudio' | 'stopAudio') => {
    const message: IRequestMessage = {
      id,
      cameraId: this.cameraId,
      viewerId: this.viewerId,
    };

    signaling.sendMessage(message);
  };

  close = () => {
    debugLog(`RTC.close cameraId: ${this.cameraId}, viewerId: ${this.viewerId}`);

    signaling.deleteRtc(this.viewerId);
    if (this.peerConnectionTimeoutId) {
      clearInterval(this.peerConnectionTimeoutId);
      this.peerConnectionTimeoutId = undefined;
    }

    if (this.getNetWorkStatusId) {
      clearInterval(this.getNetWorkStatusId);
      this.getNetWorkStatusId = undefined;
    }

    if (!this.pc) return;

    const message: IRequestMessage = {
      id: 'stop',
      cameraId: this.cameraId,
      viewerId: this.viewerId,
    };

    signaling.sendMessage(message);
    this.pc.close();
  };
}

export default RTC;
