import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import client from 'src/clients/http'
import {
  IPagination,
  Organization,
  OrganizationInvitation,
  OrganizationMember,
  Payment,
  Plan,
  RequestStatus,
  RequestStatusOptions,
  Subscription,
  SubscriptionPlan,
  SubscriptionStation,
} from 'src/types'

export const listPlans = createAsyncThunk<Plan[]>('list_plans', async (_, { rejectWithValue }) => {
  try {
    const plans = await client.listPlans()
    return plans
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const listSubscriptionPlans = createAsyncThunk<SubscriptionPlan[], { planId: string }>(
  'list_subscription_plans',
  async (payload, { rejectWithValue }) => {
    try {
      const subPlans = await client.listSubscriptionPlans(payload.planId)
      return subPlans
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const listOrganizations = createAsyncThunk<
  IPagination<Organization>,
  { searchKeyword: string; page: number; limit: number }
>('list_organizations', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.listOrganizations({ ...payload })
    return data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getOrganization = createAsyncThunk<Organization, { orgId: string }>(
  'get_organization',
  async (payload, { rejectWithValue }) => {
    try {
      const org = await client.getOrganization(payload.orgId)
      return org
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateOrganization = createAsyncThunk<
  Organization,
  { orgId: string; data: { name: string; email: string; phone: string; address: string; description: string } }
>('update_organization', async (payload, { rejectWithValue }) => {
  try {
    const org = await client.updateOrganization(payload.orgId, payload.data)
    return org
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const listOrganizationStations = createAsyncThunk<any[], { orgId: string }>(
  'list_organization_stations',
  async (payload, { rejectWithValue }) => {
    try {
      const stations = await client.listOrganizationStations(payload.orgId)
      return stations
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const listOrganizationMembers = createAsyncThunk<OrganizationMember[], { orgId: string }>(
  'list_organization_members',
  async (payload, { rejectWithValue }) => {
    try {
      const members = await client.listOrganizationMembers(payload.orgId)
      return members
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const listOrganizationInvitations = createAsyncThunk<OrganizationInvitation[], { orgId: string }>(
  'list_organization_invitations',
  async (payload, { rejectWithValue }) => {
    try {
      const invitations = await client.listOrganizationInvitations(payload.orgId)
      return invitations
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const listOrganizationSubscriptions = createAsyncThunk<
  Subscription[],
  { orgId: string; type: number; status: string }
>('list_organization_subscriptions', async (payload, { rejectWithValue }) => {
  try {
    const subscriptions = await client.listOrganizationSubscriptions(payload.orgId, payload.type, payload.status)
    return subscriptions
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getOrganizationSubscription = createAsyncThunk<Subscription, { orgId: string; subscriptionId: string }>(
  'get_organization_subscription',
  async (payload, { rejectWithValue }) => {
    try {
      const subscription = await client.getOrganizationSubscription(payload.orgId, payload.subscriptionId)
      return subscription
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const listSubscriptionPayments = createAsyncThunk<Payment[], { orgId: string; subscriptionId: string }>(
  'list_subscription_payments',
  async (payload, { rejectWithValue }) => {
    try {
      const payments = await client.listSubscriptionPayments(payload.orgId, payload.subscriptionId)
      return payments
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const listSubscriptionStations = createAsyncThunk<any[], { orgId: string; subscriptionId: string }>(
  'list_subscription_stations',
  async (payload, { rejectWithValue }) => {
    try {
      const stations = await client.listSubscriptionStations(payload.orgId, payload.subscriptionId)
      return stations
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const listOrganizationPayments = createAsyncThunk<Payment[], { orgId: string }>(
  'list_organization_payments',
  async (payload, { rejectWithValue }) => {
    try {
      const payments = await client.listOrganizationPayments(payload.orgId)
      return payments
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const organizationSlice = createSlice({
  name: 'organizations',
  initialState: {
    plans: [] as Plan[],
    subscriptionPlans: {} as { [planId: string]: SubscriptionPlan[] },
    organizations: { total: 0, page: 0, data: [], totalPage: 0 } as IPagination<Organization>,
    members: [] as OrganizationMember[],
    stations: [] as any[],
    invitations: [] as OrganizationInvitation[],
    payments: [] as Payment[],
    organizationSubscriptions: [] as Subscription[],
    stationSubscriptions: [] as Subscription[],
    subscriptionStations: [] as SubscriptionStation[],
    requests: {
      listPlans: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listSubscriptionPlans: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listOrganizations: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      getOrganization: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      updateOrganization: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listOrganizationMembers: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listOrganizationInvitations: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listOrganizationStations: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listOrganizationSubscriptions: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      getOrganizationSubscription: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listSubscriptionPayments: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listSubscriptionStations: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
      listOrganizationPayments: { status: RequestStatusOptions.IDLE, error: null } as RequestStatus,
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listPlans.pending, (state) => {
        state.plans = []
        state.requests.listPlans = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listPlans.fulfilled, (state, action) => {
        state.plans = action.payload
        state.requests.listPlans = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listPlans.rejected, (state) => {
        state.requests.listPlans = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(listSubscriptionPlans.fulfilled, (state, action) => {
        state.subscriptionPlans[action.meta.arg.planId] = action.payload
        state.requests.listSubscriptionPlans = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listOrganizations.pending, (state) => {
        state.organizations = {} as IPagination<Organization>
        state.requests.listOrganizations = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listOrganizations.fulfilled, (state, action) => {
        state.requests.listOrganizations.status = RequestStatusOptions.FULFILLED
        state.organizations = action.payload
      })
      .addCase(listOrganizations.rejected, (state) => {
        state.requests.listOrganizations.status = RequestStatusOptions.REJECTED
      })
      .addCase(getOrganization.pending, (state) => {
        state.requests.getOrganization = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.requests.getOrganization = { status: RequestStatusOptions.FULFILLED, error: null }
        const index = state.organizations.data.findIndex((org) => org.id === action.payload.id)
        if (index !== -1) {
          state.organizations.data[index] = action.payload
        } else {
          state.organizations.data.push(action.payload)
        }
      })
      .addCase(getOrganization.rejected, (state) => {
        state.requests.getOrganization = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(updateOrganization.pending, (state) => {
        state.requests.updateOrganization = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.requests.updateOrganization = { status: RequestStatusOptions.FULFILLED, error: null }
        const index = state.organizations.data.findIndex((org) => org.id === action.payload.id)
        if (index !== -1) {
          state.organizations.data[index] = action.payload
        }
      })
      .addCase(updateOrganization.rejected, (state) => {
        state.requests.updateOrganization = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(listOrganizationMembers.pending, (state) => {
        state.members = []
        state.requests.listOrganizationMembers = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listOrganizationMembers.fulfilled, (state, action) => {
        state.members = action.payload
        state.requests.listOrganizationMembers = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listOrganizationMembers.rejected, (state) => {
        state.requests.listOrganizationMembers = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(listOrganizationStations.pending, (state) => {
        state.stations = []
        state.requests.listOrganizationStations = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listOrganizationStations.fulfilled, (state, action) => {
        state.stations = action.payload
        state.requests.listOrganizationStations = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listOrganizationStations.rejected, (state) => {
        state.requests.listOrganizationStations = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(listOrganizationInvitations.pending, (state) => {
        state.invitations = []
        state.requests.listOrganizationInvitations = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listOrganizationInvitations.fulfilled, (state, action) => {
        state.invitations = action.payload
        state.requests.listOrganizationInvitations = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listOrganizationInvitations.rejected, (state) => {
        state.requests.listOrganizationInvitations = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(listOrganizationSubscriptions.pending, (state) => {
        state.requests.listOrganizationSubscriptions = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listOrganizationSubscriptions.fulfilled, (state, action) => {
        if (action.meta.arg.type === 1) {
          state.organizationSubscriptions = action.payload
        } else if (action.meta.arg.type === 2) {
          state.stationSubscriptions = action.payload
        }
        state.requests.listOrganizationSubscriptions = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listOrganizationSubscriptions.rejected, (state) => {
        state.requests.listOrganizationSubscriptions = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(getOrganizationSubscription.pending, (state) => {
        state.requests.getOrganizationSubscription = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(getOrganizationSubscription.fulfilled, (state, action) => {
        state.requests.getOrganizationSubscription = { status: RequestStatusOptions.FULFILLED, error: null }
        if (action.payload.type === 1) {
          const index = state.organizationSubscriptions.findIndex((sub) => sub.id === action.payload.id)
          if (index !== -1) {
            state.organizationSubscriptions[index] = action.payload
          } else {
            state.organizationSubscriptions.push(action.payload)
          }
        } else if (action.payload.type === 2) {
          const index = state.stationSubscriptions.findIndex((sub) => sub.id === action.payload.id)
          if (index !== -1) {
            state.stationSubscriptions[index] = action.payload
          } else {
            state.stationSubscriptions.push(action.payload)
          }
        }
      })
      .addCase(getOrganizationSubscription.rejected, (state) => {
        state.requests.getOrganizationSubscription = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(listSubscriptionPayments.pending, (state) => {
        state.payments = []
        state.requests.listSubscriptionPayments = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listSubscriptionPayments.fulfilled, (state, action) => {
        state.payments = action.payload
        state.requests.listSubscriptionPayments = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listSubscriptionPayments.rejected, (state) => {
        state.requests.listSubscriptionPayments = { status: RequestStatusOptions.REJECTED, error: null }
      })
      .addCase(listSubscriptionStations.pending, (state) => {
        state.subscriptionStations = []
        state.requests.listSubscriptionStations = { status: RequestStatusOptions.PENDING, error: null }
      })
      .addCase(listSubscriptionStations.fulfilled, (state, action) => {
        state.subscriptionStations = action.payload
        state.requests.listSubscriptionStations = { status: RequestStatusOptions.FULFILLED, error: null }
      })
      .addCase(listSubscriptionStations.rejected, (state) => {
        state.requests.listSubscriptionStations = { status: RequestStatusOptions.REJECTED, error: null }
      })
  },
})

const { reducer } = organizationSlice

export default reducer
