import { DragSourceMonitor } from 'react-dnd';
import { ThunkAppDispatch } from 'src/redux_store';

import { MODE_OPTIONS } from 'src/constants/watchlist';
import { addSingleCamToWatchlist } from 'src/redux_store/watchlist/watchlist_action';
import { PATH } from 'src/routes/path';
import { ICameraItemProps } from 'src/types/camera';
import { IStreamFilterRequest, TStreamMode } from 'src/types/stream';

interface DropResult {
  groupId: string;
  position: number;
  page: number;
  listSize: number;
}

export const dragPlayerEnd = (
  draggedItem: ICameraItemProps,
  monitor: DragSourceMonitor<ICameraItemProps, unknown>,
  dispatch: ThunkAppDispatch,
) => {
  const dropResult = monitor.getDropResult<DropResult>();

  if (
    !dropResult ||
    !dropResult.position ||
    !dropResult.groupId ||
    !dropResult.page ||
    !dropResult.listSize
  )
    return;

  const { position, groupId, page, listSize } = dropResult;

  const posOfListSize = listSize - position;
  const newPos = listSize * page - posOfListSize;

  dispatch(
    addSingleCamToWatchlist({
      data: {
        cameraId: draggedItem.camera.id,
        position: newPos,
      },
      groupId,
    }),
  );
};

export const dragPlayerCollect = (monitor: DragSourceMonitor<ICameraItemProps, unknown>) => ({
  isDragging: monitor.isDragging(),
  handlerId: monitor.getHandlerId(),
});

export const preFilter = (data: IStreamFilterRequest, mode: TStreamMode) => {
  const isLiveMode = MODE_OPTIONS[0].id === mode;
  const { groupId, listSize, page, time } = data;

  const dataFilter: IStreamFilterRequest = {
    groupId,
    listSize,
    page,
  };

  if (!isLiveMode) {
    dataFilter.time = time;
  }

  return dataFilter;
};

export const navigateTo = (mode: TStreamMode) => {
  return `${PATH.watchList}?mode=${mode}`;
};

export const isStreamMode = (modeId: TStreamMode) => {
  return MODE_OPTIONS[0].id === modeId;
};
