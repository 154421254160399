import {
  ArrowBackOutlined,
  // CreditCardRounded,
  GroupAddOutlined,
  Groups2Outlined,
  InfoOutlined,
  MergeOutlined,
  SubscriptionsOutlined,
} from '@mui/icons-material'
import { Box, CircularProgress, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { useRouteMatch } from 'src/hooks'
import Profile from './profile'
import Members from './members'
import Stations from './stations'
import Subscriptions from './subscriptions'
import Invitations from './invitations'
import SubscriptionDetail from './subscription_detail'
import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { useEffect } from 'react'
import { getOrganization } from 'src/redux_store/organizations/slice'
import { RequestStatusOptions } from 'src/types'

const Tabs = [
  {
    id: 'profile',
    label: 'label.general',
    icon: InfoOutlined,
    element: Profile,
  },
  {
    id: 'members',
    label: 'organization.members',
    icon: Groups2Outlined,
    element: Members,
  },
  {
    id: 'invitations',
    label: 'organization.invitations',
    icon: GroupAddOutlined,
    element: Invitations,
  },
  {
    id: 'stations',
    label: 'organization.stations',
    icon: MergeOutlined,
    element: Stations,
  },
  {
    id: 'subscriptions',
    label: 'subscription.plans_and_subscriptions',
    icon: SubscriptionsOutlined,
    element: Subscriptions,
  },
  // {},
  // {
  //   id: 'payments',
  //   label: 'subscription.payment',
  //   icon: CreditCardRounded,
  //   element: Subscriptions,
  // },
]

const Organization = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const orgId = useParams().organization_id || ''
  const routeMatch = useRouteMatch(['/organizations/:organization_id/:tab/*'])
  const currentTab = routeMatch?.params?.tab || 'profile'

  const dispatch = useAppDispatch()
  const { organizations, requests } = useAppSelector((state) => state.organizations)
  const organization = organizations.data.find((org) => org.id === orgId)

  useEffect(() => {
    dispatch(getOrganization({ orgId }))
  }, [dispatch, orgId])

  // if (requests.getOrganization.status === RequestStatusOptions.PENDING) {
  //   return (
  //     <Box display={'flex'} flex={1} justifyContent={'center'} p={3}>
  //       <CircularProgress />
  //     </Box>
  //   )
  // }

  if (requests.getOrganization.status === RequestStatusOptions.REJECTED) {
    return (
      <Box display={'flex'} flex={1} justifyContent={'center'} p={3}>
        <Typography textAlign={'center'}>Something wrong !</Typography>
      </Box>
    )
  }

  if (!organization) return

  return (
    <Box display={'flex'} flex={1}>
      <Box height={'100%'} width={300}>
        <Scrollbars>
          <Box p={2}>
            <Box mb={1}>
              <MenuItem
                sx={{
                  borderRadius: 2,
                  '&.Mui-selected': {
                    color: indigo[500],
                  },
                }}
                disableRipple
                onClick={() => navigate('/organizations')}
              >
                <ListItemIcon>
                  <ArrowBackOutlined />
                </ListItemIcon>
                <Box>
                  <Typography variant="subtitle1">{t('Back')}</Typography>
                </Box>
              </MenuItem>
            </Box>
            <Box pl={2} py={1}>
              <Typography variant="subtitle1" fontWeight={600}>
                {organization?.name}
              </Typography>
            </Box>
            {Tabs.map((tab, index) => {
              return (
                <Box mb={1} key={index}>
                  <MenuItem
                    key={index}
                    sx={{
                      borderRadius: 2,
                      '&.Mui-selected': {
                        color: indigo[500],
                      },
                    }}
                    disableRipple
                    component={Link}
                    to={tab.id}
                    selected={currentTab === tab.id}
                  >
                    <ListItemIcon>
                      {<tab.icon sx={{ color: currentTab === tab.id ? indigo[500] : 'inherit' }} />}
                    </ListItemIcon>
                    <Box>
                      <Typography variant="subtitle1" fontWeight={currentTab === tab.id ? 600 : 500}>
                        {t(tab.label)}
                      </Typography>
                    </Box>
                  </MenuItem>
                </Box>
              )
            })}
          </Box>
        </Scrollbars>
      </Box>
      <Box flex={1}>
        <Routes>
          <Route path="" element={<Navigate to={'profile'} />} />
          {/* <Route path="profile" element={<div>Profile</div>} /> */}
          {Tabs.map((tab, index) => {
            return <Route key={index} path={tab.id} element={<tab.element />} />
          })}
          <Route path="/subscriptions/:subscription_id/*" element={<SubscriptionDetail />} />
        </Routes>
      </Box>
    </Box>
  )
}

export default Organization
