import momentTimeZone from 'moment-timezone';
import moment, { Moment } from 'moment';
import config from 'src/config';
import { toastMessage } from './toast';
import { TFunction } from 'react-i18next';
import { LOCALE_LANGUAGE_LIST } from 'src/constants/i18n';
import i18n from 'src/i18n';

export const toastErrorAction = (error: any) => {
  const { errors } = error;

  if (errors) {
    for (const i in errors) {
      errors[i].forEach((err: { id: string; message: string }) => {
        toastMessage.error(err.message);
      });
    }
    return;
  }
  toastMessage.error(error.message);
};

export const stringTrim = (name: string) => name.replace(/\s+/g, ' ').trim();

export const isVMSCloud = config.projectName === 'VMS_CLOUD';

export const systemTitle = isVMSCloud ? 'DANAPOD' : 'Camera Hội An';

export const formatStartDateAndEndDate = (
  startDate: string | Date | Moment,
  endDate: string | Date | Moment,
) => {
  const newStartDate = new Date(moment(startDate).format());
  const newEndDate = new Date(moment(endDate).format());

  newStartDate.setHours(0, 0, 0, 0);
  newEndDate.setHours(23, 59, 59);

  return { startDate: newStartDate, endDate: newEndDate };
};

export const isValidEmail = (text: string) => {
  const pattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  return pattern.test(text);
};

export const isValidPhoneNumber = (phone: string) => {
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(phone);
};

export const momentLocale = (time?: string | Date | number | Moment) => {
  const locale = localStorage.getItem('i18nextLng') || 'en';
  const timeZone =
    LOCALE_LANGUAGE_LIST(i18n.t).find((item) => item.id === locale)?.timezone || 'Asia/Ho_Chi_Minh';
  momentTimeZone.locale(locale);

  if (!time) return momentTimeZone().tz(timeZone);
  return momentTimeZone(time).tz(timeZone);
};

export type TI18n = TFunction<'translation', undefined>;
