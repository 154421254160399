import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';
import { useStyles } from './style';
import { useIsRequestError, useIsRequestPending } from 'src/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TLevelNotification } from 'src/types/notification';
import _ from 'lodash';
import Loading from 'src/components/loading';
import ErrorMessage from 'src/components/error_message';
import { useAppDispatch } from 'src/components/player_record/redux_store/store';
import { getInfoNotification } from 'src/redux_store/notification/notification_action';
import { useAppSelector } from 'src/redux_store';

const levelNotificationList: { level: TLevelNotification; label: string }[] = [
  {
    level: 'INFO',
    label: 'Thông tin',
  },
  {
    level: 'WARNING',
    label: 'Cảnh báo',
  },
  {
    level: 'ERROR',
    label: 'Lỗi',
  },
];

const NotificationDetail = () => {
  const classes = useStyles();
  const { infoNotification, idNotificationSelected } = useAppSelector(
    (state) => state.notificationSlice,
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useIsRequestPending('notification', 'getInfoNotification');
  const isError = useIsRequestError('notification', 'getInfoNotification');
  const returnLevelLabel = (level: TLevelNotification) => {
    const levelItem = levelNotificationList.find((item) => item.level === level);

    if (levelItem) {
      return levelItem.label;
    }
    return '';
  };

  const fieldRenderList: { name: string; value: string }[] = [
    {
      name: t('notification.infoNotification.title'),
      value: infoNotification.title.content,
    },
    {
      name: t('notification.infoNotification.detail'),
      value: infoNotification.description.content,
    },
    {
      name: t('notification.infoNotification.time'),
      value: moment(infoNotification.time).format('DD-MM-YYYY HH:mm:ss'),
    },
    // {
    //   name: t('notification.infoNotification.source'),
    //   value: infoNotification.source,
    // },
    {
      name: t('notification.infoNotification.type'),
      value: returnLevelLabel(infoNotification.level),
    },
  ];

  const renderDetail = () => {
    return fieldRenderList.map((field, index) => {
      return (
        <Grid container key={index} marginBottom={1} alignItems="center">
          <Grid item md={4}>
            <Typography color="text.secondary" variant="subtitle1" fontWeight="700">
              {field.name}:
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography>{field.value}</Typography>
          </Grid>
        </Grid>
      );
    });
  };

  const handleRetryReload = () => {
    dispatch(getInfoNotification(idNotificationSelected));
  };
  const renderContent = () => {
    if (isLoading) return <Loading />;
    if (isError) return <ErrorMessage onClick={handleRetryReload} />;
    return (
      <>
        <Typography color="text.secondary" fontWeight="700" variant="subtitle1">
          Thông tin thông báo
        </Typography>
        <Box mt={2} px={1} flex={1}>
          {_.isEmpty(infoNotification) ? (
            <Typography>Thông báo không tìm thấy hoặc đã bị xóa</Typography>
          ) : (
            <Scrollbars>
              <Grid container flexDirection="column">
                {renderDetail()}
              </Grid>
            </Scrollbars>
          )}
        </Box>
      </>
    );
  };

  return (
    <Box display="flex" flexDirection="column" flex={1} p={1} className={classes.bgWhite}>
      {renderContent()}
    </Box>
  );
};

export default NotificationDetail;
