import React, { useEffect } from 'react';
import { Box, Typography, Divider, IconButton } from '@mui/material';
import { Close, FullscreenOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TbPin, TbPinnedOff } from 'react-icons/tb';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import {
  closeBoxRightContent,
  changePinBoxContent,
} from 'src/redux_store/common/topbar/topbar_slice';
import theme from 'src/theme';

import Notification from '../notification';
import DownloadApp from '../download_app';
import IconButtonTooltip from '../tooltip/icon_button_tooltip';
import SettingTheme from '../setting_theme';
import Helps from '../helps';
import WhatNews from '../what_news';
import { useStyles } from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetNotificationState } from 'src/redux_store/notification/notification_slice';
import { PATH } from 'src/routes/path';
import websocket from 'src/clients/websocket';

export default function BoxRightContent() {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { typeContentShow, isPin } = useAppSelector(({ topBarSlice }) => topBarSlice);
  const { me } = useAppSelector((state) => state.myAccountSlice);
  const { numberOfNotifications } = useAppSelector((state) => state.notificationSlice);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pin = localStorage.getItem('pin');

    dispatch(changePinBoxContent(pin === 'true'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseBoxRightContent = () => {
    dispatch(closeBoxRightContent());
  };

  const changePinBox = () => {
    dispatch(changePinBoxContent(!isPin));
    localStorage.setItem('pin', `${!isPin}`);
  };

  const renderContentBoxRight = () => {
    const content = {
      notification: <Notification />,
      downloadApp: <DownloadApp />,
      settingTheme: <SettingTheme />,

      help: <Helps />,

      whatNews: <WhatNews />,
      '': null,
    };

    return content[typeContentShow];
  };

  const handleViewAll = () => {
    dispatch(closeBoxRightContent());
    dispatch(resetNotificationState());
    navigate(PATH.notification, { replace: true, state: { from: location } });
    if (numberOfNotifications > 0) {
      websocket.syncNumberOfNotification(me.id);
    }
  };

  return (
    <Box className={isPin && typeContentShow === 'notification' ? classes.rootPin : classes.root}>
      <Box className={classes.header}>
        <Typography className={classes.headerTitle}>
          {t(`common.topbar.${typeContentShow}`)}
        </Typography>
        <Box display="flex">
          {typeContentShow === 'notification' &&
            (isPin ? (
              <IconButtonTooltip
                title={t('common.topbar.button.unpin')}
                icon={<TbPinnedOff />}
                size="medium"
                style={{ color: theme.palette.secondary.main }}
                onClick={changePinBox}
              />
            ) : (
              <IconButtonTooltip
                title={t('common.topbar.button.pin')}
                icon={<TbPin />}
                size="medium"
                style={{ color: theme.palette.secondary.main }}
                onClick={changePinBox}
              />
            ))}

          <IconButtonTooltip
            title={t('notification.button.viewAll')}
            icon={<FullscreenOutlined fontSize="medium" />}
            size="medium"
            style={{ color: theme.palette.secondary.main }}
            onClick={handleViewAll}
          />

          <IconButton onClick={handleCloseBoxRightContent}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      {renderContentBoxRight()}
    </Box>
  );
}
