import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  flex: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    background: theme.palette.background.paper,
    padding: 8,
    borderRadius: 4,
    cursor: 'pointer',
    marginTop: 8,
    border: `1px solid transparent`,
    borderColor: theme.palette.divider,
    '&:first-child': {
      marginTop: 0,
    },
  },
  activeCard: {
    borderColor: theme.palette.primary.main,
  },
  cardContent: {
    flex: 1,
    paddingLeft: 8,
    overflow: 'hidden',
  },
  badge: {
    position: 'absolute',
    bottom: -5,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  dot: {
    width: 6,
    height: 6,
    backgroundColor: 'red',
    borderRadius: '50%',
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
