export const PERMISSION_DEFAULT_LIST = {
  LIST_CUSTOMERS: 'list_customers',
  ADD_CUSTOMER: 'add_customer',
  EDIT_CUSTOMER: 'edit_customer',
  DELETE_CUSTOMER: 'delete_customer',

  LIST_CAMERAS: 'list_cameras',
  GET_VIDEOS: 'get_videos',
  GET_CONFIG_CAMERA: 'get_config_camera',
  READ_RTSP: 'read_rtsp',
  ADD_CAMERA: 'add_camera',
  EDIT_CAMERA: 'edit_camera',
  DELETE_CAMERA: 'delete_camera',

  LIST_SYSTEM_USERS: 'list_system_users',
  ADD_SYSTEM_USERS: 'add_system_users',
  EDIT_SYSTEM_USER: 'edit_system_user',
  DELETE_SYSTEM_USER: 'delete_system_user',

  LIST_SYSTEM_ROLES: 'list_system_roles',
  ADD_SYSTEM_ROLE: 'add_system_role',
  EDIT_SYSTEM_ROLE: 'edit_system_role',
  DELETE_SYSTEM_ROLE: 'delete_system_role',

  LIST_CUSTOMER_USERS: 'list_customer_users',
  ADD_CUSTOMER_USER: 'add_customer_user',
  EDIT_CUSTOMER_USER: 'edit_customer_user',
  DELETE_CUSTOMER_USER: 'delete_customer_user',

  LIST_CUSTOMER_ROLE: 'list_customer_roles',
  ADD_CUSTOMER_ROLE: 'add_customer_role',
  EDIT_CUSTOMER_ROLE: 'edit_customer_role',
  DELETE_CUSTOMER_ROLE: 'delete_customer_role',

  LIST_AI_EVENTS: 'list_ai_events',

  LIST_AUDIT_LOGS: 'list_audit_logs',

  LIST_STATIONS_MAP: 'list_stations_map',
  LIST_STATIONS: 'list_stations',
  ADD_STATION: 'add_station',
  EDIT_STATION: 'edit_station',
  DELETE_STATION: 'delete_station',
};

export const PERMISSION_LEVEL = {
  SYSTEM: 2,
  CUSTOMER: 4,
};
