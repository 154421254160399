import { MoreVert } from '@mui/icons-material'
import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { ModalIdentifiers } from 'src/constants/modal'

// import { Roles } from 'src/constants/role'
import { useAppDispatch } from 'src/redux_store'
import { openModal } from 'src/redux_store/common/modal/modal_slice'
import { OrganizationInvitation } from 'src/types'
// import DeleteInvitationModal from './delete_invitation_modal'
// import { resendOrganizationInvitation } from 'src/redux_store/user_organizations/slice'
import { toastMessage } from 'src/utils/toast'

interface Props {
  orgId: string
  invitation: OrganizationInvitation
}

const InvitationRow = ({ orgId, invitation }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const resend = () => {
    // handleClose()
    // dispatch(resendOrganizationInvitation({ orgId, invitationId: invitation.id }))
    //   .unwrap()
    //   .then(() => toastMessage.success('Resend invitation success!'))
    //   .catch(() => {
    //     toastMessage.error('Failed')
    //   })
  }

  //   const deleteInvitation = () => {
  //     handleClose()
  //     dispatch(
  //       openModal({
  //         modalId: ModalIdentifiers.DELETE_INVITATION,
  //         dialogComponent: <DeleteInvitationModal modalId={ModalIdentifiers.DELETE_INVITATION} invitation={invitation} />,
  //       })
  //     )
  //   }

  return (
    <Box display={'flex'} flexDirection="row" py={1.5}>
      {invitation.user ? (
        <>
          <Avatar src={invitation.user.avatarUrl} />
          <Box px={1} display={'flex'} flexDirection={'column'}>
            <Typography fontWeight={'bold'}>{invitation.user.name}</Typography>
            <Typography>{invitation.user.email}</Typography>
          </Box>
        </>
      ) : (
        <>
          <Avatar src="" />
          <Box px={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography>{invitation.email}</Typography>
          </Box>
        </>
      )}

      <Box display={'flex'} ml="auto" alignItems={'center'} justifyContent={'center'}>
        <Typography fontWeight={'bold'}>
          {/* {Roles.owner.id === invitation.roleId ? Roles.owner.name : Roles.member.name} */}
          {invitation.role.name}
        </Typography>
      </Box>
      <Box pl={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {/* <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton> */}
        {/* <Menu
          id="member-options"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={2}
          sx={{ minWidth: 320 }}
        >
          <MenuItem onClick={resend}>{t('invitation.resend_invitation')}</MenuItem>
          <MenuItem onClick={deleteInvitation} style={{ color: 'red', fontWeight: 'bold' }}>
            {t('button.delete')}
          </MenuItem>
        </Menu> */}
      </Box>
    </Box>
  )
}

export default InvitationRow
