import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';

import { ISystemGpu } from 'src/types/system';
import { toastMessage } from 'src/utils/toast';

export const getGpus = createAsyncThunk<ISystemGpu[], void>(
  'system/getGpus',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getGpus();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
