import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  box: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  list: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
    padding: `${theme.spacing(0.5)} 0`,
    marginBottom: theme.spacing(1),
    '& > p': {
      lineHeight: 1.1,
    },
  },
}));
