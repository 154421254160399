import { createAsyncThunk } from '@reduxjs/toolkit';

import client from 'src/clients/http';
import { IProduct } from 'src/types/customer';

export const getProducts = createAsyncThunk<IProduct[], void>(
  'products/getProducts',
  async (_payload, { rejectWithValue }) => {
    try {
      const products = await client.getProducts();
      return products;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
