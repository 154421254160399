import { createSlice } from '@reduxjs/toolkit';

import { IPlayer } from '../../types/player';

const initialState: IPlayer = {
  paused: true,
  isFullscreen: false,
  waiting: false,
  isWsOpen: false,
  isMuted: true,
  isAlarm: false,
  isAudioStarting: false,
  isAudioNotSupport: false,
  error: null,
  isControlBar: false,
  isPopover: false,
};

const playerSlice = createSlice({
  initialState,
  name: 'playerSlice',
  reducers: {
    changeFullscreen: (state, action) => {
      state.isFullscreen = action.payload;
    },
    play: (state) => {
      state.paused = false;
      state.waiting = false;
    },
    playing: (state) => {
      state.waiting = false;
    },
    canPlay: (state) => {
      state.waiting = false;
    },
    canPlayThrough: (state) => {
      state.waiting = false;
    },
    pause: (state) => {
      state.paused = true;
    },
    waiting: (state) => {
      state.waiting = true;
    },
    mute: (state) => {
      state.isMuted = true;
    },
    unmute: (state) => {
      state.isMuted = false;
    },
    startAlarm: (state) => {
      state.isAlarm = true;
    },
    stopAlarm: (state) => {
      state.isAlarm = false;
    },
    startAudio: (state) => {
      state.isAudioStarting = true;
    },
    stopAudio: (state) => {
      state.isAudioStarting = false;
    },
    setIsAudioNotSupport: (state) => {
      state.isAudioNotSupport = true;
    },
    wsOpenSuccess: (state) => {
      state.isWsOpen = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.waiting = false;
      state.paused = true;
    },
    setPeerConnectionIssue: (state, action) => {
      state.peerConnectionIssue = action.payload;
    },
    setIsControlBar: (state, action) => {
      state.isControlBar = action.payload;
    },
    setIsPopover: (state, action) => {
      state.isPopover = action.payload;
    },
  },
});

export const { reducer, actions } = playerSlice;
export const { setError } = actions;
export const { wsOpenSuccess } = actions;
export default reducer;
