import { useEffect } from 'react';
import { IPlayerProps } from '../../types/player';
import fullscreen from '../../utils/fullscreen';

function Shortcut(props: any) {
  const slice = { ...props } as IPlayerProps;
  const { actions } = slice;

  useEffect(() => {
    fullscreen.addEventListener(exitHandler);
    return () => fullscreen.removeEventListener(exitHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exitHandler = () => {
    if (fullscreen.isFullscreen) return;
    actions.changeFullscreen(fullscreen.isFullscreen);
  };

  return null;
}

export default Shortcut;
