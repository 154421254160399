import React from 'react';
import { CameraAltOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { IPlayerProps } from '../../../types/player';
import { useTranslation } from 'react-i18next';

function Snapshot(props: any) {
  const slice = { ...props } as IPlayerProps;
  const { actions, player, manager } = slice;
  const { video } = manager;
  const { t } = useTranslation();

  const controlText = t('stream.controlBar.snapshot');

  const handleClick = () => {
    if (player.error || !video?.hls) return;
    actions.snapshot();
  };

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          <CameraAltOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default Snapshot;
