import React from 'react';
import { Box, Typography } from '@mui/material';

import { IPlayerProps } from '../../types/player';
import { useStyles } from './styles';

function CameraName(props: any) {
  const classes = useStyles();
  const slice = props as IPlayerProps;

  const cameraName = slice.cam.name || '';

  return (
    <Box className={classes.camName}>
      <Typography noWrap fontSize={16} fontWeight={600}>
        {cameraName}
      </Typography>
    </Box>
  );
}

export default CameraName;
