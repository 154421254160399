import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { listOrganizationInvitations } from 'src/redux_store/organizations/slice'
import InvitationRow from './invitation_row'
import { RequestStatusOptions } from 'src/types'

const Invitation = () => {
  const { t } = useTranslation()
  const orgId = useParams().organization_id || ''
  const dispatch = useAppDispatch()
  const { requests, invitations } = useAppSelector((state) => state.organizations)

  useEffect(() => {
    dispatch(listOrganizationInvitations({ orgId }))
  }, [dispatch, orgId])

  if (requests.listOrganizationInvitations.status === RequestStatusOptions.PENDING) {
    return (
      <Box display={'flex'} justifyContent={'center'} mt={10}>
        <CircularProgress />
      </Box>
    )
  }

  if (requests.listOrganizationInvitations.status === RequestStatusOptions.REJECTED) {
    return (
      <Box>
        <Typography textAlign={'center'}>Something wrong!</Typography>
      </Box>
    )
  }

  return (
    <Scrollbars>
      <Box p={2}>
        <Container>
          <Box maxWidth={'sm'}>
            <Box>
              <Typography variant="h6">{t('organization.invitations')}</Typography>
            </Box>
            <Box>
              {!invitations.length && (
                <Box>
                  <Box
                    sx={{
                      height: 130,
                      backgroundImage: `url("/no_invitations_yet.svg")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      mb: 2,
                    }}
                  ></Box>
                  <Typography textAlign={'center'}>{t('invitation.no_invitations_yet')}</Typography>
                </Box>
              )}
              {invitations.map((invitation, index) => (
                <InvitationRow key={index} orgId={orgId} invitation={invitation} />
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </Scrollbars>
  )
}

export default Invitation
