import { Box, Typography } from '@mui/material';
import ControlPTZ from 'src/components/control_ptz';
import EmptyMessage from 'src/components/empty_message';
import PlayerOptions from 'src/components/player_options';
import PlayerWithTimeline from 'src/components/player_with_timeline';
import { useIsRequestPending } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { toggleStationLight } from 'src/redux_store/map/map_action';
import {
  resetStationFunctions,
  toggleStationMic,
  toggleStationSpeaker,
} from 'src/redux_store/station/station_slice';
import { changeStreamMode, toggleTheaterMode } from 'src/redux_store/stream/stream_slice';
import { ICamera } from 'src/types/camera';
import { EStationStatus, IStation } from 'src/types/station';
import { isStreamMode } from 'src/utils/stream';
import { toastMessage } from 'src/utils/toast';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface IProps {
  wsOpen: boolean;
  cam: ICamera | null;
  station: IStation | null;
}

const Player = (props: IProps) => {
  const classes = useStyles();
  const { mode, isTheaterMode } = useAppSelector((state) => state.streamSlice);
  const { cam, wsOpen } = props;

  const { t } = useTranslation();

  const isToggleLight = useIsRequestPending('map', 'toggleStationLight');

  const stationDetail = useAppSelector((state) => state.mapSlice.stationDetail);
  const {
    functions: { micStatus, isMicAutoClick, speakerStatus, isSpeakerAutoClick },
  } = useAppSelector(({ stationSlice }) => stationSlice);

  const dispatch = useAppDispatch();

  if (!cam)
    return (
      <Box className={classes.root}>
        <Typography textAlign="center" p={2}>
          {t('label.camera.notHave')}
        </Typography>
      </Box>
    );

  if (!isStreamMode(mode)) return <PlayerWithTimeline isHideTheaterMode />;

  if (!stationDetail) {
    return <EmptyMessage title={t('label.emptyData.station')} />;
  }

  const toggleLight = () => {
    if (!stationDetail || !stationDetail.id || isToggleLight) return;

    if (stationDetail.status === EStationStatus.ERROR) {
      toastMessage.error(t('label.status.station.error'));
      return;
    }

    dispatch(toggleStationLight({ stationId: stationDetail.id }));
  };

  const toggleMic = () => {
    dispatch(toggleStationMic({ autoClick: false }));
  };

  const toggleSpeaker = () => {
    dispatch(toggleStationSpeaker({ autoClick: false }));
  };
  const togglePTZ = () => {
    console.log('toggle PTZ');
  };

  const onChangeStreamMode = (id: string) => {
    dispatch(changeStreamMode(id));
    dispatch(resetStationFunctions());

    if (isTheaterMode) return;

    dispatch(toggleTheaterMode());
  };

  return (
    <Box className={classes.root}>
      <PlayerOptions
        // station={station}
        // isHideTheaterMode
        // isTheaterMode={isTheaterMode}
        cam={cam}
        wsOpen={wsOpen}
        controlBar={{
          isHover: false,
          modeOptions: {
            changeStreamMode: onChangeStreamMode,
          },
          light: stationDetail.isEnableLight
            ? {
                status: stationDetail.lightStatus,
                toggleLight,
              }
            : undefined,
          mic: stationDetail.isEnableMic
            ? {
                status: isMicAutoClick ? micStatus : undefined,
                toggleMic,
              }
            : undefined,
          speaker: stationDetail.isEnableSpeaker
            ? {
                status: isSpeakerAutoClick ? speakerStatus : undefined,
                toggleSpeaker,
              }
            : undefined,

          ptz: {
            ptzCPN: (
              <ControlPTZ
                isPtzSmall={true}
                selectedCamera={stationDetail?.cameras?.[0] as ICamera}
                stationId={stationDetail?.id}
              />
            ),
            togglePTZ,
          },
        }}
      />
    </Box>
  );
};

export default Player;
