import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { listOrganizationSubscriptions } from 'src/redux_store/organizations/slice'
import { PlanType } from 'src/types'
import ActiveSubscriptionRow from './active_subscription_row'
import EmptySvg from 'src/assets/images/empty.svg'

interface Props {
  orgId: string
}

const ActiveSubscriptions = ({ orgId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { stationSubscriptions } = useAppSelector((state) => state.organizations)

  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    dispatch(listOrganizationSubscriptions({ orgId, type: PlanType.Station, status: 'active' }))
      .unwrap()
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, orgId])

  if (hasError) {
    return (
      <Box display="flex" alignItems={'center'} justifyContent={'center'} p={3}>
        <Typography>Something wrong</Typography>
      </Box>
    )
  }

  if (loading) {
    return (
      <Box display="flex" alignItems={'center'} justifyContent={'center'} p={3}>
        <CircularProgress />
      </Box>
    )
  }

  if (!stationSubscriptions.length) {
    return (
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} p={3}>
        <img width={160} src={EmptySvg} />
        <Typography>No subscriptions</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <TableContainer sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>{t('subscription.plan')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('subscription.stations')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('subscription.subscription_plan')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('subscription.order_time')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('subscription.start_time')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('subscription.expired_time')}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>{t('subscription.status')}</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stationSubscriptions.map((sub, index) => (
              <ActiveSubscriptionRow key={index} subscription={sub} orgId={orgId} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ActiveSubscriptions
