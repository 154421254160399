import { Box } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';
import StationInfoCard from 'src/components/card/station_info_card';
import StationFunctionAction from 'src/components/station_function_action';
import { IStation } from 'src/types/station';

interface IProps {
  station: IStation;
}

const General = ({ station }: IProps) => {
  return (
    <Scrollbars>
      <Box display="flex" flexDirection="column" flex={1}>
        <StationFunctionAction station={station} />

        <Box flex={1} pb={2}>
          <StationInfoCard station={station} />
        </Box>
      </Box>
    </Scrollbars>
  );
};

export default General;
