import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import client from 'src/clients/http';
import config from 'src/config';
import i18n from 'src/i18n';
import { IUser } from 'src/types/auth';
import { IRole } from 'src/types/role';
import { getMe, getMyRole, updateUserLocale } from './my_account_action';
import { TAutoLogout } from 'src/types/user';
import moment from 'moment';

interface IMyAccountState {
  token: string | null;
  me: IUser;
  codeMFA: { secret: string; qrCode: string };
  role: IRole;
  isReload: boolean;
  isReloadPageEventSocket: boolean;
  autoLogout: {
    isAutoLogout: boolean;
    type: TAutoLogout;
  };
  userSession: string | undefined;
}

const initialState: IMyAccountState = {
  token: '',
  me: {} as IUser,
  codeMFA: { secret: '', qrCode: '' },
  role: {} as IRole,
  isReload: true,
  isReloadPageEventSocket: false,
  userSession: Cookies.get('USER_SESSION'),
  autoLogout: {
    isAutoLogout: false,
    type: '',
  },
};

const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    setDataMe: (state, action) => {
      state.me = action.payload;
    },
    logoutLocal: (state) => {
      state.token = initialState.token;
      state.me = initialState.me;
      state.codeMFA = initialState.codeMFA;
      state.role = initialState.role;
      state.userSession = undefined;
      state.autoLogout = initialState.autoLogout;
      client.setToken('');
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    },
    changeIsReload: (state, action) => {
      state.isReload = action.payload;
    },
    changeUserSession: (state, action) => {
      state.userSession = action.payload;
    },

    changeIsReloadPageEventSocket: (state, action: PayloadAction<boolean>) => {
      state.isReloadPageEventSocket = action.payload;
    },
    changeIsAutoLogoutEventSocket: (
      state,
      action: PayloadAction<{ isAutoLogout: boolean; type: TAutoLogout }>,
    ) => {
      state.autoLogout = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, action) => {
      const locale = action.payload.locale;
      Cookies.set('locale', locale, { path: '/', domain: config.domain });
      localStorage.setItem('i18nextLng', locale);
      i18n.changeLanguage(locale);
      state.userSession = Cookies.get('USER_SESSION');
      moment.defineLocale(locale, {});
      state.token = Cookies.get('USER_SESSION') || '';
      state.me = action.payload;
    });
    builder.addCase(getMe.rejected, (state) => {
      state.token = initialState.token;
      state.me = initialState.me;
      state.codeMFA = initialState.codeMFA;
      state.isReload = initialState.isReload;
      state.role = initialState.role;
      state.userSession = undefined;
      client.setToken('');
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    });
    // builder.addCase(logout.fulfilled, (state) => {
    //   state.me = initialState.me;
    //   state.token = initialState.token;
    //   state.role = initialState.role;
    //   state.codeMFA = initialState.codeMFA;
    //   client.setToken('');
    //   Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    // });
    // builder.addCase(logout.rejected, (state) => {
    //   state.token = '';
    //   state.me = {} as IUser;
    //   client.setToken('');
    //   Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    // });
    builder.addCase(getMyRole.fulfilled, (state, action) => {
      state.role = action.payload;
    });
    builder.addCase(getMyRole.rejected, (state) => {
      state.isReload = false;
      state.token = initialState.token;
      state.me = initialState.me;
      state.codeMFA = initialState.codeMFA;
      state.role = initialState.role;
      state.userSession = undefined;
      client.setToken('');
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    });

    builder.addCase(updateUserLocale.fulfilled, (state, action) => {
      const { data } = action.meta.arg;

      const currentMe = { ...state.me };
      state.me = Object.assign(currentMe, data);
    });
  },
});

const { actions, reducer } = myAccountSlice;
export const {
  logoutLocal,
  setDataMe,
  changeIsReload,
  changeUserSession,
  changeIsReloadPageEventSocket,
  changeIsAutoLogoutEventSocket,
} = actions;
export default reducer;
