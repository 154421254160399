import React from 'react';
import {
  CheckOutlined,
  DeleteOutline,
  KeyboardDoubleArrowRightOutlined,
  // RemoveRedEyeOutlined,
} from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MODAL_IDS } from 'src/constants/modal';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { openModal } from 'src/redux_store/common/modal/modal_slice';
import { hiddenInfoSelect } from 'src/redux_store/notification/notification_slice';
import { markItemNotification } from 'src/utils/notification';
import _ from 'lodash';

import DeleteNotificationModal from '../delete_notification_modal';
// import ViewDetailEventNotification from '../view_detail_event_notification_modal';

const NotificationDetailAction = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { infoNotification } = useAppSelector((state) => state.notificationSlice);

  const handleHiddenInfo = () => {
    dispatch(hiddenInfoSelect());
  };

  const handleOpenDeleteNotificationModal = () => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.deleteNotification,
        dialogComponent: <DeleteNotificationModal />,
      }),
    );
  };

  // const handleOpenReviewNotification = () => {
  //   dispatch(
  //     openModal({
  //       modalId: MODAL_IDS.viewDetailNotification,
  //       dialogComponent: <ViewDetailEventNotification />,
  //     }),
  //   );
  // };

  const handleMarkNotificationItem = () => {
    // dispatch(getInfoNotification({ ...infoNotification, isRead: !infoNotification.isRead }));

    if (infoNotification.isRead) {
      markItemNotification(dispatch, 'unread', infoNotification.id);
    } else {
      markItemNotification(dispatch, 'read', infoNotification.id);
    }
  };

  return (
    <Box flex={1} display="flex" alignItems="center">
      <Box>
        <Button
          color="secondary"
          startIcon={<KeyboardDoubleArrowRightOutlined />}
          onClick={handleHiddenInfo}
        >
          {t('button.hiddenInfo')}
        </Button>
      </Box>
      {!_.isEmpty(infoNotification) && (
        <Box flex={1} display="flex" justifyContent="flex-end" gap={1}>
          <Button
            color="secondary"
            startIcon={<CheckOutlined />}
            onClick={handleMarkNotificationItem}
          >
            {infoNotification.isRead
              ? t('notification.button.unread')
              : t('notification.button.read')}
          </Button>

          {/* {infoNotification.referenceId && (
         <Button
           color="secondary"
           startIcon={<RemoveRedEyeOutlined />}
           onClick={handleOpenReviewNotification}
         >
           {t('notification.button.review')}
         </Button>
       )} */}
          <Button
            color="secondary"
            startIcon={<DeleteOutline />}
            onClick={handleOpenDeleteNotificationModal}
          >
            {t('notification.button.delete')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NotificationDetailAction;
