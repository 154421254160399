import { Box, CircularProgress, Menu, MenuItem, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { PropsWithChildren, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import Cookies from 'js-cookie'
import imageBackground from 'src/assets/images/background.jpg'
import config from 'src/config'
import { LOCALE_LANGUAGE_LIST } from 'src/constants/i18n'
import { ROLE_LEVEL } from 'src/constants/role'
import { useIsRequestPending } from 'src/hooks'
import { useAppSelector } from 'src/redux_store'
import { PATH } from 'src/routes/path'
import { systemTitle } from 'src/utils/common'
import { getParamToNavigate } from 'src/utils/helper'
import en from '../../assets/images/en-us-flag-small.png'
import vi from '../../assets/images/vi-flag-small.png'
import { useStyles } from './style'
import { useTranslation } from 'react-i18next'

const AuthLayout = ({ children }: PropsWithChildren) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const classes = useStyles()

  const { me, token, role } = useAppSelector((state) => state.myAccountSlice)
  const { t } = useTranslation()

  const isLoadingGetMe = useIsRequestPending('myAccount', 'getMe')
  const isLoadingGetMyRole = useIsRequestPending('myAccount', 'getMyRole')
  const location = useLocation() as any
  const locale = localStorage.getItem('i18nextLng') || 'en'

  const [localeSelected, setLocaleSelected] = useState<string>('vi')

  useEffect(() => {
    const locale = localStorage.getItem('i18nextLng') || 'en'
    setLocaleSelected(locale)
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    document.title = systemTitle
  }, [])

  // loading get me action
  if (isLoadingGetMe || isLoadingGetMyRole) {
    return (
      <Box className={classes.center}>
        <CircularProgress color="error" />
      </Box>
    )
  }

  // if have token redirect to admin page
  const currentPath = location.state?.from?.pathname + location.state?.from?.search

  if (token && !isEmpty(me) && !isEmpty(role)) {
    let from = ''

    if (currentPath) {
      from = currentPath
    } else {
      if (role.level === ROLE_LEVEL.SUPER_ADMIN || role.level === ROLE_LEVEL.SYSTEM_ADMIN) {
        // from = PATH.customerManagement;
        from = '/organizations'
      } else {
        from = getParamToNavigate(me, role)
      }
    }

    return <Navigate to={from} replace />
  }

  const handleChangeLocale = (language: string) => {
    if (localeSelected !== language) {
      localStorage.setItem('i18nextLng', language)
      Cookies.set('locale', language, { path: '/', domain: config.domain })
      window.location.reload()
    } else {
      setAnchorEl(null)
    }
  }

  return (
    <Box
      className={classes.root}
      sx={{
        backgroundImage: `url(${imageBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: '2',
          right: '1.5%',
          top: '2.5%',
          cursor: 'pointer',
        }}
      >
        <Box
          onClick={(e: any) => {
            handleClick(e)
          }}
        >
          <img
            src={localeSelected === 'vi' ? vi : en}
            alt="locate"
            style={{
              width: '32px',
              height: '32px',
              objectFit: 'cover',
              borderRadius: '50%',
            }}
          />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {LOCALE_LANGUAGE_LIST(t).map((item) => {
            return (
              <MenuItem onClick={() => handleChangeLocale(item.id)} key={item.id} selected={item.id === localeSelected}>
                <Box display={'flex'} gap={1.5} alignItems={'center'}>
                  <img width={'25px'} alt="Danapod" src={item.flag} />
                  <Typography>{item.name}</Typography>
                </Box>
              </MenuItem>
            )
          })}
        </Menu>
      </Box>
      {children}
    </Box>
  )
}

export default AuthLayout
