import {
  styled,
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  AccordionDetails,
  AccordionDetailsProps,
} from '@mui/material';

import theme from 'src/theme';

export const AccordionNotificationSetting = styled(Accordion)<AccordionProps>({
  '&:before': {
    opacity: '1 !important',
  },
});
export const MainNotificationSetting = styled(AccordionSummary)<AccordionSummaryProps>({
  padding: 0,
  minHeight: `${theme.spacing(6)} !important`,
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
  },
});
export const SubNotificationSetting = styled(AccordionDetails)<AccordionDetailsProps>({
  padding: `0px ${theme.spacing(4)}`,
});
