import config from 'src/config';
import { TVehicleType } from 'src/types/events';

export const replacePath = (link: string) => {
  if (!link) return '';

  return `${config.baseUrl}${link}`;
};

export const formatTimeZone = (
  timeArray: {
    start:
      | {
          hour: number;
          minute: number;
        }
      | Date;
    end:
      | {
          hour: number;
          minute: number;
        }
      | Date;
  }[],
) => {
  if (!timeArray?.length) return [];
  const result = timeArray.map((time) => {
    const { start, end } = time;

    let hourStart, hourEnd, minuteStart, minuteEnd;
    if (start instanceof Date) {
      hourStart = start.getHours();
      minuteStart = start.getMinutes();
    } else {
      hourStart = start.hour;
      minuteStart = start.minute;
    }
    if (end instanceof Date) {
      hourEnd = end.getHours();
      minuteEnd = end.getMinutes();
    } else {
      hourEnd = end.hour;
      minuteEnd = end.minute;
    }

    return {
      start: {
        hour: hourStart,
        minute: minuteStart,
      },
      end: {
        hour: hourEnd,
        minute: minuteEnd,
      },
    };
  });

  return result;
};

export const formatVehicle = (vehicleArr: (TVehicleType | number)[]) => {
  if (!vehicleArr?.length) return [];

  return vehicleArr.map((vehicle) => (typeof vehicle === 'number' ? vehicle : vehicle.id));
};
