import { createSlice } from '@reduxjs/toolkit';
import { ISystemGpu } from 'src/types/system';
import { getGpus } from './gpus_action';

const gpus = createSlice({
  initialState: [] as ISystemGpu[],
  name: 'system/gpus',
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getGpus.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default gpus.reducer;
