import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { IPermission } from 'src/types/permission';
import { toastMessage } from 'src/utils/toast';

export const getPermissions = createAsyncThunk<IPermission, number>(
  'permission/getPermissions',
  async (roleLevel, { rejectWithValue }) => {
    try {
      const data = await client.getPermission(roleLevel);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
