import {
  FlashlightOffOutlined,
  FlashlightOn,
  MicOffOutlined,
  MicOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
} from '@mui/icons-material';
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { Fragment } from 'react';
import { useIsRequestPending } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { toggleStationLight } from 'src/redux_store/map/map_action';
import { ELightStatus, EStationStatus, IStation } from 'src/types/station';
import { CircleButton, useStyles } from './styles';
import { toastMessage } from 'src/utils/toast';
import { toggleStationMic, toggleStationSpeaker } from 'src/redux_store/station/station_slice';
import { validateStationOrCameraStatus } from 'src/utils/station';
import { isStreamMode } from 'src/utils/stream';
import { getAudioDevice } from '../player_rtc/utils/video';
import { useTranslation } from 'react-i18next';

interface IProps {
  station: IStation;
  isHideTitle?: boolean;
}

const StationFunctionAction = ({ station, isHideTitle = false }: IProps) => {
  const classes = useStyles({ status: station.status });
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector((state) => state.streamSlice);

  const { t } = useTranslation();

  const {
    functions: { micStatus = false, speakerStatus = false },
  } = useAppSelector(({ stationSlice }) => stationSlice);

  const isLoading = useIsRequestPending('map', 'toggleStationLight');

  const onToggleLight = (e: any) => {
    e?.stopPropagation();
    if (!station.id) return;

    if (station.status === EStationStatus.ERROR)
      return toastMessage.error(t('label.status.station.error'));

    dispatch(
      toggleStationLight({
        stationId: station.id,
      }),
    );
  };

  const onToggleSpeaker = () => {
    if (!station.id) return;

    if (!isStreamMode(mode)) return toastMessage.error(t('message.viewStreamCamera'));

    const isMessage = validateStationOrCameraStatus(station.status, station.cameras);
    if (isMessage) return;

    dispatch(toggleStationSpeaker({ autoClick: true }));
  };

  const onToggleMic = async () => {
    if (!station.id) return;

    if (!isStreamMode(mode)) return toastMessage.error(t('message.viewStreamCamera'));

    const isMessage = validateStationOrCameraStatus(station.status, station.cameras);
    if (isMessage) return;

    if (!micStatus) {
      const lcStream = await getAudioDevice();
      if (!lcStream) return;
    }

    dispatch(toggleStationMic({ autoClick: true }));
  };

  if (!station.isEnableLight && !station.isEnableMic && !station.isEnableSpeaker) {
    return null;
  }

  return (
    <Fragment>
      <Box px={1}>
        {!isHideTitle && (
          <Typography mt={1} component="h6" variant="subtitle2" fontWeight="bold" noWrap>
            {t('label.function')}
          </Typography>
        )}
        <Box className={classes.action}>
          {station.isEnableLight && (
            <Tooltip
              title={
                station.lightStatus === ELightStatus.OFF ? t('label.turnOn') : t('label.turnOff')
              }
            >
              <Stack gap={0.5} alignItems="center">
                <CircleButton
                  status={station.status}
                  disabled={isLoading}
                  className={classNames({
                    [classes.active]: station.lightStatus === ELightStatus.ON,
                  })}
                  onClick={onToggleLight}
                >
                  {station.lightStatus === ELightStatus.ON ? (
                    <FlashlightOn />
                  ) : (
                    <FlashlightOffOutlined />
                  )}
                </CircleButton>
                <Typography>{t('label.light')}</Typography>
              </Stack>
            </Tooltip>
          )}
          {station.isEnableMic && (
            <Tooltip
              title={
                (micStatus
                  ? t('stream.controlBar.audio.off')
                  : t('stream.controlBar.audio.on')) as string
              }
            >
              <Stack gap={0.5} alignItems="center">
                <CircleButton status={station.status} onClick={onToggleMic}>
                  {micStatus ? <MicOutlined /> : <MicOffOutlined />}
                </CircleButton>
                <Typography>{t('label.camera.configuration.parameters.mic')}</Typography>
              </Stack>
            </Tooltip>
          )}
          {station.isEnableSpeaker && (
            <Tooltip
              title={
                (speakerStatus
                  ? t('stream.controlBar.volume.off')
                  : t('stream.controlBar.volume.on')) as string
              }
            >
              <Stack gap={0.5} alignItems="center">
                <CircleButton status={station.status} onClick={onToggleSpeaker}>
                  {speakerStatus ? <VolumeUpOutlined /> : <VolumeOffOutlined />}
                </CircleButton>
                <Typography>{t('label.camera.configuration.parameters.speaker')}</Typography>
              </Stack>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Divider />
    </Fragment>
  );
};

export default StationFunctionAction;
