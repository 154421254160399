import React from 'react';
import { RefreshRounded } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { IPlayerProps } from '../../../types/player';
import { ECameraStatus } from 'src/types/camera';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function Refresh(props: any) {
  const slice = { ...props } as IPlayerProps;
  const { t } = useTranslation();

  const handleClick = () => {
    const { manager, player } = slice;

    if (!manager.video) return;

    if (props?.player.error) {
      slice.actions.setError(null);
    }

    manager.video.handleHlsFullscreen(player.isFullscreen);
  };

  if (slice.cam.status === ECameraStatus.ERROR || slice.cam.status === ECameraStatus.OFFLINE)
    return null;

  return (
    <Box>
      <Tooltip
        title={t('stream.controlBar.refresh')}
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          <RefreshRounded />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default Refresh;
