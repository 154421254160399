import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  controlBar: {
    position: 'absolute',
    bottom: 0,
    left: 12,
    right: 12,
    height: 36,
    marginBottom: 5,
    flexDirection: 'row',
    transformStyle: 'preserve-3d',
    '& button': {
      color: 'white',
    },
  },
}));
