import { styled } from '@mui/styles';

export const VideoDanaPlayer = styled('div')({
  width: '100%',
  background: '#000',
  height: 0,
  transformStyle: 'preserve-3d',
  paddingTop: '56.25%',
  maxHeight: '100%',
  '&:hover div:last-child': {
    opacity: 1,
    visibility: 'visible',
  },
});
