import React from 'react';
import { EGpsStatus } from 'src/types/enum';
import { EStationStatus } from 'src/types/station';
import StationGPSIconSmall from './station_gps_small';
import StationGPSIconMedium from './station_gps_medium';
import theme from 'src/theme';
import { isStationLevelWarning } from 'src/utils/station';
import { useTranslation } from 'react-i18next';
import { momentLocale } from 'src/utils/common';

type Props = {
  status: EGpsStatus;
  gpsWaveStrength: number;
  stationStatus: EStationStatus;
  size?: 'small' | 'medium';
  gpsLastUpdatedAt: string;
};

function StationGPSIcon({
  stationStatus,
  gpsWaveStrength,
  status,
  size = 'medium',
  gpsLastUpdatedAt,
}: Props) {
  const { t } = useTranslation();
  const getGPSColor = () => {
    if (isStationLevelWarning(stationStatus)) return theme.palette.warning.main;
    if (
      stationStatus === EStationStatus.ERROR ||
      (stationStatus !== EStationStatus.OFFLINE && status === EGpsStatus.DISCONNECT)
    )
      return theme.palette.error.main;

    if (stationStatus === EStationStatus.OFFLINE) return theme.palette.grey[500];

    return theme.palette.success.main;
  };

  if (size === 'small') {
    console.log({ time: gpsLastUpdatedAt });

    return (
      <StationGPSIconSmall
        gpsWaveStrength={gpsWaveStrength}
        title={t('label.lastUpdated', {
          lastUpdated: momentLocale(gpsLastUpdatedAt).format('HH:mm DD/MM/YYYY'),
        })}
        color={getGPSColor()}
      />
    );
  }

  return (
    <StationGPSIconMedium
      gpsWaveStrength={gpsWaveStrength}
      title={t('label.lastUpdated', {
        lastUpdated: momentLocale(gpsLastUpdatedAt).format('HH:mm DD/MM/YYYY'),
      })}
      color={getGPSColor()}
    />
  );
}

export default StationGPSIcon;
