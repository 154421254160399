import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  controlBar: {
    position: 'absolute',
    display: 'flex',
    // opacity: 0,
    // visibility: 'hidden',
    bottom: 0,
    left: 12,
    right: 12,
    height: 36,
    marginBottom: 5,
    flexDirection: 'row',
    transformStyle: 'preserve-3d',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      transformStyle: 'preserve-3d',
      flexDirection: 'row',
    },
    '& button': {
      color: 'white',
      transformStyle: 'preserve-3d',
    },
    '& > div:first-child': {
      flex: 1,
    },
    '& > div:last-child': {
      marginLeft: 'auto',
    },
  },
}));
