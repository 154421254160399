import {
  IStorageSpaceUsage,
  ISystemBackup,
  ISystemDatabaseResponse,
  ISystemGeneralInfo,
  ISystemGpu,
  ISystemNode,
  ISystemNodeDetail,
  ISystemStorage,
} from 'src/types/system';
import ClientBase from './base';

export interface ClientSystemMix {
  getGeneralInfo: () => Promise<ISystemGeneralInfo>;
  updateGeneralInfoName: (name: string) => Promise<{ message: string }>;
  getGpus: () => Promise<ISystemGpu[]>;
  getNodes: () => Promise<ISystemNode[]>;
  getNode: (node: string) => Promise<ISystemNodeDetail>;
  getStorage: () => Promise<ISystemStorage>;
  updateStorage: (data: ISystemStorage) => Promise<{ message: string }>;
  getVideoStorageGroup: () => Promise<any>;
  getVideoStorageCamera: (data: any) => Promise<any>;
  addVideoStorageGroup: (data: any) => Promise<any>;
  updateVideoStorageGroup: (data: any) => Promise<any>;
  getStorageSpaceUsage: () => Promise<IStorageSpaceUsage[]>;
  getBackupSpaceUsage: () => Promise<any>;
  getBackup: () => Promise<ISystemBackup>;
  updateBackup: (data: ISystemBackup) => Promise<{ message: string }>;
  getDatabases: (data: any) => Promise<ISystemDatabaseResponse>;
  deleteDatabase: (id: string) => Promise<{ message: string }>;
  downloadDatabase: (id: string) => Promise<any>;
  databaseDoBackup: () => Promise<{ message: string }>;
}

const ClientSystem = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientSystemMix {
    getGeneralInfo = async () => {
      return this.doFetch<ISystemGeneralInfo>(`${this.getBaseRoute()}/system_info/info`, {
        method: 'GET',
      });
    };

    updateGeneralInfoName = async (name: string) => {
      return this.doFetch<{ message: string }>(`${this.getBaseRoute()}/system_info/info/name`, {
        method: 'PUT',
        data: {
          name,
        },
      });
    };

    getGpus = async () => {
      return this.doFetch<ISystemGpu[]>(`${this.getBaseRoute()}/system_info/gpus`, {
        method: 'GET',
      });
    };

    getNodes = async () => {
      return this.doFetch<ISystemNode[]>(`${this.getBaseRoute()}/system_info/nodes`, {
        method: 'GET',
      });
    };

    getNode = async (node: string) => {
      return this.doFetch<ISystemNodeDetail>(`${this.getBaseRoute()}/system_info/nodes/${node}`, {
        method: 'GET',
      });
    };

    getStorage = async () => {
      return this.doFetch<ISystemStorage>(`${this.getBaseRoute()}/settings/storage`, {
        method: 'GET',
      });
    };

    updateStorage = async (data: ISystemStorage) => {
      return this.doFetch<{ message: string }>(`${this.getBaseRoute()}/settings/storage`, {
        method: 'PUT',
        data,
      });
    };

    getVideoStorageGroup = async () => {
      return this.doFetch(`${this.getBaseRoute()}/camera/video_storage_group`, {
        method: 'GET',
      });
    };

    getVideoStorageCamera = async (data: any) => {
      return this.doFetch(
        `${this.getBaseRoute()}/video_storage_camera?province=48&district=${
          data.district
        }&commune=${data.commune}&group=${data.group}&query=${data.q}&maxKeepDay=${
          data.maxKeepDay
        }&step=${data.step}`,
        {
          method: 'GET',
        },
      );
    };

    addVideoStorageGroup = async (data: any) => {
      return this.doFetch(`${this.getBaseRoute()}/video_storage_group`, {
        method: 'POST',
        data,
      });
    };
    updateVideoStorageGroup = async (data: any) => {
      return this.doFetch(`${this.getBaseRoute()}/video_storage_group`, {
        method: 'PUT',
        data,
      });
    };

    getStorageSpaceUsage = async () => {
      return this.doFetch<IStorageSpaceUsage[]>(`${this.getBaseRoute()}/system_info/mountpoints`, {
        method: 'GET',
      });
    };

    getBackupSpaceUsage = async () => {
      return this.doFetch(`${this.getBaseRoute()}/system_info/backup_space_usage`, {
        method: 'GET',
      });
    };

    getBackup = async () => {
      return this.doFetch<ISystemBackup>(`${this.getBaseRoute()}/settings/backup`, {
        method: 'GET',
      });
    };

    updateBackup = async (data: ISystemBackup) => {
      return this.doFetch<{ message: string }>(`${this.getBaseRoute()}/settings/backup`, {
        method: 'PUT',
        data,
      });
    };

    getDatabases = async (data: any) => {
      return this.doFetch<ISystemDatabaseResponse>(`${this.getBaseRoute()}/backup/databases`, {
        method: 'POST',
        data,
      });
    };

    deleteDatabase = async (id: string) => {
      return this.doFetch<{ message: string }>(`${this.getBaseRoute()}/backup/databases/${id}`, {
        method: 'DELETE',
      });
    };

    downloadDatabase = async (id: string) => {
      return this.doFetch<{ message: string }>(
        `${this.getBaseRoute()}/backup/databases/${id}/download`,
        {
          method: 'DELETE',
          headers: {
            responseType: 'blob',
          },
        },
      );
    };

    databaseDoBackup = async () => {
      return this.doFetch<{ message: string }>(
        `${this.getBaseRoute()}/backup/databases/do_backup`,
        {
          method: 'POST',
          data: {},
        },
      );
    };
  };

export default ClientSystem;
