import React from 'react';
import { Badge, Box, Typography } from '@mui/material';

import store from 'src/redux_store';
import { TStreamMode } from 'src/types/stream';
import { changeStreamMode, toggleTheaterMode } from 'src/redux_store/stream/stream_slice';
import { IPlayerProps } from '../../../types/player';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

type IColor = 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning';

interface IDotWithText {
  color?: IColor;
  text: string;
  id: TStreamMode;
  isTheaterMode: boolean;
}

const dispatch = store.dispatch;

function DotWithText(props: IDotWithText) {
  const { id, isTheaterMode, color, text } = props;
  const classes = useStyles();

  const handleClick = () => {
    dispatch(changeStreamMode(id));

    if (isTheaterMode) return;
    dispatch(toggleTheaterMode());
  };

  return (
    <Box onClick={handleClick} className={classes.root}>
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        overlap="circular"
        color={color}
        variant="dot"
        classes={{ badge: !color ? classes.disabledBadge : undefined }}
      />
      <Typography variant="body2" ml={1} color="white">
        {text}
      </Typography>
    </Box>
  );
}

function ModeOptions(props: any) {
  const slice = { ...props } as IPlayerProps;
  const { t } = useTranslation();
  if (slice.cam.classifiedListId || slice.cam.position) return null;

  const isTheaterMode = slice.isTheaterMode ? true : false;

  return (
    <Box display="flex" alignItems="center">
      <DotWithText
        text={t('stream.controlBar.mode.record')}
        id="playback"
        isTheaterMode={isTheaterMode}
      />
      <DotWithText
        text={t('stream.controlBar.mode.live')}
        id="live"
        color="error"
        isTheaterMode={isTheaterMode}
      />
    </Box>
  );
}

export default ModeOptions;
