import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConfigMapTypes } from 'src/constants/map';
import { ICamera } from 'src/types/camera';
import { ILocation } from 'src/types/common';
import {
  EStationAction,
  EWorkingMode,
  IStation,
  IStationFunctionStream,
  IUpdateStationGPS,
  WorkingSchedule,
} from 'src/types/station';
import {
  createCameraStation,
  deleteCamera,
  deleteCameraStation,
  updateCameraStationGeneral,
} from '../camera/camera_action';
import {
  createStation,
  createStationLight,
  deleteStation,
  deleteStationLight,
  getStationFunction,
  getStations,
  updateStationFunction,
  updateStationGeneral,
  updateStationLight,
  updateWorkingMode,
} from './station_action';
import {
  stationBatteryUpdatedUtil,
  stationGPSUpdatedUtil,
  stationMobileNetworkUpdatedUtil,
  updateStationBatteryDisconnectedUtil,
  updateStationMobileNetworkDisconnectedUtil,
  updateStationStatusUtil,
} from 'src/utils/station';
import {
  IStationBatteryUpdated,
  IStationNetworkUpdated,
  IStationUpdateStatus,
} from 'src/types/notification';
import { EGpsStatus } from 'src/types/enum';

interface IState {
  stations: IStation[];
  selectedStation: IStation | null;
  stationAction: EStationAction;
  isEditingLocation: boolean;
  createLocation: ILocation | null;
  updateLocation: ILocation | null;
  functions: {
    stationId: string;
    functionEnabled: boolean;
    cameras: ICamera[];
    lights: { id: string; name: string }[];
    workingMode: EWorkingMode;
    workingSchedule: WorkingSchedule;
    currentStream: IStationFunctionStream | null;
    streams: IStationFunctionStream[];

    micStatus: boolean;
    isMicAutoClick: boolean;
    speakerStatus: boolean;
    isSpeakerAutoClick: boolean;
  };
}

const initialState: IState = {
  stations: [],
  selectedStation: null,
  stationAction: EStationAction.CLOSE,
  isEditingLocation: false,
  createLocation: ConfigMapTypes.location,
  updateLocation: null,
  functions: {
    functionEnabled: false,
    stationId: '',
    cameras: [],
    lights: [],
    workingMode: EWorkingMode.FULLTIME,
    currentStream: null,
    streams: [],
    workingSchedule: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    micStatus: false,
    speakerStatus: false,
    isMicAutoClick: false,
    isSpeakerAutoClick: false,
  },
};

const StationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    setStationAction: (state: IState, action: PayloadAction<EStationAction>) => {
      state.stationAction = action.payload;
    },

    setSelectedStation: (state: IState, action: PayloadAction<IStation | null>) => {
      state.selectedStation = action.payload;
    },

    toggleStationMic: (state: IState, action: PayloadAction<{ autoClick: boolean }>) => {
      state.functions.micStatus = !state.functions.micStatus;
      state.functions.isMicAutoClick = action.payload.autoClick;
    },

    toggleStationSpeaker: (state: IState, action: PayloadAction<{ autoClick: boolean }>) => {
      state.functions.speakerStatus = !state.functions.speakerStatus;
      state.functions.isSpeakerAutoClick = action.payload.autoClick;
    },

    setIsEditingStationLocation: (state: IState, action: PayloadAction<boolean>) => {
      state.isEditingLocation = action.payload;
    },

    setCreateStationLocation: (state: IState, action: PayloadAction<ILocation>) => {
      state.createLocation = action.payload;
    },

    setUpdateStationLocation: (state: IState, action: PayloadAction<ILocation | null>) => {
      state.updateLocation = action.payload;
    },

    resetStationState: (state: IState) => {
      state.stations = initialState.stations;
      state.selectedStation = initialState.selectedStation;
      state.stationAction = initialState.stationAction;
      state.isEditingLocation = initialState.isEditingLocation;
      state.createLocation = initialState.createLocation;
      state.updateLocation = initialState.updateLocation;
    },

    resetStationFunctions: (state: IState) => {
      state.functions.isMicAutoClick = initialState.functions.isMicAutoClick;
      state.functions.isSpeakerAutoClick = initialState.functions.isSpeakerAutoClick;
      state.functions.micStatus = initialState.functions.micStatus;
      state.functions.speakerStatus = initialState.functions.speakerStatus;
    },

    deleteStationLocal: (state: IState, action: PayloadAction<string>) => {
      const stationId = action.payload;
      const currentStations = [...state.stations];
      const index = currentStations.findIndex((station) => station.id === stationId);

      if (state.selectedStation && state.selectedStation.id === stationId) {
        state.selectedStation = null;
      }

      if (index !== -1) {
        currentStations.splice(index, 1);
        state.stations = currentStations;
      }
    },

    stationBatteryUpdated: (state: IState, action: PayloadAction<IStationBatteryUpdated>) => {
      const { stationId, newBattery, timestamp } = action.payload;
      const stations = [...state.stations];

      const station = state.selectedStation ? { ...state.selectedStation } : null;
      const { stationsCurrent, currentStationInfo } = stationBatteryUpdatedUtil(stations, station, {
        stationId,
        newBattery,
        timestamp,
      });

      state.stations = stationsCurrent;
      state.selectedStation = currentStationInfo;
    },

    stationMobileNetworkUpdated: (state: IState, action: PayloadAction<IStationNetworkUpdated>) => {
      const { stationId, newNetwork, timestamp } = action.payload;
      const stations = [...state.stations];

      const station = state.selectedStation ? { ...state.selectedStation } : null;

      const { stationsCurrent, currentStationInfo } = stationMobileNetworkUpdatedUtil(
        stations,
        station,
        {
          stationId,
          newNetwork,
          timestamp,
        },
      );

      state.stations = stationsCurrent;
      state.selectedStation = currentStationInfo;
    },

    stationMobileNetworkDisconnected: (
      state: IState,
      action: PayloadAction<{ stationId: string; timestamp: number }>,
    ) => {
      const currentStations = [...state.stations];
      const { stationId, timestamp } = action.payload;
      const stationInfo = state.selectedStation ? { ...state.selectedStation } : null;
      const { stationsCurrent, currentStationInfo } = updateStationMobileNetworkDisconnectedUtil(
        currentStations,
        stationInfo,
        {
          stationId,
          timestamp,
        },
      );

      state.stations = stationsCurrent;
      state.selectedStation = currentStationInfo;
    },

    updateStationStatus: (state: IState, action: PayloadAction<IStationUpdateStatus>) => {
      const { stationId, status, timestamp } = action.payload;

      const currentStations = [...state.stations];
      const currentInfo = state.selectedStation ? { ...state.selectedStation } : null;
      const { stationsCurrent, currentStationInfo } = updateStationStatusUtil(
        currentStations,
        currentInfo,
        {
          stationId,
          status,
          timestamp,
        },
      );

      state.stations = stationsCurrent;
      state.selectedStation = currentStationInfo;
    },

    stationGpsDisconnected: (
      state: IState,
      action: PayloadAction<{ stationId: string; gpsStatus: EGpsStatus; timestamp: number }>,
    ) => {
      const { stationId, gpsStatus, timestamp } = action.payload;
      const currentStations = [...state.stations];
      if (currentStations.length) {
        const index = currentStations.findIndex((station) => station.id === stationId);

        if (index !== -1) {
          const stationInfo: IStation = {
            ...currentStations[index],
            gpsConnectionStatus: gpsStatus,
            gpsLastUpdatedAt: new Date(timestamp).toDateString(),
          };
          currentStations.splice(index, 1, stationInfo);
          state.stations = currentStations;
        }
      }
    },

    stationGPSConnected: (state: IState, action: PayloadAction<IUpdateStationGPS>) => {
      const stations = [...state.stations];
      const station = state.selectedStation ? { ...state.selectedStation } : null;
      const { stationsCurrent, currentStationInfo } = stationGPSUpdatedUtil(
        stations,
        station,
        action.payload,
        state.isEditingLocation,
      );

      state.stations = stationsCurrent;
      state.selectedStation = currentStationInfo;
    },

    stationBatteryDisconnected: (
      state: IState,
      action: PayloadAction<{ stationId: string; timestamp: number }>,
    ) => {
      const stations = [...state.stations];
      const { stationId, timestamp } = action.payload;
      const { stationsCurrent } = updateStationBatteryDisconnectedUtil(stations, null, {
        stationId,
        timestamp,
      });

      state.stations = stationsCurrent;
    },
  },
  extraReducers(builder) {
    builder.addCase(getStations.fulfilled, (state, action) => {
      state.stations = action.payload;
      if (action.payload.findIndex((i) => i.id === state.selectedStation?.id) === -1) {
        state.selectedStation = initialState.selectedStation;
      }
    });

    builder.addCase(getStations.rejected, (state) => {
      state.stations = [];
      state.selectedStation = initialState.selectedStation;
    });

    builder.addCase(createStation.fulfilled, (state, action) => {
      state.stations = [...state.stations, action.payload];
    });

    builder.addCase(updateStationGeneral.fulfilled, (state, action) => {
      const index = state.stations.findIndex((station) => station.id === action.meta.arg.stationId);

      if (index !== -1) {
        const stations = [...state.stations];

        const newStation = action.payload;
        stations.splice(index, 1, newStation);

        state.stations = stations;
        state.selectedStation = newStation;
      }
    });

    builder.addCase(deleteStation.fulfilled, (state, action) => {
      const stations = state.stations.filter((station) => station.id !== action.payload);

      state.stations = stations;
    });

    builder.addCase(getStationFunction.pending, (state) => {
      state.functions = initialState.functions;
    });

    builder.addCase(getStationFunction.fulfilled, (state, action) => {
      state.functions.cameras = action.payload?.cameras || [];
      state.functions.lights = action.payload?.lights || [];
      state.functions.stationId = action.meta.arg;
      state.functions.workingMode = action.payload.workingMode;
      state.functions.workingSchedule = action.payload.workingSchedule;
      state.functions.functionEnabled = action.payload.functionEnabled;
      state.functions.streams = action.payload.streams;
      state.functions.currentStream = action.payload.currentStream;
    });

    builder.addCase(updateWorkingMode.fulfilled, (state, action) => {
      const { data, stationId } = action.payload;

      if (state.functions.stationId === stationId) {
        state.functions.workingMode = data.mode;
        if (data.mode === EWorkingMode.SCHEDULED) {
          state.functions.workingSchedule = data.schedule;
        }
      }
    });

    builder.addCase(createStationLight.fulfilled, (state, action) => {
      state.functions.lights = [...state.functions.lights, action.payload];
    });

    builder.addCase(updateStationLight.fulfilled, (state, action) => {
      const lights = [...state.functions.lights];

      const index = lights.findIndex((item) => item.id === action.payload.id);
      lights.splice(index, 1, action.payload);

      state.functions.lights = lights;
    });

    builder.addCase(deleteStationLight.fulfilled, (state, action) => {
      const lights = state.functions.lights.filter((l) => l.id !== action.payload.lightId);
      state.functions.lights = lights;
    });

    builder.addCase(createCameraStation.fulfilled, (state, action) => {
      if (state.functions.stationId === action.meta.arg.stationId) {
        state.functions.cameras = [...state.functions.cameras, action.payload];
      }
    });

    builder.addCase(deleteCamera.fulfilled, (state, action) => {
      const cameras = state.functions.cameras.filter((cam) => cam.id !== action.payload);

      state.functions.cameras = cameras;
    });

    builder.addCase(deleteCameraStation.fulfilled, (state, action) => {
      const cameras = state.functions.cameras.filter((cam) => cam.id !== action.payload.cameraId);

      state.functions.cameras = cameras;
    });

    builder.addCase(updateCameraStationGeneral.fulfilled, (state, action) => {
      const index = state.functions.cameras.findIndex((cam) => cam.id === action.payload.cameraId);

      if (index !== -1) {
        const cameras = [...state.functions.cameras];

        cameras.splice(index, 1, {
          ...cameras[index],
          address: action.payload.data.address,
          name: action.payload.data.name,
          lat: action.payload.data.lat,
          lng: action.payload.data.lng,
        });

        state.functions.cameras = cameras;
      }
    });
    builder.addCase(updateStationFunction.fulfilled, (state, action) => {
      const newStationFunctioNEnabled = action.meta.arg.data.functionEnabled;
      const index = state.stations.findIndex((station) => station.id === action.meta.arg.stationId);

      state.functions.functionEnabled = newStationFunctioNEnabled;

      if (index !== -1) {
        const stations = [...state.stations];

        const newStation = action.payload;
        stations.splice(index, 1, newStation);

        state.stations = stations;
        state.selectedStation = newStation;
      }
    });
  },
});

export const {
  resetStationState,
  setStationAction,
  setSelectedStation,
  setCreateStationLocation,
  setIsEditingStationLocation,
  setUpdateStationLocation,
  toggleStationSpeaker,
  toggleStationMic,
  resetStationFunctions,

  //socket
  stationBatteryUpdated,
  stationMobileNetworkUpdated,
  stationMobileNetworkDisconnected,
  updateStationStatus,
  deleteStationLocal,
  stationGpsDisconnected,
  stationBatteryDisconnected,
  stationGPSConnected,
} = StationSlice.actions;

export default StationSlice.reducer;
