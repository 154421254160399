import { createSlice } from '@reduxjs/toolkit';

import { ICustomer, ICustomerData, ICustomerFilter } from 'src/types/customer';
import {
  createCustomer,
  deleteCustomer,
  filterCustomer,
  getSingleCustomer,
  inviteCustomerOwner,
  updateCustomer,
} from './actions';

interface ICustomerState {
  customer: ICustomerData;
  customerDetail: ICustomer | null;
  filter: ICustomerFilter;
  customerIdSelected: string;
}

const initialState: ICustomerState = {
  customer: {
    data: [],
    totalPage: 0,
    totalRecord: 0,
  },
  filter: {
    name: '',
    page: 1,
    limit: 20,
  },
  customerDetail: null,
  customerIdSelected: '',
};

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetSingleCustomer: (state) => {
      state.customerDetail = null;
      state.customerIdSelected = '';
    },
    changeParamsFilterCustomer: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    changeCustomerIdSelected: (state, action) => {
      state.customerIdSelected = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.customer.data.push(action.payload);
      })
      .addCase(filterCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
        state.customerDetail = null;
        state.customerIdSelected = '';
      })
      .addCase(getSingleCustomer.fulfilled, (state, action) => {
        state.customerDetail = action.payload;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.customer.data = state.customer.data.map((customer) =>
          customer.id === action.payload.id ? { ...customer, ...action.payload.data } : customer,
        );
        state.customerDetail = { ...state.customerDetail, ...(action.payload.data as any) };
      })
      .addCase(inviteCustomerOwner.fulfilled, (state, action) => {
        const { customerId, user, email, userId } = action.payload;
        const customer = state.customer.data.find((c) => c.id === customerId);

        if (customer) {
          customer.ownerInfo = { user, status: 'pending' };

          if (userId) {
            customer.ownerInfo.id = userId;
          }

          if (email) {
            customer.ownerInfo.email = email;
          }

          state.customerDetail = { ...customer };
        }
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.customer.data = state.customer.data.filter(
          (customer) => customer.id !== action.payload,
        );
        state.customerDetail = null;
      });
  },
});

const { actions, reducer } = customerSlice;

export const { resetSingleCustomer, changeParamsFilterCustomer, changeCustomerIdSelected } =
  actions;
export default reducer;
