import {
  ICamera,
  ICameraConfig,
  ICameraConfigPatch,
  ICameraFilter,
  ICameraFunction,
  ITransferCameraInfo,
  ITransferCameraInfoConfig,
  IUpdateUserCameras,
  TCameraGeneral,
} from 'src/types/camera';
import ClientBase from './base';
import { INotification } from 'src/types/notification';

export interface ClientCameraMix {
  createCamera: (data: TCameraGeneral) => Promise<ICamera>;
  createCameraStation: (stationId: string, data: TCameraGeneral) => Promise<ICamera>;
  getCameras: (params: ICameraFilter) => Promise<ICamera[]>;
  getCamerasOfConfigs: (params: ICameraFilter) => Promise<ICamera[]>;
  getCamerasByStationId: (stationId: string) => Promise<ICamera[]>;
  getCameraGeneral: (cameraId: string) => Promise<TCameraGeneral>;
  getCameraConfig: (cameraId: string) => Promise<ICameraConfig>;
  getCameraFunction: (cameraId: string) => Promise<ICameraFunction>;
  getCameraStationGeneral: (cameraId: string, stationId: string) => Promise<TCameraGeneral>;
  getCameraStationConfig: (cameraId: string, stationId: string) => Promise<ICameraConfig>;
  getCameraStationFunction: (cameraId: string, stationId: string) => Promise<ICameraFunction>;
  getCameraLogs: (
    cameraId: string,
    params: { after: string; limit: number },
  ) => Promise<INotification[]>;
  updateCameraGeneral: (cameraId: string, data: TCameraGeneral) => Promise<string>;
  updateCameraStationGeneral: (
    cameraId: string,
    stationId: string,
    data: TCameraGeneral,
  ) => Promise<string>;
  updateCameraConfig: (cameraId: string, data: ICameraConfigPatch) => Promise<string>;
  updateCameraStationConfig: (
    cameraId: string,
    stationId: string,
    data: ICameraConfigPatch,
  ) => Promise<string>;
  updateCameraFunction: (cameraId: string, data: Partial<ICameraFunction>) => Promise<string>;
  updateCameraStationFunction: (
    cameraId: string,
    stationId: string,
    data: Partial<ICameraFunction>,
  ) => Promise<string>;
  deleteCamera: (cameraId: string) => Promise<string>;

  getFilterInfoOfCameras: ({
    userId,
    query,
  }: {
    userId: string;
    query?: string;
  }) => Promise<ITransferCameraInfo[]>;
  getConfigInfoOfCameras: (userId: string) => Promise<ITransferCameraInfoConfig>;
  getInfoOfUserCameraList: (userId: string) => Promise<ICamera[]>;
  updateUserCameras: (data: { userId: string; cameraInfo: IUpdateUserCameras }) => Promise<any>;
  deleteCameraStation: (cameraId: string, stationId: string) => Promise<string>;

  refreshSnapshot: (cameraId: string) => Promise<string>;
}

const ClientCamera = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientCameraMix {
    createCamera = async (data: TCameraGeneral) => {
      return this.doFetch<ICamera>(`${this.getSystemCameraRoute()}`, {
        method: 'POST',
        data,
      });
    };

    createCameraStation = async (stationId: string, data: TCameraGeneral) => {
      return this.doFetch<ICamera>(`${this.getStationRoute()}/${stationId}/camera`, {
        method: 'POST',
        data,
      });
    };

    getCameras = async (params: ICameraFilter) => {
      return this.doFetch<ICamera[]>(`${this.getSystemCameraRoute()}`, {
        method: 'GET',
        params,
      });
    };

    getCamerasOfConfigs = async (params: ICameraFilter) => {
      return this.doFetch<ICamera[]>(`${this.getSystemCameraRoute()}/configs`, {
        method: 'GET',
        params,
      });
    };

    getCamerasByStationId = async (stationId: string) => {
      return this.doFetch<ICamera[]>(`${this.getStationRoute()}/${stationId}/cameras`, {
        method: 'GET',
      });
    };

    getCameraGeneral = async (cameraId: string) => {
      return this.doFetch<TCameraGeneral>(`${this.getSystemCameraRoute()}/${cameraId}/general`, {
        method: 'GET',
      });
    };

    getCameraConfig = async (cameraId: string) => {
      return this.doFetch<ICameraConfig>(`${this.getSystemCameraRoute()}/${cameraId}/config`, {
        method: 'GET',
      });
    };

    getCameraFunction = async (cameraId: string) => {
      return this.doFetch<ICameraFunction>(`${this.getSystemCameraRoute()}/${cameraId}/function`, {
        method: 'GET',
      });
    };

    getCameraStationGeneral = async (cameraId: string, stationId: string) => {
      return this.doFetch<TCameraGeneral>(
        `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/general`,
        {
          method: 'GET',
        },
      );
    };

    getCameraStationConfig = async (cameraId: string, stationId: string) => {
      return this.doFetch<ICameraConfig>(
        `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/config`,
        {
          method: 'GET',
        },
      );
    };

    getCameraStationFunction = async (cameraId: string, stationId: string) => {
      return this.doFetch<ICameraFunction>(
        `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/function`,
        {
          method: 'GET',
        },
      );
    };

    getCameraLogs = async (cameraId: string, params: { after: string; limit: number }) => {
      return this.doFetch<INotification[]>(`${this.getBaseRoute()}/cameras/${cameraId}/logs`, {
        method: 'GET',
        params,
      });
    };

    updateCameraGeneral = async (cameraId: string, data: TCameraGeneral) => {
      return this.doFetch<string>(`${this.getSystemCameraRoute()}/${cameraId}/general`, {
        method: 'PUT',
        data,
      });
    };

    updateCameraStationGeneral = async (
      cameraId: string,
      stationId: string,
      data: TCameraGeneral,
    ) => {
      return this.doFetch<string>(
        `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/general`,
        {
          method: 'PUT',
          data,
        },
      );
    };

    updateCameraConfig = async (cameraId: string, data: ICameraConfigPatch) => {
      return this.doFetch<string>(`${this.getSystemCameraRoute()}/${cameraId}/config`, {
        method: 'PUT',
        data,
      });
    };

    updateCameraStationConfig = async (
      cameraId: string,
      stationId: string,
      data: ICameraConfigPatch,
    ) => {
      return this.doFetch<string>(
        `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/config`,
        {
          method: 'PUT',
          data,
        },
      );
    };

    updateCameraFunction = async (cameraId: string, data: Partial<ICameraFunction>) => {
      return this.doFetch<string>(`${this.getSystemCameraRoute()}/${cameraId}/function`, {
        method: 'PUT',
        data,
      });
    };

    updateCameraStationFunction = async (
      cameraId: string,
      stationId: string,
      data: Partial<ICameraFunction>,
    ) => {
      return this.doFetch<string>(
        `${this.getStationRoute()}/${stationId}/cameras/${cameraId}/function`,
        {
          method: 'PUT',
          data,
        },
      );
    };

    deleteCamera = async (cameraId: string) => {
      return this.doFetch<string>(`${this.getSystemCameraRoute()}/${cameraId}`, {
        method: 'DELETE',
      });
    };

    getFilterInfoOfCameras = async ({ userId, query }: { userId: string; query?: string }) => {
      return this.doFetch<ITransferCameraInfo[]>(
        `${this.getSystemCameraRoute()}/${userId}/manage`,
        {
          method: 'GET',
          params: query,
        },
      );
    };

    getConfigInfoOfCameras = async (userId: string) => {
      return this.doFetch<ITransferCameraInfoConfig>(
        `${this.getUserRoute()}/${userId}/cameras/config`,
        {
          method: 'GET',
        },
      );
    };

    getInfoOfUserCameraList = async (userId: string) => {
      return this.doFetch<ICamera[]>(`${this.getUserRoute()}/${userId}/cameras/filter`, {
        method: 'GET',
      });
    };

    updateUserCameras = async (data: { userId: string; cameraInfo: IUpdateUserCameras }) => {
      return this.doFetch<any>(`${this.getSystemCameraRoute()}/${data.userId}/manage_config`, {
        method: 'PUT',
        data: data.cameraInfo,
      });
    };
    deleteCameraStation = async (cameraId: string, stationId: string) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}/camera/${cameraId}`, {
        method: 'DELETE',
      });
    };

    refreshSnapshot = async (cameraId: string) => {
      return this.doFetch<string>(`${this.getSystemCameraRoute()}/${cameraId}/refresh_snapshot`, {
        method: 'PUT',
      });
    };
  };

export default ClientCamera;
