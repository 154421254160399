import React, { useEffect, useState } from 'react';
import DialogWrapper from '../dialog_wrapper';
import { MODAL_IDS } from 'src/constants/modal';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { logoutLocal } from 'src/redux_store/my_account/my_account_slice';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import autoLogoutImage from 'src/assets/images/auto_logout.png';
import { useTranslation } from 'react-i18next';

function ModalAutoLogout() {
  const [timeCount, setTimeCount] = useState<number>(10);
  const dispatch = useAppDispatch();
  const {
    autoLogout: { type },
  } = useAppSelector((state) => state.myAccountSlice);

  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeCount = timeCount - 1;
      if (newTimeCount === 0) {
        handleLogout();
      } else {
        setTimeCount(newTimeCount);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeCount]);

  const handleLogout = () => {
    dispatch(logoutLocal());
    dispatch(closeModal({ modalId: MODAL_IDS.autoLogout }));
  };

  const renderTitle = () => {
    if (type === 'update_role') return t('label.system.autoLogout.updateRole');

    return t('label.system.autoLogout.another');
  };

  return (
    <DialogWrapper
      isFullHeight
      isFullWidth
      isShowIconClose={false}
      isNotAutoClose
      modalId={MODAL_IDS.autoLogout}
      sx={{ height: '100%', width: '100%' }}
      maxWidthDialog={'lg'}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Box
            width="100%"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img width="40%" alt="Danapod" src={autoLogoutImage} />
          </Box>
          <Box>
            <Typography textAlign="center" fontWeight={600}>
              {renderTitle()}
            </Typography>
          </Box>

          <Box>
            <Typography textAlign="center">
              {t('label.system.autoLogout.main', { timeCount })}
            </Typography>
          </Box>

          <Box mt={1} textAlign="center">
            <Button onClick={handleLogout} variant="contained">
              {t('label.logout.main')}
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogWrapper>
  );
}

export default ModalAutoLogout;
