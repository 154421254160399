import { AutorenewSharp, Payment } from '@mui/icons-material'
import {
  Box,
  Chip,
  Button,
  Typography,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  TableContainer,
} from '@mui/material'
import moment from 'moment'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'src/redux_store'
import { getOrganizationSubscription, listSubscriptionPayments } from 'src/redux_store/organizations/slice'
import { RequestStatusOptions, SubscriptionItemStatuses } from 'src/types'
import BorderLinearProgress from 'src/components/border_linear_progress'
import { getSubscriptionPlan, isExpiredSubscription } from '../utils'
// import { ModalIdentifiers } from 'src/constants/modal'
// import SubscriptionPaymentModal from '../subscription_payment_modal'
// import { openModal } from 'src/redux_store/common/modal/modal_slice'
import SubscriptionPayments from './subscription_payments'
import { useTranslation } from 'react-i18next'

interface Props {
  orgId: string
  subscriptionId: string
}

const Info = ({ subscriptionId, orgId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { stationSubscriptions, requests, subscriptionPlans } = useAppSelector((state) => state.organizations)
  const subscription = stationSubscriptions.find((sub) => sub.id === subscriptionId)

  useEffect(() => {
    dispatch(getOrganizationSubscription({ orgId, subscriptionId }))
    dispatch(listSubscriptionPayments({ orgId, subscriptionId }))
  }, [dispatch, orgId, subscriptionId])

  if (requests.getOrganizationSubscription.status === RequestStatusOptions.PENDING) {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="100%">
        <CircularProgress />
      </Box>
    )
  }

  if (requests.getOrganizationSubscription.status === RequestStatusOptions.REJECTED) {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="100%">
        <Typography variant="h6">Something wrong</Typography>
      </Box>
    )
  }

  if (!subscription) {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="100%">
        <Typography variant="h6">Something wrong</Typography>
      </Box>
    )
  }

  // const handlePayment = () => {
  //   dispatch(
  //     openModal({
  //       modalId: ModalIdentifiers.SUBSCRIPTION_PAYMENT,
  //       dialogComponent: <SubscriptionPaymentModal orgId={orgId} subscription={subscription} />,
  //     })
  //   )
  // }

  const subPlan = getSubscriptionPlan(subscriptionPlans, subscription?.items?.[0]?.subscriptionPlanId)

  if (!subPlan) return null
  return (
    <Box>
      <Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          {/* {isExpiredSubscription(subscription) && (
            <Box mr={1}>
              <Button variant="contained" startIcon={<AutorenewSharp />}>
                {t('subscription.renew')}
              </Button>
            </Box>
          )}
          {subscription.items.length === 1 && subscription.items[0].status === SubscriptionItemStatuses.UNPAID && (
            <Box mr={1}>
              <Button variant="contained" startIcon={<Payment />} onClick={handlePayment}>
                {t('subscription.payment')}
              </Button>
            </Box>
          )} */}
          {/* <Button variant="contained" startIcon={<AutorenewSharp />}>
            Renew
          </Button> */}
        </Box>
        <Box mb={1}>
          <Typography variant="h6">{t('subscription.details')}</Typography>
        </Box>
        <TableContainer sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={600}>{t('subscription.plan')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{t('subscription.stations')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{t('subscription.subscription_plan')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{t('subscription.order_time')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{t('subscription.start_time')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{t('subscription.expired_time')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{t('subscription.status')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Chip label={subPlan.plan.name} size="small" variant="outlined" />
                </TableCell>
                <TableCell>
                  <Typography
                    fontWeight={600}
                    textAlign={'end'}
                  >{`${subscription.metadata['filledStations']} / ${subscription.metadata['stations']}`}</Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    value={(subscription.metadata['filledStations'] / subscription.metadata['stations']) * 100}
                  />
                </TableCell>
                <TableCell>
                  {subPlan.interval === 1 && <Chip label={t('subscription.Month')} size="small" variant="outlined" />}
                  {subPlan.interval === 2 && <Chip label={t('subscription.Year')} size="small" variant="outlined" />}
                </TableCell>
                <TableCell>
                  <Typography>{moment(subscription.createdAt).format('HH:mm:ss DD-MM-YYYY')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {subscription.currentPeriodStart
                      ? moment(subscription.currentPeriodStart).format('HH:mm:ss DD-MM-YYYY')
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {subscription.currentPeriodEnd
                      ? moment(subscription.currentPeriodEnd).format('HH:mm:ss DD-MM-YYYY')
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  {subscription.currentPeriodEnd && new Date(subscription.currentPeriodEnd) < new Date() && (
                    <Chip label="Expired" size="small" color="error" />
                  )}
                  {subscription.items.length === 1 &&
                    subscription.items[0].status === SubscriptionItemStatuses.UNPAID && (
                      <Chip label="Unpaid" size="small" color="warning" />
                    )}
                  {!!subscription.items.length &&
                    (subscription.items[0].status === SubscriptionItemStatuses.WAITING ||
                      subscription.items[0].status === SubscriptionItemStatuses.IN_SERIVCE) && (
                      <Chip label="Active" size="small" color="success" />
                    )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box py={2}>
        <Box mb={1}>
          <Typography variant="h6">{t('subscription.transaction_history')}</Typography>
        </Box>
        <SubscriptionPayments orgId={orgId} subscriptionId={subscriptionId} />
      </Box>
    </Box>
  )
}

export default Info
