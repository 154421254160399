import React from 'react';
import { VisibilityOffOutlined } from '@mui/icons-material';

import i18n from 'src/i18n';
import { IPlayerProps } from '../../../types/player';
import ButtonWrapper from '../button_wrapper';

function FollowToggle(props: any) {
  const { controlBar } = props as IPlayerProps;
  const { t } = i18n;

  if (!controlBar?.follow) return null;

  const { unFollow } = controlBar.follow;

  const controlText = t('stream.controlBar.unFollow');

  const handleClick = () => unFollow();

  return (
    <ButtonWrapper
      title={controlText}
      icon={VisibilityOffOutlined}
      handleClick={handleClick}
      disablePortal
    />
  );
}

export default FollowToggle;
