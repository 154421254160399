import {
  AccountTree,
  AccountTreeOutlined,
  Apps,
  AppsOutlined,
  AssignmentInd,
  AssignmentIndOutlined,
  BusinessCenterOutlined,
  Groups,
  GroupsOutlined,
  Map,
  MapOutlined,
  VideoSettings,
  VideoSettingsOutlined,
  // Videocam,
  // VideocamOutlined,
} from '@mui/icons-material'
import { EPermissions } from 'src/types/enum'

import { IMenuRoute } from 'src/types/route'
import { PATH } from './path'

export const MENU_LIST: IMenuRoute[] = [
  {
    id: 'organization',
    path: '/organizations',
    icon: BusinessCenterOutlined,
    activeIcon: BusinessCenterOutlined,
    isHaveMenu: true,
    permission: [EPermissions.GET_LIST_CUSTOMER],
  },
  // {
  //   id: 'routes.customerManagement',
  //   icon: AssignmentIndOutlined,
  //   activeIcon: AssignmentInd,
  //   path: PATH.customerManagement,
  //   permission: [EPermissions.GET_LIST_CUSTOMER],
  //   isHaveMenu: true,
  // },
  {
    id: 'routes.usersManagement',
    path: PATH.userManagement,
    icon: GroupsOutlined,
    activeIcon: Groups,
    isHaveMenu: true,
    permission: [EPermissions.LIST_USER],
  },

  // {
  //   id: 'common.routes.cameraMap',
  //   path: PATH.cameraMap,
  //   icon: VideocamOutlined,
  //   activeIcon: Videocam,
  //   isHaveMenu: true,
  //   permission: [
  //     EPermissions.GET_LIST_CUSTOMER,
  //     EPermissions.GET_LIST_STATION,
  //     EPermissions.GET_LIST_CAMERA,
  //   ],
  // },

  {
    id: 'routes.stationMap',
    path: PATH.stationMap,
    icon: MapOutlined,
    activeIcon: Map,
    isHaveMenu: true,
    permission: [EPermissions.GET_LIST_CUSTOMER, EPermissions.GET_LIST_STATION, EPermissions.GET_LIST_CAMERA],
  },
  {
    id: 'routes.watchList',
    icon: AppsOutlined,
    activeIcon: Apps,
    path: PATH.watchList,
    isHaveMenu: true,
    permission: [EPermissions.GET_LIST_CUSTOMER, EPermissions.GET_LIST_STATION, EPermissions.GET_LIST_CAMERA],
  },

  {
    id: 'routes.stationManagement',
    path: PATH.stationManagement,
    icon: AccountTreeOutlined,
    activeIcon: AccountTree,
    isHaveMenu: true,
    permission: [EPermissions.GET_LIST_STATION],
  },
  {
    id: 'routes.cameraManagement',
    path: PATH.cameraManagement,
    icon: VideoSettingsOutlined,
    activeIcon: VideoSettings,
    isHaveMenu: true,
    permission: [EPermissions.GET_LIST_CAMERA],
  },
]
